//IssueWork.js

import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';

import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as IssuesActions } from './IssuesStore';

import { useScroller } from '../../carcass/common/MyHooks';
import Loading from '../../carcass/common/Loading';
import GridView from './GridView';
import LinkColumn from './LinkColumn';
import CustomColumn from './CustomColumn';
import CheckboxColumn from './CheckboxColumn';
import {issueDetailTypes, issueDetailTypesGeoNames, issueDetailTableNames} from './IssueDetails';



const IssueWork = (props) => {

  const {alert, oneIssueLoading, issuesRepo, showIssueDetailRows, insideChanging, 
    savedIssueDetailLine, loadingIssueDetailRows, 
    filterSortRepo, 
    //settingFilterSort, 
    filterSortIds,
    tabWindowId, 
    getOneIssueById, checkDetail, changeIssueDetailsOffsetAndShowRows, loadIssueDetails, 
    createIssueDetailsFilterSort, saveIssueDetailLine
  } = props;
  //console.log("IssueWork props=", props);

  // const defaultShowRowsCount = 10;

  const [curIssueIdVal, setCurIssueIdVal] = useState(null); //1. იდენტიფიკატორი

  const { issueId } = props.match.params;


  const [curscrollTo, backLigth] = useScroller(savedIssueDetailLine);



  // const detailsRowsCount = 10;

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const issueIdVal = issueId ? parseInt(issueId) : 0;
    //console.log("PhoneticsTypeEdit useEffect issueIdVal=", issueIdVal);

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curIssueIdVal !== issueIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurIssueIdVal(issueIdVal);
      if (issueIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneIssueById(issueIdVal);
        return;
      }
      return;
    }

  }, [curIssueIdVal, issueId, getOneIssueById]);

  if (oneIssueLoading) {
    return (<Loading />);
  }

  if (!issuesRepo || !issuesRepo[curIssueIdVal]) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  const oneIssue = issuesRepo[curIssueIdVal];

  //console.log("IssueWork curIssueIdVal=", curIssueIdVal);
  //console.log("IssueWork issuesRepo[curIssueIdVal]=", issuesRepo[curIssueIdVal]);
  //console.log("IssueWork oneIssue=", oneIssue);

  const strFormat = 'YYYY-MM-DDTHH:mm:ss';


  const detailsGridColumns = [];


  detailsGridColumns[issueDetailTypes.notes] = [
    {caption: "#", visible: true, sortable: true, fieldName: "isdId", isKey: true,
      control: (<CustomColumn onGetCell={(value, record)=>{
        return (<span>#{oneIssue.issId}-N-{value}</span>);
      }} > </CustomColumn>)},
    {caption: "შენიშვნა", visible: true, fieldName: "note"},
  ];




  detailsGridColumns[issueDetailTypes.roots] = [
    {caption: "#", visible: true, sortable: true, fieldName: "isdId", isKey: true,
      control: (<CustomColumn onGetCell={(value, record)=>{
        return (<span>#{oneIssue.issId}-R-{value}</span>);
      }} > </CustomColumn>)},
    {caption: "ბმული", visible: true, sortable: true, fieldName: "rootName", 
      control: (<CustomColumn onGetCell={(value, record, index, offset, showRows)=>{
        return (
          <span>
            <Link to={`/root/${record.rootId}`} onClick={(e) => {
              // e.preventDefault(); ეს საჭირო არ არის, რადგან კლინკზე აღარ გადადის
              //ვინახავთ სიაში მიმდინარე ადგილს, საიდანაც მოხდა ამ ბმულზე გადასვლა. იმისათვის, რომ "სიაში დაბრუნება" ღილაკის გამოყენებით მოხდეს უკან დაბრუნება
              saveIssueDetailLine(curIssueIdVal, issueDetailTypes.roots, record.isdId, index, offset, showRows);  
            }}> {" "}
              <FontAwesomeIcon icon="folder-open" size="2x" /> {" "}
            </Link>
            <Link to={`/rootEdit/${record.rootId}`} onClick={(e) => {
              // e.preventDefault(); ეს საჭირო არ არის, რადგან კლინკზე აღარ გადადის
              //ვინახავთ სიაში მიმდინარე ადგილს, საიდანაც მოხდა ამ ბმულზე გადასვლა. იმისათვის, რომ "სიაში დაბრუნება" ღილაკის გამოყენებით მოხდეს უკან დაბრუნება
              saveIssueDetailLine(curIssueIdVal, issueDetailTypes.roots, record.isdId, index, offset, showRows);  
            }}>
              {record.rootName}
              {(record.rootHomonymIndex > 0) && <sup>{record.rootHomonymIndex}</sup>}
              {(record.rootNote) && <span> {record.rootNote}</span>}
            </Link>
          </span>
        ); }} > </CustomColumn>)},
      {caption: "შენიშვნა", visible: true, fieldName: "note"},
      {caption: "თოლია", visible: true, sortable: false, fieldName: "checked", changingFieldName: "checkedChanging",
      control: (<CheckboxColumn idFieldName="isdId" onChangeValue={(id, checkedValue)=>{
        checkDetail(issueId, issueDetailTypes.roots, id, checkedValue);
      }}></CheckboxColumn>)},


  ];



  detailsGridColumns[issueDetailTypes.derivationBranches] = [
    {caption: "#", visible: true, sortable: true, fieldName: "isdId", isKey: true,
      control: (<CustomColumn onGetCell={(value, record)=>{
        return (<span>#{oneIssue.issId}-D-{value}</span>);
      }} > </CustomColumn>)},
    {caption: "ბმული", visible: true, sortable: true, fieldName: "dbrBaseName", 
      control: (<LinkColumn linkBase="derivEdit" idFieldName="derivationBranchId" onClick={(record, index, offset, showRows) => {
        //ვინახავთ სიაში მიმდინარე ადგილს, საიდანაც მოხდა ამ ბმულზე გადასვლა. იმისათვის, რომ "სიაში დაბრუნება" ღილაკის გამოყენებით მოხდეს უკან დაბრუნება
        saveIssueDetailLine(curIssueIdVal, issueDetailTypes.derivationBranches, record.isdId, index, offset, showRows);  
      }}></LinkColumn>)},
    {caption: "შენიშვნა", visible: true, sortable: true, fieldName: "note"},
    {caption: "ძირები", visible: true, sortable: false, fieldName: "derivationBranchId", 
      control: (<CustomColumn onGetCell={(value, record, index, offset, showRows)=>{
        if ( record && record.roots && record.roots.length > 0 ) {
          return record.roots.map((rootItem, indx)=>{
            return (
              <span key={indx}>{indx>0 && "; "}
                <Link to={`/root/${rootItem.rootId}/${value}`} onClick={(e) => {
                  // e.preventDefault(); ეს საჭირო არ არის, რადგან კლინკზე აღარ გადადის
                  //ვინახავთ სიაში მიმდინარე ადგილს, საიდანაც მოხდა ამ ბმულზე გადასვლა. იმისათვის, რომ "სიაში დაბრუნება" ღილაკის გამოყენებით მოხდეს უკან დაბრუნება
                  saveIssueDetailLine(curIssueIdVal, issueDetailTypes.derivationBranches, record.isdId, index, offset, showRows);  
                }}>
                  {rootItem.rootName}
                  {(rootItem.rootHomonymIndex > 0) && <sup>{rootItem.rootHomonymIndex}</sup>}
                  {(rootItem.rootNote) && <span> {rootItem.rootNote}</span>}
                </Link>
              </span>
            );
          });
        }
        return value;
      }} > </CustomColumn>)},

    {caption: "თოლია", visible: true, sortable: false, fieldName: "checked", changingFieldName: "checkedChanging",
      control: (<CheckboxColumn idFieldName="isdId" onChangeValue={(id, checkedValue)=>{
        checkDetail(issueId, issueDetailTypes.derivationBranches, id, checkedValue);
      }}></CheckboxColumn>)},
  ];



  detailsGridColumns[issueDetailTypes.inflections] = [
    {caption: "#", visible: true, sortable: true, fieldName: "isdId", isKey: true,
      control: (<CustomColumn onGetCell={(value, record)=>{
        return (<span>#{oneIssue.issId}-I-{value}</span>);
      }} > </CustomColumn>)},
    {caption: "ბმული", visible: true, sortable: true, fieldName: "inflectionId", 
      control: (<CustomColumn onGetCell={(value, record)=>{
        return (<Link to={`/inflEdit/${value}`} >
        {record.infName} ({record.infSamples})
          </Link>);
      }} > </CustomColumn>)},

    {caption: "შენიშვნა", visible: true, sortable: true, fieldName: "note"},

    {caption: "დერივაცია-ძირი", visible: true, sortable: false, fieldName: "derivationBranches", 
      control: (<CustomColumn onGetCell={(value, record)=>{
        if ( record && record.derivationBranches && record.derivationBranches.length > 0 ) {
          return record.derivationBranches.map((derItem,indx)=>{
            return (
              <span key={indx}>{indx>0 && "; "}
                <Link to={`/root/${derItem.rootId}/${derItem.derivationBranchId}`} >
                  {derItem.dbrBaseName} - 
                  {derItem.rootName}
                  {(derItem.rootHomonymIndex > 0) && <sup>{derItem.rootHomonymIndex}</sup>}
                  {(derItem.rootNote) && <span> {derItem.rootNote}</span>}
                </Link>
              </span>
            );
          });
        }
        return value;
      }} > </CustomColumn>)},


      {caption: "თოლია", visible: true, sortable: false, fieldName: "checked", changingFieldName: "checkedChanging",
      control: (<CheckboxColumn idFieldName="isdId" onChangeValue={(id, checkedValue)=>{
        checkDetail(issueId, issueDetailTypes.inflections, id, checkedValue);
      }}></CheckboxColumn>)},

  ];


  return (
    <div>
      <h5><strong>საკითხი:</strong> #{oneIssue.issId}-{oneIssue.issTitle}</h5>
      <h6>
        <strong>ავტორი: </strong> {oneIssue.issCreatorUserName}. 
        <strong> მიწერა: </strong> {oneIssue.issAssignedUserName ?? "საკითხი არავისზე არ არის მიწერილი"}
        <strong> შეიქმნა: </strong> {moment(oneIssue.issCreateDate).format(strFormat)}. 
        <strong> განახლება: </strong> {moment(oneIssue.issUpdateDate).format(strFormat)}. 
      </h6>
      {!!oneIssue.issDescription && <p><strong> მოკლე აღწერა: </strong>{oneIssue.issDescription}</p>}
      <p><strong> საკითხის ტიპი: </strong>{oneIssue.issueKindName}
        <strong> პრიორიტეტი: </strong>{oneIssue.issuePriorityName}
        <strong> სტატუსი: </strong>{oneIssue.issueStatusName}</p>
      {/* <p>
        { !!oneIssue.detailsCount && <span><strong> შენიშვნები: </strong>{oneIssue.detailsCount}</span>}
        { !!oneIssue.rootsDetailsCount && <span><strong> ძირები: </strong>{oneIssue.rootsDetailsCount}</span>}
        { !!oneIssue.derivationBranchesDetailsCount && <span><strong> დერივაცია: </strong>{oneIssue.derivationBranchesDetailsCount}</span>}
        { !!oneIssue.inflectionsDetailsCount && <span><strong> ფლექსია: </strong>{oneIssue.inflectionsDetailsCount}</span>}
      </p>         */}


      {Object.values(issueDetailTypes).filter(f=>oneIssue.detailsCounts[f] /*&& f in oneIssue && oneIssue[f]*/).map((detName) => {return (
        <div key={detName} >
          <p><span><strong> {issueDetailTypesGeoNames[detName]}: </strong>{oneIssue.detailsCounts[detName]}</span></p>
          <GridView showCountColumn columns={detailsGridColumns[detName]} rows={oneIssue[detName]} 
            offset={oneIssue.offset[detName]} showRows={showIssueDetailRows[detName]}
            allRowsCount={oneIssue.detailsCounts[detName]}
            onChangeOffsetAndShowRows={(offset, showRows) => changeIssueDetailsOffsetAndShowRows(curIssueIdVal, detName, offset, showRows) }
            onLoad={(offset, rowsCount, reLoad) => loadIssueDetails(issueId, detName, offset, rowsCount, reLoad)}
            onFilterSortChange={(filterFields, sortFields, rowCount) => 
              createIssueDetailsFilterSort({tabWindowId, tableName: issueDetailTableNames[detName], filterByFields: filterFields ?? [], sortByFields: sortFields ?? [] }, issueId, detName, rowCount)} 
            loading={loadingIssueDetailRows[detName]}
            filterSortObject={filterSortRepo[issueDetailTableNames[detName]]}
            // settingFilterSort={settingFilterSort[issueDetailTableNames[detName]]}
            filterSortId={filterSortIds[issueDetailTableNames[detName]]} 
            curscrollTo={(curscrollTo?.detailsName === detName) ? curscrollTo : null} backLigth={backLigth} >
          </GridView>
        </div>
      );})}

      
      {insideChanging && <span>/</span>}
    </div>
  );
}


function mapStateToProps(state) {

  const alert = state.alert;
  const masterData = state.masterData;
  const {isMenuLoading, flatMenu} = state.navMenu;
  const { oneIssueLoading, issuesRepo, showIssueDetailRows, insideChanging, 
    savedIssueDetailLine, loadingIssueDetailRows, 
    filterSortRepo, 
    //settingFilterSort, 
    filterSortIds 
  } = state.issuesStore;
  const {tabWindowId} = state.authentication;


  return {alert, isMenuLoading, flatMenu, masterData, oneIssueLoading, issuesRepo, 
    showIssueDetailRows, insideChanging, savedIssueDetailLine, loadingIssueDetailRows, 
    filterSortRepo, 
    //settingFilterSort, 
    filterSortIds, 
    tabWindowId };

}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    getOneIssueById: (issueId) => dispatch(IssuesActions.getOneIssueById(issueId)),
    checkDetail: (issueId, detailsName, id, checkedValue) => dispatch(IssuesActions.checkDetail(issueId, detailsName, id, checkedValue)),
    changeIssueDetailsOffsetAndShowRows: (issueId, detailsName, offset, showRows) => dispatch(IssuesActions.changeIssueDetailsOffsetAndShowRows(issueId, detailsName, offset, showRows)),
    loadIssueDetails: (issueId, detailsName, offset, showRows) => dispatch(IssuesActions.loadIssueDetails(issueId, detailsName, offset, showRows)),
    createIssueDetailsFilterSort: (filterSortObject, issueId, detailsName, rowCount) => dispatch(IssuesActions.createIssueDetailsFilterSort(filterSortObject, issueId, detailsName, rowCount)),
    //saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    saveIssueDetailLine: (issueId, detailsName, isdId, index, offset, showRows) => dispatch(IssuesActions.saveIssueDetailLine(issueId, detailsName, isdId, index, offset, showRows))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueWork);
