//CreateAfterDominantPersonMarkers.js

import React, {useEffect, useMemo, useCallback} from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap'

import Loading from '../../carcass/common/Loading';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';
import NameListEditor from './NameListEditor';
import { checkDataLoaded } from '../../carcass/modules/CheckDataLoaded'
import MdListView from '../../carcass/masterdata/MdListView';


const CreateAfterDominantPersonMarkers = (props) => {

  const { alert, isMenuLoading, flatMenu, masterData, loadMultipleListData} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = props.masterData;

  const { actantGrammarCasesByActantTypes, actantTypesByVerbTypes, dominantActants, afterDominantPersonMarkers, 
    actantGrammarCases, actantGroups, actantPositions, actantTypes, verbNumbers, verbPersons, 
    verbSeries, verbTransitions, verbTypes } = props.masterData.mdRepo;

  //console.log("CreateAfterDominantPersonMarkers props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["actantGrammarCases", "actantGroups", 
    "actantPositions", "actantTypes", "verbNumbers", "verbPersons", "verbSeries", "verbTransitions", "verbTypes"], []);

  const listTableNames = useMemo(() => ["actantGrammarCasesByActantTypes", "actantTypesByVerbTypes", 
    "dominantActants", "afterDominantPersonMarkers"], []);//

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

      loadMultipleListData(listTableNames, tableNamesForLoad);
  }, [isMenuLoading, flatMenu, isValidPage, loadMultipleListData, listTableNames, tableNamesForLoad]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recId: NzInt(props.match.params.recName), 
    recName: props.match.params.recName
  });

  function saveReturnPageName() {
    props.saveReturnPageName(props.match.url.split('/')[1]);
  }

  const checkedDataTypes =[];
  const GridRules =[];
  let allListsLoaded = false;

  if ( ! mdWorkingOnLoad )  {
    allListsLoaded = true;
    listTableNames.forEach(listTableName => {
      const checkResult = checkDataLoaded(masterData, listTableName, listTableName);
      if (checkResult) {
        const { dataType, gridRules } = checkResult;
        checkedDataTypes[listTableName] = dataType;
        GridRules[listTableName] = gridRules;
      }
      else
        allListsLoaded = false;
    });
  }
  //console.log("CreateAfterDominantPersonMarkers allListsLoaded=", allListsLoaded);
  //debugger;

  //console.log("CreateAfterDominantPersonMarkers before check load allListsLoaded=", allListsLoaded);
  //console.log("CreateAfterDominantPersonMarkers before check load curscrollTo=", curscrollTo);

  if (mdWorkingOnLoad || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }


  if (!allListsLoaded || !curscrollTo || 
    !actantGrammarCases || !actantGrammarCasesByActantTypes || !actantGroups || !actantPositions || !actantTypes || 
    !actantTypesByVerbTypes || !dominantActants || !verbNumbers || !verbPersons || 
    !verbSeries || !verbTransitions || !verbTypes || !afterDominantPersonMarkers || !datatypes ) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }




  const verbTypesDict = {};
  
  verbTypes.forEach(element => {
    verbTypesDict[element.vtpId] = element;
  });

  const actantTypesDict = {};
  
  actantTypes.forEach(element => {
    actantTypesDict[element.attId] = element;
  });

  const verbSeriesDict = {};
  
  verbSeries.forEach(element => {
    verbSeriesDict[element.vsrId] = element;
  });



  const actantGroupsDict = {};
  
  actantGroups.forEach(element => {
    actantGroupsDict[element.agrId] = element;
  });

  const verbPersonsDict = {};
  
  verbPersons.forEach(element => {
    verbPersonsDict[element.vprId] = element;
  });


  const actantPositionsDict = {};
  
  actantPositions.forEach(element => {
    actantPositionsDict[element.apnId] = element;
  });

  //console.log("actantTypesDict=", actantTypesDict);
  //console.log("verbSeriesDict=", verbSeriesDict);
  //console.log("actantGroupsDict=", actantGroupsDict);
  //console.log("verbPersonsDict=", verbPersonsDict);

  const listEditorTableNames = ["actantGrammarCases", "actantPositions", "actantTypes", "actantGroups", "verbTransitions", 
    "verbPersons", "verbNumbers", "verbTypes", "verbSeries"];


  return (
    <div>
      <h3>პირის ნიშნების დათვლა დომინანტების გათვალისწინებით</h3>
      <h4>ტერმინები</h4>
      <p><strong>აქტანტის ბრუნვა</strong> - აქტანტის ბრუნვის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის პოზიცია</strong> - აქტანტის პოზიციის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის ტიპი</strong> - აქტანტის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის ჯგუფი</strong> - აქტანტის ჯგუფის განმარტება (გასაკეთებელია).</p>
      <p><strong>გარდამავლობა</strong> - გარდამავლობის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის პირი</strong> - ზმნის პირის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის რიცხვი</strong> - ზმნის რიცხვის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის ტიპი</strong> - ზმნის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>სერია</strong> - სერიის განმარტება (გასაკეთებელია).</p>

      {listEditorTableNames.map(tn => {
        const dataType = datatypes.find(f=>f.dtTable === tn);

        return (<NameListEditor key={dataType.dtName} dataType={dataType} tableForEdit={masterData.mdRepo[tn]} 
          curscrollTo={curscrollTo} backLigth={backLigth} saveReturnPageName={saveReturnPageName} />);
      } )}

      <MdListView dataType={checkedDataTypes["actantGrammarCasesByActantTypes"]} 
        table={actantGrammarCasesByActantTypes
          .sort((a, b) => {
            const f = actantTypesDict[a.actantTypeId].sortId-actantTypesDict[b.actantTypeId].sortId;
            if ( f ) 
              return f;
            return verbSeriesDict[a.verbSeriesId].sortId-verbSeriesDict[b.verbSeriesId].sortId
          })
        } gridColumns={GridRules["actantGrammarCasesByActantTypes"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <MdListView dataType={checkedDataTypes["actantTypesByVerbTypes"]} 
        table={actantTypesByVerbTypes
          .sort((a, b) => {
            const f = verbTypesDict[a.verbTypeId].sortId-verbTypesDict[b.verbTypeId].sortId;
            if ( f ) 
              return f;
          return actantPositionsDict[a.actantPositionId].sortId-actantPositionsDict[b.actantPositionId].sortId
          })
        } gridColumns={GridRules["actantTypesByVerbTypes"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <MdListView dataType={checkedDataTypes["dominantActants"]} 
        table={dominantActants
          .sort((a, b) => {
            const f = actantGroupsDict[a.actantGroupId].agrKey.localeCompare(actantGroupsDict[b.actantGroupId].agrKey);
            if ( f ) 
              return f;
            return verbPersonsDict[a.verbPersonId].sortId-verbPersonsDict[b.verbPersonId].sortId
          })
        } gridColumns={GridRules["dominantActants"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />



      <h4>პროცესის აღწერა</h4>
      <p>ყოველი ზმნის ტიპის მიხედვით და იმის მიხედვით რა პოზიციაზე დგას აქტანტი, განისაზღვრება აქტანტის ტიპი. <br/>
        აქტანტის ყოველი ტიპისათვის, იმის მიხედვით რომელ სერიაში არის გამოყენებული, განისაზღვრება ბრუნვა და აქტანტის ჯგუფი. (ანუ სუბიექტია თუ ობიექტი). <br/>
        აქტანტების ჯგუფის, ზმნის პირის და აქტანტის ტიპის მიხედვით განისაზღვრება არის თუ არა აქტანტი დომინანტი. <br/>
        ზემოთ ჩამოთვლილ ინფორმაციაზე დაყრდნობით თითოეული ზმნის ტიპისათვის, სერიისთვის და აქტანტის კომბინაციისთვის განისაზღვრება დომინანტი აქტანტი, რიცხვი და პირი</p>

      <h4>შედეგი</h4>

      <MdListView readOnly dataType={checkedDataTypes["afterDominantPersonMarkers"]} 
        table={afterDominantPersonMarkers
          .sort((a, b) => {
            const f = verbTypesDict[a.verbTypeId].sortId-verbTypesDict[b.verbTypeId].sortId;
            if ( f ) 
              return f;
            return verbSeriesDict[a.verbSeriesId].sortId-verbSeriesDict[b.verbSeriesId].sortId
          })
        } gridColumns={GridRules["afterDominantPersonMarkers"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth}
        firstFilter={ { verbTypeId: true, verbSeriesId: true } } />




    </div>
  );


}

function mapStateToProps(state) {
  const alert = state.alert;
  const masterData = state.masterData;
  const {isMenuLoading, flatMenu} = state.navMenu;

  return {alert, isMenuLoading, flatMenu, masterData};
}

function mapDispatchToProps(dispatch) {
  return {
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    loadMultipleListData: (listTableNames, tableNamesForLoad) => dispatch(MasterDataActions.loadMultipleListData(listTableNames, listTableNames, tableNamesForLoad))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAfterDominantPersonMarkers);
