//DateTimeColumn.js

//import React from 'react';
import moment from 'moment';

const DateTimeColumn = (props) => {

  const { showDate, showTime, value } = props;

  //console.log("DateTimeColumn props=", props);
  //console.log("DateTimeColumn value=", value);

  if ( !showDate && !showTime )
    return value;

  const strFormat = `${showDate ? "DD-MMM-YYYY" : ""} ${showTime ? "HH:mm:ss" : ""}`
  return moment(value).format(strFormat);
}

export default DateTimeColumn;