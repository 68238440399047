//VerbPersonMarkersOverview.js

import React, {useEffect, useMemo, useCallback} from 'react';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';

import Loading from '../../carcass/common/Loading';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';
import NameListEditor from './NameListEditor';
import ParadigmListEditor from './ParadigmListEditor';

const VerbPersonMarkersOverview = (props) => {

  const {alert, isMenuLoading, flatMenu, masterData, verbPersonMarkerParadigmNamesLoading, verbPersonMarkerParadigmNames,
    checkLoadMdTables, checkLoadVerbPersonMarkerParadigmNames } = props;  

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const {verbPluralityTypes, actantGroups, verbPersons, verbNumbers} = masterData.mdRepo;

  //console.log("VerbPersonMarkersOverview props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["verbPluralityTypes", "actantGroups", "verbPersons", "verbNumbers"], []);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    checkLoadMdTables(tableNamesForLoad);
    checkLoadVerbPersonMarkerParadigmNames();
  }, [isMenuLoading, flatMenu, isValidPage, checkLoadMdTables, checkLoadVerbPersonMarkerParadigmNames, tableNamesForLoad]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recId: NzInt(props.match.params.recName), 
    recName: props.match.params.recName
  });

  function saveReturnPageName() {
    props.saveReturnPageName(props.match.url.split('/')[1]);
  }

  if (mdWorkingOnLoad || verbPersonMarkerParadigmNamesLoading || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }

  if (!verbPluralityTypes || !actantGroups || !verbPersons || !verbNumbers || !verbPersonMarkerParadigmNames || !curscrollTo || !datatypes) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  const listEditorTableNames = ["verbPluralityTypes", "actantGroups", "verbPersons", "verbNumbers"];

  const verbPersonMarkerParadigmsDataType = datatypes.find(f=>f.dtTable === "verbPersonMarkerParadigms");

  return (
    <div>
      <h3>ზმნის ცალი პირის ნიშნების პარადიგმების მიმოხილვა</h3>
      <h4>ტერმინები</h4>
      <p><strong>მრალობითობა</strong> - მრალობითობის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტების ჯგუფი</strong> - აქტანტების ჯგუფის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის პირი</strong> - ზმნის პირის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის რიცხვი</strong> - ზმნის რიცხვის განმარტება (გასაკეთებელია).</p>
      <p><strong>ცალი პირის ნიშნის პარადიგმა</strong> - ცალი პირის ნიშნის პარადიგმის განმარტება (გასაკეთებელია).</p>

      {listEditorTableNames.map(tn => {
        const dataType = datatypes.find(f=>f.dtTable === tn);
        if ( !dataType ) {
          return <div></div>
        }
        return (<NameListEditor key={dataType.dtName} dataType={dataType} tableForEdit={masterData.mdRepo[tn]} 
          curscrollTo={curscrollTo} backLigth={backLigth} saveReturnPageName={saveReturnPageName} />);
      } )}

      <ParadigmListEditor dataType={verbPersonMarkerParadigmsDataType} paradigmNamesTable={verbPersonMarkerParadigmNames} 
        formulasTableName="verbPersonMarkerFormulas" curscrollTo={curscrollTo} 
        backLigth={backLigth} saveReturnPageName={saveReturnPageName} />

    </div>
  );


}

function mapStateToProps(state) {

  const alert = state.alert;
  const masterData = state.masterData;
  const {isMenuLoading, flatMenu} = state.navMenu;
  const { verbPersonMarkerParadigmNamesLoading, verbPersonMarkerParadigmNames } = state.formulasStore;

  return {alert, isMenuLoading, flatMenu, masterData, verbPersonMarkerParadigmNamesLoading, verbPersonMarkerParadigmNames};

}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    checkLoadVerbPersonMarkerParadigmNames: () => dispatch(FormulasActions.checkLoadVerbPersonMarkerParadigmNames())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerbPersonMarkersOverview);
