import React, { useState } from 'react';
import { Nav, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux';

import ChangePasswordModal from './ChangePasswordModal';
import MessageBox from '../common/MessageBox';
import { actionCreators as userActions } from './UserStore';


const ProfileTopNavMenu = (props) => {

  //console.log("ProfileTopNavMenu props=", props);

  const { deletingRegistation } = props;
  const [showDeleteConfirmMessage, setShowDeleteConfirmMessage] = useState(false);
  const [changePasswordModalShow, setChangePasswordModalShow] = useState(false);

  return (


    <Nav>
      <Nav className="mr-auto">
        <Button variant="warning" className="btn-space" onClick={() => {
          setChangePasswordModalShow(true);
          //props.SaveDtaRightChanges();
        }} ><FontAwesomeIcon icon="key" /> პაროლის შეცვლა</Button>

        <ChangePasswordModal
          show={changePasswordModalShow}
          onHide={() => setChangePasswordModalShow(false)}
        />


        <Button variant="danger" className="btn-space" onClick={(e) => {
            e.preventDefault();
            setShowDeleteConfirmMessage(true);
        }}><FontAwesomeIcon icon="user-minus" /> ანგარიშის წაშლა
          {deletingRegistation && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        </Button>
      </Nav>
      <MessageBox show={showDeleteConfirmMessage} title="იშლება თქვენი ანგარიში"
                  text="წაშლილი ანგარიშის აღდგენა ვეღარ მოხდება. დარწმუნებული ხართ, რომ გსურთ წაშალოთ თქვენი ანგარიში?"
                  primaryButtonText="დიახ" secondaryButtonText="არა" onConfirmed={() => {
        setShowDeleteConfirmMessage(false);
        //დადგინდეს მიმდინარე მომხმარებლის იდენტიფიკატორი
        //გაიგზავნოს სერვერზე ანგარიშის წაშლის მოთხოვნა
        props.deleteCurentUser(props.history);
      }} onClosed={() => setShowDeleteConfirmMessage(false)} />

    </Nav>
  );
}

function mapStateToProps(state) {
  const { deletingRegistation } = state.authentication;
  return { deletingRegistation };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCurentUser: (history) => dispatch(userActions.deleteCurentUser(history))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileTopNavMenu);

