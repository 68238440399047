//CreateForRecountVerbPersonMarkers.js
import React, {useEffect, useMemo, useCallback} from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap'

import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as ModelOverviewsStoreActions } from './ModelOverviewsStore';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';
import { checkDataTableLoaded, checkGridLoaded, checkDataTypeLoaded } from '../../carcass/modules/CheckDataLoaded'
import Loading from '../../carcass/common/Loading';
import NameListEditor from './NameListEditor';
import MdListView from '../../carcass/masterdata/MdListView';

const CreateForRecountVerbPersonMarkers = (props) => {

  const {alert, isMenuLoading, flatMenu, masterData, loadingForRecountVerbPersonMarkers, forRecountVerbPersonMarkers, 
    loadMultipleListData, getFilteredForRecountVerbPersonMarkers} = props;

    const { mdWorkingOnLoad, datatypesLoading, datatypes } = props.masterData;
    const { actantGrammarCasesByActantTypes, actantTypesByVerbTypes, pluralityChangesByVerbTypes, 
    verbPersonMarkerParadigmChanges, verbPluralityTypeChanges,
    actantTypes, verbSeries, actantGrammarCases, actantGroups, verbTypes, verbTransitions, actantPositions, 
    verbPluralityTypes, verbPersonMarkerParadigms, verbNumbers, verbPersons, dominantActantsQuery, morphemesQuery
  } = props.masterData.mdRepo;

  //console.log("CreateForRecountVerbPersonMarkers props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["actantTypes", "verbSeries", "actantGrammarCases", "actantGroups", 
    "verbTypes", "verbTransitions", "actantPositions", "verbPluralityTypes", "verbPersonMarkerParadigms", "verbNumbers", 
    "verbPersons", "dominantActantsQuery", "morphemesQuery"], []);

  const listTableNames = useMemo(() => ["actantGrammarCasesByActantTypes", "actantTypesByVerbTypes", 
    "pluralityChangesByVerbTypes", "verbPersonMarkerParadigmChanges", "verbPluralityTypeChanges"], []);

  const onlygridRuleNames = useMemo(() => ["forRecountVerbPersonMarkers"], []);

  const gridRuleNames = useMemo(() =>  listTableNames.concat(onlygridRuleNames), [listTableNames, onlygridRuleNames]);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    loadMultipleListData(gridRuleNames, listTableNames, tableNamesForLoad);
  }, [isMenuLoading, flatMenu, isValidPage, loadMultipleListData, listTableNames, tableNamesForLoad, gridRuleNames]);

  useEffect(() => {
    getFilteredForRecountVerbPersonMarkers(1);
  }, [getFilteredForRecountVerbPersonMarkers]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recId: NzInt(props.match.params.recName), 
    recName: props.match.params.recName
  });

  function saveReturnPageName() {
    props.saveReturnPageName(props.match.url.split('/')[1]);
  }

  const checkedDataTypes =[];

  let allDataTypesLoaded = true;
  gridRuleNames.forEach(listTableName => {
    const checkResult = checkDataTypeLoaded(masterData, listTableName);
    if (checkResult) {
      checkedDataTypes[listTableName] = checkResult;
    }
    else
    allDataTypesLoaded = false;
  });

  let allListTablessLoaded = true;
  listTableNames.forEach(listTableName => {
    const checkResult = checkDataTableLoaded(masterData, listTableName);
    if (!checkResult)
      allListTablessLoaded = false;
  });

  const GridRules =[];

  let allGridsLoaded = true;
  gridRuleNames.forEach(gridRuleName => {
    const checkResult = checkGridLoaded(masterData, gridRuleName);
    if (checkResult) {
      GridRules[gridRuleName] = checkResult;
    }
    else
      allGridsLoaded = false;
  });


  
  if (mdWorkingOnLoad || loadingForRecountVerbPersonMarkers || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }


  if (!allDataTypesLoaded || !allListTablessLoaded || !allGridsLoaded || !curscrollTo || !forRecountVerbPersonMarkers || 
    !actantGrammarCasesByActantTypes || !actantTypesByVerbTypes || !pluralityChangesByVerbTypes || 
    !verbPersonMarkerParadigmChanges || !verbPluralityTypeChanges || !actantTypes || 
    !verbSeries || !actantGrammarCases || !actantGroups || !verbTypes || !verbTransitions || !actantPositions || 
    !verbPluralityTypes || !verbPersonMarkerParadigms || !verbNumbers || !verbPersons || !dominantActantsQuery || !morphemesQuery || !datatypes ) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }


  const actantGroupsDict = {};

  actantGroups.forEach(element => {
    actantGroupsDict[element.agrId] = element;
  });

  const actantPositionsDict = {};

  actantPositions.forEach(element => {
    actantPositionsDict[element.apnId] = element;
  });

  const actantTypesDict = {};

  actantTypes.forEach(element => {
    actantTypesDict[element.attId] = element;
  });

  const verbNumbersDict = {};

  verbNumbers.forEach(element => {
    verbNumbersDict[element.vnmId] = element;
  });

  const verbPluralityTypesDict = {};

  verbPluralityTypes.forEach(element => {
    verbPluralityTypesDict[element.vptId] = element;
  });

  const verbPersonsDict = {};

  verbPersons.forEach(element => {
    verbPersonsDict[element.vprId] = element;
  });

  const verbPersonMarkerParadigmsDict = {};

  verbPersonMarkerParadigms.forEach(element => {
    verbPersonMarkerParadigmsDict[element.vpmpnId] = element;
  });

  const verbSeriesDict = {};

  verbSeries.forEach(element => {
    verbSeriesDict[element.vsrId] = element;
  });

  const verbTypesDict = {};

  verbTypes.forEach(element => {
    verbTypesDict[element.vtpId] = element;
  });

  const listEditorTableNames = ["actantGrammarCases", "actantPositions", "actantTypes", "actantGroups", "verbTransitions", 
    "verbPluralityTypes", "verbPersons", "verbNumbers", "verbTypes", "verbPersonMarkerParadigms", "verbSeries"];

  return (
    <div>
      <h3>პირის ნიშნების ფორმულების დათვლის პირველი ფაზა</h3>
      <h4>ტერმინები</h4>
      <p><strong>აქტანტის ბრუნვა</strong> - აქტანტის ბრუნვის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის პოზიცია</strong> - აქტანტის პოზიციის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის ტიპი</strong> - აქტანტის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის ჯგუფი</strong> - აქტანტის ჯგუფის განმარტება (გასაკეთებელია).</p>
      <p><strong>გარდამავლობა</strong> - გარდამავლობის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის მრავლობითობის ტიპი</strong> - ზმნის მრავლობითობის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის პირი</strong> - ზმნის პირის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის რიცხვი</strong> - ზმნის რიცხვის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის ტიპი</strong> - ზმნის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის ცალი პირის ნიშნების პარადიგმა</strong> - ზმნის ცალი პირის ნიშნების პარადიგმის განმარტება (გასაკეთებელია).</p>
      <p><strong>სერია</strong> - სერიის განმარტება (გასაკეთებელია).</p>

      {listEditorTableNames.map(tn => {
        const dataType = datatypes.find(f=>f.dtTable === tn);

        return (<NameListEditor key={dataType.dtName} dataType={dataType} tableForEdit={masterData.mdRepo[tn]} 
          curscrollTo={curscrollTo} backLigth={backLigth} saveReturnPageName={saveReturnPageName} />);
      } )}

      <MdListView dataType={checkedDataTypes["actantGrammarCasesByActantTypes"]} 
        table={actantGrammarCasesByActantTypes
          .sort((a, b) => {
            const f = actantTypesDict[a.actantTypeId].sortId-actantTypesDict[b.actantTypeId].sortId;
            if ( f ) 
              return f;
            return verbSeriesDict[a.verbSeriesId].sortId-verbSeriesDict[b.verbSeriesId].sortId;
          })
        } gridColumns={GridRules["actantGrammarCasesByActantTypes"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <MdListView dataType={checkedDataTypes["actantTypesByVerbTypes"]} 
        table={actantTypesByVerbTypes
          .sort((a, b) => {
            const f = verbTypesDict[a.verbTypeId].sortId-verbTypesDict[b.verbTypeId].sortId;
            if ( f ) 
              return f;
          return actantPositionsDict[a.actantPositionId].sortId-actantPositionsDict[b.actantPositionId].sortId;
          })
        } gridColumns={GridRules["actantTypesByVerbTypes"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <MdListView dataType={checkedDataTypes["pluralityChangesByVerbTypes"]} 
        table={pluralityChangesByVerbTypes
          .sort((a, b) => {
            let f = verbPluralityTypesDict[a.pluralityTypeIdStart].sortId-verbPluralityTypesDict[b.pluralityTypeIdStart].sortId;
            if ( f ) 
              return f;
            f = verbTypesDict[a.verbTypeId].sortId-verbTypesDict[b.verbTypeId].sortId;
            if ( f ) 
              return f;
            return verbSeriesDict[a.verbSeriesId].sortId-verbSeriesDict[b.verbSeriesId].sortId;
          })
        } gridColumns={GridRules["pluralityChangesByVerbTypes"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <MdListView dataType={checkedDataTypes["verbPersonMarkerParadigmChanges"]} 
        table={verbPersonMarkerParadigmChanges
          .sort((a, b) => {
            const f = actantGroupsDict[a.actantGroupId].agrKey.localeCompare(actantGroupsDict[b.actantGroupId].agrKey);
            if ( f ) 
              return f;
            return verbPersonMarkerParadigmsDict[a.verbPersonMarkerParadigmIdStart].sortId-verbPersonMarkerParadigmsDict[b.verbPersonMarkerParadigmIdStart].sortId;
          })
        } gridColumns={GridRules["verbPersonMarkerParadigmChanges"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <MdListView dataType={checkedDataTypes["verbPluralityTypeChanges"]} 
        table={verbPluralityTypeChanges
          .sort((a, b) => {
            let f = verbPluralityTypesDict[a.pluralityTypeIdStart].sortId-verbPluralityTypesDict[b.pluralityTypeIdStart].sortId;
            if ( f ) 
              return f;
            f =  a.pluralityTypeDominantId-b.pluralityTypeDominantId;
            if ( f ) 
              return f;
            f = actantGroupsDict[a.actantGroupId].agrKey.localeCompare(actantGroupsDict[b.actantGroupId].agrKey);
            if ( f ) 
              return f;
            f = verbNumbersDict[a.verbNumberId].sortId-verbNumbersDict[b.verbNumberId].sortId;
            if ( f ) 
              return f;
            f = verbPersonsDict[a.verbPersonId].sortId-verbPersonsDict[b.verbPersonId].sortId;
            if ( f ) 
              return f;
            return actantTypesDict[a.actantTypeId].sortId-actantTypesDict[b.actantTypeId].sortId;
            })
        } gridColumns={GridRules["verbPluralityTypeChanges"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <h4>პროცესის აღწერა</h4>
      <p>რთული გამოთვლებით ხდება შემდეგი კომბინაციების დაანგარიშება: პირების კომბინაციის იდენტიფიკატორი, დომინანტი აქტანტი, მორფემა</p>


      {/* ეს ცხრილი ძალიან დიდია, ამიტომ საჭიროა ნაწილ ნაწილ ჩატვირთვის უზრუნველყოფა */}
      {/* თვითონ ფილტრის არჩევანიც ცალკე უნდა ჩაიტვირთოს */}
      <MdListView readOnly dataType={checkedDataTypes["forRecountVerbPersonMarkers"]} 
        table={forRecountVerbPersonMarkers
          .sort((a, b) => {
            const f = a.verbPersonMarkerCombinationId-b.verbPersonMarkerCombinationId
            if ( f ) 
              return f;
            return a.dominantActantId-b.dominantActantId
          })
        } gridColumns={GridRules["forRecountVerbPersonMarkers"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth}
        firstFilter={ { verbPersonMarkerCombinationId: true } }
        filterChanged={(frm) => {
          //console.log("CreateForRecountVerbPersonMarkers filterChanged frm=", frm);
          getFilteredForRecountVerbPersonMarkers(frm.verbPersonMarkerCombinationId, frm.dominantActantId, frm.morphemeId);
        }}
         />


    </div>
  );
}

function mapStateToProps(state) {
  const alert = state.alert;
  const {isMenuLoading, flatMenu} = state.navMenu;
  const masterData = state.masterData;
  const {loadingForRecountVerbPersonMarkers, forRecountVerbPersonMarkers} = state.modelOverviewsStore;

  return {alert, isMenuLoading, flatMenu, masterData, loadingForRecountVerbPersonMarkers, forRecountVerbPersonMarkers};
}

function mapDispatchToProps(dispatch) {
  return {
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    loadMultipleListData: (gridRuleNames, listTableNames, tableNamesForLoad) => dispatch(MasterDataActions.loadMultipleListData(gridRuleNames, listTableNames, tableNamesForLoad)),
    getFilteredForRecountVerbPersonMarkers: (verbPersonMarkerCombinationId, dominantActantId, morphemeId) => 
      dispatch(ModelOverviewsStoreActions.getFilteredForRecountVerbPersonMarkers(verbPersonMarkerCombinationId, dominantActantId, morphemeId))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateForRecountVerbPersonMarkers);
