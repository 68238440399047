//IssuesStore.js

import apiClient from '../../carcass/modules/ApiClient';
import {issueDetailTypes} from './IssueDetails';
import { NzInt } from '../../carcass/common/myFunctions';


// const clearIssues = 'CLEAR_ISSUES';

const requestIssues = 'REQUEST_ISSUES';
const setIssues = 'SET_ISSUES';
const getIssuesFailure = 'GET_ISSUES_FAILURE';

const requestOneIssue = 'REQUEST_ONE_ISSUE';
const setOneIssues = 'SET_ONE_ISSUE';
const getOneIssuesFailure = 'GET_ONE_ISSUE_FAILURE';

const issueStartCheckingDetail = 'ISSUE_START_CHECKING_DETAIL';
const issueCheckingDetailSuccess = 'ISSUE_CHECKING_DETAIL_SUCCESS';
const issueCheckingDetailFailure = 'ISSUE_CHECKING_DETAIL_FAILURE';

const requestOneIssueDetails = 'REQUEST_ONE_ISSUE_DETAILS';
const setOneIssueDetails = 'SET_ONE_ISSUE_DETAILS';
const getOneIssueDetailsFailure = 'GET_ONE_ISSUE_DETAILS_FAILURE';

const requestFilterSortId = 'REQUEST_FILTER_SORT_ID';
const setFilterSortId = 'SET_FILTER_SORT_ID';
const getFilterSortIdFailure = 'GET_FILTER_SORT_ID_FAILURE';

const setIssueDetailsOffsetAndShowRows = "SET_ISSUE_DETAILS_OFFSET_AND_SHOW_ROWS";
const setSavedIssueDetailLine = "SET_SAVED_ISSUE_DETAIL_LINE";
const clearIssueDetails = "CLEAR_ISSUE_DETAILS";

const requestIssuesCount = "REQUEST_ISSUES_COUNT";
const setIssuesCount = "SET_ISSUES_COUNT";
const getIssuesCountFailure = "GET_ISSUES_COUNT_FAILURE";



const detailsRowsCount = 10;

const showRows = [];
const loadingRows = [];

Object.values(issueDetailTypes).forEach(m => {
  showRows[m] = detailsRowsCount;
  loadingRows[m] = false;
});

const initialState = { 
  issuesLoading: false, issues: null, issuesCount: 0, issuesLoadingFailure: false,
  oneIssueLoading: false, issuesRepo: [], insideChanging: false,
  // showDetailRows: detailsRowsCount,                 //ჩვეულებრივი დეტალების საჩვენებელი სტრიქონების რაოდენობა
  // showRootDetailRows: detailsRowsCount,             //ძირების დეტალების საჩვენებელი სტრიქონების რაოდენობა
  // showDerivationBranchDetailRows: detailsRowsCount, //დერივაციის დეტალების საჩვენებელი სტრიქონების რაოდენობა
  // showInflectionDetailRows: detailsRowsCount,        //ფლექსიის დეტალების საჩვენებელი სტრიქონების რაოდენობა
  // loadingDetailRows: false,              //ჩვეულებრივი დეტალების ჩატვირთვა მიმდინარეობს
  // loadRootDetailRows: false,             //ძირების დეტალების ჩატვირთვა მიმდინარეობს
  // loadDerivationBranchDetailRows: false, //დერივაციის დეტალების ჩატვირთვა მიმდინარეობს
  // loadInflectionDetailRows: false,        //ფლექსიის დეტალების ჩატვირთვა მიმდინარეობს
  showIssueDetailRows: showRows,
  loadingIssueDetailRows: loadingRows,
  filterSortRepo: [],
  // settingFilterSort: [],
  filterSortIds: [],
  savedIssueDetailLine: null
};

async function funLoadIssueDetails(dispatch, getState, issueId, detailsName, offset, rowsCount) {
  const {tabWindowId} = getState().authentication;
  await apiClient.Get(`/issues/getdetails/${issueId}/${detailsName}/${tabWindowId}/${offset}/${rowsCount}`,
      { get: requestOneIssueDetails, 
        set: setOneIssueDetails, 
        failure: getOneIssueDetailsFailure },
      dispatch, getState, true, {issueId, detailsName, offset} );
}

async function funLoadIssues(dispatch, getState, offset, rowsCount) {
  const {tabWindowId} = getState().authentication;
  //console.log('IssuesStore.js apiClient.Get', `"/issues/getissues/${tabWindowId}/${offset}/${rowsCount}`);
  await apiClient.Get(`/issues/getissues/${tabWindowId}/${offset}/${rowsCount}`,
    { get: requestIssues, 
      set: setIssues, 
      failure: getIssuesFailure },
    dispatch, getState, true, offset );
}


async function funCreateFilterSort(dispatch, getState, filterSortObject, issueId, detailsName) {

  //console.log('funCreateFilterSort {filterSortObject, issueId, detailsName}=', {filterSortObject, issueId, detailsName});
  //console.log('IssuesStore.js apiClient.Get', "/issues/createfiltersort");

  await apiClient.Post("/issues/createfiltersort",
    { get: requestFilterSortId, 
      set: setFilterSortId, 
      failure: getFilterSortIdFailure },
    dispatch, getState, true, {filterSortObject, issueId, detailsName}, true, filterSortObject );
}



export const actionCreators = {

  // clearIssues: () => {
  //   return { type: clearIssues };
  // },
  saveIssueDetailLine: (issueId, detailsName, isdId, index, offset, showRows) => {
    return { type: setSavedIssueDetailLine, payload: {issueId, detailsName, isdId, index, offset, showRows} };
  },


  changeIssueDetailsOffsetAndShowRows: (issueId, detailsName, offset, showRows) => {
    return { type: setIssueDetailsOffsetAndShowRows, payload: {issueId, detailsName, offset, showRows} };
  },


  loadIssueDetails: (issueId, detailsName, offset, rowsCount, reLoad) => async (dispatch, getState) => {
    // dispatch({ type: setIssueOffsetAndShowRows, payload: {issueId, detailsName, offset, showRows} });
    await funLoadIssueDetails(dispatch, getState, issueId, detailsName, offset, rowsCount)
  },

  loadIssues: (offset, rowsCount) => async (dispatch, getState) => {
    // dispatch({ type: setIssueOffsetAndShowRows, payload: {issueId, detailsName, offset, showRows} });
    //dispatch({ type: setIssuesShowRows, payload: {detailsName, showRows} });
    await funLoadIssues(dispatch, getState, offset, rowsCount)
  },

  // setOffset: (issueId, detailsName, offset) => async (dispatch, getState) => {
  //   await funLoadIssueDetails(dispatch, getState, issueId, detailsName)
  // },

  createIssuesFilterSort: (filterSortObject, rowCount) => async (dispatch, getState) => {
    await funCreateFilterSort(dispatch, getState, filterSortObject);
    // await dispatch({ type: clearIssueDetails, payload: {issueId, detailsName} });

    //რაოდენობის თავიდან ჩატვირთვა და რეპოზიტორიის გასიფთავება
    //dispatch({ type: setIssueOffsetAndShowRows, payload: {issueId, detailsName, offset: 0, showRows} });
    //await funLoadIssues(dispatch, getState, 0, rowCount);
  },

  createIssueDetailsFilterSort: (filterSortObject, issueId, detailsName, rowCount) => async (dispatch, getState) => {
    await funCreateFilterSort(dispatch, getState, filterSortObject, issueId, detailsName);
    //რაოდენობის თავიდან ჩატვირთვა და რეპოზიტორიის გასიფთავება
    //dispatch({ type: setIssueOffsetAndShowRows, payload: {issueId, detailsName, offset: 0, showRows} });
    // await funLoadIssueDetails(dispatch, getState, issueId, detailsName, 0, rowCount);
    // dispatch({ type: setIssueDetailsOffsetAndShowRows, payload: {issueId, detailsName, offset: 0, showRows: rowCount} });
  },



  // loadIssues: () => async (dispatch, getState) => {

  //   //თუ უკვე იტვირთება, აღარ ჩავტვირთოთ
  //   if (getState().issuesStore.issuesLoading)
  //     return;
  //   //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
  //   //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
  //   if (getState().issuesStore.issues)
  //     return; //წესით ასეი შემთხვევა არ უნდა მოხდეს, მაგრამ მაინც
  //   console.log('IssuesStore.js apiClient.Get', "/issues/getissues");
  //   await apiClient.Get("/issues/getissues",
  //     { get: requestIssues, 
  //       set: setIssues, 
  //       failure: getIssuesFailure },
  //     dispatch, getState, true );
  // },



  getIssuesCount: () => async (dispatch, getState) => {
    //FIXME გაფილტრული ვარიანტების ჩასატვირთად აუცილებლად დაგვჭირდება პარამეტრი int tabWindowId
    //const {tabWindowId} = getState().authentication;
    //console.log('IssuesStore.js apiClient.Get', `/issues/getissuescount`);
    await apiClient.Get(`/issues/getissuescount`,
      { get: requestIssuesCount, 
        set: setIssuesCount,
        failure: getIssuesCountFailure },
      dispatch, getState, true );
  },


  getOneIssueById: (issueId) => async (dispatch, getState) => {

    //თუ უკვე იტვირთება, აღარ ჩავტვირთოთ
    if (getState().issuesStore.oneIssueLoading)
      return;
    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
    if (getState().issuesStore.issuesRepo[issueId])
      return; //წესით ასეი შემთხვევა არ უნდა მოხდეს, მაგრამ მაინც
    //console.log('IssuesStore.js apiClient.Get', `/issues/getoneissue/${issueId}`);
    if ( await apiClient.Get(`/issues/getoneissue/${issueId}`,
      { get: requestOneIssue, 
        set: setOneIssues, 
        failure: getOneIssuesFailure },
      dispatch, getState, true, issueId ) );
      //  {
      //   const oneIssue = getState().issuesStore.issuesRepo[issueId];
      // }
  },

  checkDetail: (issueId, detailsName, id, checkedValue) => async (dispatch, getState) => {
    //console.log('IssuesStore.js apiClient.Patch', `/issues/checkdetail/${issueId}/${detailsName}/${id}/${checkedValue}`);
    await apiClient.Patch(`/issues/checkdetail/${issueId}/${detailsName}/${id}/${checkedValue}`,
      { get: issueStartCheckingDetail, 
        set: issueCheckingDetailSuccess, 
        failure: issueCheckingDetailFailure },
      dispatch, getState, true, {issueId, detailsName, id, checkedValue} );

    }

}



export const reducer = (state, action) => {
  state = state || initialState;
  
  // if (action.type === clearIssues) {
  //   return { ...state, issues: null, issuesLoading: false, issuesLoadingFailure: false,
  //     issuesRepo: [], oneIssueLoading: false };
  // }

  if (action.type === setIssueDetailsOffsetAndShowRows) {
    const {issueId, detailsName, offset, showRows} = action.payload;
    const newShowIssueDetailRows = state.showIssueDetailRows;
    newShowIssueDetailRows[detailsName] = showRows;
    const newIssuesRepo = state.issuesRepo;
    newIssuesRepo[issueId].offset[detailsName] = offset;
    return { ...state, issuesRepo: newIssuesRepo, showIssueDetailRows: newShowIssueDetailRows, showIssueDetailRowsChanged: !state.showIssueDetailRowsChanged, insideChanging: !state.insideChanging };
  }
  
  if (action.type === setSavedIssueDetailLine) {
    return { ...state, savedIssueDetailLine: action.payload};
  }


  if (action.type === requestIssues) {
    return { ...state, issuesLoading: true, issuesLoadingFailure: false };
  }
  
  if (action.type === setIssues) {
    const data = action.payload.data;
    return { ...state, issues: data, issuesLoading: false, issuesLoadingFailure: false };
  }
  
  if (action.type === getIssuesFailure) {
    return { ...state, issues: null, issuesLoading: false, issuesLoadingFailure: true };
  }


  if (action.type === requestOneIssue) {
    return { ...state, oneIssueLoading: true };
  }
  
  if (action.type === setOneIssues) {
    const data = action.payload.data;
    const newIssuesRepo = state.issuesRepo;
    const issId = data.issId;
    newIssuesRepo[issId] = data;
    newIssuesRepo[issId].offset = [];
    Object.values(issueDetailTypes).forEach((detName)=>{
      newIssuesRepo[issId].offset[detName] = 0;
    });
    // newIssuesRepo[issId].offset[issueDetails.details] = 0;
    // newIssuesRepo[issId].offset[issueDetails.rootsDetails] = 0;
    // newIssuesRepo[issId].offset[issueDetails.derivationBranchesDetails] = 0;
    // newIssuesRepo[issId].offset[issueDetails.inflectionsDetails] = 0;
    return { ...state, issuesRepo: newIssuesRepo, oneIssueLoading: false };
  }
  
  if (action.type === getOneIssuesFailure) {
    const newIssuesRepo = state.issuesRepo;
    const issId = action.payload.params;
    newIssuesRepo[issId] = null;
    return { ...state, issuesRepo: newIssuesRepo, oneIssueLoading: false };
  }

  if(action.type === issueStartCheckingDetail) {
    const newIssuesRepo = state.issuesRepo;
    const {issueId, detailsName, id} = action.payload.params;
    //console.log("IssuesStore issueStartCheckingDetail {issueId, detailsName, id}=", {issueId, detailsName, id});
    //console.log("IssuesStore issueStartCheckingDetail newIssuesRepo=", newIssuesRepo);
    //debugger;
    const detail = newIssuesRepo[issueId][detailsName].find(f=>f?.isdId === id);
    detail.checkedChanging = true;
    //console.log("IssuesStore issueStartCheckingDetail changing=", true);
    return { ...state, issuesRepo: newIssuesRepo, insideChanging: !state.insideChanging };
  }

  if(action.type === issueCheckingDetailSuccess) {
    const newIssuesRepo = state.issuesRepo;
    const {issueId, detailsName, id, checkedValue} = action.payload.params;
    const detail = newIssuesRepo[issueId][detailsName].find(f=>f?.isdId === id);
    detail.checkedChanging = false;
    detail.checked = checkedValue;
    //console.log("IssuesStore issueCheckingDetailSuccess changing=", false);
    return { ...state, issuesRepo: newIssuesRepo, insideChanging: !state.insideChanging };
  }

  if(action.type === issueCheckingDetailFailure) {
    const newIssuesRepo = state.issuesRepo;
    const {issueId, detailsName, id} = action.payload.params;
    const detail = newIssuesRepo[issueId][detailsName].find(f=>f?.isdId === id);
    detail.checkedChanging = false;
    //console.log("IssuesStore issueCheckingDetailFailure changing=", false);
    return { ...state, issuesRepo: newIssuesRepo, insideChanging: !state.insideChanging };
  }









  if(action.type === requestOneIssueDetails) {
    const {detailsName} = action.payload.params;
    const newLoadingIssueDetailRows = state.loadingIssueDetailRows;
    newLoadingIssueDetailRows[detailsName] = true;
    return { ...state, loadingIssueDetailRows: newLoadingIssueDetailRows, insideChanging: !state.insideChanging };// insideChanging: !state.insideChanging
  }

  if(action.type === setOneIssueDetails) {
    const {issueId, detailsName, offset} = action.payload.params;
    const data = action.payload.data;
    const newIssuesRepo = state.issuesRepo;
    if ( ! (detailsName in newIssuesRepo[issueId]) )
      newIssuesRepo[issueId][detailsName] = [];
    const details = newIssuesRepo[issueId][detailsName];
    data.forEach((element, index) => {
      details[offset+index] = element;
    });
    const newLoadingIssueDetailRows = state.loadingIssueDetailRows;
    newLoadingIssueDetailRows[detailsName] = false;
    return { ...state, issuesRepo: newIssuesRepo, loadingIssueDetailRows: newLoadingIssueDetailRows, insideChanging: !state.insideChanging };// insideChanging: !state.insideChanging
  }

  if(action.type === getOneIssueDetailsFailure) {
    const {detailsName} = action.payload.params;
    const newLoadingIssueDetailRows = state.loadingIssueDetailRows;
    newLoadingIssueDetailRows[detailsName] = false;
    return { ...state, loadingIssueDetailRows: newLoadingIssueDetailRows, insideChanging: !state.insideChanging };// insideChanging: !state.insideChanging
  }

  // if(action.type === requestFilterSortId) {
  //   const { tableName } = action.payload.params;
  //   const newSettingFilterSort = state.settingFilterSort;
  //   newSettingFilterSort[tableName] = true;
  //   return { ...state, settingFilterSort: newSettingFilterSort };
  // }

  if(action.type === setFilterSortId) {
    const { filterSortObject, issueId, detailsName } = action.payload.params;
    const newFilterSortRepo = state.filterSortRepo;
    const { tableName } = filterSortObject;
    newFilterSortRepo[tableName] = filterSortObject;
  //   const newSettingFilterSort = state.settingFilterSort;
  //   newSettingFilterSort[tableName] = true;
    const newFilterSortIds = state.filterSortIds;
    newFilterSortIds[tableName] = NzInt(newFilterSortIds[tableName]) + 1;

    if ( issueId === undefined ){
      return { ...state, issues: null, filterSortRepo: newFilterSortRepo, filterSortIds: newFilterSortIds, insideChanging: !state.insideChanging };
    }
    else
    {
      const newIssuesRepo = state.issuesRepo;
      if ( (detailsName in newIssuesRepo[issueId]) )
        delete newIssuesRepo[issueId][detailsName];
      return { ...state, issuesRepo: newIssuesRepo, filterSortRepo: newFilterSortRepo, filterSortIds: newFilterSortIds, insideChanging: !state.insideChanging };
    }
  //   return { ...state, filterSortRepo: newFilterSortRepo, settingFilterSort: newSettingFilterSort, filterSortIds: newFilterSortIds };
}

  if(action.type === getFilterSortIdFailure) {
    const newFilterSortRepo = state.filterSortRepo;
    const {tableName} = action.payload.params;
    if ( tableName in newFilterSortRepo) {
      delete newFilterSortRepo[tableName];
    }
    // const newSettingFilterSort = state.settingFilterSort;
    // newSettingFilterSort[tableName] = false;
    return { ...state, filterSortRepo: newFilterSortRepo };//, settingFilterSort: newSettingFilterSort
    //return { ...state, filterSortRepo: newFilterSortRepo, settingFilterSort: newSettingFilterSort };
  }

  if(action.type === requestIssuesCount) {
    return { ...state, issuesLoading: true, issuesCount: 0 };
  }

  if(action.type === setIssuesCount) {
    return { ...state, issuesLoading: false, issuesCount: action.payload.data };
  }

  if(action.type === getIssuesCountFailure) {
    return { ...state, issuesLoading: false, issuesCount: 0 };
  }


  if(action.type === clearIssueDetails) {
    const {issueId, detailsName} = action.payload.params;
    const newIssuesRepo = state.issuesRepo;
    if ( (detailsName in newIssuesRepo[issueId]) )
      delete newIssuesRepo[issueId][detailsName];
    return { ...state, issuesRepo: newIssuesRepo };//, insideChanging: !state.insideChanging
  }


  





  return state;
}