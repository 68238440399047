import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, ToggleButton, Button, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MessageBox from '../../carcass/common/MessageBox';
import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import { actionCreators as FormulasActions } from '../overviews/FormulasStore';


const ParadigmTopNavMenu = (props) => {
  console.log("ParadigmTopNavMenu props=", props);

  const {prdShowhyphens, prdShowFormulas, prdIspMorphemeNom, paradigm, savingParadigmSamples, user} = props;

  const [showParadigmSaveAsSampleConfirmMessage, setShowParadigmSaveAsSampleConfirmMessage] = useState(false);



  const infId = props.match && props.match.params ? props.match.params.infId : null;
  if (!infId || !paradigm)
    return null;

  let title = 'ძირითადი';
  const ispMorpheme = paradigm.ispMorphemes.find((item) => item.ispMorphemeNom === prdIspMorphemeNom);
  if ( ispMorpheme )
    title = ispMorpheme.ispMorpheme

  return (
    
    <Nav>
      <Navbar.Brand> პარადიგმა </Navbar.Brand>
      <ToggleButton type="checkbox" checked={prdShowhyphens ? true : false} className="btn-space" onChange={(val) => {
          props.Showhyphens(val.target.checked);
      }}><FontAwesomeIcon icon="minus" /> დეფისებით</ToggleButton>

      <DropdownButton id="select-indirect-speech-particle" title={title} className="btn-space">
        {paradigm.ispMorphemes.map(element => {
          return (<Dropdown.Item key={element.ispMorphemeNom} as="button" onClick={()=>{
            props.SwithcIspMorphemeNom(element.ispMorphemeNom);
          }}>{element.ispMorpheme}</Dropdown.Item>);
        })}
      </DropdownButton>

      <ToggleButton type="checkbox" checked={prdShowFormulas ? true : false} className="btn-space" onChange={(val) => {
          props.ShowFormulas(val.target.checked);
      }}><FontAwesomeIcon icon="bezier-curve" /> ფორმულები</ToggleButton>

      {user.appClaims.some(s=>s === 'SaveSamples') &&
        <div>
          <Button variant="warning" className="btn-space" onClick={(e) => {
            e.preventDefault();
            setShowParadigmSaveAsSampleConfirmMessage(true);
          }} ><FontAwesomeIcon icon="file-export" /> ნიმუშებში
            {savingParadigmSamples && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}          
          </Button>

          <MessageBox show={showParadigmSaveAsSampleConfirmMessage} title="პარადიგმის ნიმუშებად შენახვა"
                      text={"ძველი ნიმუშების ადგილას მოხდება ამ პარადიგმის ნიმუშებად შენახვა, მაინც გსურთ პარადიგმის ნიმუშებად შენახვა?"}
                      primaryButtonText="დიახ" secondaryButtonText="არა" onConfirmed={() => {
                        setShowParadigmSaveAsSampleConfirmMessage(false);
                        props.paradigmSaveSamples(paradigm.inflectionId);
                        if ( paradigm.nounParadigmId ) {
                          props.clearNounParadigmFormulas([paradigm.nounParadigmId]);
                        }
                        if ( paradigm.verbParadigmId ) {
                          props.clearVerbRowParadigmFormulas([paradigm.verbParadigmId]);
                        }
                      }} onClosed={() => setShowParadigmSaveAsSampleConfirmMessage(false)} />
        </div>
      }

    </Nav>

  );


}


function mapStateToProps(state) {
  const { prdShowhyphens, prdShowFormulas, prdIspMorphemeNom, paradigm, savingParadigmSamples } = state.derivTree;
  const { user } = state.authentication;
  return { prdShowhyphens, prdShowFormulas, prdIspMorphemeNom, paradigm, savingParadigmSamples, user };

}

function mapDispatchToProps(dispatch) {
  return {
    Showhyphens: (val) => dispatch(DerivTreeActions.Showhyphens(val)),
    ShowFormulas: (val) => dispatch(DerivTreeActions.ShowFormulas(val)),
    SwithcIspMorphemeNom: (val) => dispatch(DerivTreeActions.SwithcIspMorphemeNom(val)),
    paradigmSaveSamples: (infId) => dispatch(DerivTreeActions.paradigmSaveSamples(infId)),
    clearNounParadigmFormulas: (paradigmIdsForClear) => dispatch(FormulasActions.clearNounParadigmFormulas(paradigmIdsForClear)),
    clearVerbRowParadigmFormulas: (paradigmIdsForClear) => dispatch(FormulasActions.clearVerbRowParadigmFormulas(paradigmIdsForClear))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParadigmTopNavMenu);

