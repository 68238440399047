//DerivationTreeStore.js
//import { history } from '../../carcass/common/History';

import apiClient from '../../carcass/modules/ApiClient';

const requestBasesForDropdown = 'DTS_REQUEST_BASES_FOR_DROPDOWN';
const setBasesForDropdown = 'DTS_SET_BASES_FOR_DROPDOWN';
const getBasesForDropdownFailure = 'DTS_GET_BASES_FOR_DROPDOWN_FAILURE';

const requestVerbsForDropdown = 'DTS_REQUEST_VERBS_FOR_DROPDOWN';
const setVerbsForDropdown = 'DTS_SET_VERBS_FOR_DROPDOWN';
const getVerbsForDropdownFailure = 'DTS_GET_VERBS_FOR_DROPDOWN_FAILURE';

const basesPageLoading = 'DTS_BASES_PAGE_LOADING';
const basesPageLoadingFinish = 'DTS_BASES_PAGE_LOADING_FINISH';
const setBasesCount = 'DTS_SET_BASES_COUNT';
const getBasesCountFailure = 'DTS_GET_BASES_COUNT_FAILURE';
const setBasesPage = 'DTS_SET_BASES_PAGE';
const getBasesPageFailure = 'DTS_GET_BASES_PAGE_FAILURE';

const forConfirmRootsListPageLoading = 'DTS_CONFIRM_ROOTS_LIST_PAGE_LOADING';
const forConfirmRootsListPageLoadingFinish = 'DTS_CONFIRM_ROOTS_LIST_PAGE_LOADING_FINISH';
const setForConfirmRootsCount = 'DTS_SET_FOR_CONFIRM_ROOTS_COUNT';
const getForConfirmRootsCountFailure = 'DTS_GET_FOR_CONFIRM_ROOTS_COUNT_FAILURE';
const setForConfirmRootsPage = 'DTS_SET_FOR_CONFIRM_ROOTS_PAGE';
const getForConfirmRootsPageFailure = 'DTS_GET_FOR_CONFIRM_ROOTS_PAGE_FAILURE';
const clearForConfirmRootsPagesMemo = "DTS_CLEAR_FOR_CONFIRM_ROOTS_PAGES_MEMO";

const startLoading = 'DTS_START_LOADING';
const finishLoading = 'DTS_FINISH_LOADING';

const setOneRootByid = 'DTS_SET_ONE_ROOT_BYID';
const getOneRootByidFailure = 'DTS_GET_ONE_ROOT_BYID_FAILURE';

const setRootsByBranchid = 'DTS_SET_ROOTS_BY_BRANCHID';
const getRootsByBranchidFailure = 'DTS_GET_ROOTS_BY_BRANCHID_FAILURE';

const setRootsByInflectionId = 'DTS_SET_ROOTS_BY_INFLECTION_ID';
const getRootsByInflectionIdFailure = 'DTS_GET_ROOTS_BY_INFLECTION_ID_FAILURE';

const setRootsByInflectionVerbCompositionId = 'DTS_SET_ROOTS_BY_INFLECTION_VERB_COMPOSITION_ID';
const getRootsByInflectionVerbCompositionIdFailure = 'DTS_GET_ROOTS_BY_INFLECTION_VERB_COMPOSITION_ID_FAILURE';

const clearMemo = "DTS_CLEAR_MEMO";
const removeOneRootFromRepo = "DTS_REMOVE_ONE_ROOT_FROM_REPO";
const removeRootsFromRepo = "DTS_REMOVE_ROOTS_FROM_REPO";
const removeAllRootsFromRepo = "DTS_REMOVE_ALL_ROOTS_FROM_REPO"

const getParadigm = "DTS_GET_PARADIGM";
const setParadigm = "DTS_SET_PARADIGM";
const failureParadigm = "DTS_FAILURE_PARADIGM";

const getUsersListForConfirmRequest = "DTS_Get_USERSLISTFORCONFIRM_REQUEST";
const getUsersListForConfirmSuccess = "DTS_Get_USERSLISTFORCONFIRM_SUCCESS";
const getUsersListForConfirmFailure = "DTS_Get_USERSLISTFORCONFIRM_FAILURE";

const getVerbCompositionParadigm = "DTS_GET_VERB_COMPOSITION_PARADIGM";
const setVerbCompositionParadigm = "DTS_SET_VERB_COMPOSITION_PARADIGM";
const failureVerbCompositionParadigm = "DTS_FAILURE_VERB_COMPOSITION_PARADIGM";

const clearVerbRowParadigmsByVerbTypes = "DTS_CLEAR_VERB_PARADIGMS_BY_VERB_TYPES"
const GetVerbRowParadigmsByVerbTypesRequest = "DTS_GET_VERB_PARADIGMS_BY_VERB_TYPES_REQUEST";
const GetVerbRowParadigmsByVerbTypesSuccess = "DTS_GET_VERB_PARADIGMS_BY_VERB_TYPES_SUCCESS";
const GetVerbRowParadigmsByVerbTypesFailure = "DTS_GET_VERB_PARADIGMS_BY_VERB_TYPES_FAILURE";

const clearParadigm = "DTS_CLEAR_PARADIGM";
const clearVerbCompositionParadigm = "DTS_CLEAR_VERB_COMPOSITION_PARADIGM";

const putParadigmSamplesSaveRequest = "DTS_PUT_PARADIGM_SAMPLES_SAVE_REQUEST";
const putParadigmSamplesSaveSuccess = "DTS_PUT_PARADIGM_SAMPLES_SAVE_SUCCESS";
const putParadigmSamplesSaveFailure = "DTS_PUT_PARADIGM_SAMPLES_SAVE_FAILURE";

const putsaveParadigmSamplePositionsRequest = "DTS_PUT_SAVE_PARADIGM_SAMPLES_POSITIONS_REQUEST";
const putsaveParadigmSamplePositionsSuccess = "DTS_PUT_SAVE_PARADIGM_SAMPLES_POSITIONS_SUCCESS";
const putsaveParadigmSamplePositionsFailure = "DTS_PUT_SAVE_PARADIGM_SAMPLES_POSITIONS_FAILURE";



const prdShowhyphens = "DTS_SHOW_HYPHENS";
const prdShowFormulas = "DTS_SHOW_FORMULAS";
const prdIspMorphemeNom = "DTS_INDIRECT_SPEECH_PARTICLE_MORPHEME_NOM";



const dropdownLinesCount = 8;

const initialState = { 
  //verbs dropdown
  memoVerbsDict: {}, verbsForDropdownloading: false,
  //bases dropdown
  memoBasesDict: {}, basesForDropdownloading: false,
  UsersListForConfirm: null, UsersListForConfirmDropdownloading: false,
  //bases pages
  basesPageLoading: false, memoBaseCounts: {}, memoBasePages: {}, 
  //forConfirmRoots pages
  forConfirmRootsListPageLoading: false, memoForConfirmRootsCounts: {}, memoForConfirmRootsPages: {}, 
  //roots
  rootLoading: false, rootsRepo: {}, 
  inflectionWorkingOnLoadParadigm: false,
  paradigm: null,
  verbRowParadigmsByVerbTypesLoading: false, verbRowParadigmsByVerbTypes: null, verbRowParadigmsByVerbTypesLoadingFailure: false,
  prdShowhyphens: false, prdShowFormulas: false, prdIspMorphemeNom: 0,
  savingParadigmSamples: false,
  savingParadigmSamplePositions: false
};

export function getInflectionByIdFromStore(rootsRepo, infId) {
  for (var rootData of Object.values(rootsRepo)) {
    if (rootData) {
      const verbInflection = rootData.verbInflections.find((item) => item.infId === infId);
      if (verbInflection) {
        return verbInflection;
      }
      const nounInflection = rootData.nounInflections.find((item) => item.infId === infId);
      if (nounInflection) {
        return nounInflection;
      }
    }
  }
  return null;
}

export function getInflectionVerbCompositionByIdFromStore(rootsRepo, ivcId) {
  for (var rootData of Object.values(rootsRepo)) {
    //console.log("getInflectionVerbCompositionByIdFromStore {rootData, ivcId}=", {rootData, ivcId})
    if (rootData) {
      const inflectionVerbComposition = rootData.inflectionVerbCompositions.find((item) => item.ivcId === ivcId);
      if (inflectionVerbComposition) {
        return inflectionVerbComposition;
      }
    }
  }
  return null;
}

export function  getBranchByIdFromStore(rootsRepo, branchId) {
  for (var rootData of Object.values(rootsRepo)) {
    if (rootData) {
      const branch = rootData.branches.find((item) => item.dbrId === branchId);
      if (branch) {
        return branch;
      }
    }
  }
  return null;
}

export function  getVerbInflectionByIdFromStore(rootsRepo, infId) {
  for (var rootData of Object.values(rootsRepo)) {
    if (rootData) {
     const inflection = rootData.verbInflections.find((item) => item.infId === infId);
      if (inflection) {
        return inflection;
      }
    }
  }
  return null;
}

export function funPredRoots(branch, rootsRepo) {
  if (!branch)
    return [];
  if (branch.derivationPredecessors.length === 0) {
    const rootData = Object.values(rootsRepo)
      .find((rtd) => rtd?.root?.rootFirstBranchId === branch.dbrId);
    if (rootData) {
      return [rootData.root.rootId];
    }
    return [];
  }

  const prebranches = branch.derivationPredecessors.map((itm) => getBranchByIdFromStore(rootsRepo, itm.parentBranchId));
  const r2 = prebranches.map((pre)=>funPredRoots(pre, rootsRepo));
  return arrayUnique([].concat(...r2));
}


export function createUrlForConfirmRoots(startUrl, rootStartsWith, createdUserName) {

  let newUrl = startUrl;
  let questionMarkAdded = false;
  if ( rootStartsWith )
  {
    if ( ! questionMarkAdded )
    {
      newUrl += "?";
      questionMarkAdded = true;
    }
    newUrl += `rootStartsWith=${rootStartsWith}`
  }
  if ( createdUserName && createdUserName !== 'ყველა' )
  {
    if ( ! questionMarkAdded )
      newUrl += "?";
    else
      newUrl += "&";
    newUrl += `createdUserName=${createdUserName}`
  }
  return newUrl;
}




function arrayUnique(array) {
  var a = array.concat();
  for(var i=0; i<a.length; ++i) {
      for(var j=i+1; j<a.length; ++j) {
          if(a[i] === a[j])
              a.splice(j--, 1);
      }
  }
  return a;
}

function startIfNotStarted(dispatch, getState) {
  const {rootLoading} = getState().derivTree;
  if (!rootLoading) {
    dispatch({ type: startLoading });
  }
}

async function getRootById(dispatch, getState, rootId) {
  
  //შევამოწმოთ ეს ძირი ჩატვირთულია თუ არა
  if (rootId in getState().derivTree.rootsRepo && getState().derivTree.rootsRepo[rootId])
    return true;

  startIfNotStarted(dispatch, getState);

  //console.log('DerivationTreeStore.js apiClient.Get', `/roots/getrootbyid/${rootId}`);
  return await apiClient.Get(`/roots/getrootbyid/${rootId}`,
    { get: null, 
      set: setOneRootByid, 
      failure: getOneRootByidFailure },
    dispatch, getState );
}

function finishIfStarted(dispatch, getState) {
  const rootLoading = getState().derivTree.rootLoading;
  if (rootLoading) {
    dispatch({ type: finishLoading });
  }
}

export async function funAfterSaveBranch(history, dispatch, getState, rootId, derivBranchId) {
  //console.log("DerivTreeStore funAfterSaveBranch {rootId, derivBranchId}=", {rootId, derivBranchId})

  //თავიდან ჩაიტვირთოს დერივაციის იდენტიფიკატორის მიხედვით ყველა საჭირო ძირი
  if (derivBranchId)
    await getRootByDerivBranchId(dispatch, getState, derivBranchId);

  //დერივაციისათვის მივიღოთ წინაპარი ძირების სია
  const branch = getBranchByIdFromStore(getState().derivTree.rootsRepo, derivBranchId);
  const predRootIds = funPredRoots(branch, getState().derivTree.rootsRepo);
  const duplicateDerivationBranchId = getState().rootEditorStore.duplicateDerivationBranchId;

  //თუ rootId შედის წინაპარი ძირების სიაში, მაშინ გამოვიყენოთ rootId, თუ არა გამოვიყენოთ პირველივე სიიდან
  const forOpenRootId = ( (!!rootId && predRootIds.includes(rootId)) || predRootIds.length === 0 ) ? rootId : predRootIds[0];

  if (forOpenRootId) {
    if ( duplicateDerivationBranchId )
      history.push(`/root/${forOpenRootId}/${duplicateDerivationBranchId}`);
    else if (derivBranchId)
      history.push(`/root/${forOpenRootId}/${derivBranchId}`);
    else
      history.push(`/root/${forOpenRootId}`);
  } else
    history.push("/basesearch");
}

export async function funAfterSaveInflection(history, dispatch, getState, rootId, derivBranchId, inflectionId) {
  //თავიდან ჩაიტვირთოს ფლექსიის იდენტიფიკატორის მიხედვით ყველა საჭირო ძირი
  if (inflectionId) {
    await getRootByInflectionId(dispatch, getState, inflectionId);
  }

  //მივიღოთ ფლექსია იდენტიფიკატორის მიხედვით
  const inflection = inflectionId === null ? null : getInflectionByIdFromStore(getState().derivTree.rootsRepo, inflectionId);
  
  let mustBeinflectionId = inflectionId;
  if (inflection) 
    dispatch(actionCreators.GetParadigm(inflectionId));
  else
    mustBeinflectionId = null;


  //მივიღოთ წინაპარი დერივაციების სია
  const preBranches = inflection === null ? null : inflection.inflectionPredecessors.map(ip=>ip.parentBranchId);
  
  //თუ derivBranchId შედის წინაპარი დერივაციების სიაში, მაშინ გამოვიყენოთ derivBranchId, თუ არა გამოვიყენოთ პირველივე სიიდან
  const dbrId = (!preBranches  || preBranches.length === 0 || ( derivBranchId && preBranches.includes(derivBranchId) ) ) ? derivBranchId : preBranches[0];

  //დადგენილი დერივაციისათვის მივიღოთ წინაპარი ძირების სია
  const branch = getBranchByIdFromStore(getState().derivTree.rootsRepo, dbrId);
  const predRootIds = funPredRoots(branch, getState().derivTree.rootsRepo);
  const duplicateInflectionId = getState().rootEditorStore.duplicateInflectionId;

  //თუ rootId შედის წინაპარი ძირების სიაში, მაშინ გამოვიყენოთ rootId, თუ არა გამოვიყენოთ პირველივე სიიდან
  const forOpenRootId = ( (!!rootId && predRootIds.includes(rootId)) || predRootIds.length === 0 ) ? rootId : predRootIds[0];

  if (forOpenRootId) {
    if (dbrId) {
      if (duplicateInflectionId)
        history.push(`/root/${forOpenRootId}/${dbrId}/${duplicateInflectionId}`);
      else if (mustBeinflectionId)
        history.push(`/root/${forOpenRootId}/${dbrId}/${mustBeinflectionId}`);
      else
        history.push(`/root/${forOpenRootId}/${dbrId}`);
    }
    else
      history.push(`/root/${forOpenRootId}`);
  } else
    history.push("/basesearch");

}



export async function funAfterSaveInflectionVerbComposition(history, dispatch, getState, rootId, derivBranchId, inflectionId, inflectionVerbCompositionId) {

  //console.log("funAfterSaveInflectionVerbComposition {rootId, derivBranchId, inflectionId, inflectionVerbCompositionId}=", {rootId, derivBranchId, inflectionId, inflectionVerbCompositionId})
  //თავიდან ჩაიტვირთოს ფლექსიის იდენტიფიკატორის მიხედვით ყველა საჭირო ძირი
  if (inflectionVerbCompositionId) {
    await getRootByInflectionVerbCompositionId(dispatch, getState, inflectionVerbCompositionId);
  }

  //მივიღოთ ზმნური კომპოზიცია იდენტიფიკატორის მიხედვით
  const inflectionVerbComposition = inflectionVerbCompositionId === null ? null : getInflectionVerbCompositionByIdFromStore(getState().derivTree.rootsRepo, inflectionVerbCompositionId);
  //console.log("funAfterSaveInflectionVerbComposition inflectionVerbComposition=", inflectionVerbComposition)

  let mustBeinflectionVerbCompositionId = inflectionVerbCompositionId;
  if (inflectionVerbComposition) 
    dispatch(actionCreators.GetVerbCompositionParadigm(inflectionVerbCompositionId));
  else
    mustBeinflectionVerbCompositionId = null;


  //მივიღოთ წინაპარი ზმნური ფლექსიების სია
  const preinflections = inflectionVerbComposition === null ? null : inflectionVerbComposition.inflectionVerbCompositionPredecessors.map(ip=>ip.parentInflectionId);

  //თუ inflectionId შედის წინაპარი ზმნური ფლექსიების სიაში, მაშინ გამოვიყენოთ inflectionId, თუ არა გამოვიყენოთ პირველივე სიიდან
  const infId = (!preinflections  || preinflections.length === 0 || ( inflectionId && preinflections.includes(inflectionId) ) ) ? inflectionId : preinflections[0];

  //მივიღოთ ფლექსია იდენტიფიკატორის მიხედვით
  const inflection = inflectionId === null ? null : getInflectionByIdFromStore(getState().derivTree.rootsRepo, infId);

  //მივიღოთ წინაპარი დერივაციების სია
  const preBranches = inflection === null ? null : inflection.inflectionPredecessors.map(ip=>ip.parentBranchId);
  
  //თუ derivBranchId შედის წინაპარი დერივაციების სიაში, მაშინ გამოვიყენოთ derivBranchId, თუ არა გამოვიყენოთ პირველივე სიიდან
  const dbrId = (!preBranches  || preBranches.length === 0 || ( derivBranchId && preBranches.includes(derivBranchId) ) ) ? derivBranchId : preBranches[0];

  //დადგენილი დერივაციისათვის მივიღოთ წინაპარი ძირების სია
  const branch = getBranchByIdFromStore(getState().derivTree.rootsRepo, dbrId);
  const predRootIds = funPredRoots(branch, getState().derivTree.rootsRepo);
  const duplicateInflectionVerbCompositionId = getState().rootEditorStore.duplicateInflectionVerbCompositionId;

  //თუ rootId შედის წინაპარი ძირების სიაში, მაშინ გამოვიყენოთ rootId, თუ არა გამოვიყენოთ პირველივე სიიდან
  const forOpenRootId = ( (!!rootId && predRootIds.includes(rootId)) || predRootIds.length === 0 ) ? rootId : predRootIds[0];


  //console.log("funAfterSaveInflectionVerbComposition {forOpenRootId, dbrId, infId, duplicateInflectionVerbCompositionId, mustBeinflectionVerbCompositionId}=", {forOpenRootId, dbrId, infId, duplicateInflectionVerbCompositionId, mustBeinflectionVerbCompositionId});

  if (forOpenRootId) {
    if (dbrId) {
      if (infId) {
        if (duplicateInflectionVerbCompositionId)
          history.push(`/root/${forOpenRootId}/${dbrId}/${infId}/${duplicateInflectionVerbCompositionId}`);
        else if (mustBeinflectionVerbCompositionId)
          history.push(`/root/${forOpenRootId}/${dbrId}/${infId}/${mustBeinflectionVerbCompositionId}`);
        else
          history.push(`/root/${forOpenRootId}/${dbrId}/${infId}`);
      }
      else
        history.push(`/root/${forOpenRootId}/${dbrId}`);
    }
    else
      history.push(`/root/${forOpenRootId}`);
  } else
    history.push("/basesearch");

}



async function getRootByDerivBranchId(dispatch, getState, derivBranchId) {
  
  startIfNotStarted(dispatch, getState);

  //console.log('DerivationTreeStore.js apiClient.Get', `/roots/getrootsbyderivationbranchid/${derivBranchId}`);
  return await apiClient.Get(`/roots/getrootsbyderivationbranchid/${derivBranchId}`,
    { get: null, 
      set: setRootsByBranchid, 
      failure: getRootsByBranchidFailure },
    dispatch, getState );
}

async function getRootByInflectionId(dispatch, getState, inflectionId) {
  
  startIfNotStarted(dispatch, getState);

  //console.log('DerivationTreeStore.js apiClient.Get', `/roots/getrootsbyinflectionid/${inflectionId}`);
  return await apiClient.Get(`/roots/getrootsbyinflectionid/${inflectionId}`,
    { get: null, 
      set: setRootsByInflectionId, 
      failure: getRootsByInflectionIdFailure },
    dispatch, getState );
}

async function getRootByInflectionVerbCompositionId(dispatch, getState, inflectionVerbCompositionId) {
  
  startIfNotStarted(dispatch, getState);

  //console.log('DerivationTreeStore.js apiClient.Get', `/roots/getrootsbyinflectionverbcompositionid/${inflectionVerbCompositionId}`);
  return await apiClient.Get(`/roots/getrootsbyinflectionverbcompositionid/${inflectionVerbCompositionId}`,
    { get: null, 
      set: setRootsByInflectionVerbCompositionId, 
      failure: getRootsByInflectionVerbCompositionIdFailure },
    dispatch, getState );
}

function haveInflectionPredRoots(getState, inflection) {
  for (var pre of inflection.inflectionPredecessors.map((itm) => getBranchByIdFromStore(getState().derivTree.rootsRepo, itm.parentBranchId))) {
    if (! pre)
      return false;
    if (! havePredRoots(getState, pre))
      return false;
  }
  return true;
}

function haveInflectionVerbCompositionPredRoots(getState, inflectionVerbComposition) {
  for (var pre of inflectionVerbComposition.inflectionVerbCompositionPredecessors.map((itm) => getVerbInflectionByIdFromStore(getState().derivTree.rootsRepo, itm.parentInflectionId))) {
    if (! pre)
      return false;
    if (! haveInflectionPredRoots(getState, pre))
      return false;
  }
  return true;
}

 function havePredRoots(getState, branch) {
  if (branch.derivationPredecessors.length === 0) {
    const rootData = Object.values(getState().derivTree.rootsRepo)
      .find((rtd) => rtd?.root?.rootFirstBranchId === branch.dbrId);
    if (rootData) {
      return true;
    }
    return false;
  }
  for (var pre of branch.derivationPredecessors.map((itm) => getBranchByIdFromStore(getState().derivTree.rootsRepo, itm.parentBranchId))) {
    if (! pre)
      return false;
    if (! havePredRoots(getState, pre))
      return false;
  }
  return true;
}

export const actionCreators = {

  clearRoot: (rootId) => {
    return { type: removeOneRootFromRepo, payload: rootId };
  },

  clearRoots: () => {
    return { type: removeAllRootsFromRepo };
  },

  clearRootsByDerivation: (dbrId) => (dispatch, getState) => {
    const branch = getBranchByIdFromStore(getState().derivTree.rootsRepo, dbrId);
    const predRootIds = funPredRoots(branch, getState().derivTree.rootsRepo);
    dispatch({ type: removeRootsFromRepo, payload: predRootIds });
  },

  Showhyphens: (turnOn) => {
    return { type: prdShowhyphens, value: turnOn };
  },

  ShowFormulas: (turnOn) => {
    return { type: prdShowFormulas, value: turnOn };
  },

  SwithcIspMorphemeNom: (morphemeNom) => {
    return { type: prdIspMorphemeNom, value: morphemeNom };
  },

  // clearDeletingFailure: () => {
  //   return { type: clearDeletingFailure };
  // },
  clearForConfirmRootsPagesMemo: () => {
    return { type: clearForConfirmRootsPagesMemo };
  },


  clearMemo: () => {
    return { type: clearMemo };
  },

  clearVerbRowParadigmsByVerbTypes: () => {
    return { type: clearVerbRowParadigmsByVerbTypes };
  },

  GetBasesForDropDown: (searchValue) => async (dispatch, getState) => {

    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    if (searchValue && getState().derivTree.memoBasesDict[searchValue])
      return;
    //console.log('DerivationTreeStore.js apiClient.Get', `/roots/getbasesbypages/${searchValue}/${dropdownLinesCount}/1`);
    await apiClient.Get(`/roots/getbasesbypages/${searchValue}/${dropdownLinesCount}/1`,
      { get: requestBasesForDropdown, 
        set: setBasesForDropdown, 
        failure: getBasesForDropdownFailure },
      dispatch, getState, true, { searchValue: searchValue } );
  },

  GetVerbsForDropDown: (searchValue) => async (dispatch, getState) => {

    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    if (searchValue && getState().derivTree.memoVerbsDict[searchValue])
      return;
    //console.log('DerivationTreeStore.js apiClient.Get', `/roots/getverbsbypages/${searchValue}/${dropdownLinesCount}/1`);
    await apiClient.Get(`/roots/getverbsbypages/${searchValue}/${dropdownLinesCount}/1`,
      { get: requestVerbsForDropdown, 
        set: setVerbsForDropdown, 
        failure: getVerbsForDropdownFailure },
      dispatch, getState, true, { searchValue: searchValue } );
  },

  GetParadigm: (infId) => async (dispatch, getState) => {
    if ( infId )
    {
    await apiClient.Get(`/roots/getparadigm/${infId}`,
      {
        get: getParadigm,
        set: setParadigm,
        failure: failureParadigm
      },
      dispatch, getState);
    }
    else
      dispatch({ type: clearParadigm });
  },

  GetVerbCompositionParadigm: (ivcId) => async (dispatch, getState) => {
    if ( ivcId )
    {
    await apiClient.Get(`/roots/getverbcompositionparadigm/${ivcId}`,
      {
        get: getVerbCompositionParadigm,
        set: setVerbCompositionParadigm,
        failure: failureVerbCompositionParadigm
      },
      dispatch, getState);
    }
    else
      dispatch({ type: clearVerbCompositionParadigm });
  },

  GetVerbRowParadigmsByVerbTypes: () => async (dispatch, getState) => {
    await apiClient.Get(`/model/getverbparadigmsbyverbtypes`,
      {
        get: GetVerbRowParadigmsByVerbTypesRequest,
        set: GetVerbRowParadigmsByVerbTypesSuccess,
        failure: GetVerbRowParadigmsByVerbTypesFailure
      },
      dispatch, getState);
  },

  saveParadigmSamplePositions: (infId, samples) => async (dispatch, getState) => {
    if ( infId )  {
      await apiClient.Put(`/roots/saveparadigmsamplepositions/${infId}`,
        {
          get: putsaveParadigmSamplePositionsRequest,
          set: putsaveParadigmSamplePositionsSuccess,
          failure: putsaveParadigmSamplePositionsFailure
        },
        dispatch, getState, true, null, samples);
    }
    else
      dispatch({ type: clearParadigm });
  },
  

  saveVerbCompositionParadigmSamplePositions: (infId, samples) => async (dispatch, getState) => {
    if ( infId )  {
      await apiClient.Put(`/roots/saveverbcompositionparadigmsamplepositions/${infId}`,
        {
          get: putsaveParadigmSamplePositionsRequest,
          set: putsaveParadigmSamplePositionsSuccess,
          failure: putsaveParadigmSamplePositionsFailure
        },
        dispatch, getState, true, null, samples);
    }
    else
      dispatch({ type: clearParadigm });
  },
      
  paradigmSaveSamples: (infId) => async (dispatch, getState) => {
    if ( infId )  {
      await apiClient.Put(`/roots/paradigmsavesamples/${infId}`,
      {
        get: putParadigmSamplesSaveRequest,
        set: putParadigmSamplesSaveSuccess,
        failure: putParadigmSamplesSaveFailure
      },
      dispatch, getState);
    }
    else
      dispatch({ type: clearParadigm });
    },

    GetUsersListForConfirm: () => async (dispatch, getState) => {
      await apiClient.Get(`/roots/getuserslistforconfirm`,
        {
          get: getUsersListForConfirmRequest,
          set: getUsersListForConfirmSuccess,
          failure: getUsersListForConfirmFailure
        },
        dispatch, getState);
      },

    GetForConfirmRootsByPages: (rootStartsWith, createdUserName, itemsPerPage, pageNom, pagekey) => async (dispatch, getState) => {
    
      dispatch({ type: forConfirmRootsListPageLoading });
      const val = `${rootStartsWith}_${createdUserName}`;
      //შევამოწმოთ შესაბამისი რაოდენობა ჩატვირთულია თუ არა 
      if (!getState().derivTree.memoForConfirmRootsCounts[val]) {
        const url = createUrlForConfirmRoots('/roots/getforconfirmrootscount', rootStartsWith, createdUserName);
        //console.log('DerivationTreeStore.js apiClient.Get', url);
        await apiClient.Get(url,
          {
            get: null,
            set: setForConfirmRootsCount,
            failure: getForConfirmRootsCountFailure
          },
          dispatch, getState, true,
          { searchValue: val });
      }

    //შევამოწმოთ შესაბამისი გვერდი ჩატვირთულია თუ არა
    if (!getState().derivTree.memoForConfirmRootsPages[pagekey]) {
      const url = createUrlForConfirmRoots(`/roots/getforconfirmrootsbypages/${itemsPerPage}/${pageNom}`, rootStartsWith, createdUserName);
      //console.log('DerivationTreeStore.js apiClient.Get', url);
      await apiClient.Get(url,
        {
          get: null,
          set: setForConfirmRootsPage,
          failure: getForConfirmRootsPageFailure
        },
        dispatch, getState, true,
        { pagekey });
    }



      dispatch({ type: forConfirmRootsListPageLoadingFinish });
    },

    GetBasesByPages: (val, itemsPerPage, pageNom, pagekey) => async (dispatch, getState) => {
        
    dispatch({ type: basesPageLoading });
    //შევამოწმოთ შესაბამისი რაოდენობა ჩატვირთულია თუ არა 
    if (!getState().derivTree.memoBaseCounts[val]) {
      //console.log('DerivationTreeStore.js apiClient.Get', `/roots/getbasescount/${val}`);
      await apiClient.Get(`/roots/getbasescount/${val}`,
        {
          get: null,
          set: setBasesCount,
          failure: getBasesCountFailure
        },
        dispatch, getState, true,
        { searchValue: val });
    }

    //შევამოწმოთ შესაბამისი გვერდი ჩატვირთულია თუ არა
    if (!getState().derivTree.memoBasePages[pagekey]) {
      //console.log('DerivationTreeStore.js apiClient.Get', `/roots/getbasesbypages/${val}/${itemsPerPage}/${pageNom}`);
      await apiClient.Get(`/roots/getbasesbypages/${val}/${itemsPerPage}/${pageNom}`,
        {
          get: null,
          set: setBasesPage,
          failure: getBasesPageFailure
        },
        dispatch, getState, true,
        { pagekey });
    }
    dispatch({ type: basesPageLoadingFinish });
  },

  getOneRootById: (rootId) => async (dispatch, getState) => {

    await getRootById(dispatch, getState, rootId);
    finishIfStarted(dispatch, getState);
  },

  checkLoadRootsByBranchId: (dbrId) => async (dispatch, getState) => {
    //დავადგინოთ ამ იდენტიფიკატორით დერივაციის ტოტი ჩატვირთული გვაქვს თუ არა
    if (dbrId) {
      const branch = getBranchByIdFromStore(getState().derivTree.rootsRepo, dbrId);
      if (!branch || !havePredRoots(getState, branch)) {
        await getRootByDerivBranchId(dispatch, getState, dbrId);
      }
    }

    finishIfStarted(dispatch, getState);

  },

  CheckLoadRootsByInflectionId: (infId) => async (dispatch, getState) => {
    //დავადგინოთ ამ იდენტიფიკატორით ფლექსიის ჩანაწერი ჩატვირთული გვაქვს თუ არა
    if (infId) {
      const inflection = getInflectionByIdFromStore(getState().derivTree.rootsRepo, infId);
      if (!inflection || !haveInflectionPredRoots(getState, inflection)) {
        await getRootByInflectionId(dispatch, getState, infId);
      }
    }

    finishIfStarted(dispatch, getState);
  },

  CheckLoadRootsByInflectionVerbCompositionId: (ivcId) => async (dispatch, getState) => {
    //დავადგინოთ ამ იდენტიფიკატორით ფლექსიის ჩანაწერი ჩატვირთული გვაქვს თუ არა
    if (ivcId) {
      const inflection = getInflectionVerbCompositionByIdFromStore(getState().derivTree.rootsRepo, ivcId);
      if (!inflection || !haveInflectionVerbCompositionPredRoots(getState, inflection)) {
        await getRootByInflectionVerbCompositionId(dispatch, getState, ivcId);
      }
    }

    finishIfStarted(dispatch, getState);
  }

};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === clearMemo) {
    return { ...state, memoBasesDict: {}, memoBaseCounts: {}, memoBasePages: {} };
  }

  if (action.type === clearForConfirmRootsPagesMemo) {
    return { ...state, memoForConfirmRootsCounts: {}, memoForConfirmRootsPages: {} };
  }
  




  if (action.type === requestBasesForDropdown) {
    return { ...state, basesForDropdownloading: true };
  }
  
  if (action.type === setBasesForDropdown) {
    const newMemoBasesDict = state.memoBasesDict;
    const data = action.payload.data;
    const { searchValue } = action.payload.params;
    newMemoBasesDict[searchValue] = data;
    return { ...state, memoBasesDict: newMemoBasesDict, basesForDropdownloading: false };
  }
  
  if (action.type === getBasesForDropdownFailure) {
    const newMemoBasesDict = state.memoBasesDict;
    const { searchValue } = action.payload.params;
    newMemoBasesDict[searchValue] = null;
    return { ...state, memoBasesDict: newMemoBasesDict, basesForDropdownloading: false };
  }

  if (action.type === getUsersListForConfirmRequest) {
    return { ...state, UsersListForConfirmDropdownloading: true };
  }
  
  if (action.type === getUsersListForConfirmSuccess) {
    const data = action.payload.data;
    return { ...state, UsersListForConfirm: data, UsersListForConfirmDropdownloading: false };
  }
  
  if (action.type === getUsersListForConfirmFailure) {
    return { ...state, UsersListForConfirm: null, UsersListForConfirmDropdownloading: false };
  }

  if (action.type === requestVerbsForDropdown) {
    return { ...state, verbsForDropdownloading: true };
  }
  
  if (action.type === setVerbsForDropdown) {
    const newMemoVerbsDict = state.memoVerbsDict;
    const data = action.payload.data;
    const { searchValue } = action.payload.params;
    newMemoVerbsDict[searchValue] = data;
    return { ...state, memoVerbsDict: newMemoVerbsDict, verbsForDropdownloading: false };
  }
  
  if (action.type === getVerbsForDropdownFailure) {
    const newMemoVerbsDict = state.memoVerbsDict;
    const { searchValue } = action.payload.params;
    newMemoVerbsDict[searchValue] = null;
    return { ...state, memoVerbsDict: newMemoVerbsDict, verbsForDropdownloading: false };
  }

  if (action.type === basesPageLoading) {
    return { ...state, basesPageLoading: true };
  }
  
  if (action.type === basesPageLoadingFinish) {
    return { ...state, basesPageLoading: false };
  }

  if (action.type === setBasesCount) {
    const newMemoBaseCounts = state.memoBaseCounts;
    const data = action.payload.data;
    const { searchValue } = action.payload.params;
    newMemoBaseCounts[searchValue] = data;
    return { ...state, memoBaseCounts: newMemoBaseCounts };
  }

  if (action.type === getBasesCountFailure) {
    const newMemoBaseCounts = state.memoBaseCounts;
    const { searchValue } = action.payload.params;
    newMemoBaseCounts[searchValue] = null;
    return { ...state, memoBaseCounts: newMemoBaseCounts };
  }

  if (action.type === setBasesPage) {
    const newMemoBasePages = state.memoBasePages;
    const data = action.payload.data;
    const { pagekey } = action.payload.params;
    newMemoBasePages[pagekey] = data;
    return { ...state, memoBasePages: newMemoBasePages };
  }
  
  if (action.type === getBasesPageFailure) {
    const newMemoBasePages = state.memoBasePages;
    const { pagekey } = action.payload.params;
    newMemoBasePages[pagekey] = null;
    return { ...state, memoBasePages: newMemoBasePages };
  }
  
  if (action.type === startLoading) {
    return { ...state, rootLoading: true };
  }

  if (action.type === finishLoading) {
    return { ...state, rootLoading: false };
  }

  if (action.type === clearParadigm) {
    return { ...state, inflectionWorkingOnLoadParadigm: false, paradigm: null };
  }




  if (action.type === getParadigm || action.type === getVerbCompositionParadigm) {
    return { ...state, inflectionWorkingOnLoadParadigm: true, paradigm: null };
  }

  if (action.type === setParadigm || action.type === setVerbCompositionParadigm) {
    const data = action.payload.data;
    //console.log("DerivationTreeStore reducer setParadigm data=", data);
    return { ...state, inflectionWorkingOnLoadParadigm: false, paradigm: data };
  }

  if (action.type === failureParadigm || action.type === failureVerbCompositionParadigm) {
    return { ...state, inflectionWorkingOnLoadParadigm: false, paradigm: null };
  }






  if (action.type === setOneRootByid) {
    const data = action.payload.data;
    const newRootsRepo = state.rootsRepo;
    const rootId = data.root.rootId;
    newRootsRepo[rootId] = data;
    //console.log("DerivationTreeStore reducer setOneRootByid newRootsRepo=", newRootsRepo);
    return { ...state, rootsRepo: newRootsRepo };
  }

  if (action.type === setRootsByBranchid || action.type === setRootsByInflectionId || action.type === setRootsByInflectionVerbCompositionId) {

    const newRootsRepo = state.rootsRepo;
    for (var rootData of action.payload.data) {
      newRootsRepo[rootData.root.rootId] = rootData;
      //console.log(`Root ${rootData.root.rootId}:${rootData.root.rootName}/${rootData.root.rootHomonymIndex} loaded`)
    }
    //console.log("DerivationTreeStore reducer setRootsByBranchid newRootsRepo=", newRootsRepo);
    return { ...state, rootsRepo: newRootsRepo };
  }

  if (action.type === getOneRootByidFailure || 
    action.type === getRootsByBranchidFailure || 
    action.type === getRootsByInflectionIdFailure || 
    action.type === getRootsByInflectionVerbCompositionIdFailure) {
    return { ...state, rootsRepo: {} };
  }

  if (action.type === removeAllRootsFromRepo) {
    return { ...state, rootsRepo: {} };
  }
  
  if (action.type === removeOneRootFromRepo) {
    const rootId = action.payload;
    const newRootsRepo = state.rootsRepo;
    delete state.rootsRepo[rootId];
    return { ...state, rootsRepo: newRootsRepo };
  }

  if (action.type === removeRootsFromRepo) {
    const predRootIds = action.payload;
    const newRootsRepo = state.rootsRepo;
    predRootIds.forEach((rootId) => { newRootsRepo[rootId] = null; });
    return { ...state, rootsRepo: newRootsRepo };
  }

  if (action.type === prdShowhyphens) {
    return { ...state, prdShowhyphens: action.value };
  }

  if (action.type === prdShowFormulas) {
    return { ...state, prdShowFormulas: action.value };
  }

  if (action.type === prdIspMorphemeNom) {
    return { ...state, prdIspMorphemeNom: action.value };
  }

  if (action.type === putParadigmSamplesSaveRequest) {
    return { ...state, savingParadigmSamples: true };
  }

  if (action.type === putParadigmSamplesSaveSuccess) {
    return { ...state, savingParadigmSamples: false };
  }

  if (action.type === putParadigmSamplesSaveFailure) {
    return { ...state, savingParadigmSamples: false };
  }


  if (action.type === putsaveParadigmSamplePositionsRequest) {
    return { ...state, savingParadigmSamplePositions: true };
  }

  if (action.type === putsaveParadigmSamplePositionsSuccess) {
    return { ...state, savingParadigmSamplePositions: false };
  }

  if (action.type === putsaveParadigmSamplePositionsFailure) {
    return { ...state, savingParadigmSamplePositions: false };
  }



  if (action.type === clearVerbRowParadigmsByVerbTypes) {
    return { ...state, verbRowParadigmsByVerbTypesLoading: false, verbRowParadigmsByVerbTypes: null, verbRowParadigmsByVerbTypesLoadingFailure: false };
  }

  if (action.type === GetVerbRowParadigmsByVerbTypesRequest) {
    return { ...state, verbRowParadigmsByVerbTypesLoading: true, verbRowParadigmsByVerbTypes: null, verbRowParadigmsByVerbTypesLoadingFailure: false };
  }

  if (action.type === GetVerbRowParadigmsByVerbTypesSuccess) {
    const data = action.payload.data;
    return { ...state, verbRowParadigmsByVerbTypesLoading: false, verbRowParadigmsByVerbTypes: data, verbRowParadigmsByVerbTypesLoadingFailure: false };
  }

  if (action.type === GetVerbRowParadigmsByVerbTypesFailure) {
    return { ...state, verbRowParadigmsByVerbTypesLoading: false, verbRowParadigmsByVerbTypes: null, verbRowParadigmsByVerbTypesLoadingFailure: true };
  }

  if (action.type === setForConfirmRootsCount) {
    const newMemoForConfirmRootsCounts = state.memoForConfirmRootsCounts;
    const data = action.payload.data;
    const { searchValue } = action.payload.params;
    newMemoForConfirmRootsCounts[searchValue] = data;
    return { ...state, memoForConfirmRootsCounts: newMemoForConfirmRootsCounts };
  }

  if (action.type === getBasesCountFailure) {
    const newMemoForConfirmRootsCounts = state.memoForConfirmRootsCounts;
    const { searchValue } = action.payload.params;
    newMemoForConfirmRootsCounts[searchValue] = null;
    return { ...state, memoForConfirmRootsCounts: newMemoForConfirmRootsCounts };
  }

  if (action.type === setForConfirmRootsPage) {
    const newMemoForConfirmRootsPages = state.memoForConfirmRootsPages;
    const data = action.payload.data;
    const { pagekey } = action.payload.params;
    newMemoForConfirmRootsPages[pagekey] = data;
    return { ...state, memoForConfirmRootsPages: newMemoForConfirmRootsPages };
  }
  
  if (action.type === getForConfirmRootsPageFailure) {
    const newMemoForConfirmRootsPages = state.memoForConfirmRootsPages;
    const { pagekey } = action.payload.params;
    newMemoForConfirmRootsPages[pagekey] = null;
    return { ...state, memoForConfirmRootsPages: newMemoForConfirmRootsPages };
  }
  
  if (action.type === forConfirmRootsListPageLoading) {
    return { ...state, forConfirmRootsListPageLoading: true };
  }
  
  if (action.type === forConfirmRootsListPageLoadingFinish) {
    return { ...state, forConfirmRootsListPageLoading: false };
  }






  return state;

};
