//CustomColumn.js

//import React from 'react';

const CustomColumn = (props) => {

  const { value, record, index, offset, showRows, onGetCell } = props;


  if ( onGetCell)
  {
    return onGetCell(value, record, index, offset, showRows);
  }


  return value;
}

export default CustomColumn;
