//ActantCombinationsReCounter.js

import React, {useEffect, useMemo, useCallback} from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap'

import Loading from '../../carcass/common/Loading';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';
import NameListEditor from './NameListEditor';
import { checkDataLoaded } from '../../carcass/modules/CheckDataLoaded'
import MdListView from '../../carcass/masterdata/MdListView';

const ActantCombinationsReCounter = (props) => {

  const { alert, isMenuLoading, flatMenu, loadMultipleListData, masterData} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = props.masterData;
  const { actantPositions, verbNumbers, verbPersons, actantCombinationDetails } = props.masterData.mdRepo;

  //console.log("ActantCombinationsReCounter props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["actantPositions", "verbNumbers", "verbPersons", "actantCombinationDetails"], []);

  const listTableNames = useMemo(() => ["actantCombinationDetails"], []);//

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    loadMultipleListData(listTableNames, tableNamesForLoad);
    
  }, [isMenuLoading, flatMenu, isValidPage, loadMultipleListData, listTableNames, tableNamesForLoad]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recId: NzInt(props.match.params.recName), 
    recName: props.match.params.recName
  });

  function saveReturnPageName() {
    props.saveReturnPageName(props.match.url.split('/')[1]);
  }

  const checkedDataTypes =[];
  const GridRules =[];
  let allListsLoaded = true;

  listTableNames.forEach(listTableName => {
    const checkResult = checkDataLoaded(masterData, listTableName, listTableName);
    if (checkResult) {
      const { dataType, gridRules } = checkResult;
      checkedDataTypes[listTableName] = dataType;
      GridRules[listTableName] = gridRules;
    }
    else
    allListsLoaded = false;
  });

  //console.log("ActantCombinationsReCounter onCheckLoad ", {actantPositions, verbNumbers, verbPersons, actantCombinationDetails, curscrollTo, mdWorkingOnLoad});

  if (mdWorkingOnLoad || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }

  if (!allListsLoaded || !curscrollTo || !actantPositions|| !verbNumbers || !verbPersons || !actantCombinationDetails || !datatypes) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  //debugger;

  const actantPositionsDict = {};
  
  actantPositions.forEach(element => {
    actantPositionsDict[element.apnId] = element;
  });

  const listEditorTableNames = ["actantPositions", "verbNumbers", "verbPersons"];

  return (
    <div>
      <h3>აქტანტების კომბინაციების დაანგარიშება</h3>
      <h4>ტერმინები</h4>
      <p><strong>აქტანტის პოზიცია</strong> - აქტანტის პოზიციის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის რიცხვი</strong> - ზმნის რიცხვის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის პირი</strong> - ზმნის პირის განმარტება (გასაკეთებელია).</p>

      {listEditorTableNames.map(tn => {
        const dataType = datatypes.find(f=>f.dtTable === tn);

        return (<NameListEditor key={dataType.dtName} dataType={dataType} tableForEdit={masterData.mdRepo[tn]} 
          curscrollTo={curscrollTo} backLigth={backLigth} saveReturnPageName={saveReturnPageName} />);
      } )}

      <h4>პროცესის აღწერა</h4>
      <p>განიხილება პოზიციების რაოდენობა 1 დან მაქსიმუმამდე ({actantPositions.length}). <br/>
      თითოეულ შემთხვევაში დაიანგარიშება პირებისა და რიცხვების ყველა შესაძლო კომბინაცია. <br/>
      თუ პირების რაოდენობა 1-ზე მეტია, არ შეიძლება პირველი ან მეორე პირი კომბინაციაში ერთზე მეტჯერ შეგვხვდეს.  <br/>
      თუ პირების რაოდენობა 2-ზე მეტია, არ შეიძლება კომბინაციაში ერთდროულად იყოს პირველი და მეორე პირი.  <br/>
      სამზე მეტ პირიანებისათვის მესამე პოზიციიდან დაწყებული ყველა პირი უნდა იყოს მესამე პირი.  <br/>
      ყველა არსებული კომბინაციიდან ვტოვებთ მხოლოდ იმათ, რომლებიც ზემოთ ჩამოთვლილ კრიტერიუმებს აკმაყოფილებს.</p>

      <h4>შედეგი</h4>


      <MdListView readOnly dataType={checkedDataTypes["actantCombinationDetails"]} 
        table={actantCombinationDetails
          .sort((a, b) => {
            const f = a.actantCombinationId-b.actantCombinationId;
            if ( f ) 
              return f;
            return actantPositionsDict[a.actantPositionId].sortId-actantPositionsDict[b.actantPositionId].sortId
          })
        } gridColumns={GridRules["actantCombinationDetails"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth}
        firstFilter={ { actantCombinationId: true } } 
        />

    </div>
  );


}

function mapStateToProps(state) {

  const alert = state.alert;
  const masterData = state.masterData;
  const {isMenuLoading, flatMenu} = state.navMenu;

  return {alert, isMenuLoading, flatMenu, masterData};
}

function mapDispatchToProps(dispatch) {
  return {
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    loadMultipleListData: (listTableNames, tableNamesForLoad) => dispatch(MasterDataActions.loadMultipleListData(listTableNames, listTableNames, tableNamesForLoad))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActantCombinationsReCounter);
