//AlertStore.js
const alertMessage = 'ALERT_MESSAGE';
const alertClear = 'ALERT_CLEAR';

const initialState = { message: null, type: null };

export const actionCreators = {
  success: (message) => ({ type: alertMessage, payload: {alerType: 'success', message} }),
  error: (message) => ({ type: alertMessage, payload: {alerType: 'danger', message} }),
  info: (message) => ({ type: alertMessage, payload: {alerType: 'info', message} }),
  primary: (message) => ({ type: alertMessage, payload: {alerType: 'primary', message} }),
  secondary: (message) => ({ type: alertMessage, payload: {alerType: 'secondary', message} }),
  warning: (message) => ({ type: alertMessage, payload: {alerType: 'warning', message} }),
  light: (message) => ({ type: alertMessage, payload: {alerType: 'light', message} }),
  dark: (message) => ({ type: alertMessage, payload: {alerType: 'dark', message} }),
  clear: () => ({ type: alertClear })
}

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === alertMessage) {
    const {alerType, message} = action.payload;
    return { message: message, type: alerType };
  }

  if (action.type === alertClear) {
    //console.log("alertClear");
    return { message: null, type: null };
  }

  return state;
};
