//InflectionVerbCompositionPredecessors.js
import React from 'react'
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import TextBoxAutoComplete from './TextBoxAutoComplete';
import VerbInflectionLink from './VerbInflectionLink';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';

import { actionCreators as DerivTreeActions, getVerbInflectionByIdFromStore } from './DerivationTreeStore';

const InflectionVerbCompositionPredecessors = (props) => {

  //რედაქსიდან
  const { rootsRepo, memoVerbsDict, verbsForDropdownloading } = props;
  //კონტროლის თვისებებიდან
  const {predecessors, verbCompositionLastRanges, getError, 
    onPredecessorChange, onPredecessorLastMorphemeRangeIdChange} = props;

  //console.log("InflectionVerbCompositionPredecessors props=", props);



  function getListItemById(memoVerbsDict, infId) {
    let Verb = null;
    for (var Verbs of Object.values(memoVerbsDict)) {
      Verb = Verbs.find((item) => item.infId === infId);
      if (Verb) {
        return Verb;
      }
    }
    return null;
  }


 const predecessorKeys = [0,1];

  return (
    <div>
      <OneStrongLabel controlId="InflectionVerbCompositionPredecessorsLabel" label="წინაპარი ზმნები" />

      {predecessorKeys.map((predKey, index) => {

        const predecessor = predecessors ? predecessors[predKey] : null;
        const parentInflectionId = predecessor ? predecessor.parentInflectionId : null;
        const lastMorphemeRangeId = predecessor ? predecessor.lastMorphemeRangeId : null;

        const selectLastRange = (predKey === 0);

        return (
          <div key={index}>

            <Form.Group className="mb-1" as={Row} controlId={`pred[${index}]`}>
              <Form.Label column sm="4" className="text-right">{`წინაპარი ${index+1}`}</Form.Label>
              <Col sm="6">


              <TextBoxAutoComplete listElementComponent={VerbInflectionLink} placeholder={`წინაპარი ${index+1}`} formControlClassName="mr-sm-2"
                  value={parentInflectionId}
                  memodict={memoVerbsDict}
                  onTextChange={(searchValue) => {
                    //range.text = searchValue;
                    onPredecessorChange(predKey, searchValue); /*setVerbSearchValue(searchValue)*/
                  }}
                  loadDropDown={(searchValue) => props.GetVerbsForDropDown(searchValue)}
                  // loading={verbsForDropdownloading}
                  onSelectedElement={(item) => {
                    //console.log("InflectionVerbCompositionPredecessors onSelectedElement {predKey, item}=", { predKey, item });
                    onPredecessorChange(predKey, item);
                  }}
                  getTextByValue={(val) => {
                    //console.log("VerbFreeMorphsChoicer getTextByValue val=", val);
                    let text = "";
                    const listItemVerb = getListItemById(memoVerbsDict, val);
                    if (listItemVerb) {
                      //const { roots } = listItemVerb;
                      //text = countText(listItemVerb.infName, roots);
                      text = listItemVerb.infName;
                    } else {
                      const preVerb = getVerbInflectionByIdFromStore(rootsRepo, val);
                      // const predRootIds = funPredRoots(preVerb, rootsRepo);
                      // const roots = predRootIds.map((rootId) => rootsRepo[rootId].root);
                      // text = countText(preVerb ? preVerb.infName : "", roots);
                      text = preVerb ? preVerb.infName : "";
                    }
                    //console.log("VerbFreeMorphsChoicer getTextByValue results text=", text);
                    return text;
                  }}
                  />
              </Col>
              <Col sd="2">
                  {verbsForDropdownloading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Col>              
            </Form.Group>




            { parentInflectionId && parentInflectionId > 0 && selectLastRange &&
              <OneComboBoxControl controlId={`lastMorphemeRangeId${predKey}`} 
                label={"ბოლო მორფემის რანგი"} 
                value={lastMorphemeRangeId} 
                dataMember={verbCompositionLastRanges} 
                valueMember="mrId" displayMember="mrKeyName" sortByDisplayMember={true}
                //firstItem={{ id: 3, name: "D-ფუძე" }}
                firstItemIsSelectable
                getError={getError} onChangeValue={(fieldPath, value) => {
                  onPredecessorLastMorphemeRangeIdChange(predKey, value);
                }} />
            }





          </div>
        );




      })}

    </div>
  );
}

function mapStateToProps(state) {
  const { rootsRepo, memoVerbsDict, verbsForDropdownloading } = state.derivTree;
  return { rootsRepo, memoVerbsDict, verbsForDropdownloading };
}

function mapDispatchToProps(dispatch) {
  return {
    GetVerbsForDropDown: (val) => dispatch(DerivTreeActions.GetVerbsForDropDown(val))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InflectionVerbCompositionPredecessors);
