//MdItemEdit.js
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

import { actionCreators as MasterDataActions } from './MasterDataStore';
import { actionCreators as alertActions } from '../store/AlertStore';
import { checkDataLoaded } from '../modules/CheckDataLoaded'
import WaitPage from '../common/WaitPage';
// import { useHistory } from "react-router-dom";
import { useForman } from '../../carcass/common/MyHooks';

import EditorHeader from '../editorParts/EditorHeader';
import OneTextControl from '../editorParts/OneTextControl';
import OneNumberControl from '../editorParts/OneNumberControl';
import OneComboBoxControl from '../editorParts/OneComboBoxControl';
import OneCheckBoxControl from '../editorParts/OneCheckBoxControl';
import OneDateBoxControl from '../editorParts/OneDateBoxControl';
import OneSaveCancelButtons from '../editorParts/OneSaveCancelButtons';
import OneErrorRow from '../editorParts/OneErrorRow';

const MdItemEdit = (props) => {
  
  //const history = useHistory();
  
  //1. იდენტიფიკატორი
  const [curMdIdVal, setCurMdIdVal] = useState(null);

  //1.1. ცხრილის სახელი
  const [curTableName, setCurTableName] = useState(null);
  //1.2. მონაცემთა ტიპის შესახებ ინფორმაცია
  const [curDataType, setCurDataType] = useState(null);
  //1.3. ველების შესახები ინფორმაცია
  const [curGridRules, setCurGridRules] = useState(null);
  //1.4. ველების სქემა
  const [curYupSchema, setCurYupSchema] = useState(null);
  //1.5. საჭირო დამხმარე ცხრილების სახელების სია
  //const [tableNamesForLoad, setTableNamesForLoad] = useState([]);

  //2. კომპონენტის თვისებები
  const { masterData, alert, mdWorkingOnLoad, mdWorkingOnSave, deletingKey, DeleteFailure, itemEditorTables,
    clearAlert, loadListData, returnPageName } = props;
  //console.log("MdItemEdit props=", props);

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData, setSchema] = useForman(curYupSchema);

  function countSchema(gridRules) {
    const fields = {};
    gridRules.cells.forEach((col)=>{
      let yupResult;

      switch(col.typeName) {
        case "Integer":
        case "Lookup":
          yupResult = yup.number();
          if ( typeof col.intMes === "string" ) {
            if ( col.intMes )
              yupResult = yupResult.integer(col.intMes);
            else
              yupResult = yupResult.integer();
          }
          if ( typeof col.minVal === "number" ) {
            yupResult = yupResult.min(col.minVal);
          }
          if ( col.isPositive ) {
            if ( col.positiveMes )
              yupResult = yupResult.positive(col.positiveMes);
            else
              yupResult = yupResult.positive();
          }
          if ( typeof col.def === "number" ) {
            yupResult = yupResult.default(col.def);
          }
          break;
        case "Boolean":
          yupResult = yup.boolean();
          break;
        case "Date":
          yupResult = yup.date();
          break;
        case "String":
          yupResult = yup.string();
          if ( typeof col.def === "string" ) {
            yupResult = yupResult.default(col.def);
          }
          if ( typeof col.maxLen === "number" ) {
            if ( col.positiveMes )
              yupResult = yupResult.max(col.maxLen, col.positiveMes);
            else
              yupResult = yupResult.max(col.maxLen);
          }
          break;
        default:
          throw new Error();
      }

      if ( col.isRequired ) {
        if ( col.req )
          yupResult = yupResult.required(col.req);
        else
          yupResult = yupResult.required();
      }
      if ( typeof col.isNullable === "boolean" ) {
        yupResult = yupResult.nullable(col.isNullable);
      }

      fields[col.fieldName] = yupResult;
    });
    return yup.object().shape(fields);
  }

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const { mdIdValue } = props.match.params;
    const tableName = props.match.params.dataType;

    if (curTableName !== tableName) {
      setCurTableName(tableName);
      clearAlert();
      loadListData(tableName);
      return;
    }

    if (mdWorkingOnLoad)
       return;

    const checkResult = checkDataLoaded(masterData, tableName, tableName);
    if ( !checkResult )
      return;

    const { dataType, gridRules } = checkResult;
    setCurDataType(dataType);
    setCurGridRules(gridRules);

    const YupSchema = countSchema(gridRules);
    setCurYupSchema(YupSchema);
    setSchema(YupSchema);

    if (curMdIdVal !== mdIdValue) {
      setCurMdIdVal(mdIdValue);
      if (mdIdValue !== undefined) {
        //console.log("MdItemEdit useEffect mdIdValue=", mdIdValue);
        //console.log("MdItemEdit useEffect tableName=", tableName);
        //console.log("MdItemEdit useEffect dataType.idFieldName=", dataType.idFieldName);
        //console.log("MdItemEdit useEffect masterData.mdRepo[tableName]=", masterData.mdRepo[tableName]);
        const mdItm = masterData.mdRepo[tableName].find(itm => { return itm[dataType.idFieldName] === parseInt(mdIdValue) });
        // dispatchMdItem({ type: "use", payload: mdItm });
        //ჩატვირთული ინფორმაცია მივცეთ ფორმის მენეჯერს
        //console.log("MdItemEdit useEffect finded mdItm=", mdItm);
        setFormData(mdItm);
        return;
      }
      // setCurMdIdVal(mdIdValue);
      // dispatchMdItem({ type: "createNew" });
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }

  }, [props.match.params, masterData, masterData.gridRules, masterData.mdRepo, mdWorkingOnLoad, curMdIdVal, curTableName, 
    clearToDefaults, setFormData, setSchema, clearAlert, loadListData]);

    //8. ჩატვირთვის შემოწმება
  let allNeedTablesLoaded = true;
  
  if ( itemEditorTables ) {
    for (let i=0; i < itemEditorTables.length; i++ ) {
      const tname = itemEditorTables[i];
      if ( !(tname in masterData.mdRepo) ) {
        allNeedTablesLoaded = false;
        break;
      }
      if (!masterData.mdRepo[tname]) {
        allNeedTablesLoaded = false;
        break;
      }
    }
  }

  //console.log("MdItemEdit before Check Loading {curDataType, curGridRules, curYupSchema, frm, allNeedTablesLoaded, mdWorkingOnLoad}=", {curDataType, curGridRules, curYupSchema, frm, allNeedTablesLoaded, mdWorkingOnLoad});


  if ( !curDataType || !curGridRules || !curYupSchema || !frm || !allNeedTablesLoaded ) { 
    if ( mdWorkingOnLoad ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
      return (<WaitPage />);
      return (
        <div>
          <h5>ჩატვირთვის პრობლემა</h5>
          {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        </div>
      );
    }

  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;

    if (curMdIdVal !== undefined) {
      props.updateMdItem(props.history, curDataType, frm);
    } else {
      props.addMdItem(props.history, curDataType, frm);
    }
  }

  const edObjKey = (frm && frm[curDataType.keyFieldName]) ? frm[curDataType.keyFieldName] : "";
  const edObjName = (frm && frm[curDataType.nameFieldName]) ? frm[curDataType.nameFieldName] : "";
  const editedObjectName = edObjKey + edObjKey !== "" ? " - " : "" + edObjName;


  //const isDeleting = (deletingKey === curTableName + frm[curDataType.idFieldName].toString());

  return (
    <Row id="MdItemEdit">
      <Col md="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form className="border border-secondary rounded" onSubmit={handleSubmit} noValidate>
          <EditorHeader curIdVal={curMdIdVal} EditorName={curDataType.dtNameNominative} EditorNameGenitive={curDataType.dtNameGenitive}
            EditedObjectName={editedObjectName}
            workingOnDelete={!!deletingKey} DeleteFailure={DeleteFailure}
            onDelete={() => {props.deleteMdItem (props.history, curTableName, curDataType.idFieldName, parseInt(curMdIdVal));}}
            onClearDeletingFailure={() => props.clearDeletingFailure()} allowDelete={curDataType.delete} />
          {curGridRules.cells.filter(col=>col.visible).map(field => {
            const caption = field.caption ? field.caption : "";
            const fieldName = field.fieldName ? field.fieldName : "";

            switch (field.typeName) {
              case "Lookup":
                if ( field.dataMember && field.valueMember && field.displayMember )
                  return (<OneComboBoxControl key={fieldName} controlId={fieldName} 
                    label={caption} value={frm[fieldName]} 
                    dataMember={masterData.mdRepo[field.dataMember]} valueMember={field.valueMember} displayMember={field.displayMember}
                    getError={getError} onChangeValue={changeField} 
                    firstItem={ { id: -1, name: `აირჩიე ${caption}` } } />);
                if ( field.rowSource ) {
                  const rows = [];
                  const rsarr = field.rowSource.split(";");
                  rsarr.forEach((item, index) => {
                    if (index % 2 === 0)
                      return;
                    rows.push({ val: rsarr[index-1], disp: item });
                  });
                  return (<OneComboBoxControl key={fieldName} controlId={fieldName} 
                    label={caption} value={frm[fieldName]} 
                    dataMember={rows} valueMember="val" displayMember="disp"
                    getError={getError} onChangeValue={changeField} 
                    firstItem={ { id: -1, name: `აირჩიე ${caption}` } } />);
                  }
                //თუ აქ მოვიდა კოდი, ნიშნავს, რომ კი არის მითითებული კომბო ბოქსი, მაგრამ პარამეტრები არ აქვს საკმარისი,
                //ამიტომ გამოვა ტექსტ ბოქსი
                break;
              case "Boolean":
                return (<OneCheckBoxControl key={fieldName} controlId={fieldName} label={caption} value={frm[fieldName]} 
                  getError={getError} onChangeValue={changeField} />);
              case "Date":
                return (<OneDateBoxControl key={fieldName} controlId={fieldName} label={caption} 
                  value={frm[fieldName]} showDate={!!field.showDate} showTime={!!field.showTime} 
                  getError={getError} onChangeValue={changeField} />);
              case "Integer":
                if ( field.isSortId )
                  return (<OneNumberControl key={fieldName} controlId={fieldName} label={caption} 
                    value={frm[fieldName]+1} getError={getError} onChangeValue={(id, value) => {
                      changeField(id, value-1);
                    }} />);
                else
                  return (<OneNumberControl key={fieldName} controlId={fieldName} label={caption} 
                    value={frm[fieldName]} getError={getError} onChangeValue={changeField} />);
              default:
                break;
              }
            //თყ არცერთი ნაცნობი ვარიანტი არ იყო მითითებული, ავიღებთ ტექსტ ბოქსს              
            return (<OneTextControl key={fieldName} controlId={fieldName} label={caption} value={frm[fieldName]} 
              getError={getError} onChangeValue={changeField} />);
          })}

          <OneSaveCancelButtons curIdVal={curMdIdVal} haveErrors={haveErrors} savingNow={mdWorkingOnSave} onCloseClick={() => { 
            props.clearAlert(); 
            //history.goBack(); 
            const realReturnPageName = returnPageName ? returnPageName : "mdList";
            props.history.push(`/${realReturnPageName}/${curTableName}/${curMdIdVal}`);
          }} allowEdit={curDataType.update} />
          <OneErrorRow allErrors={allErrors} />

        </Form>
      </Col>
    </Row>
  );


}

function mapStateToProps(state) {
  const masterData = state.masterData;
  const { mdWorkingOnLoad, mdWorkingOnSave, deletingKey, DeleteFailure, itemEditorTables, returnPageName } = state.masterData;
  const alert = state.alert;
  return { masterData, alert, mdWorkingOnLoad, mdWorkingOnSave, deletingKey, DeleteFailure, itemEditorTables, returnPageName };
}

function mapDispatchToProps(dispatch) {
  return {
    addMdItem: (history, dataType, mdItem) => dispatch(MasterDataActions.addMdItem(history, dataType.dtTable, dataType.idFieldName, mdItem)),
    updateMdItem: (history, dataType, mdItem) => dispatch(MasterDataActions.updateMdItem(history, dataType.dtTable, dataType.idFieldName, mdItem)),
    loadListData: (tableName) => dispatch(MasterDataActions.loadListData(tableName, tableName)),
    deleteMdItem: (history, tableName, idFielName, idValue) => dispatch(MasterDataActions.deleteMdItem(history, tableName, idFielName, idValue)),
    clearDeletingFailure: () => dispatch(MasterDataActions.clearDeletingFailure()),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MdItemEdit);
