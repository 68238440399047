//BaseLink.js

import React from 'react';
import { Link } from 'react-router-dom';

const BaseLink = (props) => {
  const { searchVal, item, viewLinks } = props;
  //console.log("BaseLink Start props=", props);

  /*make the matching letters bold:*/
  const wparts = item.dbrBaseName.split(searchVal);
  const strongpart = (<strong>{searchVal}</strong>);

  function wrapLink(source, href, key) {
    if (href) {
      if (key)
        return (<Link key={key} to={href}>{source}</Link>);
      else
        return (<Link key={0} to={href}>{source}</Link>);
    }
    return source;
  }


  return (
    <span>
      {wrapLink(wparts.map((itm, indx) => {
        if (indx === 0)
          return (<span key={indx}>{itm}</span>);
        return (<span key={indx}>{strongpart}{itm}</span>);
      }), viewLinks?getBaseHref(item,0):null )}
      {" ("}
      {item.roots && item.roots.map((rootItem, indx) => {
        return wrapLink(
          (<span key={indx}> 
            {indx ? "; " : ""}
            {rootItem.rootName}
            {(rootItem.rootHomonymIndex > 0) && <sup>{rootItem.rootHomonymIndex}</sup>}
            {(rootItem.rootNote) && <span> {rootItem.rootNote}</span>}
          </span>),
          viewLinks ? getBaseHref(item,indx) : null, indx);
      })}
      {")"}
    </span>
  );
}

export default BaseLink;

export function getBaseHref(baseItem, rootnom) {
  //console.log("BaseLink getBaseHref baseItem=", baseItem);
  //console.log("BaseLink getBaseHref rootnom=", rootnom);
  // return baseItem ? (`/root/${baseItem.roots[rootnom].rootId}/${baseItem.dbrId}`) : "";

  if ( baseItem ) {
    if ( baseItem.roots[rootnom].rootFirstBranchId === baseItem.dbrId ) 
      return `/root/${baseItem.roots[rootnom].rootId}`;
    else
      return `/root/${baseItem.roots[rootnom].rootId}/${baseItem.dbrId}`;
  }
  else
    return "";


}
