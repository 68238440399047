//RightsStore.js
import apiClient from '../modules/ApiClient';

const drRequestLoadParent = "DR_REQUEST_LOAD_PARENT";
const drLoadParentSuccess = "DR_LOAD_PARENT_SUCCESS";
const drLoadParentFailure = "DR_LOAD_PARENT_FAILURE";

const drRequestLoadChild = "DR_REQUEST_LOAD_CHILD";
const drLoadChildSuccess = "DR_LOAD_CHILD_SUCCESS";
const drLoadChildFailure = "DR_LOAD_CHILD_FAILURE";

const drRequestLoadChecks = "DR_REQUEST_LOAD_CHECKS";
const drLoadChecksSuccess = "DR_LOAD_CHECKS_SUCCESS";
const drLoadChecksFailure = "DR_LOAD_CHILD_FAILURE";

const drSwitchWithCodes = "DR_SWITCH_WITH_CODES";
const drSwitchLinear = "DR_SWITCH_LINEAR";
const drAddRight = "DR_ADD_RIGHT";

const drStartSaving = "DR_START_SAVING";
const drSaveChangesSuccess = "DR_SAVE_CHANGES_SUCCESS";
const drSaveChangesFailure = "DR_SAVE_CHANGES_FAILURE";
const drClearForRefresh = "DR_CLEAR_FOR_REFRESH";
const drFinishRefresh = "DR_FINISH_REFRESH";

const drStartOptimising = "DRSTARTOPTIMISING";
const drOptimisingSuccess = "DROPTIMISINGSUCCESS";
const drOptimisingFailure = "DROPTIMISINGFAILURE";

const drSetSelectedChildDataType = "DR_SET_SELECTED_CHILD_DATATYPE";

const drSetSearchText = "DR_SET_SEARCH_TEXT";


const initialState = {
  drParentsLoading: false, drParentsRepo: {},
  drChildsLoading: false, drChildsRepo: {},
  drChecksLoading: false, drChecksRepo: {},
  drWithCodes: false, drLinear: false,
  changedRights: [],
  drWorkingOnSave: false,
  drWorkingOnRefresh: false,
  drWorkingOnOptimisation: false,
  selectedChildDataType: null,
  searchText: null
};

const eRightsEditorViewStyle = {
  NormalView: 0,
  ReverseView: 1
};


export function getChildrenDataTypes(drChildsRepo, dataType, drLinear, curRView, curParentDtKey) {
  let childrenDataTypes = [];
  if (!drLinear && curRView === "NormalView")
    childrenDataTypes = drChildsRepo[curRView][curParentDtKey].dataTypes.filter((w) => (
      w.dtParentDataTypeId !== null &&
      dataType.dtId === w.dtParentDataTypeId
    ));
  return childrenDataTypes;
}

export function createOneRight(drParentsRepo, curRView, curParentDtKey, curKey, dataType, retValue) {

  const dt = drParentsRepo[curRView].dataTypes.find((item) => item.dtKey === curParentDtKey);
  // const retVal = dt.returnValues.find((it) => it.key === curKey);
  const oneRight = {};
  if (curRView === "NormalView") {
    oneRight.parent = { dtId: dt.dtId, dKey: curKey };
    oneRight.child = { dtId: dataType.dtId, dKey: retValue.key };
  } else {
    oneRight.parent = { dtId: dataType.dtId, dKey: retValue.key };
    oneRight.child = { dtId: dt.dtId, dKey: curKey };
  }
  return oneRight;
}

function funAddOneRightAndChildren(dispatch, getState, dataType, oneRight, curRView, curParentDtKey) {

  dispatch({ type: drAddRight, value: oneRight });

  const { drChildsRepo, drLinear } = getState().rights;

  const childrenDataTypes = getChildrenDataTypes(drChildsRepo, dataType, drLinear, curRView, curParentDtKey);

  childrenDataTypes.forEach((chdt) => {
    chdt.returnValues.filter((f) => {
      if (curRView === "NormalView")
        return f.parentId === oneRight.child.dKey;
      return f.parentId === oneRight.parent.dKey;
    }).forEach((item) => {
      const nextOneRight = {};
      if (curRView === "NormalView") {
        nextOneRight.parent = { dtId: oneRight.parent.dtId, dKey: oneRight.parent.dKey };
        nextOneRight.child = { dtId: chdt.dtId, dKey: item.ivalue };
      } else {
        nextOneRight.parent = { dtId: chdt.dtId, dKey: item.ivalue };
        nextOneRight.child = { dtId: oneRight.child.dtId, dKey: oneRight.child.dKey };
      }
      nextOneRight.checked = oneRight.checked;
      funAddOneRightAndChildren(dispatch, getState, chdt, nextOneRight, curRView, curParentDtKey);
    });

  });
}

async function funLoadParentsTreeData(rView, dispatch, getState) {

  const { drParentsRepo } = getState().rights;

  if (drParentsRepo && rView in drParentsRepo && drParentsRepo[rView])
    return;

  const rViewId = (rView === "ReverseView") ? eRightsEditorViewStyle.ReverseView : eRightsEditorViewStyle.NormalView;

  //console.log('RightsStore.js apiClient.Get', `/rights/getparentstreedata/${rViewId}`);
  await apiClient.Get(`/rights/getparentstreedata/${rViewId}`,
    { get: drRequestLoadParent, set: drLoadParentSuccess, failure: drLoadParentFailure },
    dispatch, getState, true, { rView });

}

async function funLoadChildsTreeData(rView, dtKey, dispatch, getState) {

  await funLoadParentsTreeData(rView, dispatch, getState);

  if (!dtKey)
    return;

  const { drChildsRepo } = getState().rights;

  if (drChildsRepo && rView in drChildsRepo && drChildsRepo[rView] && dtKey in drChildsRepo[rView] && drChildsRepo[rView][dtKey])
    return;

  const rViewId = (rView === "ReverseView") ? eRightsEditorViewStyle.ReverseView : eRightsEditorViewStyle.NormalView;

  // const { drParentsRepo } = getState().rights;
  // const dt = drParentsRepo[rView].dataTypes.find((item) => item.dtKey === dtKey);
  // const parentTypeId = dt.dtId;

  await apiClient.Get(`/rights/getchildrentreedata/${dtKey}/${rViewId}`,
    { get: drRequestLoadChild, set: drLoadChildSuccess, failure: drLoadChildFailure },
    dispatch, getState, true, { rView, dtKey });
}

export const actionCreators = {

  switchWithCodes: (turnOn) => {
    return { type: drSwitchWithCodes, value: turnOn };
  },

  switchLinear: (turnOn) => {
    return { type: drSwitchLinear, value: turnOn };
  },

  setSearchText: (text) => {
    return { type: drSetSearchText, value: text };
  },

  setSelectedChildDataType: (dataType) => {
    return { type: drSetSelectedChildDataType, value: dataType };
  },


  addOneRightAndChildren: (dataType, oneRight, curRView, curParentDtKey) => (dispatch, getState) => {
    funAddOneRightAndChildren(dispatch, getState, dataType, oneRight, curRView, curParentDtKey);
  },

  turnAll: (curRView, curParentDtKey, curKey, selectedChildDataType, turnOn) => (dispatch, getState) => {

    const { drParentsRepo } = getState().rights;

    selectedChildDataType.returnValues.forEach((item) => {
      const oneRight = createOneRight(drParentsRepo, curRView, curParentDtKey, curKey, selectedChildDataType, item);
      oneRight.checked = turnOn;
      funAddOneRightAndChildren(dispatch, getState, selectedChildDataType, oneRight, curRView, curParentDtKey);
    });
  },


  // LoadParentsTreeData: (rView) => async (dispatch, getState) => {

  //   await funLoadParentsTreeData(rView, dispatch, getState);

  // },

  LoadChildsTreeData: (rView, dtKey) => async (dispatch, getState) => {

    await funLoadChildsTreeData(rView, dtKey, dispatch, getState);

  },

  LoadChildsTreeDataAndChecks: (rView, dtKey, key, refresh) => async (dispatch, getState) => {

    if (refresh)
      dispatch({ type: drClearForRefresh });

    await funLoadChildsTreeData(rView, dtKey, dispatch, getState);

    const { drChecksRepo } = getState().rights;

    if (!dtKey || !key)
      return;

    if (drChecksRepo && rView in drChecksRepo && drChecksRepo[rView] &&
      dtKey in drChecksRepo[rView] && drChecksRepo[rView][dtKey] &&
      key in drChecksRepo[rView][dtKey] && drChecksRepo[rView][dtKey][key])
      return;

    const rViewId = (rView === "ReverseView") ? eRightsEditorViewStyle.ReverseView : eRightsEditorViewStyle.NormalView;

    const { drParentsRepo } = getState().rights;
    if ( !drParentsRepo || ! (rView in drParentsRepo) || ! drParentsRepo[rView] )
      return;
    const dt = drParentsRepo[rView].dataTypes.find((item) => item.dtKey === dtKey);
    if ( ! dt )
      return;
    const parentTypeId = dt.dtId;

    //const retVal = dt.returnValues.find((itm) => itm.key === key);
    //const parentId = retVal.ivalue;

    //console.log('RightsStore.js apiClient.Post ', `/rights/halfchecks/${parentTypeId}/${key}/${rViewId}`);
    await apiClient.Get(`/rights/halfchecks/${parentTypeId}/${key}/${rViewId}`,
      { get: drRequestLoadChecks, set: drLoadChecksSuccess, failure: drLoadChecksFailure },
      dispatch, getState, true, { rView, dtKey, key });


    if (refresh)
      dispatch({ type: drFinishRefresh });

  },

  SaveDtaRightChanges: () => async (dispatch, getState) => {

    //dispatch({ type: drStartSaving });
    //await funSaveDrChanges(dispatch, getState);

    if (getState().rights.changedRights.length === 0)
      return false;
    //console.log('RightsStore.js apiClient.Post /rights/savedata');
    return await apiClient.Post("/rights/savedata",
      { get: drStartSaving, set: drSaveChangesSuccess, failure: drSaveChangesFailure },
      dispatch, getState, true,
      null,
      false,
      getState().rights.changedRights);

    //dispatch({ type: drFinishSaving });
  },

  OptimizeRights: () => async (dispatch, getState) => {
    //console.log('RightsStore.js apiClient.Post /rights/optimize');
    return await apiClient.Post("/rights/optimize",
      { get: drStartOptimising, set: drOptimisingSuccess, failure: drOptimisingFailure },
      dispatch, getState);
  }

};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === drSetSearchText) {
    return { ...state, searchText: action.value };
  }

  if (action.type === drClearForRefresh) {
    return { ...state, drParentsRepo: {}, drChildsRepo: {}, drChecksRepo: {}, changedRights: [], drWorkingOnRefresh: true };
  }

  if (action.type === drFinishRefresh) {
    return { ...state, drWorkingOnRefresh: false };
  }

  if (action.type === drSwitchWithCodes) {
    return { ...state, drWithCodes: action.value };
  }

  if (action.type === drSwitchLinear) {
    return { ...state, drLinear: action.value };
  }

  if (action.type === drAddRight) {

    const oneRight = action.value;
    const newChangedRights = state.changedRights.slice();

    const findedRightIndex = newChangedRights.findIndex((f) => f.parent.dtId === oneRight.parent.dtId &&
      f.parent.dKey === oneRight.parent.dKey &&
      f.child.dtId === oneRight.child.dtId &&
      f.child.dKey === oneRight.child.dKey);

    if (findedRightIndex === -1) {
      newChangedRights.push(oneRight);
    } else {
      newChangedRights[findedRightIndex].checked = oneRight.checked;
    }

    return { ...state, changedRights: newChangedRights };
  }

  if (action.type === drSetSelectedChildDataType) {
    const dataType = action.value;
    return { ...state, selectedChildDataType: dataType };
  }

  if (action.type === drRequestLoadParent) {
    return { ...state, drParentsLoading: true };
  }

  if (action.type === drLoadParentSuccess) {
    const newdrParentsRepo = state.drParentsRepo;
    const data = action.payload.data;
    const { rView } = action.payload.params;
    newdrParentsRepo[rView] = data;
    return { ...state, drParentsRepo: newdrParentsRepo, drParentsLoading: false };
  }

  if (action.type === drLoadParentFailure) {
    const newdrParentsRepo = state.drParentsRepo;
    const { rView } = action.payload.params;
    newdrParentsRepo[rView] = null;
    return { ...state, drParentsRepo: newdrParentsRepo, drParentsLoading: false };
  }

  if (action.type === drRequestLoadChild) {
    return { ...state, drChildsLoading: true };
  }

  if (action.type === drLoadChildSuccess) {
    const newdrChildsRepo = state.drChildsRepo;
    const data = action.payload.data;
    const { rView, dtKey } = action.payload.params;

    if (!newdrChildsRepo[rView])
      newdrChildsRepo[rView] = {};

    newdrChildsRepo[rView][dtKey] = data;
    return { ...state, drChildsRepo: newdrChildsRepo, drChildsLoading: false };
  }

  if (action.type === drLoadChildFailure) {
    const newdrChildsRepo = state.drChildsRepo;
    const { rView, dtKey } = action.payload.params;
    if ( newdrChildsRepo && rView in  newdrChildsRepo && newdrChildsRepo[rView] && dtKey in  newdrChildsRepo[rView] )
      newdrChildsRepo[rView][dtKey] = null;
    return { ...state, drChildsRepo: newdrChildsRepo, drChildsLoading: false };
  }

  if (action.type === drRequestLoadChecks) {
    return { ...state, drChecksLoading: true };
  }

  if (action.type === drLoadChecksSuccess) {
    const newDrChecksRepo = state.drChecksRepo;
    const data = action.payload.data;
    const { rView, dtKey, key } = action.payload.params;

    if (!newDrChecksRepo[rView])
      newDrChecksRepo[rView] = {};

    if (!newDrChecksRepo[rView][dtKey])
      newDrChecksRepo[rView][dtKey] = {};

    newDrChecksRepo[rView][dtKey][key] = data;
    return { ...state, drChecksRepo: newDrChecksRepo, drChecksLoading: false };
  }

  if (action.type === drLoadChecksFailure) {
    const newDrChecksRepo = state.drChecksRepo;
    const { rView, dtKey, key } = action.payload.params;

    if (!newDrChecksRepo[rView])
      newDrChecksRepo[rView] = {};

    if (!newDrChecksRepo[rView][dtKey])
      newDrChecksRepo[rView][dtKey] = {};

    newDrChecksRepo[rView][dtKey][key] = null;
    return { ...state, drChecksRepo: newDrChecksRepo, drChecksLoading: false };
  }


  if (action.type === drStartSaving) {
    return { ...state, drWorkingOnSave: true };
  }

  if (action.type === drSaveChangesFailure) {
    return { ...state, drWorkingOnSave: false };
  }

  if (action.type === drSaveChangesSuccess) {
    return { ...state, drChecksRepo: {}, changedRights: [], drWorkingOnSave: false };
  }

  if (action.type === drStartOptimising) {
    return { ...state, drWorkingOnOptimisation: true };
  }

  if (action.type === drOptimisingSuccess) {
    return { ...state, drChecksRepo: {}, changedRights: [], drWorkingOnOptimisation: false };
  }

  if (action.type === drOptimisingFailure) {
    return { ...state, drWorkingOnOptimisation: false };
  }

  return state;

};
