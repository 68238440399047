//PhoneticsCombEditor.js
import React from 'react'
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import OnePlusButton from '../editorParts/OnePlusButton';


const PhoneticsCombEditor = (props) => {

  const { controlGroupId, label, basePhoneticsChanges, getError, onChangeValue, onTrashButtonClick, onPlusButtonClick, phoneticsChangesQuery } = props;
  //console.log("PhoneticsCombEditor props=", props);

  return (
    <div>
      <OneStrongLabel controlId="phoneticsCombEditorLabel" label={label} />
      
      {basePhoneticsChanges && basePhoneticsChanges.map((item, index) => {
        return (<OneComboBoxControl key={index} controlId={`${controlGroupId}[${index}]`} label={`${index+1}`} 
          value={item} dataMember={phoneticsChangesQuery.filter(fc => !fc.onlyPhoneticsType )} 
          firstItem={{ id: 0, name: "აირჩიე ფონეტიკური ცვლილება" }}
          valueMember="phcId" displayMember="phcName" sortByDisplayMember={true} getError={getError} 
          onChangeValue={onChangeValue} onTrashButtonClick={() => {
            onTrashButtonClick(index);
          }} />);
      })}

      <OnePlusButton onClick={onPlusButtonClick} />

    </div>
  );


}

export default PhoneticsCombEditor;
