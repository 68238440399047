//OneCheckBoxControl.js
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const OneCheckBoxControl = (props) => {

  //onChange=handleChange
  const { controlId, label, value, getError, onChangeValue } = props;


  const error = getError(controlId);

  function handleChange(e) {
    const { id, checked } = e.target;
    onChangeValue(id, checked);
  }

  return (
    <Form.Group className="mb-1" as={Row} controlId={controlId}>
      <Form.Label column sm="4" className="text-right">{label}</Form.Label>
      <Col sm="6">
        <Form.Control type="checkbox" checked={value} onChange={handleChange} 
          className={!!error && "is-invalid"} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}

export default OneCheckBoxControl;
