import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { actionCreators as NavMenuActions } from './NavMenuStore';

import { ProjectContext } from '../../project/ProjectProvider';
//import { NzInt } from '../common/myFunctions'


//იდეა წამოღებულია https://bootstrapious.com/p/bootstrap-sidebar

const NavMenu = (props) => {

  const { navMenu, user, tabWindowId, getMainMenu } = props;
  //console.log("NavMenu props=", props);



  useEffect(() => {
    getMainMenu();
  }, [getMainMenu]);

  const { appName } = useContext(ProjectContext);
  
  return (
    <nav id="sidebar" className={navMenu.active ? 'active' : null}>
      <div className="sidebar-header">
        <h5><Link to={'/'}>{appName}</Link></h5>
      </div>
      <ul className="list-unstyled components">
        <li>
          <Link to="/profile"><FontAwesomeIcon icon="user" /> {user ? `${user.firstName} ${user.lastName} ${user.sequentialNumber ?? 0} ${tabWindowId}` : ""}</Link>
        </li>
        <li>
          <Link to="/"><FontAwesomeIcon icon="home" /> საწყისი გვერდი</Link>
        </li>

        {navMenu.mainMenu && navMenu.mainMenu.length > 0 && navMenu.mainMenu.map((item, index) => {
          if (item.menu && item.menu.length > 0)
          {
            if ( item.hidden )
            {
              return GetMenuList(item);
            }
            else
            {
              return (
                <li key={item.mengKey}>
                  <Link to="#" data-toggle="collapse" aria-expanded={item.expanded ? "true" : "false"}
                    className={"dropdown-toggle" + (item.expanded ? "" : " collapsed")} onClick={() => props.toggleexp(index)}>{item.mengName}
                  </Link>
                  <ul className={"list-unstyled collapse" + (item.expanded ? " show" : "")} id={item.mengKey} >
                    {GetMenuList(item)}
                  </ul>
                </li>
              );
            }
          }
          return (<div />);
        })}

        <li>
          <Link to="/login"><FontAwesomeIcon icon="sign-out-alt" /> გასასვლელი</Link>
        </li>
      </ul>
    </nav>
  );

}


function GetMenuList(item) {
  return item.menu.map((menuItem) => (
    <li key={menuItem.menKey}>
      <Link to={`/${menuItem.menLinkKey}${(menuItem.menValue ? `/${menuItem.menValue}` : "")}`}>
        {!!menuItem.menIconName && <FontAwesomeIcon icon={menuItem.menIconName} />} {menuItem.menName}
      </Link>
    </li>
  ));
}

function mapStateToProps(state) {
  const navMenu = state.navMenu;
  const {user, tabWindowId} = state.authentication;
  return { navMenu, user, tabWindowId };
}


function mapDispatchToProps(dispatch) {
  return {
    toggleexp: (submenuindex) => dispatch(NavMenuActions.toggleexp(submenuindex)),
    getMainMenu: () => dispatch(NavMenuActions.getMainMenu())
  };
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(NavMenu);


