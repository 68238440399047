//ApiClient.js
import { actionCreators as alertActions } from '../store/AlertStore';
import { actionCreators as UserActions } from '../user/UserStore';


function handleResponse(response, dispatch) {
  // debugger;
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    //console.log("ApiClient handleResponse data=", data);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        dispatch(UserActions.logout());
        //window.location.reload(true);
      }
      //const error = (data && data.message) || response.statusText; ეს დაკომენტარებული ვარიანტი გამოგვადგება, 
      //თუ შეცდომისას დაბრუნებული json შეიცავს message ველს
      //console.log("handleResponse data=", data);
      const error = ( (data && typeof data === 'string') ? data : response.statusText);
      return window.Promise.reject(error);
    }
    return data;
  });
}



async function doFetch(endpoint, requestOptions, actionTypes, dispatch, params = null, returnData = true, checkData = () => true) {

  let response = null;
  dispatch(alertActions.clear());
  try {
    response = await window.fetch(endpoint, requestOptions);
    let data = null;
    data = await handleResponse(response, dispatch);
    // debugger;

    if (checkData(data)) {
      if (actionTypes.set !== null)
        dispatch({ type: actionTypes.set, payload: { params, data } });
      return true;
    }
  } catch (err) {
    //debugger;
    console.log('ApiClient DoFetch response=', response);
    console.log('ApiClient DoFetch err=', err);
    if (actionTypes.failure !== null)
      dispatch({ type: actionTypes.failure, payload: { params } });
    //უნდა შემოწმდეს არის თუ არა დეველოპმენტის რეჟიმი და ისე გამოვიდეს
    dispatch(alertActions.error(err));
  }
  return false;
}


function initClient(getState, useAccessToken) {

  //const baseUrl = process.env.NODE_ENV === 'development'
  //  ? window.location.origin + '/api'
  //  : 'https://www.grammar.ge/api';

  const baseUrl = getState().params.baseUrl;
  //console.log("ApiClient initClient baseUrl=", baseUrl);

  let accessToken = null;
  if (useAccessToken)
    accessToken = getState().authentication.user.token;

  return { baseUrl, accessToken };
}


function prepareRequest(method, accessToken, bodyData = null) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf8'
  };
  if (accessToken) {
    headers['Authorization'] = 'Bearer ' + accessToken;
  }
  const requestOptions = {
    method: method,
    headers: headers
  };
  if (bodyData !== null) {
    requestOptions.body = JSON.stringify(bodyData);
  }
  return requestOptions;
}


const apiClient = {

  Get: async function (url, actionTypes, dispatch, getState, useAccessToken = true, params = null, returnData = true) {

    const { baseUrl, accessToken } = initClient(getState, useAccessToken);

    if (actionTypes.get !== null)
      dispatch({ type: actionTypes.get, payload: { params } });

    const requestOptions = prepareRequest('GET', accessToken);

    const endpoint = baseUrl + url;
    return await doFetch(endpoint, requestOptions, actionTypes, dispatch, params, returnData);

  },

  Post: async function (url, actionTypes, dispatch, getState, useAccessToken = true, params = null, returnData = true, bodyData = null, checkData = () => true) {

    const { baseUrl, accessToken } = initClient(getState, useAccessToken);

    if (actionTypes.get !== null)
      dispatch({ type: actionTypes.get, payload: { params } });

    const requestOptions = prepareRequest('POST', accessToken, bodyData);

    const endpoint = baseUrl + url;
    return await doFetch(endpoint, requestOptions, actionTypes, dispatch, params, returnData, checkData);

  },

  Delete: async function (url, actionTypes, dispatch, getState, useAccessToken = true, params = null) {

    const { baseUrl, accessToken } = initClient(getState, useAccessToken);

    if (actionTypes.get !== null)
      dispatch({ type: actionTypes.get, payload: { params } });

    const requestOptions = prepareRequest('DELETE', accessToken);

    const endpoint = baseUrl + url;
    return await doFetch(endpoint, requestOptions, actionTypes, dispatch, params, false);

  },

  Patch: async function (url, actionTypes, dispatch, getState, useAccessToken = true, params = null) {

    const { baseUrl, accessToken } = initClient(getState, useAccessToken);

    if (actionTypes.get !== null)
      dispatch({ type: actionTypes.get, payload: { params } });

    const requestOptions = prepareRequest('PATCH', accessToken);

    const endpoint = baseUrl + url;
    return await doFetch(endpoint, requestOptions, actionTypes, dispatch, params, false);

  },

  Put: async function (url, actionTypes, dispatch, getState, useAccessToken = true, params = null, bodyData = null) {

    const { baseUrl, accessToken } = initClient(getState, useAccessToken);

    if (actionTypes.get !== null)
      dispatch({ type: actionTypes.get, payload: { params } });

    const requestOptions = prepareRequest('PUT', accessToken, bodyData);

    const endpoint = baseUrl + url;
    return await doFetch(endpoint, requestOptions, actionTypes, dispatch, params, false);

  }

}

export default apiClient;
