//RootEdit.js
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

//import { useHistory } from "react-router-dom";
import WaitPage from '../../carcass/common/WaitPage';
import { useForman } from '../../carcass/common/MyHooks';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OneTextControl from '../../carcass/editorParts/OneTextControl';
import OneNumberControl from '../../carcass/editorParts/OneNumberControl';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import OnePlaintextRow from '../editorParts/OnePlaintextRow';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import StatusConfirmRejectPart from './StatusConfirmRejectPart';

import PhoneticsCombEditor from '../editorParts/PhoneticsCombEditor';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import { actionCreators as RootEditorActions } from './RootEditorStore';
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';


const RootEdit = (props) => {

  //const history = useHistory();

  //1. იდენტიფიკატორი
  const [curRootIdVal, setCurRootIdVal] = useState(null); 

  //2. კომპონენტის თვისებები
  const { alert, user, mdWorkingOnLoad, checkLoadMdTables, derivationTypes, classifiers, phoneticsChangesQuery,
    savingRoot, loadingRoot, workingOnDeleteRoot, rootForEdit, getOneRootById, DeleteFailure, 
    clearTablesFromRepo, clearRoot, clearMemo,
    workingOnConfirmRejectRootChange, confirmRejectFailure, 
    confirmRejectRootChange, clearConfirmRejectFailure, clearForConfirmRootsPagesMemo,
    clearAlert, updateRoot, createRoot, deleteRoot, clearDeletingFailure
  } = props;

  const fromParamsRootId = props.match.params.rootId;

  //console.log("RootEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => [], []);

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["derivationTypes", "classifiers", "phoneticsChangesQuery"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [tableNamesForLoad, checkLoadMdTables]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    root: yup.object().shape({
      rootId: yup.number().integer().default(0).required(),
      classifierId: yup.number().integer().default(0),
      rootName: yup.string().required("ძირის სახელი შევსებული უნდა იყოს").max(50, "ძირის სახელის სიგრძე არ შეიძლება იყოს 50 სიმბოლოზე მეტი").default(""),
      rootHomonymIndex: yup.number().integer().min(0, "ომონიმიის ინდექსი შეიძლება იყოს დადებითი, ან 0")
        .required("ომონიმიის ინდექსი შევსებული უნდა იყოს").default(0),
      rootNote: yup.string().max(255, "შენიშვნის სიგრძე არ შეიძლება იყოს 255 სიმბოლოზე მეტი").default("").nullable(true),
    }),
    basePhoneticsCombDetails: yup.array().ensure()
      .of(yup.number().integer().positive("გამოყოფილ უჯრაში ფონეტიკური ცვლილება არჩეული უნდა იყოს").default(0)
      .required())
  });

  //console.log("RootEdit yupSchema=", yupSchema);

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
    clearRoot(curRootIdVal);
    clearMemo();
    clearForConfirmRootsPagesMemo();
  }

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const rootIdVal = fromParamsRootId ? parseInt(fromParamsRootId) : 0;

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curRootIdVal !== rootIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurRootIdVal(rootIdVal);
      if (rootIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneRootById(rootIdVal);
        return;
      }
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }

    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთულიობიექტი მაინც შევამოწმოთ
    if ( loadingRoot || !fromParamsRootId || !rootForEdit )
      return;

    //ჩატვირთული ინფორმაცია მივცეთ ფორმის მენეჯერს
    setFormData(rootForEdit);

  }, [clearToDefaults, curRootIdVal, fromParamsRootId, getOneRootById, loadingRoot, rootForEdit, setFormData]);

//console.log("RootEdit.js on check Load {curRootIdVal, rootForEdit, derivationTypes, loadingRoot, mdWorkingOnLoad}=", {curRootIdVal, rootForEdit, derivationTypes, loadingRoot, mdWorkingOnLoad});

  //8. ჩატვირთვის შემოწმება
  //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( (curRootIdVal && !rootForEdit) || !derivationTypes || !classifiers || !phoneticsChangesQuery ) { 
    if ( loadingRoot || mdWorkingOnLoad ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
      return (<WaitPage />);
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    clearAlert();
    if (haveErrors)
      return;

//გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
//წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას

    const currentForm = {...frm};
    if ( curRootIdVal )
      updateRoot(props.history, currentForm);
    else
      createRoot(props.history, currentForm);
    clearUsedTables();
  }


  const rootResultName = (frm && frm.root && frm.root.rootName) ? `${frm.root.rootName}:${frm.root.rootHomonymIndex ? frm.root.rootHomonymIndex : 0}` : "";
  const userHasConfirmRight = user.appClaims.some(s=>s === 'Confirm');
  const allowEditAndDelete = (userHasConfirmRight && frm.root.recordStatusId !== 1 && frm.root.recordStatusId !== 0) || 
    (!userHasConfirmRight && frm.root.recordStatusId !== 1);

    //console.log("RootEdit userHasConfirmRight=", userHasConfirmRight);
    //console.log("RootEdit frm.root.recordStatusId=", frm.root.recordStatusId);
    //console.log("RootEdit frm.root.allowEditAndDelete=", allowEditAndDelete);


  return (
    <Row>
      <Col md="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          <EditorHeader curIdVal={curRootIdVal} EditorName="ძირი" EditorNameGenitive="ძირის"
            EditedObjectName={rootResultName}
            workingOnDelete={workingOnDeleteRoot} DeleteFailure={DeleteFailure}
            onDelete={() => {deleteRoot(props.history, curRootIdVal); clearUsedTables();}}
            onClearDeletingFailure={() => {clearDeletingFailure();}} allowDelete={allowEditAndDelete} />

          { userHasConfirmRight && frm.root.recordStatusId !== undefined && 
            <StatusConfirmRejectPart recordStatusId={frm.root.recordStatusId} creator={frm.root.creator} 
              workingOnConfirmReject={workingOnConfirmRejectRootChange}
              confirmRejectFailure={confirmRejectFailure}
              onConfirmRejectClick={(confirm, withAllDescendants) => {
                confirmRejectRootChange(props.history, curRootIdVal, confirm, withAllDescendants); 
                clearUsedTables();
              }} 
              onClearConfirmRejectFailure={() => {clearConfirmRejectFailure();}} /> }

          <OnePlaintextRow controlId="result" label="შედეგი" 
            text={rootResultName} />          
          <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />

          <OneComboBoxControl controlId="root.classifierId" 
                  label="კლასიფიკატორი" value={frm.root.classifierId} 
                  dataMember={classifiers} firstItem={{ id: 0, name: "არცერთი" }} 
                  valueMember="clfId" displayMember="clfName" sortByDisplayMember={true} getError={getError} 
                  firstItemIsSelectable 
                  onChangeValue={changeField} />

          <OneTextControl controlId="root.rootName" label="ძირი" value={frm.root.rootName} 
            getError={getError} onChangeValue={changeField} />
          <OneNumberControl controlId="root.rootHomonymIndex" label="ომონიმიის სორტირების ნომერი" 
            value={frm.root.rootHomonymIndex} minv={0} getError={getError} onChangeValue={changeField} />
          <OneTextControl controlId="root.rootNote" label="შენიშვნა" value={frm.root.rootNote} 
            getError={getError} onChangeValue={changeField} />

          <PhoneticsCombEditor controlGroupId="basePhoneticsCombDetails" label="შედეგის ფონეტიკური შესაძლებლობები"
            basePhoneticsChanges={frm.basePhoneticsCombDetails} phoneticsChangesQuery={phoneticsChangesQuery} 
            getError={getError} onChangeValue={changeField} onTrashButtonClick={(index) => {
              const newFrm = {...frm};
              newFrm.basePhoneticsCombDetails.splice(index,1);
              setFormData(newFrm);
            }} onPlusButtonClick={() => {
              const newFrm = {...frm};
              newFrm.basePhoneticsCombDetails.push(0);
              setFormData(newFrm);
             }} />

          <OneSaveCancelButtons curIdVal={curRootIdVal} haveErrors={haveErrors} savingNow={savingRoot} onCloseClick={() => { 
            clearAlert(); props.history.goBack(); }} allowEdit={allowEditAndDelete} />
          <OneErrorRow allErrors={allErrors} />

        </Form>
      </Col>
    </Row>
  );


}



function mapStateToProps(state) {
  const { mdWorkingOnLoad } = state.masterData;
  const {derivationTypes, classifiers, phoneticsChangesQuery} = state.masterData.mdRepo;
  const { savingRoot, loadingRoot, workingOnDeleteRoot, rootForEdit, getOneRootById, DeleteFailure, 
    confirmRejectFailure,
    workingOnConfirmRejectRootChange } = state.rootEditorStore;
  const alert = state.alert;
  const { user } = state.authentication;

  return { alert, user, mdWorkingOnLoad, derivationTypes, classifiers, phoneticsChangesQuery, 
    savingRoot, loadingRoot, workingOnDeleteRoot, rootForEdit, getOneRootById, DeleteFailure, 
    confirmRejectFailure,
    workingOnConfirmRejectRootChange };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    getOneRootById: (rootId) => dispatch(RootEditorActions.getOneRootById(rootId, true)),
    updateRoot: (history, rootForSave) => dispatch(RootEditorActions.updateRoot(history, rootForSave)),
    createRoot: (history, rootForSave) => dispatch(RootEditorActions.createRoot(history, rootForSave)),
    deleteRoot: (history, rootId) => dispatch(RootEditorActions.deleteRoot(history, rootId)),
    clearDeletingFailure: () => dispatch(RootEditorActions.clearDeletingFailure()),
    clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad)),
    clearAlert: () => dispatch(alertActions.clear()),
    clearRoot: (rootId) => dispatch(DerivTreeActions.clearRoot(rootId)),
    clearMemo: () => dispatch(DerivTreeActions.clearMemo()),
    clearForConfirmRootsPagesMemo: () => dispatch(DerivTreeActions.clearForConfirmRootsPagesMemo()),
    confirmRejectRootChange: (history, rootId, confirm, withAllDescendants) => dispatch(RootEditorActions.confirmRejectRootChange(history, rootId, confirm, withAllDescendants)),
    clearConfirmRejectFailure: () => dispatch(RootEditorActions.clearConfirmRejectFailure()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootEdit);


