//ForConfirmRootsList
import React, {useState, useEffect, useCallback} from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { connect } from 'react-redux';
import { actionCreators as DerivTreeActions, createUrlForConfirmRoots } from './DerivationTreeStore';
import WaitPage from '../../carcass/common//WaitPage';
// import BaseLink from './BaseLink';
import { Link } from 'react-router-dom';



const ForConfirmRootsList = (props) => {

  const itemsPerPage = 30;

  function getPageKey(rootStartsWith, createdUserName, itemsPerPage, currentPage) {
    var pageNom = 1;
    if (currentPage)
      pageNom = parseInt(currentPage);
    return { pageNom, pagekey: rootStartsWith + '_' + createdUserName + '_' + itemsPerPage + '_' + pageNom };
  }

  const { isMenuLoading, flatMenu, forConfirmRootsListPageLoading, memoForConfirmRootsCounts, memoForConfirmRootsPages, GetForConfirmRootsByPages } = props;
  //console.log("ForConfirmRootsList props=", props);
  

  const [rootStartsWith, ] = useQueryParam('rootStartsWith', StringParam);
  const [createdUserName, ] = useQueryParam('createdUserName', StringParam);
  const [currentPageNom, setCurrentPageNom] = useState(null);
  const [currentPageKey, setCurrentPageKey] = useState(null);
  const [currentFilterKey, setCurrentFilterKey] = useState(null);

  const menLinkKey = props.match.url.split('/')[1];
  const { page } = props.match.params;

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    //console.log("ForConfirmRootsList useEffect menuItem=", menuItem);
    if ( ! menuItem )
      return;

    const { pageNom, pagekey } = getPageKey(rootStartsWith, createdUserName, itemsPerPage, page);
    if (currentPageKey !== pagekey) {
      setCurrentPageNom(pageNom);
      setCurrentPageKey(pagekey);
      setCurrentFilterKey(`${rootStartsWith}_${createdUserName}`);
      if (pageNom)
        GetForConfirmRootsByPages(rootStartsWith, createdUserName, itemsPerPage, pageNom, pagekey);
    }
  }, [isMenuLoading, flatMenu, isValidPage, rootStartsWith, createdUserName, page, GetForConfirmRootsByPages, currentPageKey]);

  function createLink(rootStartsWith, createdUserName, i, linkText = null) {
    const text = linkText ? linkText : i.toString();

    return (<Link key={text} className="paginglink" to={createUrlForConfirmRoots(`/forConfirmRootsList/${i}`, rootStartsWith, createdUserName)}>{text}</Link>);
  }

  function createPageLinks() {
    const pageLinks = [];

    if (memoForConfirmRootsCounts[currentFilterKey] && memoForConfirmRootsCounts[currentFilterKey] > 1) {
      const pagesCount = memoForConfirmRootsCounts[currentFilterKey] / itemsPerPage;
      let startWith = currentPageNom - 4;
      if (startWith < 1)
        startWith = 1;
      let endWith = startWith + 9;
      if (endWith > pagesCount)
        endWith = pagesCount;
      if (currentPageNom > 1)
        pageLinks.push(createLink(rootStartsWith, createdUserName, currentPageNom - 1, "<"));
      for (let i = startWith; i < endWith; i++) {
        if (i === currentPageNom)
          pageLinks.push(<strong key="b">{createLink(rootStartsWith, createdUserName, i)}</strong>);
        else
          pageLinks.push(createLink(rootStartsWith, createdUserName, i));
      }
      if (currentPageNom < pagesCount)
        pageLinks.push(createLink(rootStartsWith, createdUserName, currentPageNom + 1, ">"));
    }
    return pageLinks;
  }

  if (forConfirmRootsListPageLoading || isMenuLoading)
    return (<WaitPage />);

  if (!currentFilterKey || !currentPageKey || !flatMenu) {
    return (<div></div>);
  }

  const pageLinks = createPageLinks();

  if (!memoForConfirmRootsCounts[currentFilterKey]) {
    return (
      <div className="mt-5 row">
        <h5>ჩანაწერები არ მოიძებნა</h5>
      </div>
    );
  }

  return (
    <div>
      <div className="mt-5 row">
        <h5>დასადასტურებელი ძირები 
          {!!rootStartsWith && <span>რომლებიც იწყება ასოებით - <strong>{rootStartsWith}</strong></span>}
          {!!createdUserName && <span>რედაქტორი - <strong>{createdUserName}</strong></span>}
            გვერდი: <strong>{currentPageNom}</strong></h5>
      </div>
      <div id="base-search" className="mt-5 row">
        <ul className="container threecolumns">
          {memoForConfirmRootsPages[currentPageKey] &&
            memoForConfirmRootsPages[currentPageKey].map((item, index) => {
              return (
                <li key={index} >
                  <Link to={`/root/${item.rootId}`}>{item.rootName}</Link>
                </li>
              );
            })
          }
        </ul>

        {pageLinks.length && (<div className="text-center container">{pageLinks}</div>) }

      </div>
    </div>
  );
}


function mapStateToProps(state) {
  const { forConfirmRootsListPageLoading, memoForConfirmRootsCounts, memoForConfirmRootsPages } = state.derivTree;
  const { isMenuLoading, flatMenu } = state.navMenu;
  return { isMenuLoading, flatMenu, forConfirmRootsListPageLoading, memoForConfirmRootsCounts, memoForConfirmRootsPages };
}

function mapDispatchToProps(dispatch) {
  return {
    GetForConfirmRootsByPages: (rootStartsWith, createdUserName, itemsPerPage, currentPage, pagekey) => dispatch(DerivTreeActions.GetForConfirmRootsByPages(rootStartsWith, createdUserName, itemsPerPage, currentPage, pagekey))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForConfirmRootsList);
