import React, {useEffect, useContext} from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import WaitPage from './WaitPage';
import { actionCreators as UserActions } from '../user/UserStore';
import { actionCreators as paramsActions } from '../store/ParamsStore';

import { ProjectContext } from '../../project/ProjectProvider';

//import { isCurrentPathPublic } from './History';

const PrivateApp = (props) => {

  //console.log("PrivateApp props=", props);

  const { baseUrl } = useContext(ProjectContext);
  const { setBaseUrl, isCurrentUserValid } = props;
  const { userValidationChecked, user } = props;
  const publicPathces = ['/login', '/registration', '/createdb', '/undercounstruction'];
  
  useEffect(() => {
    if (!userValidationChecked && user) {
      setBaseUrl(baseUrl);
      isCurrentUserValid();
    }
  }, [baseUrl, userValidationChecked, user, setBaseUrl, isCurrentUserValid]);

  let location = useLocation();

  //const { component: Component } = props;
  // const { userValidationChecked, user } = props;

  if (publicPathces.includes(location.pathname)) {
    return <div />;
  }

  if (!userValidationChecked && user) {
    return <WaitPage />;
  }

  if (user) {
    return (<div>{props.children}</div>);// (<Component />);
  } else {
    if (window.location.pathname !== '/login') {
      return (<Redirect to={{ pathname: '/login', state: { from: window.location.pathname } }} />);
    }
  }

}

function mapStateToProps(state) {
  const { userValidationChecked, user } = state.authentication;
  return { userValidationChecked, user };
}

function mapDispatchToProps(dispatch) {
  return {
    isCurrentUserValid: () => dispatch(UserActions.isCurrentUserValid()),
    setBaseUrl: (baseUrl) => dispatch(paramsActions.setBaseUrl(baseUrl))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateApp);

