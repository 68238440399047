//ForConfirmRootsListTopForm.js

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Spinner, Nav, Navbar } from 'react-bootstrap';
import { createUrlForConfirmRoots } from './DerivationTreeStore';
import { actionCreators as DerivTreeActions } from './DerivationTreeStore';

const ForConfirmRootsListTopForm = (props) => {

  const { flatMenu, forConfirmRootsListDropdownloading, 
    UsersListForConfirmDropdownloading, GetUsersListForConfirm, UsersListForConfirm } = props;

  const [rootStartsWith, setRootStartsWith] = useState("");
  const [createdUserName, setCreatedUserName] = useState("");

  const menLinkKey = 'forConfirmRootsList';

  function isValidPage() {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.some(f=>f.menLinkKey === menLinkKey);
  }

  useEffect(() => {
      GetUsersListForConfirm();
  }, [GetUsersListForConfirm]);


  if ( ! isValidPage() ) {
    return ( <div/> );
  }


  return (
    <Nav>
      <Navbar.Brand className="mr-2" > ძირის დასაწყისი: </Navbar.Brand> 
      <Form.Control type="text" value={ (rootStartsWith) ? rootStartsWith : "" } className="mr-2" onChange={(e) => {
        const newRootStartsWith = e.target.value;
        setRootStartsWith(newRootStartsWith);
      }} autoComplete="off" />
      <Navbar.Brand className="mr-2" > რედაქტორი: </Navbar.Brand>
      <Form.Control as="select" defaultValue="ყველა" value={createdUserName} onChange={(e) => { 
        e.preventDefault();
        const newCreatedUserName = e.target.value;
        setCreatedUserName(newCreatedUserName);
      }}>
        <option>ყველა</option>
        {!!UsersListForConfirm && UsersListForConfirm.map(item => 
          (<option>{item}</option>)
        )}
      </Form.Control>
      <Button variant="outline-success" onClick={(e) => { 
        e.preventDefault();
        props.history.push(createUrlForConfirmRoots('/forConfirmRootsList', rootStartsWith.trim(), createdUserName));
      }}>ჩატვირთვა</Button>
      {(forConfirmRootsListDropdownloading || UsersListForConfirmDropdownloading) && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}      
    </Nav>
    );


}


function mapStateToProps(state) {
  const { forConfirmRootsListDropdownloading, UsersListForConfirmDropdownloading, UsersListForConfirm } = state.derivTree;
  const { flatMenu } = state.navMenu;

  return { flatMenu, forConfirmRootsListDropdownloading, UsersListForConfirmDropdownloading, UsersListForConfirm };
}

function mapDispatchToProps(dispatch) {
  return {
    //GetBasesForDropDown: (val) => dispatch(DerivTreeActions.GetBasesForDropDown(val))
    //აქ დაგვჭირდება იმ მომხმარებლების სიის ჩატვირთვა, რომლებსაც არ აქვს დამოწმების უფლება
    GetUsersListForConfirm: () => dispatch(DerivTreeActions.GetUsersListForConfirm())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForConfirmRootsListTopForm);

