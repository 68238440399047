import { useReducer, useEffect, useRef, useCallback, useState } from 'react';
import equal from 'deep-equal'

export function useScroller(scrollTo) {
  const [curscrollTo, setCurscrollTo] = useState(null);

  const backLigth = useCallback(node => {
    if (node) {
      node.scrollIntoView({block: "center", inline: "nearest"});
    }
  }, []);

  // function isString(myVar) {
  //   return (typeof myVar === 'string' || myVar instanceof String);
  // }

  // function getPropKeies(myVar) {
  //   return isString((myVar) ? [] : Object.keys((myVar)));
  // }

  // function arrDifference(arrA, arrB) {
  //   return arrA.filter(x => !arrB.includes(x)).concat(arrB.filter(x => !arrA.includes(x)));
  // }

  // function isArrDiff(arrA, arrB) {
  //   arrDifference(arrA, arrB).length > 0;
  // }

  // function isDeepDiff(objA, objB) {
  //   const objAPropKeies = getPropKeies(objA);
  //   const objBPropKeies = getPropKeies(objB);
  //   if ( objAPropKeies.length !== objBPropKeies.length )
  //     return true;
  //   if ( curPropKeies.length === 0 )
  //     return ( curscrollTo !== scrollTo );
  //   if ( isArrDiff(curPropKeies, newPropKeies) )
  //     return true;

  // }

  useEffect(() => {

    if ( equal(curscrollTo, scrollTo) )
      return;
    setCurscrollTo(scrollTo);
  }, [scrollTo, curscrollTo]);


  return [curscrollTo, backLigth]

}


// export function useTabRecscroller(tabKey, recName) {

//   const [curTabKey, setCurTabKey] = useState(null);
//   const [curRecName, setCurRecName] = useState(null);

//   const backLigth = useCallback(node => {
//     if (node) {
//       node.scrollIntoView();
//     }
//   // }, [curTabKey, curRecName]);
//   }, []);

//   useEffect(() => {
//     if (curTabKey !== tabKey || curRecName !== recName) {
//       if (curTabKey !== tabKey) {
//         setCurTabKey(tabKey);
//       }
//       if (curRecName !== recName) {
//         setCurRecName(recName);
//       }
//     }
//   }, [tabKey, recName, curTabKey, curRecName]);


//   return [curTabKey, curRecName, backLigth]

// }





export function useForman(yupSchema) {

  function getValidationErrMsg(scema, path, value, options) {
    try {
      scema.validateSyncAt(path, value, options);
      return "";
    } catch (err) {
      //console.log("useForman getValidationErrMsg err=", err);
      if (err.name === 'ValidationError') 
        return err.message;
      throw err;
    }
  }

  function getAllValidationErrMsg(scema, value, options) {
    try {
      if ( scema )
        scema.validateSync(value, options);
      return "";
    } catch (err) {
      //console.log("useForman getValidationErrMsg err=", err);
      if (err.name === 'ValidationError') 
        return err.message;
      throw err;
    }
  }

  
  function setValueByPatch(obj, path, value) {

    // debugger;


    let s = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var schema = obj;  // a moving reference to internal objects within obj
    var pList = s.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) 
          schema[elem] = {}
        schema = schema[elem];
    }
    schema[pList[len-1]] = value;
  }



  const [formState, dispatchForm] = useReducer((formState, action) => {
    //console.log("useForman changeField formState=", formState);
    //console.log("useForman changeField action=", action);
    const { type, payload } = action;
    switch (type) {
      case "clearToDefault": {
        return { ...formState, frm: yupSchema.default(), err: {} };
      }
      case "changeField": {
        const newfrm = formState.frm;
        const { fieldPath, value } = payload;
        //console.log("useForman changeField { fieldPath, value }=", { fieldPath, value });
        // debugger;
        //newfrm[fieldPath] = value;
        setValueByPatch(newfrm, fieldPath, value);
        //console.log("useForman changeField newfrm[fieldPath]=", newfrm[fieldPath]);
        const newerr = formState.err;
        if ( formState.sch )
          newerr[fieldPath] = getValidationErrMsg(formState.sch, fieldPath, newfrm);
        //console.log("useForman changeField newerr[fieldPath]=", newerr[fieldPath]);
        return { ...formState, frm: newfrm, err: newerr };
      }
      case "setForm":
        return { ...formState, frm: {...payload}, err: {} };
      case "setSchema":
        return { ...formState, sch: payload, err: {} };
      default:
        throw new Error();
    }
  }, { sch: yupSchema, frm: yupSchema ? yupSchema.default() : null, err: {} });

  return [

    //frm
    formState.frm,

    //changeField
    (fieldPath, value) => {
      dispatchForm({ type: "changeField", payload: { fieldPath, value } });
    },

    //getError
    (fieldPath) => {
      const errors = formState.err;
      if (errors && fieldPath in errors &&
        errors[fieldPath] &&
        errors[fieldPath].length > 0)
        return errors[fieldPath];
      return null;
    },

    //getAllErrors
    () => {
      // const errors = formState.err;
      // return Object.values(errors).some((item) => item && item.length > 0);
      const errMsg = formState.sch ?  getAllValidationErrMsg(formState.sch, formState.frm) : "";
      return errMsg;
    },

    // () => {
    //   dispatchForm({ type: "clearToDefault" });
    // },

    //clearToDefaults
    useCallback(() => {
      dispatchForm({ type: "clearToDefault" });
    }, [dispatchForm]),

    // (frm) => {
    //   dispatchForm({ type: "setForm", payload: frm });
    // },
    
    //setFormData
    useCallback((frm) => {
      dispatchForm({ type: "setForm", payload: frm });
    }, [dispatchForm]),

    //setSchema
    useCallback((yupSchema) => {
      dispatchForm({ type: "setSchema", payload: yupSchema });
    }, [dispatchForm])


  ];

}

export function useValidation(checkLogic, fieldsEmptyValueMessages) {

  const [validationState, dispatchValidation] = useReducer((validationState, action) => {
    const { type, payload } = action;
    switch (type) {
      //case "clearAllErrors": {
      //  return { errors: {} };
      //}
      //case "clearError": {
      //  const { fieldName } = payload;
      //  const err = validationState.errors;
      //  err[fieldName] = null;
      //  return { errors: err };
      //}
      case "addError": {
        const { fieldName, message } = payload;
        const err = validationState.errors;
        err[fieldName] = message;
        return { errors: err };
      }
      default:
        throw new Error();
    }
  }, { errors: {} });

  return [

    (fieldName, value) => {
      const isValueEmpty = (!value || value.length === 0);
      if (isValueEmpty && !!fieldsEmptyValueMessages && fieldName in fieldsEmptyValueMessages)
        dispatchValidation({ type: "addError", payload: { fieldName, message: fieldsEmptyValueMessages[fieldName] } });
      else
        dispatchValidation({ type: "addError", payload: { fieldName, message: checkLogic(fieldName, value) } });
    },
    //(fieldName) => { dispatchValidation({ type: "clearError", payload: { fieldName } }) },
    //(fieldName, message) => { dispatchValidation({ type: "addError", payload: { fieldName, message } }) },

    (fieldName) => {
      const { errors } = validationState;
      if (fieldName in errors &&
        errors[fieldName] &&
        errors[fieldName].length > 0)
        return errors[fieldName];
      //return (<span className='error'>{errors[fieldName]}</span>);
      return null;
    },

    (fieldName, message) => {
        dispatchValidation({ type: "addError", payload: { fieldName, message } });
    },

    () => {
      const { errors } = validationState;
      return Object.values(errors).some((item) => item && item.length > 0);
    }

  ];

}


// Hook
export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}


export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

