import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'
import { Button, Spinner, Form, Alert  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as yup from 'yup'; // for everything // or //import { string, object } from 'yup'; // for only what you need

import { useForman } from './MyHooks';
import { actionCreators as userActions } from '../user/UserStore';

import { ProjectContext } from '../../project/ProjectProvider';
import RegTextControl from '../editorParts/RegTextControl';


const RegistrationPage = (props) => {

  //console.log("RegistrationPage props=", props);

  const { registering, user, alert, logout } = props;

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
      logout();
    },
    [logout]);

  useEffect(() => {
      if (!registering && !user)
        setSubmitted(false);
    },
    [props, registering, user]);


  const schema = yup.object().shape({
    email: yup.string().email("ელფოსტის მისამართი არასწორია").required("ელფოსტის მისამართი შევსებული უნდა იყოს").default(""),
    firstName: yup.string().required("სახელი შევსებული უნდა იყოს").max(50, "სახელის სიგრძე არ შეიძლება იყოს 50 სიმბოლოზე მეტი"),
    lastName: yup.string().required("გვარი შევსებული უნდა იყოს").max(100, "გვარის სიგრძე არ შეიძლება იყოს 100 სიმბოლოზე მეტი"),
    username: yup.string().required("მომხმარებლის სახელი შევსებული უნდა იყოს").max(256, "მომხმარებლის სახელის სიგრძე არ შეიძლება იყოს 256 სიმბოლოზე მეტი"),
    password: yup.string().required("პაროლი შევსებული უნდა იყოს"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], "განმეორებითი პაროლი უნდა ემთხვეოდეს პაროლს")
  });

  const [frm, changeField, getError, haveErrors, 
    // eslint-disable-next-line
    clearToDefaults, 
    // eslint-disable-next-line
    setFormData
    ] = useForman(schema);

  const { appName } = useContext(ProjectContext);

  // function handleChange(e) {
  //   const { name, value } = e.target;
  //   changeField(name, value);
  // }

  function handleSubmit(e) {
    e.preventDefault();
    if (haveErrors())
      return;
    setSubmitted(true);
    props.registration(props.history, frm);
  }


  const { from } = props.location.state || { from: { pathname: "/" } };
  if (submitted && user) {
    //console.log("RegistrationPage submitted && user from=", from);
    return <Redirect to={from} />;
  }

  // const { email, firstName, lastName, username, password, confirmPassword } = frm;

  return (
    <div id="LoginPage">
      <div className="login-wrapper fadeInDown">
        <div id="formContent">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
          <h2>რეგისტრაცია - {appName}</h2>
          <Form name="form" onSubmit={handleSubmit}>
            <RegTextControl controlId="email" label="ელფოსტის მისამართი" value={frm.email} 
              getError={getError} onChangeValue={changeField} />
            <RegTextControl controlId="firstName" label="სახელი" value={frm.firstName} 
              getError={getError} onChangeValue={changeField} />
            <RegTextControl controlId="lastName" label="გვარი" value={frm.lastName} 
              getError={getError} onChangeValue={changeField} />
            <RegTextControl controlId="username" label="მომხმარებელი" value={frm.username} 
              getError={getError} onChangeValue={changeField} />
            <RegTextControl controlId="password" label="პაროლი" value={frm.password} 
              getError={getError} onChangeValue={changeField} />
            <RegTextControl controlId="confirmPassword" label="პაროლი განმეორებით" value={frm.confirmPassword} 
              getError={getError} onChangeValue={changeField} />
            <Button type="submit" className="fadeIn fourth">
              <FontAwesomeIcon icon="user-plus" /> რეგისტრაცია
              {registering && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </Button>
            <Link to="/login">ავტორიზაცია</Link>
          </Form>
        </div>
      </div>

    </div>
  );
}


function mapStateToProps(state) {
  const { registering, user } = state.authentication;
  const alert = state.alert;
  return { registering, user, alert };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout()),
    registration: (history, regData) => dispatch(userActions.registration(history, regData))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationPage);

//{loggingIn && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
