
//NounParadigmFormulaEdit.js
import React, {useState, useEffect, useCallback, useMemo} from 'react';
//import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

//import { history } from '../../carcass/common/History';
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as ModelEditorActions } from './ModelEditorStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { useForman } from '../../carcass/common/MyHooks';
import WaitPage from '../../carcass/common/WaitPage';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OneTextControl from '../../carcass/editorParts/OneTextControl';
import OneNumberControl from '../../carcass/editorParts/OneNumberControl';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import OnePlaintextRow from '../editorParts/OnePlaintextRow';
import { getFormulaVisual2, createFormulaFormData } from './FormulasModule';
import { NzInt } from '../../carcass/common/myFunctions';

const NounParadigmFormulaEdit = (props) => {

  //1. იდენტიფიკატორი
  const [curFormulaIdVal, setCurFormulaIdVal] = useState(null);
  const [paradigmIdsForClear, setParadigmIdsForClear] = useState([]); 
  const [rangesInGroup, setRangesInGroup] = useState(null);
  const [formDataPrepared, setFormDataPrepared] = useState(false);

  //const history = useHistory();

  //2. კომპონენტის თვისებები
  const { alert, masterData, savingNounParadigmFormula, loadingNounParadigmFormula, 
    workingOnDeleteNounParadigmFormula, nounParadigmFormulaForEdit, DeleteFailure, 
    checkLoadMdTables, getOneNounParadigmFormulaById} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, nounParadigms, grammarCases, 
     nounNumbers, morphemeRangesByInflectionBlocks } = masterData.mdRepo;

  // const { alert, mdWorkingOnLoad, checkLoadMdTables, 
  //   morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, morphemeRangesByInflectionBlocks, nounParadigms, 
  //   grammarCases, nounNumbers,
  //   savingNounParadigmFormula, loadingNounParadigmFormula, workingOnDeleteNounParadigmFormula, nounParadigmFormulaForEdit, 
  //   getOneNounParadigmFormulaById, DeleteFailure } = props;

  //console.log("NounParadigmFormulaEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => ["NounParadigmRows"], []);

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["morphemeRanges", "morphemesQuery", "inflectionBlocks", 
    "inflectionTypes", "morphemeRangesByInflectionBlocks", "nounParadigms", "grammarCases", "nounNumbers"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [tableNamesForLoad, checkLoadMdTables]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    nounParadigmFormula: yup.object().shape({
      nprId: yup.number().integer().default(0).required(),
      nounParadigmId: yup.number().integer().positive("პარადიგმა არჩეული უნდა იყოს")
        .required("პარადიგმა არჩეული უნდა იყოს").default(0),
      nprOrderInParadigm: yup.number().integer().default(0).min(0, "რიგითი ნომერი 0-ზე მეტი უნდა იყოს"),
      grammarCaseId: yup.number().integer().positive("ბრუნვა არჩეული უნდა იყოს")
        .required("ბრუნვა არჩეული უნდა იყოს").default(0),
      nounNumberId: yup.number().integer().positive("რიცხვი არჩეული უნდა იყოს")
        .required("რიცხვი არჩეული უნდა იყოს").default(0),
      nprSample: yup.string().required("ნიმუში შევსებული უნდა იყოს").default("-")
    }),
    // rangesLength: yup.number().integer().default(0),
    morphemeIds: yup.array().ensure().min(1, "ფორმულაში უნდა იყოს ერთი მაინც არანულოვანი მორფემა")
      .of(yup.number().integer().positive().default(0)
      .required()),
    formData: yup.object().shape({
      // morphemeGroupId: yup.number().default(0),
      ranges: yup.array().ensure(),
      morphemes: yup.array().ensure()
        .of(yup.number().integer().positive("მორფემა არჩეული უნდა იყოს").default(0)
        .required()),
    })
  });

  //console.log("NounParadigmFormulaEdit yupSchema=", yupSchema);

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    props.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
    props.clearNounParadigmFormulas(paradigmIdsForClear);
  }

  const copyMorphemsToMainData = useCallback((forForm) => {
    const newForm = {...forForm};

    //console.log("NounParadigmFormulaEdit copyMorphemsToMainData forForm=", forForm);
    //console.log("NounParadigmFormulaEdit copyMorphemsToMainData newForm=", newForm);

    newForm.morphemeIds = newForm.formData.morphemes.map((mrpId) => {
      return mrpId ? morphemesQuery.find((f)=> f.mrpId === mrpId) : null;
    }).filter(f=>f && f.mrpNom).map(m=>m.mrpId);
    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
    setFormData(newForm);
  },[morphemesQuery, setFormData]);


  const addForClearParadigmId = useCallback((paradigmId) => {
    if ( paradigmIdsForClear.includes(paradigmId) )
      return;
    const newParadigmIdsForClear = [...paradigmIdsForClear, paradigmId];
    setParadigmIdsForClear(newParadigmIdsForClear);
  }, [paradigmIdsForClear]);

  //ფლექსიის ბლოკის ეფექტი
  useEffect(() => {

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშIნ გაგრეძელებას აზრი არ აქვს
    if ( mdWorkingOnLoad || !inflectionBlocks || !inflectionTypes || !morphemeRanges || !morphemeRangesByInflectionBlocks )
      return;

    //მოვძებნოთ ფლექსიის ბლოკის შესაბამისი ობიექტი
    const inflectionBlock = inflectionBlocks.find(f=>f.inbKey === "NounRowsBlock");

    //console.log("NounParadigmFormulaEdit useEffect inflectionBlock=", inflectionBlock);

    //დერივაციის ტიპის პოვნას აზრი აქვს მხოლოდ იმ შემთხვევაში, თუ დერივაციიც ტიპების ცხრილი ჩატვირთულია
    //მოვძებნოთ დერივაციის ტიპის შესაბამისი ობიექტი
    const inflectionType = inflectionTypes.find(f=>f.iftId === inflectionBlock.inflectionTypeId);

    //console.log("NounParadigmFormulaEdit useEffect inflectionType=", inflectionType);
    //console.log("NounParadigmFormulaEdit useEffect morphemeRangesByInflectionBlocks=", morphemeRangesByInflectionBlocks);

    //ამოვკრიბოთ იმ რანგების შესახებ ინფორმაცია, რომლებიც მონაწილეობენ ამ ტიპის დერივაციის ფორმირებაში
    const MorphemeRangeIdsByIB = morphemeRangesByInflectionBlocks
      .filter(f=>f.inflectionBlockId === inflectionBlock.inbId)
      .map(m=>m.morphemeRangeId);

    //console.log("NounParadigmFormulaEdit useEffect MorphemeRangeIdsByIB=", MorphemeRangeIdsByIB);

    const newRangesInGroup = morphemeRanges
      .filter(f=>f.morphemeGroupId === inflectionType.morphemeGroupId && MorphemeRangeIdsByIB.includes(f.mrId))
      .sort((a,b) => a.mrPosition-b.mrPosition);

    setRangesInGroup(newRangesInGroup);

  }, [mdWorkingOnLoad, inflectionBlocks, inflectionTypes, morphemeRanges, morphemeRangesByInflectionBlocks]);

  const prepareFormDataForEdit = useCallback((forForm) => {
    const newForm = forForm ? forForm : {...frm};
    newForm.formData = createFormulaFormData(newForm.formData, rangesInGroup, newForm.morphemeIds, morphemesQuery, false, true);
    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
    //console.log("NounParadigmFormulaEdit prepareFormDataForEdit newForm=", newForm);
    copyMorphemsToMainData(newForm);
  }, [morphemesQuery, copyMorphemsToMainData, frm, rangesInGroup]);


  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const { formulaId } = props.match.params;
    const formulaIdVal = NzInt(formulaId);

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curFormulaIdVal !== formulaIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurFormulaIdVal(formulaIdVal);
      setFormDataPrepared(false);
      setParadigmIdsForClear([]);
      if (formulaIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneNounParadigmFormulaById(formulaIdVal);
        return;
      }
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      //console.log("NounParadigmFormulaEdit useEffect after clearToDefaults frm=", frm);
    }

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშIნ გაგრეძელებას აზრი არ აქვს
    if ( mdWorkingOnLoad || !morphemesQuery || !nounParadigms || !rangesInGroup )
      return;

    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთული ობიექტი მაინც შევამოწმოთ

    //დავამზადოთ ფორმის ახალი ობიექტი
    if ( formulaIdVal ) {
      //ტუ ინფომრაცია ჯერ ჩატვირთული არ არის, ვჩერდებით
      if ( loadingNounParadigmFormula || !nounParadigmFormulaForEdit )
        return;
      addForClearParadigmId(nounParadigmFormulaForEdit.nounParadigmFormula.nounParadigmId);
      if ( !formDataPrepared ) {
        setFormDataPrepared(true);
        prepareFormDataForEdit(nounParadigmFormulaForEdit);
      }
    }
    else {
      if ( !formDataPrepared ) {
        setFormDataPrepared(true);
        prepareFormDataForEdit(null);
      }
    }


  }, [morphemesQuery, nounParadigms, addForClearParadigmId, clearToDefaults, curFormulaIdVal, 
    getOneNounParadigmFormulaById, loadingNounParadigmFormula, mdWorkingOnLoad, nounParadigmFormulaForEdit, 
    prepareFormDataForEdit, props.match.params, rangesInGroup, formDataPrepared]);
    //setFormData


  // clearToDefaults, curFormulaIdVal, props.match.params, setFormData, nounParadigmFormulaForEdit, 
  // loadingNounParadigmFormula, getOneNounParadigmFormulaById,
  // inflectionBlocks, inflectionTypes, morphemeRanges, morphemeRangesByInflectionBlocks, morphemesQuery, nounParadigms,
  // copyMorphemsToMainData, mdWorkingOnLoad, addForClearParadigmId, prepareFormDataForEdit, rangesInGroup  

  //8. ჩატვირთვის შემოწმება
  if ( loadingNounParadigmFormula || mdWorkingOnLoad || datatypesLoading ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
    return (<WaitPage />);
  //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( (curFormulaIdVal && !nounParadigmFormulaForEdit) || !morphemeRanges || !morphemesQuery || !inflectionBlocks || 
      !inflectionTypes || !morphemeRangesByInflectionBlocks || !nounParadigms || !grammarCases || !nounNumbers || !datatypes ) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }
  
  //console.log("NounParadigmFormulaEdit frm=", frm);

  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");


  // function getParadigmName() {
  //   const nounParadigm = nounParadigms.find(f=>f.npnId === frm.nounParadigmFormula.nounParadigmId);
  //   return nounParadigm.npnName
  // }



  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;

//გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
//წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას

    const currentForm = {...frm};
    copyMorphemsToMainData(currentForm);
    delete currentForm.formData;
    // const paradigmName = getParadigmName();
    if ( curFormulaIdVal )
      props.updateNounParadigmFormula(props.history, currentForm, frm.nounParadigmFormula.nounParadigmId);
    else
      props.createNounParadigmFormula(props.history, currentForm, frm.nounParadigmFormula.nounParadigmId);
    clearUsedTables();
  }

  //აქ ითვლება, რომ თუ პარადიგმის სახელების დარედაქტირების უფლება აქვს მომხმარებელს
  //მაშინ პარადიგმებზეც უნდა ჰქონდეს.
  //ასე იმიტომ გაკეთდა, რომ არ გამხდარიყო საჭირო პარადიგმების მწკრივის ცხრილის დარეგისტრირება DataType-ებში
  //თუ მომავალში ამ ორი უფლების გამიჯვნა გახდა საჭირო, მოგვიწევს DataType-ებში დავარეგისტრიროთ პარადიგმების მწკრივის ცხრილი
  const dataType = datatypes.find(f=>f.dtTable === "nounParadigms");

  return (
    <Row>
      <Col md="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          <EditorHeader curIdVal={curFormulaIdVal} EditorName="სახელის პარადიგმის ფორმულა" EditorNameGenitive="სახელის პარადიგმის ფორმულის"
            EditedObjectName={(frm && frm.nounParadigmFormula && frm.nounParadigmFormula.formulaName) ? frm.nounParadigmFormula.formulaName : ""}
            workingOnDelete={workingOnDeleteNounParadigmFormula} DeleteFailure={DeleteFailure}
            onDelete={() => {
              // const paradigmName = getParadigmName();
              props.deleteNounParadigmFormula(props.history, curFormulaIdVal, frm.nounParadigmFormula.nprId); 
              clearUsedTables();
            }}
            onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={dataType.delete} />
          <OnePlaintextRow controlId="result" label="შედეგი" 
            text={getFormulaVisual2(frm.morphemeIds, frm.formData.ranges, morphemesQuery)} />          
          <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />
          <OneComboBoxControl controlId="nounParadigmFormula.nounParadigmId" 
            label="პარადიგმა" value={frm.nounParadigmFormula.nounParadigmId} 
            dataMember={nounParadigms} valueMember="npnId" displayMember="npnName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ პარადიგმა" }} />
          <OneNumberControl controlId="nounParadigmFormula.nprOrderInParadigm" label="რიგითი ნომერი" 
            value={frm.nounParadigmFormula.nprOrderInParadigm+1} getError={getError} onChangeValue={(id, value) => {
            changeField(id, value-1);
          }} />
          <OneComboBoxControl controlId="nounParadigmFormula.grammarCaseId" 
            label="ბრუნვა" value={frm.nounParadigmFormula.grammarCaseId} 
            dataMember={grammarCases} valueMember="grcId" displayMember="grcName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ ბრუნვა" }} />
          <OneComboBoxControl controlId="nounParadigmFormula.nounNumberId" 
            label="რიცხვი" value={frm.nounParadigmFormula.nounNumberId} 
            dataMember={nounNumbers} valueMember="nnnId" displayMember="nnnName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ რიცხვი" }} />
          <OneTextControl controlId="nounParadigmFormula.nprSample" label="ნიმუში" value={frm.nounParadigmFormula.nprSample} 
            getError={getError} onChangeValue={changeField} />
          <OneStrongLabel controlId="morphemes" label="მორფემები რანგების მიხედვით" />
          {frm.formData.ranges.map((range, index) => {
            return (<OneComboBoxControl key={index} controlId={`frm.formData.morphemes[${index}]`}
            label={`${range.mrPosition+1}. ${range.mrName}`} value={frm.formData.morphemes[index]} 
            dataMember={morphemesQuery.filter(mph=>mph.morphemeRangeId === range.mrId && mph.mrpNom >= range.minNom && mph.mrpNom <= range.maxNom)} 
            valueMember="mrpId" displayMember="mrpName" sortByDisplayMember={false}
            getError={getError} onChangeValue={(fieldPath, value) => {
              const newForm = {...frm};
              newForm.formData.morphemes[index] = value;
              copyMorphemsToMainData(newForm);
            }} />);
          })}
          <OneSaveCancelButtons curIdVal={curFormulaIdVal} haveErrors={haveErrors} savingNow={savingNounParadigmFormula} onCloseClick={() => { 
            // const paradigmName = getParadigmName();
            props.clearAlert(); 
            props.history.push(`/nounParadigmFormulas/${frm.nounParadigmFormula.nounParadigmId}/${curFormulaIdVal}`);
          }} allowEdit={dataType.update} />
          <OneErrorRow allErrors={allErrors} />
        </Form>
      </Col>
    </Row>
  );

}

function mapStateToProps(state) {
  const alert = state.alert;
  const masterData = state.masterData;
  const { savingNounParadigmFormula, loadingNounParadigmFormula, workingOnDeleteNounParadigmFormula, 
    nounParadigmFormulaForEdit, DeleteFailure } = state.modelEditorStore

  return { alert, masterData, savingNounParadigmFormula, loadingNounParadigmFormula, 
    workingOnDeleteNounParadigmFormula, nounParadigmFormulaForEdit, DeleteFailure
   };
}

function mapDispatchToProps(dispatch) {
  return {
    getOneNounParadigmFormulaById: (npfId) => dispatch(ModelEditorActions.getOneNounParadigmFormulaById(npfId)),
    updateNounParadigmFormula: (history, npf, paradigmName) => dispatch(ModelEditorActions.updateNounParadigmFormula(history, npf, paradigmName)),
    createNounParadigmFormula: (history, npf, paradigmName) => dispatch(ModelEditorActions.createNounParadigmFormula(history, npf, paradigmName)),
    deleteNounParadigmFormula: (history, npfId, paradigmName) => dispatch(ModelEditorActions.deleteNounParadigmFormula(history, npfId, paradigmName)),
    clearDeletingFailure: () => dispatch(ModelEditorActions.clearDeletingFailure()),
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad)),
    clearNounParadigmFormulas: (paradigmIdsForClear) => dispatch(FormulasActions.clearNounParadigmFormulas(paradigmIdsForClear)),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NounParadigmFormulaEdit);

