import React from 'react';
import { Route } from 'react-router-dom';

import BaseSearch from './derivationTreeEditor/BaseSearch';
import DerivationEdit from './derivationTreeEditor/DerivationEdit';
import InflectionEdit from './derivationTreeEditor/InflectionEdit';
import InflectionVerbCompositionEdit from './derivationTreeEditor/InflectionVerbCompositionEdit';
import RootDerivationTree from './derivationTreeEditor/RootDerivationTree';
import RootEdit from './derivationTreeEditor/RootEdit';
import ForConfirmRootsList from './derivationTreeEditor/ForConfirmRootsList';

//მოდელი
import PhoneticsTypesOverview from './overviews/PhoneticsTypesOverview';
import PhoneticsTypeEdit from './overviews/PhoneticsTypeEdit';
import PhoneticsOptionEdit from './overviews/PhoneticsOptionEdit';
import DerivationFormulasOverview from './overviews/DerivationFormulasOverview';
import DerivationFormulaEdit from './overviews/DerivationFormulaEdit';
import MorphemesOverview from './overviews/MorphemesOverview';
import MorphemeEdit from './overviews/MorphemeEdit';

//მოდელის აღწერა
import NounParadigmsOverview from './overviews/NounParadigmsOverview';
import NounParadigmFormulas from './overviews/NounParadigmFormulas';
import NounParadigmFormulaEdit from './overviews/NounParadigmFormulaEdit';

import VerbRowParadigmsOverview from './overviews/VerbRowParadigmsOverview';
import VerbRowParadigmFormulas from './overviews/VerbRowParadigmFormulas';
import VerbRowParadigmFormulaEdit from './overviews/VerbRowParadigmFormulaEdit';

import VerbPersonMarkersOverview from './overviews/VerbPersonMarkersOverview';
import VerbPersonMarkerFormulas from './overviews/VerbPersonMarkerFormulas';
import VerbPersonMarkerFormulaEdit from './overviews/VerbPersonMarkerFormulaEdit';

import ActantCombinationsReCounter from './overviews/ActantCombinationsReCounter';
import CreateAfterDominantPersonMarkers from './overviews/CreateAfterDominantPersonMarkers';
import CreateVerbPersonMarkerCombinations from './overviews/CreateVerbPersonMarkerCombinations';
import CreateForRecountVerbPersonMarkers from './overviews/CreateForRecountVerbPersonMarkers';
import CreateVerbPersonMarkerCombinationFormulaDetails from './overviews/CreateVerbPersonMarkerCombinationFormulaDetails';

import RecountsDashboard from './recounters/RecountsDashboard';

import Issues from './issues/Issues';
import IssueWork from './issues/IssueWork';



const AuthAppRoutes = (props) => {
  return (
    <div>
      <Route path='/basesearch/:baseName?/:page?' component={BaseSearch} />
      <Route path='/root/:rootId/:dbrId?/:infId?/:ivcId?' component={RootDerivationTree} />
      <Route path='/rootEdit/:rootId?' component={RootEdit} />
      <Route path='/derivEdit/:dbrId?/:rootId?' component={DerivationEdit} />
      <Route path='/inflEdit/:infId?/:dbrId?/:rootId?' component={InflectionEdit} />
      <Route path='/inflVerbCompEdit/:ivcId?/:infId?/:dbrId?/:rootId?' component={InflectionVerbCompositionEdit} />

      <Route path='/forConfirmRootsList/:page?' component={ForConfirmRootsList} />

      <Route path='/phoneticsTypesOverview/:tabKey?/:recName?' component={PhoneticsTypesOverview} />
      <Route path='/phoneticsTypeEdit/:phtId?' component={PhoneticsTypeEdit} />
      <Route path='/phoneticsOptionEdit/:phoId?' component={PhoneticsOptionEdit} />

      <Route path='/derivationFormulasOverview/:tabKey?/:recName?' component={DerivationFormulasOverview} />
      <Route path='/derivationFormulaEdit/:dfId?' component={DerivationFormulaEdit} />
      
      <Route path='/morphemesOverview/:tabKey?/:recName?' component={MorphemesOverview} />
      <Route path='/morphemeEdit/:mrpId?' component={MorphemeEdit} />

      <Route path='/nounParadigmsOverview/:tabKey?/:recName?' component={NounParadigmsOverview} />
      <Route path='/nounParadigmFormulas/:paradigmId?/:formulaId?' component={NounParadigmFormulas} />
      <Route path='/nounParadigmFormulaEdit/:formulaId?' component={NounParadigmFormulaEdit} />

      <Route path='/verbRowParadigmsOverview/:tabKey?/:recName?' component={VerbRowParadigmsOverview} />
      <Route path='/verbRowParadigmFormulas/:paradigmId?/:formulaId?' component={VerbRowParadigmFormulas} />
      <Route path='/verbRowParadigmFormulaEdit/:formulaId?' component={VerbRowParadigmFormulaEdit} />

      <Route path='/verbPersonMarkersOverview/:tabKey?/:recName?' component={VerbPersonMarkersOverview} />
      <Route path='/verbPersonMarkerFormulas/:paradigmId?/:formulaId?' component={VerbPersonMarkerFormulas} />
      <Route path='/verbPersonMarkerFormulaEdit/:formulaId?' component={VerbPersonMarkerFormulaEdit} />

      <Route path='/ActantCombinationsReCounter' component={ActantCombinationsReCounter} />
      <Route path='/CreateAfterDominantPersonMarkers' component={CreateAfterDominantPersonMarkers} />
      <Route path='/CreateVerbPersonMarkerCombinations' component={CreateVerbPersonMarkerCombinations} />
      <Route path='/CreateForRecountVerbPersonMarkers' component={CreateForRecountVerbPersonMarkers} />
      <Route path='/CreateVerbPersonMarkerCombinationFormulaDetails' component={CreateVerbPersonMarkerCombinationFormulaDetails} />

      <Route path='/recountsDashboard' component={RecountsDashboard} />

      <Route path='/issues' component={Issues} />
      <Route path='/issuework/:issueId' component={IssueWork} />
      
    </div>
  );
}

export default AuthAppRoutes;
