//RootDerivationTree.js

import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { Route } from 'react-router-dom';
import { Row, Col, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import WaitPage from '../../carcass/common/WaitPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import Paradigm from './Paradigm'
import StatusPart from './StatusPart'
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions'

import './RootDerivationTree.css';


const RootDerivationTree = (props) => {

  const { isMenuLoading, flatMenu, rootLoading, rootsRepo, mdWorkingOnLoad, pronouns, classifiers, derivationTypes, inflectionTypes, checkLoadMdTables, getOneRootById } = props;
  //console.log("RootDerivationTree start props=", props);

  const [currentRootId, setCurrentRootId] = useState(null);
  const [currentDerivId, setCurrentDerivId] = useState(null);
  const [currentInflId, setCurrentInflId] = useState(null);
  const [currentIvcId, setCurrentIvcId] = useState(null);
  // const [autoscroll, setAutoscroll] = useState(false);

  const [curscrollTo, backLigth] = useScroller({
    rootId: NzInt(props.match.params.rootId), 
    dbrId: NzInt(props.match.params.dbrId), 
    infId: NzInt(props.match.params.infId), 
    ivcId: NzInt(props.match.params.ivcId)
  });

  const {rootId, dbrId, infId, ivcId} = props.match.params;

  const menLinkKey = 'basesearch';

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {
    
    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    if (currentRootId !== rootId) {
      setCurrentRootId(rootId);
      getOneRootById(rootId);
    }
    if (currentDerivId !== dbrId) {
      setCurrentDerivId(dbrId ? parseInt(dbrId) : 0);
      //console.log("RootDerivationTree useEffect dbrId=", dbrId)
    }
    if (currentInflId !== infId) {
      setCurrentInflId(infId ? parseInt(infId) : 0);
      //console.log("RootDerivationTree useEffect dbrId=", dbrId)
    }
    if (currentIvcId !== ivcId) {
      setCurrentIvcId(ivcId ? parseInt(ivcId) : 0);
      //console.log("RootDerivationTree useEffect ivcId=", ivcId)
    }
  }, [isMenuLoading, flatMenu, isValidPage, rootId, dbrId, infId, ivcId, getOneRootById, currentRootId, currentDerivId, currentInflId, currentIvcId]); //, autoscroll

  const tableNamesForLoad = useMemo(() => ["pronouns", "classifiers", "derivationTypes", "inflectionTypes"], []);

  //მიუხედავად იმისა, რომ ეფექტისთვის გადაწოდებული ფუნქცია მხოლოდ ერთ ბრძანებას შეიცავს, მაინც საჭირო გახდა {} -ის გამოყენება
  useEffect(() => { checkLoadMdTables(tableNamesForLoad); }, [checkLoadMdTables, tableNamesForLoad]);



  if ( rootLoading || mdWorkingOnLoad || isMenuLoading )  
    return (<WaitPage />);
  
  if ( !currentRootId )
    return (<div></div>);

  const oneRootData = rootsRepo[currentRootId];

  if ( !oneRootData || !pronouns || !classifiers || !derivationTypes || !inflectionTypes || 
    !curscrollTo || !flatMenu ) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }


  const { branches, verbInflections, nounInflections, inflectionVerbCompositions } = oneRootData;


  function GetClassifierById(classifierId) {
    const classifier = classifiers.find(f=>f.clfId === classifierId)
    return classifier ? `(${classifier.clfName})` : null;
  }

  function GetColorByInflectionType(inflectionTypeId) {
    const inflectionType = inflectionTypes.find(f=>f.iftId === inflectionTypeId)
    return inflectionType ? inflectionType.iftFileName : null;
  }

  function GetColorByDerivationType(derivationTypeId) {
    const derivationType = derivationTypes.find(f=>f.drtId === derivationTypeId)
    return derivationType ? derivationType.drtFolderName : "Yellow";
  }


  function getBranches(branch, isRoot = false) {
    //console.log("RootDerivationTree getBranches branch=", branch);
    const subBranches = branches.filter((brn) => brn.derivationPredecessors.some((dp) => (dp.parentBranchId === branch.dbrId)))
      .sort((a,b) => (!!a.dbrBaseName?a.dbrBaseName:"").localeCompare(!!b.dbrBaseName?b.dbrBaseName:""));

    const subVerbInflections = verbInflections.filter((vin) => vin.inflectionPredecessors.some((ip) => (ip.parentBranchId === branch.dbrId)))
      .sort((a,b) => (!!a.infName?a.infName:"").localeCompare(!!b.infName?b.infName:""));

    const subNounInflections = nounInflections.filter((nin) => nin.inflectionPredecessors.some((ip) => (ip.parentBranchId === branch.dbrId)))
      .sort((a,b) => (!!a.infName?a.infName:"").localeCompare(!!b.infName?b.infName:""));



    return (
      <ul className="tree">
        {subVerbInflections.map((svi) => { 

          const subInflectionVerbCompositions = inflectionVerbCompositions.filter((ivc) => ivc.inflectionVerbCompositionPredecessors.some((ip) => (ip.parentInflectionId === svi.infId)))
          .sort((a,b) => (!!a.ivcName?a.ivcName:"").localeCompare(!!b.ivcName?b.ivcName:""));
          //console.log("RootDerivationTree subVerbInflection svi=", svi);

          const colorName = GetColorByInflectionType(svi.inflectionTypeId);
          const inflBackLight = (!curscrollTo.ivcId && curscrollTo.infId === svi.infId);

          return (//autoscroll && 
            <li key={svi.infId} ref={ inflBackLight ? backLigth : null} >
              <Link to={`/root/${currentRootId}/${branch.dbrId}/${svi.infId}`} className={inflBackLight ? "backLigth" : null}>
                <FontAwesomeIcon icon="file-alt" color={colorName} /> {" "}
              </Link>
              <Link to={`/inflEdit/${svi.infId}/${branch.dbrId}/${currentRootId}`} className={inflBackLight ? "backLigth" : null}>
              {svi.infName ? svi.infName : "(---)" } {svi.infSamples ? `(${svi.infSamples})` : "" } {GetClassifierById(svi.classifierId)} { svi.recordStatusId !== 2 && <StatusPart recordStatusId={svi.recordStatusId} creator={svi.creator} />}
              </Link>

              <ul className="tree">
              {subInflectionVerbCompositions.map((sivc) => { 

                //console.log("RootDerivationTree subInflectionVerbCompositions svi=", svi);
                const colorName = "Lime";
                const ivcBackLight = (curscrollTo.ivcId === sivc.ivcId);

                return (//autoscroll && 
                  <li key={sivc.ivcId} ref={ ivcBackLight ? backLigth : null} >
                    <Link to={`/root/${currentRootId}/${branch.dbrId}/${svi.infId}/${sivc.ivcId}`} className={ivcBackLight ? "backLigth" : null}>
                      <FontAwesomeIcon icon="file-alt" color={colorName} /> {" "}
                    </Link>
                    <Link to={`/inflVerbCompEdit/${sivc.ivcId}/${svi.infId}/${branch.dbrId}/${currentRootId}`} className={ivcBackLight ? "backLigth" : null}>
                      {sivc.ivcName ? sivc.ivcName : "(---)" } {sivc.infSamples ? `(${sivc.infSamples})` : "" } {GetClassifierById(sivc.classifierId)} { sivc.recordStatusId !== 2 && <StatusPart recordStatusId={sivc.recordStatusId} creator={sivc.creator} />}
                    </Link>
                  </li>
                ); }) }
              </ul>
            </li>
        ); }) }
        {subNounInflections.map((sni) => { 
          //console.log("RootDerivationTree subNounInflections sni=", sni);
          const colorName = GetColorByInflectionType(sni.inflectionTypeId);
          const inflBackLight = (!curscrollTo.ivcId && curscrollTo.infId === sni.infId);
          return (//autoscroll && 
          <li key={sni.infId} ref={ inflBackLight ? backLigth : null} >
            <Link to={`/root/${currentRootId}/${branch.dbrId}/${sni.infId}`} className={inflBackLight ? "backLigth" : null}>
              <FontAwesomeIcon icon="file-alt" color={colorName} /> {" "}
            </Link>
            <Link to={`/inflEdit/${sni.infId}/${branch.dbrId}/${currentRootId}`} className={inflBackLight ? "backLigth" : null}>
              {sni.infName ? sni.infName : "(---)" } {sni.infSamples ? `(${sni.infSamples})` : "" } {GetClassifierById(sni.classifierId)} { sni.recordStatusId !== 2 && <StatusPart recordStatusId={sni.recordStatusId} creator={sni.creator} />}
            </Link>
          </li>
        ); }) }
        {subBranches.map((brn) => { 
          const colorName = GetColorByDerivationType(brn.drtId);
          //console.log("RootDerivationTree brn=", brn)
          //console.log("RootDerivationTree brn.drtId=", brn.drtId)
          //console.log("RootDerivationTree colorName=", colorName)
          const derivBackLight = (!curscrollTo.ivcId && !curscrollTo.infId && brn.dbrId === curscrollTo.dbrId);
          //console.log("RootDerivationTree curscrollTo=", curscrollTo);
          return (//autoscroll && 
            <li key={brn.dbrId + "_" + brn.brLevel} ref={ derivBackLight ? backLigth : null} >
              <Link to={`/root/${currentRootId}/${brn.dbrId}`} className={derivBackLight ? "backLigth" : null}>
                <FontAwesomeIcon icon="folder-open" color={colorName} /> {" "}
              </Link>
              <Link to={`/derivEdit/${brn.dbrId}/${currentRootId}`} className={derivBackLight ? "backLigth" : null}>
                {brn.dbrBaseName ? brn.dbrBaseName : "(---)" } {GetClassifierById(brn.classifierId)} { brn.recordStatusId !== 2 && <StatusPart recordStatusId={brn.recordStatusId} creator={brn.creator} />}
              </Link>
              { getBranches(brn) }
          </li>
        ); }) }
      </ul>
    );
  }


  if (!oneRootData.branches || oneRootData.branches.length === 0)
    return (
      <div id="root-deriv-tree">
        <h5>დერივაციის ტოტების ჩატვირთვა ვერ მოხერხდა</h5>
      </div>
    );


  const firstBranch = oneRootData.branches.find(brn => brn.dbrId === oneRootData.root.rootFirstBranchId);

  if (!firstBranch)
    return (
      <div id="root-deriv-tree">
          <h5>ძირის ტოტის დადგენა ვერ მოხერხდა</h5>
        </div>
    );

  const { rootName, rootHomonymIndex, rootNote } = oneRootData.root;
  //console.log("RootDerivationTree currentDerivId=", currentDerivId)
  const { recordStatusId, classifierId, creator } = firstBranch;
  const colorName = GetColorByDerivationType(firstBranch.drtId);
  //console.log("RootDerivationTree firstBranch.drtId=", firstBranch.drtId)
  //console.log("RootDerivationTree firstBranch colorName=", colorName)
  
  const rootBackLight = (!curscrollTo.ivcId && !curscrollTo.ivnfId && !curscrollTo.dbrId);
  return (//autoscroll && 
    <Row className="root-editor-row">
      <Col md="6" className="root-editor-column">

        <div id="root-deriv-tree" className="root-editor-scroll">
          <h5 ref={ rootBackLight ? backLigth : null} >
            <Link to={`/root/${currentRootId}`} className={rootBackLight ? "backLigth" : null}>
              <FontAwesomeIcon icon="folder-open" color={colorName} /> {" "}
            </Link>
            <Link to={"/rootEdit/" + currentRootId} className={rootBackLight ? "backLigth" : null}>
              ძირი: {rootName}{rootHomonymIndex ? (<sup>{rootHomonymIndex}</sup>) : null } {rootNote} {GetClassifierById(classifierId)} { recordStatusId !== 2 && <StatusPart recordStatusId={recordStatusId} creator={creator} />}
            </Link>
          </h5>
          { getBranches(firstBranch, true) }
        </div>

      </Col>

      { !!currentInflId && <Route path='/root/:rootId/:dbrId?/:infId?/:ivcId?' component={Paradigm} /> }
    </Row>

  );
}


function mapStateToProps(state) {
  const { mdWorkingOnLoad } = state.masterData;
  const { pronouns, classifiers, derivationTypes, inflectionTypes } = state.masterData.mdRepo;
  const { rootLoading, rootsRepo } = state.derivTree;
  const { isMenuLoading, flatMenu } = state.navMenu;
  return { isMenuLoading, flatMenu, rootLoading, rootsRepo, mdWorkingOnLoad, pronouns, classifiers , derivationTypes, inflectionTypes };
}

function mapDispatchToProps(dispatch) {
  return {
    getOneRootById: (rootId) => dispatch(DerivTreeActions.getOneRootById(rootId)),
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootDerivationTree);

