import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner, Nav, Navbar, DropdownButton } from 'react-bootstrap';
import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import BaseLink, {getBaseHref} from './BaseLink';
import TextBoxAutoComplete from './TextBoxAutoComplete';
//import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

const BaseSearchForm = (props) => {
  
  //const history = useHistory();

  const { flatMenu, memoBasesDict, basesForDropdownloading, savedIssueDetailLine } = props;
  //ეს ცვლადი basesForDropdownloading წესით საჭირო არ უნდა ყოფილიყო, 
  //მაგრამ მის გარეშე ჩატვირთვის დასრულების შემდეგ განახლება არ ხდება
  // const {rootId,dbrId} = props.match.params;

  const [baseSearchValue, setBaseSearchValue] = useState("");

  const menLinkKey = 'basesearch';

  function isValidPage() {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.some(f=>f.menLinkKey === menLinkKey);
  }


  function handleElementClic(item) {
    const href = getBaseHref(item,0);
    props.history.push(href);
  }


  if ( ! isValidPage() ) {
    return ( <div/> );
  }


  return (
    <Nav>
      <Navbar.Brand> ფუძე:</Navbar.Brand>
      {/* <Form> */}
        <TextBoxAutoComplete listElementComponent={BaseLink} placeholder="ფუძე" formControlClassName="mr-sm-2"
          memodict={memoBasesDict}
          onTextChange={(searchValue) => setBaseSearchValue(searchValue)}
          loadDropDown={(searchValue) => props.GetBasesForDropDown(searchValue)}
          onSelectedElement={(item)=>handleElementClic(item)} />
        <Button variant="outline-success" onClick={(e) => { 
          e.preventDefault();
          if ( baseSearchValue )
            props.history.push(`/basesearch/${baseSearchValue}`);
        }}>ძებნა{basesForDropdownloading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}</Button>
      {/* </Form> */}
      <DropdownButton id="create-dropdown-button" title="ახალი" className="btn-space">
        <Link to="/rootEdit" className="dropdown-item">ძირი</Link>
        <Link to={"/derivEdit"} className="dropdown-item">დერივაცია</Link>
        <Link to={"/inflEdit"} className="dropdown-item">ფლექსია</Link>
        <Link to={"/inflVerbCompEdit"} className="dropdown-item">ზმნური კომპოზიცია</Link>
      </DropdownButton>
      {!!savedIssueDetailLine && (<Button variant="outline-success" onClick={(e) => { 
          e.preventDefault();
          if ( savedIssueDetailLine.issueId )
            props.history.push(`/issuework/${savedIssueDetailLine.issueId}`);
        }}>სიაში დაბრუნება</Button>)}
    </Nav>
    );


}


function mapStateToProps(state) {
  const { memoBasesDict, basesForDropdownloading } = state.derivTree;
  const { flatMenu } = state.navMenu;
  const { savedIssueDetailLine } = state.issuesStore;

  return { flatMenu, memoBasesDict, basesForDropdownloading, savedIssueDetailLine };
}

function mapDispatchToProps(dispatch) {
  return {
    GetBasesForDropDown: (val) => dispatch(DerivTreeActions.GetBasesForDropDown(val))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseSearchForm);

