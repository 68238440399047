//IssueDetails.js

export const issueDetailTypes = {
  notes: "notes", 
  inflections: "inflections",
  derivationBranches: "derivationBranches", 
  roots: "roots", 
};

export const issueDetailTypesGeoNames = {
  notes: "შენიშვნები", 
  inflections: "ფლექსია",
  derivationBranches: "დერივაცია", 
  roots: "ძირები", 
};

export const issueDetailTableNames = {
  notes: "issueDetails", 
  inflections: "issueDetailsByInflections",
  derivationBranches: "issueDetailsByDerivationBranches", 
  roots: "issueDetailsByRoots", 
};