//ParadigmListEditor.js
import React from 'react';
import { Link } from 'react-router-dom';


const ParadigmListEditor = (props) => {

  //const { caption, paradigmNamesTable, tableName, formulasTableName, curscrollTo, backLigth, saveReturnPageName } = props;
  const { dataType, paradigmNamesTable, formulasTableName, curscrollTo, backLigth, saveReturnPageName } = props;

  //console.log("ParadigmListEditor props=", props);

  const tableName = dataType.dtTable;
  //const {idFieldName, nameFieldName, keyFieldName} = dataType;
  const caption= dataType.dtName;

  return (
    <div>
      <h4>{caption} ({paradigmNamesTable.length}) {dataType.create && (<Link to={`/mdItemEdit/${tableName}`}> + </Link>)}</h4>
      <ol>
        {paradigmNamesTable
          .sort((a, b) => a.sortId - b.sortId)
          .map(m => {
            const bl = (curscrollTo.tabKey === tableName && (curscrollTo.recId === m.id ||  curscrollTo.recName === m.name));
            return (
              <li key={m.id.toString()} ref={ bl ? backLigth : null} >
                {!dataType.update && !dataType.delete && (<div>{m.name} ({m.formulasCount})</div>)}
                {(dataType.update || dataType.delete) && (<div>
                  <Link to={`/mdItemEdit/${tableName}/${m.id}`} 
                    className={ bl ? "backLigth" : null} onClick={(e) => {
                    // e.preventDefault(); ეს საჭირო არ არის, რადგან ლინკზე აღარ გადადის
                    //ვინახავთ გვერდის სახელს, საიდანაც მოხდა რედაქტორის გახსნა. იმისათვისმ რომ კორექტულად მოხდეს უკან დაბრუნება
                    //props.saveReturnPageName(props.match.url.split('/')[1]);
                    saveReturnPageName();
                  }} >{m.name} </Link>
                  <Link to={`/${formulasTableName}/${m.id}`} className={ bl ? "backLigth" : null}>({m.formulasCount})</Link>
                </div>)}
              </li>
            );
          })}
      </ol>
    </div>
  );
}

export default ParadigmListEditor;