//VerbPersonMarkerFormulas.js
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../carcass/common/Loading';
import { getFormulaVisual2 } from './FormulasModule';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { GetDisplayValue2 } from '../../carcass/modules/GetDisplayValue'
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt, filterByHeader } from '../../carcass/common/myFunctions';
import BsComboBox from '../../carcass/masterdata/BsComboBox';
import { useForman } from '../../carcass/common/MyHooks';
import * as yup from 'yup';


const VerbPersonMarkerFormulas = (props) => {
  // const { mdWorkingOnLoad, morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, 
  //   morphemeRangesByInflectionBlocks, verbPluralityTypes, verbPersons, verbNumbers, verbPersonMarkerParadigms, checkLoadMdTables,
  //   verbPersonMarkerFormulas, verbPersonMarkerFormulasLoading, checkloadVerbPersonMarkerFormulas } = props;
  const { alert, masterData, verbPersonMarkerFormulas, verbPersonMarkerFormulasLoading, 
    checkLoadMdTables, checkloadVerbPersonMarkerFormulas } = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, morphemeRangesByInflectionBlocks, 
    verbPluralityTypes, verbPersons, verbNumbers, verbPersonMarkerParadigms } = masterData.mdRepo;

  const [curParadigmId, setCurParadigmId] = useState(null);


  //console.log("VerbPersonMarkerFormulas props=", props);

  const paradigmId = NzInt(props.match.params.paradigmId);

  const tableNamesForLoad = useMemo(() => ["morphemeRanges", "morphemesQuery", "inflectionBlocks", "inflectionTypes", 
  "morphemeRangesByInflectionBlocks", "verbPluralityTypes", "verbPersons", "verbNumbers", "verbPersonMarkerParadigms"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [checkLoadMdTables, tableNamesForLoad]);


  useEffect(() => {
    if (curParadigmId !== paradigmId) {
      setCurParadigmId(paradigmId);
      checkloadVerbPersonMarkerFormulas(paradigmId);
    }

  }, [checkloadVerbPersonMarkerFormulas, paradigmId, curParadigmId]); //, loading, loadingFailure, drParentsLoading, drChildsLoading, drWorkingOnSave

  //const [curTabKey, curRecName, backLigth] = useTabRecscroller(props.match.params.paradigmId, props.match.params.recName);
  const [curscrollTo, backLigth] = useScroller(NzInt(props.match.params.recName));

  const yupSchema = yup.object().shape({
    verbPluralityTypeId: yup.number().integer().default(-1),
    verbNumberId: yup.number().integer().default(-1),
    verbPersonId: yup.number().integer().default(-1)
  });

  const [frm, changeField, getError, , , , ] = useForman(yupSchema);

  //console.log("VerbPersonMarkerFormulas before checkLoading=", {morphemeRanges, morphemesQuery, inflectionBlocks, 
    // inflectionTypes, morphemeRangesByInflectionBlocks, verbPluralityTypes, verbPersons, verbNumbers, verbPersonMarkerParadigms, 
    // curParadigmId, verbPersonMarkerFormulas, curscrollTo, mdWorkingOnLoad, verbPersonMarkerFormulasLoading});

  //console.log("VerbPersonMarkerFormulas props.match.params.recName=", props.match.params.recName);

  if (mdWorkingOnLoad || verbPersonMarkerFormulasLoading || datatypesLoading) {
    return (<Loading />);
  }

  if (!morphemeRanges || !morphemesQuery || !inflectionBlocks || !inflectionTypes || !morphemeRangesByInflectionBlocks || 
      !verbPluralityTypes || !verbPersons || !verbNumbers || !verbPersonMarkerParadigms ||
      !(curParadigmId in verbPersonMarkerFormulas) || !verbPersonMarkerFormulas[curParadigmId] || 
      (props.match.params.recName && !curscrollTo) || !datatypes ) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }


  const inflectionBlock = inflectionBlocks.find(f=>f.inbKey === 'PersonMarkersBlock');
  if ( ! inflectionBlock ) {
    return (<h5>არასწორი ფლექსიის ბლოკი</h5>);
  }

  const inflectionType = inflectionTypes.find(f=>f.iftId === inflectionBlock.inflectionTypeId);
  if ( ! inflectionType ) {
    return (<h5>არასწორი ფლექსიის ტიპი</h5>);
  }

  const MorphemeRangeIdsByIT = morphemeRangesByInflectionBlocks
    .filter(f=>f.inflectionBlockId === inflectionBlock.inbId)
    .map(m=>m.morphemeRangeId);

  const rangesInGroup = morphemeRanges
    .filter(f=>f.morphemeGroupId === inflectionType.morphemeGroupId && MorphemeRangeIdsByIT.includes(f.mrId))
    .sort((a,b) => a.mrPosition-b.mrPosition);


  function getParadigmName() {
    const verbPersonMarkerParadigm = verbPersonMarkerParadigms.find(f=>f.vpmpnId === curParadigmId);
    return `${verbPersonMarkerParadigm.vpmpnKey}`
  }

  //აქ ითვლება, რომ თუ პარადიგმის სახელების დარედაქტირების უფლება აქვს მომხმარებელს
  //მაშინ პარადიგმებზეც უნდა ჰქონდეს.
  //ასე იმიტომ გაკეთდა, რომ არ გამხდარიყო საჭირო პარადიგმების მწკრივის ცხრილის დარეგისტრირება DataType-ებში
  //თუ მომავალში ამ ორი უფლების გამიჯვნა გახდა საჭირო, მოგვიწევს DataType-ებში დავარეგისტრიროთ პარადიგმების მწკრივის ცხრილი
  const dataType = datatypes.find(f=>f.dtTable === "verbParadigms");

  return (
    <div>
      <Row>
        <Col sm="10">
          <Link to= {`/verbPersonMarkersOverview/verbPersonMarkerParadigms/${paradigmId}`} className="btn btn-primary">
            <FontAwesomeIcon icon="chevron-left" /> უკან პარადიგმების სიაში დაბრუნება
          </Link>

          <h4>ზმნის პირის ნიშნების პარადიგმა {getParadigmName()} ({verbPersonMarkerFormulas[curParadigmId].length})</h4>
        </Col>
        <Col sm="2" align="right">
        { dataType.create && (<Link to= {`/verbPersonMarkerFormulaEdit`} className="btn btn-primary">
            <FontAwesomeIcon icon="plus" /> ახალი
          </Link>) }
        </Col>
      </Row>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>ფორმულა</th>
            <th>მრავლობითობა
              <BsComboBox name="verbPluralityTypeId" 
                value={frm ? frm.verbPluralityTypeId : null} dataMember={verbPluralityTypes.sort((a, b) => a.sortId-b.sortId)} 
                valueMember="vptId" displayMember="vptName" getError={getError} onChangeValue={changeField} 
                sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable />
            </th>
            <th>ზმნის რიცხვი
              <BsComboBox name="verbNumberId" 
                value={frm ? frm.verbNumberId : null} dataMember={verbNumbers.sort((a, b) => a.sortId-b.sortId)} 
                valueMember="vnmId" displayMember="vnmName" getError={getError} onChangeValue={changeField} 
                sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable />
            </th>
            <th>ზმნის პირი
              <BsComboBox name="verbPersonId" 
                value={frm ? frm.verbPersonId : null} dataMember={verbPersons.sort((a, b) => a.sortId-b.sortId)} 
                valueMember="vprId" displayMember="vprName" getError={getError} onChangeValue={changeField} 
                sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable />
            </th>
          </tr>
        </thead>
        <tbody>
          {verbPersonMarkerFormulas[curParadigmId].filter(f=>filterByHeader(f,frm))
            .sort((a, b) => a.vprOrderInParadigm-b.vprOrderInParadigm)
            .map((vpmpr, index) => {
              const bl = (curscrollTo === vpmpr.vpmprId);
              return (
                <tr key={vpmpr.vpmprId.toString()} ref={ bl ? backLigth : null} >
                  <td className={ bl ? "backLigth" : null}>{index+1}</td>
                  <td className={ bl ? "backLigth" : null}>{getFormulaVisual2(vpmpr.verbPersonMarkerFormulaDetails.map(m=>m.morphemeId), rangesInGroup, morphemesQuery)}</td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbPluralityTypes, vpmpr.verbPluralityTypeId, {valueMember: "vptId", displayMember: "vptName", typeName: "Lookup"})}
                  </td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbNumbers, vpmpr.verbNumberId, {valueMember: "vnmId", displayMember: "vnmName", typeName: "Lookup"})}
                  </td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbPersons, vpmpr.verbPersonId, {valueMember: "vprId", displayMember: "vprName", typeName: "Lookup"})}
                  </td>
                  <td width="50px">
                    <div className="btn-toolbar pull-right">
                      { (dataType.update || dataType.delete) && 
                        (<Link to={`/verbPersonMarkerFormulaEdit/${vpmpr.vpmprId}`} className="btn btn-primary">
                          <FontAwesomeIcon icon="edit" />
                        </Link>) }
                    </div>
                  </td>
                </tr>);
            })}
        </tbody>
      </Table>
    </div>
  );



}

// export function GetDisplayValue2(dataTable, value, col) {

//   const { dataMember, valueMember, displayMember, rowSource, columnType } = col;




function mapStateToProps(state) {
  const alert = state.alert;
  const masterData = state.masterData;
  const {verbPersonMarkerFormulas, verbPersonMarkerFormulasLoading} = state.formulasStore;

  return { alert, masterData, verbPersonMarkerFormulas, verbPersonMarkerFormulasLoading };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    checkloadVerbPersonMarkerFormulas: (paradigmId) => dispatch(FormulasActions.checkloadVerbPersonMarkerFormulas(paradigmId))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerbPersonMarkerFormulas);
