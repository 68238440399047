import React, {useState, useEffect, useCallback} from 'react';
import { connect } from 'react-redux';
import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import WaitPage from '../../carcass/common//WaitPage';
import BaseLink from './BaseLink';
import { Link, useParams } from 'react-router-dom';

import './BaseSearch.css';

const itemsPerPage = 30;


const BaseSearch = (props) => {

  //console.log("BaseSearch props=", props);


  function getPageKey(val, itemsPerPage, currentPage) {
    var pageNom = 1;
    if (currentPage)
      pageNom = parseInt(currentPage);
    return { pageNom, pagekey: val + '_' + itemsPerPage + '_' + pageNom };
  }

  //console.log("BaseSearch Start props=", props);
  const { isMenuLoading, flatMenu, basesPageLoading, memoBaseCounts, memoBasePages, GetBasesByPages } = props;
  
  const menLinkKey = props.match.url.split('/')[1];
  // const { baseName, page } = props.match.params;
  const { baseName, page } = useParams();
  
  const [currentBaseName, setCurrentBaseName] = useState(null);
  const [currentPageNom, setCurrentPageNom] = useState(null);
  const [currentPageKey, setCurrentPageKey] = useState(null);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);


  useEffect(() => {

    const menuItem = isValidPage();
    //console.log("BaseSearch useEffect menuItem=", menuItem);
    if ( ! menuItem )
      return;

    const { pageNom, pagekey } = getPageKey(baseName, itemsPerPage, page);
    if (currentPageKey !== pagekey) {
      setCurrentBaseName(baseName);
      setCurrentPageNom(pageNom);
      setCurrentPageKey(pagekey);
      if (baseName && pageNom)
        GetBasesByPages(baseName, itemsPerPage, pageNom, pagekey);
    }
  }, [isMenuLoading, flatMenu, isValidPage, baseName, page, GetBasesByPages, currentPageKey]);

  function createLink(baseName, i, linkText = null) {
    const text = linkText ? linkText : i.toString();
    return (<Link key={text} className="paginglink" to={"/basesearch/" + baseName + "/" + i}>{text}</Link>);
  }

  function createPageLinks() {
    const pageLinks = [];

    if (memoBaseCounts[currentBaseName] && memoBaseCounts[currentBaseName] > 1) {
      const pagesCount = memoBaseCounts[currentBaseName] / itemsPerPage;
      let startWith = currentPageNom - 4;
      if (startWith < 1)
        startWith = 1;
      let endWith = startWith + 9;
      if (endWith > pagesCount)
        endWith = pagesCount;
      if (currentPageNom > 1)
        pageLinks.push(createLink(currentBaseName, currentPageNom - 1, "<"));
      for (let i = startWith; i < endWith; i++) {
        if (i === currentPageNom)
          pageLinks.push(<strong key="b">{createLink(currentBaseName, i)}</strong>);
        else
          pageLinks.push(createLink(currentBaseName, i));
      }
      if (currentPageNom < pagesCount)
        pageLinks.push(createLink(currentBaseName, currentPageNom + 1, ">"));
    }
    return pageLinks;
  }

  if (basesPageLoading || isMenuLoading)
    return (<WaitPage />);

  if (!currentBaseName || !currentPageKey || !flatMenu) {
    return (<div></div>);
  }

  const pageLinks = createPageLinks();

  if (!memoBaseCounts[currentBaseName]) {
    return (
      <div className="mt-5 row">
          <h5>ფუძე სახელით <strong>{currentBaseName}</strong> არ მოიძებნა</h5>
        </div>
    );
  }

  return (
    <div>
      <div className="mt-5 row">
        <h5>ფუძეების ძებნა: <strong>{currentBaseName}</strong> გვერდი: <strong>{currentPageNom}</strong></h5>
      </div>
      <div id="base-search" className="mt-5 row">
        <ul className="container threecolumns">
          {memoBasePages[currentPageKey] &&
            memoBasePages[currentPageKey].map((item, index) => {
              return (
                <li key={index} >
                  <BaseLink searchVal={currentBaseName} item={item} viewLinks />
                </li>
              );
            })
          }
        </ul>

        {pageLinks.length && (<div className="text-center container">{pageLinks}</div>) }

      </div>
    </div>
  );
}


function mapStateToProps(state) {
  const { basesPageLoading, memoBaseCounts, memoBasePages } = state.derivTree;
  const { isMenuLoading, flatMenu } = state.navMenu;
  return { isMenuLoading, flatMenu, basesPageLoading, memoBaseCounts, memoBasePages };
}

function mapDispatchToProps(dispatch) {
  return {
    GetBasesByPages: (val, itemsPerPage, currentPage, pagekey) => dispatch(DerivTreeActions.GetBasesByPages(val, itemsPerPage, currentPage, pagekey))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseSearch);
