//NavMenuStore.js
import apiClient from '../modules/ApiClient';

//constants
const toggleactive = 'TOGGLEACTIVE';
const toggleexp = 'TOGGLEEXP';

const requestGetMainMenu = 'REQUEST_GET_MAIN_MENU';
const getMainMenuFailure = 'GET_MAIN_MENU_FAILURE';
const setMainMenu = 'SET_MAIN_MENU';

const initialState = { isMenuLoading: false, active: false, mainMenu: null, flatMenu: null };

export const actionCreators = {

  toggleactive: () => {
    return { type: toggleactive }
  },

  toggleexp: (submenuindex) => {
    return { type: toggleexp, submenuindex }
  },

  getMainMenu: () => async (dispatch, getState) => {
    //console.log('MasterData.js apiClient.Get /userrights/getmainmenu');
    await apiClient.Get('/userrights/getmainmenu',
      { get: requestGetMainMenu, set: setMainMenu, failure: getMainMenuFailure },
      dispatch, getState);
  }

};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === toggleactive) {
    return {
      ...state,
      active: ! state.active
    };
  }

  if (action.type === toggleexp) {
    const submenuindex = action.submenuindex;
    return {
      ...state,
      mainMenu: state.mainMenu.map((mengitem, index) => {
        if (submenuindex === index) {
          mengitem.expanded = !mengitem.expanded; 
        }
        return mengitem;
      })
    }
  }

  if (action.type === requestGetMainMenu) {
    return {
      ...state,
      isMenuLoading: true
    };
  }

  if (action.type === setMainMenu) {
    return {
      ...state,
      mainMenu: action.payload.data.menuGroups,
      flatMenu: action.payload.data.menuGroups?.reduce( (flat, grp) => flat.concat(grp.menu), []),
      isMenuLoading: false
    };
  }

  if (action.type === getMainMenuFailure) {
    return {
      ...state,
      mainMenu: null,
      flatMenu: null,
      isMenuLoading: false
    };
  }

  return state;
};
