//NounParadigmsOverview.js
import React, {useEffect, useMemo, useCallback} from 'react';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap'

import Loading from '../../carcass/common/Loading';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';
import NameListEditor from './NameListEditor';
import ParadigmListEditor from './ParadigmListEditor';

const NounParadigmsOverview = (props) => {

  const {alert, isMenuLoading, flatMenu, masterData, nounParadigmNamesLoading, nounParadigmNames,
    checkLoadMdTables, CheckLoadNounParadigmNames } = props;  

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const grammarCases = masterData.mdRepo.grammarCases;
  const nounNumbers = masterData.mdRepo.nounNumbers;
  
  //console.log("NounParadigmsOverview props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["grammarCases", "nounNumbers"], []);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    checkLoadMdTables(tableNamesForLoad);
    CheckLoadNounParadigmNames();
  }, [isMenuLoading, flatMenu, isValidPage, checkLoadMdTables, CheckLoadNounParadigmNames, tableNamesForLoad]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recId: NzInt(props.match.params.recName), 
    recName: props.match.params.recName
  });

  function saveReturnPageName() {
    props.saveReturnPageName(menLinkKey);
  }

  //console.log("NounParadigmsOverview onCheckLoad ", {grammarCases, nounNumbers, nounParadigmNames, curscrollTo, mdWorkingOnLoad, nounParadigmNamesLoading});

  if (mdWorkingOnLoad || nounParadigmNamesLoading || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }

  if (!grammarCases || !nounNumbers || !nounParadigmNames || !curscrollTo || !datatypes) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  const listEditorTableNames = ["grammarCases", "nounNumbers"];

  const nounParadigmsDataType = datatypes.find(f=>f.dtTable === "nounParadigms");

  return (
    <div>
      <h3>სახელის ფლექსიის პარადიგმების მიმოხილვა</h3>
      <h4>ტერმინები</h4>
      <p><strong>ბრუნვა</strong> - ბრუნვის განმარტება (გასაკეთებელია).</p>
      <p><strong>რიცხვი</strong> - რიცხვის განმარტება (გასაკეთებელია).</p>
      <p><strong>პარადიგმა</strong> - პარადიგმის განმარტება (გასაკეთებელია).</p>

      {listEditorTableNames.map(tn => {
        const dataType = datatypes.find(f=>f.dtTable === tn);

        return (<NameListEditor key={dataType.dtName} dataType={dataType} tableForEdit={masterData.mdRepo[tn]} 
          curscrollTo={curscrollTo} backLigth={backLigth} saveReturnPageName={saveReturnPageName} />);
      } )}

      <ParadigmListEditor dataType={nounParadigmsDataType} paradigmNamesTable={nounParadigmNames} 
        formulasTableName="nounParadigmFormulas" curscrollTo={curscrollTo} 
        backLigth={backLigth} saveReturnPageName={saveReturnPageName} />

    </div>
  );


}

function mapStateToProps(state) {

  const alert = state.alert;
  const masterData = state.masterData;
  const {isMenuLoading, flatMenu} = state.navMenu;
  const { nounParadigmNamesLoading, nounParadigmNames } = state.formulasStore;

  return {alert, isMenuLoading, flatMenu, masterData, nounParadigmNamesLoading, nounParadigmNames};
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    CheckLoadNounParadigmNames: () => dispatch(FormulasActions.CheckLoadNounParadigmNames())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NounParadigmsOverview);
