import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Nav, Form, FormControl, Button, ToggleButton, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { actionCreators as RightsActions } from './RightsStore';

//import { useHistory } from "react-router-dom";
import MessageBox from '../common/MessageBox';

import './FrmRights.css'

const RightsTopNavMenu = (props) => {

  //console.log("RightsTopNavMenu props=", props);
  const {rView} = props.match.params;
  const {flatMenu, drWithCodes, drLinear, drWorkingOnSave, drWorkingOnRefresh, drWorkingOnOptimisation, changedRights, 
    selectedChildDataType, searchText} = props;
  const [showRefreshConfirmMessage, setShowRefreshConfirmMessage] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  
  // const history = useHistory();
  

  function isValidPage() {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.some(f=>f.menLinkKey === props.match.url.split('/')[1]);
  }




  useEffect(() => { 
    setDisableButtons(drWorkingOnSave || drWorkingOnRefresh || drWorkingOnOptimisation); 
  }, [drWorkingOnSave, drWorkingOnRefresh, drWorkingOnOptimisation]);

  function doRefresh() {
    setDisableButtons(true);
    const {rView, dtKey, key} = props.match.params;
    props.LoadChildsTreeDataAndChecks(rView, dtKey, key);
  }


  if ( ! isValidPage() ) {
    return ( <div/> );
  }

  return (
    <Nav>
      <Form inline>
          <FormControl type="text" placeholder="Search" className="mr-sm-2" text={searchText} onChange={(e) => {
          //console.log("RightsTopNavMenu FormControl onChange e.target.value=", e.target.value);
          props.setSearchText(e.target.value);
        }} />
      </Form>
    <Nav className="mr-auto">
      <Button className="btn-space" disabled={disableButtons} onClick={(e) => {
        e.preventDefault();
        if ( changedRights.length > 0 )
          setShowRefreshConfirmMessage(true);
        else
          doRefresh();
      }} ><FontAwesomeIcon icon="sync" /> განახლება
        {drWorkingOnRefresh && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}          
      </Button>

      <MessageBox show={showRefreshConfirmMessage} title="განახლება"
                  text={"უკვე გაკეთებული ცვლილებები განახლებისას დაიკარგება, მაინც გსურთ განახლების გაკეთება?"}
                  primaryButtonText="დიახ" secondaryButtonText="არა" onConfirmed={() => {
          setShowRefreshConfirmMessage(false);
          doRefresh();
      }} onClosed={() => setShowRefreshConfirmMessage(false)} />


      <Button className="btn-space" disabled={disableButtons || changedRights.length === 0} onClick={() => {
        //setDisableButtons(true);
        props.SaveDtaRightChanges();
      }} ><FontAwesomeIcon icon="save" /> შენახვა
        {drWorkingOnSave && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
      </Button>
      <ToggleButton type="checkbox" checked={drWithCodes ? true : false} className="btn-space" onChange={(val) => {
          props.switchWithCodes(val.target.checked);
      }}><FontAwesomeIcon icon="barcode" /> კოდებით</ToggleButton>
      <ToggleButton type="checkbox" checked={(rView === "ReverseView") ? true : false} className="btn-space" onChange={(val) => {
          props.history.push(`/Rights/${val.target.checked ? "ReverseView" : "NormalView" }`);
      }}><FontAwesomeIcon icon="arrows-alt-h" /> რევერსი</ToggleButton>
      <ToggleButton type="checkbox" checked={drLinear ? true : false} className="btn-space" onChange={(val) => {
          props.switchLinear(val.target.checked);
      }}><FontAwesomeIcon icon="long-arrow-alt-down" /> სწორხაზოვნად</ToggleButton>
        <Button className="btn-space" disabled={!selectedChildDataType} onClick={() => {
          const {rView, dtKey, key} = props.match.params;
          props.turnAll(rView, dtKey, key, selectedChildDataType, true);
        }}><FontAwesomeIcon icon="check-square" /> ყველა {selectedChildDataType?selectedChildDataType.dtName:""}</Button>
      <Button className="btn-space" disabled={!selectedChildDataType} onClick={() => {
          const {rView, dtKey, key} = props.match.params;
          props.turnAll(rView, dtKey, key, selectedChildDataType, false);
        }}><FontAwesomeIcon icon="square" /> არცერთი {selectedChildDataType?selectedChildDataType.dtName:""}</Button>
      <Button className="btn-space" disabled={disableButtons || changedRights.length > 0} onClick={(e) => {
        e.preventDefault();
        props.OptimizeRights();
        doRefresh();
      }}><FontAwesomeIcon icon="microscope" /> ოპტიმიზაცია
        {drWorkingOnOptimisation && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}          
      </Button>
    </Nav>
  </Nav>
  );
}



function mapStateToProps(state) {
  const { drWithCodes, drLinear, drWorkingOnSave, drWorkingOnRefresh, drWorkingOnOptimisation, changedRights, 
    selectedChildDataType, searchText } = state.rights;

  const { flatMenu } = state.navMenu;

  return { flatMenu, drWithCodes, drLinear, drWorkingOnSave, drWorkingOnRefresh, drWorkingOnOptimisation, changedRights, 
    selectedChildDataType, searchText
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchWithCodes: (turnOn) => dispatch(RightsActions.switchWithCodes(turnOn)),
    switchLinear: (turnOn) => dispatch(RightsActions.switchLinear(turnOn)),
    LoadChildsTreeDataAndChecks: (rView, dtKey, key) => dispatch(RightsActions.LoadChildsTreeDataAndChecks(rView, dtKey, key, true)),
    turnAll: (curRView, curParentDtKey, curKey, selectedChildDataType, turnOn) => dispatch(RightsActions.turnAll(curRView, curParentDtKey, curKey, selectedChildDataType, turnOn)),
    SaveDtaRightChanges: () => dispatch(RightsActions.SaveDtaRightChanges()),
    OptimizeRights: () => dispatch(RightsActions.OptimizeRights()),
    setSearchText: (text) => dispatch(RightsActions.setSearchText(text))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightsTopNavMenu);

