//OneNumberControl.js
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const OneNumberControl = (props) => {

  //onChange=handleChange
  const { controlId, label, value, getError, onChangeValue, minv, stepv } = props;
  const error = getError(controlId);

  const min = (!minv && minv !== 0) ? 1 : minv;
  const step = (!stepv && stepv !== 0) ? 1 : stepv;


  function handleChange(e) {
    const { id, value } = e.target;
    onChangeValue(id, value);
  }

  return (
    <Form.Group className="mb-1" as={Row} controlId={controlId}>
      <Form.Label column sm="4" className="text-right">{label}</Form.Label>
      <Col sm="6">
        <Form.Control type="number" placeholder={label} min={min} step={step} value={value} onChange={handleChange} 
          autoComplete="off" className={!!error && "is-invalid"} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}

export default OneNumberControl;
