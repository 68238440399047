//ParamsStore.js
const setBaseUrl = 'SET_BASE_URL';


const initialState = { baseUrl: null };

export const actionCreators = {
  setBaseUrl: (baseUrl) => ({ type: setBaseUrl, baseUrl })
}


export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === setBaseUrl) {
    //console.log("ParamsStore reducer setBaseUrl action.baseUrl=", action.baseUrl);
    return { ...state, baseUrl: action.baseUrl };
  }

  return state;
};
