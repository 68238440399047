import React from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup'; // for everything // or //import { string, object } from 'yup'; // for only what you need

import { actionCreators as alertActions } from '../store/AlertStore';
import { actionCreators as userActions } from './UserStore';
import { useForman } from '../common/MyHooks';
import OneSaveCancelButtons from '../editorParts/OneSaveCancelButtons';
import OneTextControl from '../editorParts/OneTextControl';
import OneErrorRow from '../editorParts/OneErrorRow';

const Profile = (props) => {

  const { changingProfile, user, alert } = props;

  const schema = yup.object().shape({
    firstName: yup.string().required("სახელი შევსებული უნდა იყოს").max(50,  "სახელის სიგრძე არ შეიძლება იყოს 50 სიმბოლოზე მეტი").default(user.firstName),
    lastName: yup.string().required("გვარი შევსებული უნდა იყოს").max(100, "გვარის სიგრძე არ შეიძლება იყოს 100 სიმბოლოზე მეტი").default(user.lastName)
  });

  const [frm, changeField, getError, getAllErrors, , , ] = useForman(schema);


  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  // function handleChange(e) {
  //   console.log("Profile handleChange e.target=", e.target);
  //   const { id, value } = e.target;
  //   changeField(id, value);
  // }

  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;
    //console.log("Profile handleSubmit user=", user);
    if ( user.userId )
      props.changeProfile({ ...frm, email: user.email, userName: user.userName, userid: user.userId });
  }
  
  // function getOneTextControl(id, label, type, value) {
  //   const error = getError(id);
  //   return (
  //     <Form.Group className="mb-0" controlId={id}>
  //       <Form.Label>{label}</Form.Label>
  //       <Form.Control type={type} value={value} onChange={handleChange} className={!!error && "is-invalid"} />
  //       <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
  //     </Form.Group>
  //   );
  // }

  if ( ! user.userId )
  return ( <Alert variant="danger">შეცდომა! პროფაილის რედაქტირება შეუძლებელია</Alert> );


  return (
    <Row id="MdItemEdit">
      <Col md="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          {/* {getOneTextControl("firstName", "სახელი", "text", frm.firstName)} */}
          <OneTextControl controlId="firstName" label="სახელი" value={frm.firstName} getError={getError} onChangeValue={changeField} />
          <OneTextControl controlId="lastName" label="გვარი" value={frm.lastName} getError={getError} onChangeValue={changeField} />
          {/* {getOneTextControl("lastName", "გვარი", "text", frm.lastName)} */}
          {/* <Button variant="primary" onClick={handleSubmit}> შენახვა
            {changingProfile && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </Button> */}
          <OneSaveCancelButtons curIdVal={user.userId} haveErrors={haveErrors} savingNow={changingProfile} />
          <OneErrorRow allErrors={allErrors} />
        </Form>
      </Col>
    </Row>
  );


}



function mapStateToProps(state) {
  const { changingProfile, user } = state.authentication;
  const alert = state.alert;
  return { changingProfile, user, alert };
}

function mapDispatchToProps(dispatch) {
  return {
    changeProfile: (chpData) => dispatch(userActions.changeProfile(chpData)),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);


