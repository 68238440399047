//VerbPersonMarkerFormulaEdit.js
import React, {useState, useEffect, useCallback, useMemo} from 'react';
//import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

//import { history } from '../../carcass/common/History';
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as ModelEditorActions } from './ModelEditorStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { useForman } from '../../carcass/common/MyHooks';
import WaitPage from '../../carcass/common/WaitPage';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
// import OneTextControl from '../../carcass/editorParts/OneTextControl';
// import OneNumberControl from '../../carcass/editorParts/OneNumberControl';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import OnePlaintextRow from '../editorParts/OnePlaintextRow';
import { getFormulaVisual2, createFormulaFormData } from './FormulasModule';
import { NzInt } from '../../carcass/common/myFunctions';

const VerbPersonMarkerFormulaEdit = (props) => {

  //2. კომპონენტის თვისებები
  // const { alert, mdWorkingOnLoad, checkLoadMdTables, 
  //   morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, morphemeRangesByInflectionBlocks, 
  //   verbPluralityTypes, verbPersons, verbNumbers, verbPersonMarkerParadigms,
  //   savingVerbPersonMarkerFormula, loadingVerbPersonMarkerFormula, workingOnDeleteVerbPersonMarkerFormula, verbPersonMarkerFormulaForEdit, 
  //   getOneVerbPersonMarkerFormulaById, DeleteFailure } = props;

  const {alert, masterData, savingVerbPersonMarkerFormula, loadingVerbPersonMarkerFormula, 
    workingOnDeleteVerbPersonMarkerFormula, verbPersonMarkerFormulaForEdit, DeleteFailure, 
    checkLoadMdTables, getOneVerbPersonMarkerFormulaById} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, 
    verbPluralityTypes, verbPersons, verbNumbers, verbPersonMarkerParadigms, morphemeRangesByInflectionBlocks } = masterData.mdRepo;


  //1. იდენტიფიკატორი
  const [curFormulaIdVal, setCurFormulaIdVal] = useState(null);
  const [paradigmIdsForClear, setParadigmIdsForClear] = useState([]); 
  const [rangesInGroup, setRangesInGroup] = useState(null);
  const [formDataPrepared, setFormDataPrepared] = useState(false);

  //const history = useHistory();


  //  console.log("VerbPersonMarkerFormulaEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => ["verbPersonMarkerParadigmRows"], []);

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["morphemeRanges", "morphemesQuery", "inflectionBlocks", 
    "inflectionTypes", "morphemeRangesByInflectionBlocks", "verbPersonMarkerParadigms", 
    "verbPluralityTypes", "verbPersons", "verbNumbers"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [tableNamesForLoad, checkLoadMdTables]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    verbPersonMarkerFormula: yup.object().shape({
      vpmprId: yup.number().integer().default(0).required(),
      verbPluralityTypeId: yup.number().integer().default(0).positive("მრავლობითობა არჩეული უნდა იყოს")
        .required("მრავლობითობა არჩეული უნდა იყოს").default(0),
      verbPersonMarkerParadigmId: yup.number().integer().positive("პირის ნიშნების პარადიგმა არჩეული უნდა იყოს")
        .required("პირის ნიშნების პარადიგმა არჩეული უნდა იყოს").default(0),
      verbPersonId: yup.number().integer().positive("ზმნის პირი არჩეული უნდა იყოს")
        .required("ზმნის პირი არჩეული უნდა იყოს").default(0),
      verbNumberId: yup.number().integer().positive("ზმნის რიცხვი არჩეული უნდა იყოს")
        .required("ზმნის რიცხვი არჩეული უნდა იყოს").default(0)
    }),
    morphemeIds: yup.array().ensure().min(1, "ფორმულაში უნდა იყოს ერთი მაინც არანულოვანი მორფემა")
      .of(yup.number().integer().positive().default(0)
      .required()),
    formData: yup.object().shape({
      ranges: yup.array().ensure(),
      morphemes: yup.array().ensure()
        .of(yup.number().integer().positive("მორფემა არჩეული უნდა იყოს").default(0)
        .required()),
    })
  });

  //console.log("VerbPersonMarkerFormulaEdit yupSchema=", yupSchema);

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    props.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
    props.clearVerbPersonMarkerFormulas(paradigmIdsForClear);
  }

  const copyMorphemsToMainData = useCallback((forForm) => {
    const newForm = {...forForm};

    //console.log("VerbPersonMarkerFormulaEdit copyMorphemsToMainData forForm=", forForm);
    //console.log("VerbPersonMarkerFormulaEdit copyMorphemsToMainData newForm=", newForm);

    newForm.morphemeIds = newForm.formData.morphemes.map((mrpId) => {
      return mrpId ? morphemesQuery.find((f)=> f.mrpId === mrpId) : null;
    }).filter(f=>f && f.mrpNom).map(m=>m.mrpId);
    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
    setFormData(newForm);
  },[morphemesQuery, setFormData]);

  const addForClearParadigmId = useCallback((paradigmId) => {
    if ( paradigmIdsForClear.includes(paradigmId) )
      return;
    const newParadigmIdsForClear = [...paradigmIdsForClear, paradigmId];
    setParadigmIdsForClear(newParadigmIdsForClear);
  }, [paradigmIdsForClear]);

  //ფლექსიის ბლოკის ეფექტი
  useEffect(() => {

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშIნ გაგრეძელებას აზრი არ აქვს
    if ( mdWorkingOnLoad || !inflectionBlocks || !inflectionTypes || !morphemeRanges || !morphemeRangesByInflectionBlocks )
      return;

    //მოვძებნოთ ფლექსიის ბლოკის შესაბამისი ობიექტი
    const inflectionBlock = inflectionBlocks.find(f=>f.inbKey === "PersonMarkersBlock");

    //console.log("VerbPersonMarkerFormulaEdit useEffect inflectionBlock=", inflectionBlock);

    //დერივაციის ტიპის პოვნას აზრი აქვს მხოლოდ იმ შემთხვევაში, თუ დერივაციიც ტიპების ცხრილი ჩატვირთულია
    //მოვძებნოთ დერივაციის ტიპის შესაბამისი ობიექტი
    const inflectionType = inflectionTypes.find(f=>f.iftId === inflectionBlock.inflectionTypeId);

    //console.log("VerbPersonMarkerFormulaEdit useEffect inflectionType=", inflectionType);
    //console.log("VerbPersonMarkerFormulaEdit useEffect morphemeRangesByInflectionBlocks=", morphemeRangesByInflectionBlocks);

    //ამოვკრიბოთ იმ რანგების შესახებ ინფორმაცია, რომლებიც მონაწილეობენ ამ ტიპის დერივაციის ფორმირებაში
    const MorphemeRangeIdsByIB = morphemeRangesByInflectionBlocks
      .filter(f=>f.inflectionBlockId === inflectionBlock.inbId)
      .map(m=>m.morphemeRangeId);

    //console.log("VerbPersonMarkerFormulaEdit useEffect MorphemeRangeIdsByIB=", MorphemeRangeIdsByIB);

    const newRangesInGroup = morphemeRanges
      .filter(f=>f.morphemeGroupId === inflectionType.morphemeGroupId && MorphemeRangeIdsByIB.includes(f.mrId))
      .sort((a,b) => a.mrPosition-b.mrPosition);

    setRangesInGroup(newRangesInGroup);

  }, [mdWorkingOnLoad, inflectionBlocks, inflectionTypes, morphemeRanges, morphemeRangesByInflectionBlocks]);

  const prepareFormDataForEdit = useCallback((forForm) => {
    const newForm = forForm ? forForm : {...frm};
    newForm.formData = createFormulaFormData(newForm.formData, rangesInGroup, newForm.morphemeIds, morphemesQuery, false, true);
    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
    //console.log("VerbPersonMarkerFormulaEdit prepareFormDataForEdit newForm=", newForm);
    copyMorphemsToMainData(newForm);
  }, [morphemesQuery, copyMorphemsToMainData, frm, rangesInGroup]);

  const { formulaId } = props.match.params;
  const formulaIdVal = NzInt(formulaId);

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    //console.log("VerbPersonMarkerFormulaEdit useEffect curFormulaIdVal=", curFormulaIdVal);
    //console.log("VerbPersonMarkerFormulaEdit useEffect formulaIdVal=", formulaIdVal);

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curFormulaIdVal !== formulaIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurFormulaIdVal(formulaIdVal);
      setFormDataPrepared(false);
      setParadigmIdsForClear([]);
      if (formulaIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneVerbPersonMarkerFormulaById(formulaIdVal);
        return;
      }
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      //console.log("VerbPersonMarkerFormulaEdit useEffect after clearToDefaults frm=", frm);
    }

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშIნ გაგრეძელებას აზრი არ აქვს
    if ( mdWorkingOnLoad || !morphemesQuery || !rangesInGroup )
      return;

    //console.log("VerbPersonMarkerFormulaEdit useEffect after checkLoad curFormulaIdVal=", curFormulaIdVal);

      //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთული ობიექტი მაინც შევამოწმოთ

    //დავამზადოთ ფორმის ახალი ობიექტი
    if ( formulaIdVal ) {
      //ტუ ინფომრაცია ჯერ ჩატვირთული არ არის, ვჩერდებით
      if ( loadingVerbPersonMarkerFormula || !verbPersonMarkerFormulaForEdit )
        return;
      addForClearParadigmId(verbPersonMarkerFormulaForEdit.verbPersonMarkerFormula.verbPersonMarkerParadigmId);
      if ( !formDataPrepared ) {
        setFormDataPrepared(true);
        prepareFormDataForEdit(verbPersonMarkerFormulaForEdit);
      }
    }
    else {
      if ( !formDataPrepared ) {
        setFormDataPrepared(true);
        prepareFormDataForEdit(null);
      }
    }


  }, [addForClearParadigmId, clearToDefaults, getOneVerbPersonMarkerFormulaById, curFormulaIdVal, formDataPrepared, 
    formulaIdVal, loadingVerbPersonMarkerFormula, mdWorkingOnLoad, morphemesQuery, prepareFormDataForEdit, 
    rangesInGroup, verbPersonMarkerFormulaForEdit]);
    
    
    //formDataPrepared
    //loadingVerbPersonMarkerFormula


    //mdWorkingOnLoad
    //morphemesQuery
    // , rangesInGroup, 
    // verbPersonMarkerFormulaForEdit, verbPersonMarkerParadigms


    //

    //morphemesQuery, verbPersonMarkerParadigms, addForClearParadigmId, clearToDefaults, curFormulaIdVal, 
    // getOneVerbPersonMarkerFormulaById, loadingVerbPersonMarkerFormula, mdWorkingOnLoad, verbPersonMarkerFormulaForEdit, 
    // prepareFormDataForEdit, formulaIdVal, rangesInGroup, formDataPrepared


  // clearToDefaults, curFormulaIdVal, props.match.params, setFormData, verbPersonMarkerFormulaForEdit, 
  // loadingVerbPersonMarkerFormula, getOneVerbPersonMarkerFormulaById,
  // inflectionBlocks, inflectionTypes, morphemeRanges, morphemeRangesByInflectionBlocks, morphemesQuery, verbPersonMarkerParadigms,
  // copyMorphemsToMainData, mdWorkingOnLoad, addForClearParadigmId, prepareFormDataForEdit, rangesInGroup  

// debugger;


  //8. ჩატვირთვის შემოწმება
  if ( loadingVerbPersonMarkerFormula || mdWorkingOnLoad || datatypesLoading ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
    return (<WaitPage />);
  //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( (curFormulaIdVal && !verbPersonMarkerFormulaForEdit) || !morphemeRanges || !morphemesQuery || !inflectionBlocks || 
      !inflectionTypes || !morphemeRangesByInflectionBlocks || !verbPersonMarkerParadigms || 
      !verbPluralityTypes || !verbPersons || !verbNumbers || !verbPersonMarkerParadigms || !datatypes ) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }
  
  //console.log("VerbPersonMarkerFormulaEdit frm=", frm);

  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");


  // function getParadigmName() {
  //   const verbPersonMarkerParadigm = verbPersonMarkerParadigms.find(f=>f.vpnId === frm.VerbPersonMarkerFormula.verbParadigmId);
  //   return verbPersonMarkerParadigm.vpnName
  // }



  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;

//გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
//წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას
    //console.log("VerbPersonMarkerFormulaEdit handleSubmit e=",e);
    //console.log("VerbPersonMarkerFormulaEdit handleSubmit frm=",frm);

    const currentForm = {...frm};
    copyMorphemsToMainData(currentForm);
    delete currentForm.formData;
    // const paradigmName = getParadigmName();
    if ( curFormulaIdVal )
      props.updateVerbPersonMarkerFormula(props.history, currentForm, frm.verbPersonMarkerFormula.verbPersonMarkerParadigmId);
    else
      props.createVerbPersonMarkerFormula(props.history, currentForm, frm.verbPersonMarkerFormula.verbPersonMarkerParadigmId);
    clearUsedTables();
  }

  //აქ ითვლება, რომ თუ პარადიგმის სახელების დარედაქტირების უფლება აქვს მომხმარებელს
  //მაშინ პარადიგმებზეც უნდა ჰქონდეს.
  //ასე იმიტომ გაკეთდა, რომ არ გამხდარიყო საჭირო პარადიგმების მწკრივის ცხრილის დარეგისტრირება DataType-ებში
  //თუ მომავალში ამ ორი უფლების გამიჯვნა გახდა საჭირო, მოგვიწევს DataType-ებში დავარეგისტრიროთ პარადიგმების მწკრივის ცხრილი
  const dataType = datatypes.find(f=>f.dtTable === "verbPersonMarkerParadigms");

  return (
    <Row>
      <Col md="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          <EditorHeader curIdVal={curFormulaIdVal} EditorName="ზმნის ცალი პირის პარადიგმის ფორმულა" EditorNameGenitive="ზმნის ცალი პირის პარადიგმის ფორმულის"
            EditedObjectName={(frm && frm.verbPersonMarkerFormula && frm.verbPersonMarkerFormula.formulaName) ? frm.verbPersonMarkerFormula.formulaName : ""}
            workingOnDelete={workingOnDeleteVerbPersonMarkerFormula} DeleteFailure={DeleteFailure}
            onDelete={() => {
              // const paradigmName = getParadigmName();
              props.deleteVerbPersonMarkerFormula(props.history, curFormulaIdVal, frm.verbPersonMarkerFormula.vpmprId); 
              clearUsedTables();
            }}
            onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={dataType.delete} />
          <OnePlaintextRow controlId="result" label="შედეგი" 
            text={getFormulaVisual2(frm.morphemeIds, frm.formData.ranges, morphemesQuery)} />          
          <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />
          <OneComboBoxControl controlId="verbPersonMarkerFormula.verbPluralityTypeId" 
            label="მრავლობითობა" value={frm.verbPersonMarkerFormula.verbPluralityTypeId} 
            dataMember={verbPluralityTypes} valueMember="vptId" displayMember="vptName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ მრავლობითობა" }} />
          <OneComboBoxControl controlId="verbPersonMarkerFormula.verbPersonMarkerParadigmId" 
            label="პარადიგმა" value={frm.verbPersonMarkerFormula.verbPersonMarkerParadigmId} 
            dataMember={verbPersonMarkerParadigms.map(m => { return {id: m.vpmpnId, name: `${m.vpmpnKey}` }})} 
            valueMember="id" displayMember="name"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ პარადიგმა" }} />
          <OneComboBoxControl controlId="verbPersonMarkerFormula.verbPersonId" 
            label="პირი" value={frm.verbPersonMarkerFormula.verbPersonId} 
            dataMember={verbPersons} valueMember="vprId" displayMember="vprName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ ზმნის პირი" }} />
          <OneComboBoxControl controlId="verbPersonMarkerFormula.verbNumberId" 
            label="რიცხვი" value={frm.verbPersonMarkerFormula.verbNumberId} 
            dataMember={verbNumbers} valueMember="vnmId" displayMember="vnmName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ ზმნის რიცხვი" }} />
          {/* <OneTextControl controlId="verbPersonMarkerFormula.nprSample" label="ნიმუში" value={frm.verbPersonMarkerFormula.nprSample} 
            getError={getError} onChangeValue={changeField} /> */}
          <OneStrongLabel controlId="morphemes" label="მორფემები რანგების მიხედვით" />
          {frm.formData.ranges.map((range, index) => {
            return (<OneComboBoxControl key={index} controlId={`frm.formData.morphemes[${index}]`}
            label={`${range.mrPosition+1}. ${range.mrName}`} value={frm.formData.morphemes[index]} 
            dataMember={morphemesQuery.filter(mph=>mph.morphemeRangeId === range.mrId && mph.mrpNom >= range.minNom && mph.mrpNom <= range.maxNom)} 
            valueMember="mrpId" displayMember="mrpName" sortByDisplayMember={false}
            getError={getError} onChangeValue={(fieldPath, value) => {
              const newForm = {...frm};
              newForm.formData.morphemes[index] = value;
              copyMorphemsToMainData(newForm);
            }} />);
          })}
          <OneSaveCancelButtons curIdVal={curFormulaIdVal} haveErrors={haveErrors} savingNow={savingVerbPersonMarkerFormula} onCloseClick={() => { 
            // const paradigmName = getParadigmName();
            props.clearAlert(); 
            props.history.push(`/verbPersonMarkerFormulas/${frm.verbPersonMarkerFormula.verbPersonMarkerParadigmId}/${curFormulaIdVal}`);
          }} allowEdit={dataType.update} />
          <OneErrorRow allErrors={allErrors} />
        </Form>
      </Col>
    </Row>
  );

}

function mapStateToProps(state) {

  const alert = state.alert;
  const masterData = state.masterData;
  const { savingVerbPersonMarkerFormula, loadingVerbPersonMarkerFormula, workingOnDeleteVerbPersonMarkerFormula, 
    verbPersonMarkerFormulaForEdit, DeleteFailure } = state.modelEditorStore

    return {alert, masterData, savingVerbPersonMarkerFormula, loadingVerbPersonMarkerFormula, 
    workingOnDeleteVerbPersonMarkerFormula, verbPersonMarkerFormulaForEdit, DeleteFailure};
}

function mapDispatchToProps(dispatch) {
  return {
    getOneVerbPersonMarkerFormulaById: (vpfId) => dispatch(ModelEditorActions.getOneVerbPersonMarkerFormulaById(vpfId)),
    updateVerbPersonMarkerFormula: (history, vpf, paradigmName) => dispatch(ModelEditorActions.updateVerbPersonMarkerFormula(history, vpf, paradigmName)),
    createVerbPersonMarkerFormula: (history, vpf, paradigmName) => dispatch(ModelEditorActions.createVerbPersonMarkerFormula(history, vpf, paradigmName)),
    deleteVerbPersonMarkerFormula: (history, vpfId, paradigmName) => dispatch(ModelEditorActions.deleteVerbPersonMarkerFormula(history, vpfId, paradigmName)),
    clearDeletingFailure: () => dispatch(ModelEditorActions.clearDeletingFailure()),
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad)),
    clearVerbPersonMarkerFormulas: (paradigmIdsForClear) => dispatch(FormulasActions.clearVerbPersonMarkerFormulas(paradigmIdsForClear)),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerbPersonMarkerFormulaEdit);

