//OneTextControl.js
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const OneTextControl = (props) => {

  //onChange=handleChange
  const { controlId, label, value, getError, onChangeValue } = props;
  const error = getError(controlId);

  function handleChange(e) {
    const { id, value } = e.target;
    onChangeValue(id, value);
  }

  return (
    <Form.Group className="mb-1" as={Row} controlId={controlId}>
      <Form.Label column sm="4" className="text-right">{label}</Form.Label>
      <Col sm="6">
        <Form.Control type="text" placeholder={label} value={ (value || value === 0) ? value : "" } onChange={handleChange} 
          autoComplete="off" className={!!error && "is-invalid"} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}

export default OneTextControl;
