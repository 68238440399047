//BasesAndFreeMorphemes.js
import React from 'react'
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import TextBoxAutoComplete from './TextBoxAutoComplete';
import BaseLink from './BaseLink';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';

import { actionCreators as DerivTreeActions, getBranchByIdFromStore, funPredRoots } from './DerivationTreeStore';

const BasesAndFreeMorphemes = (props) => {

  //რედაქსიდან
  const { rootsRepo, memoBasesDict, basesForDropdownloading } = props;
  //კონტროლის თვისებებიდან
  const {ranges, morphemes, morphemesQuery, forInflection, phoneticsTypes, phoneticsChangesQuery, morphemesdPath, getError, 
    selectPhoneticsType, autoPhonetics, predecessors, onMorphemeChange, onPredecessorChange, onPredecessorPhoneticsTypeChange} = props;

  function getListItemById(memoBaseDict, dbrId) {
    let base = null;
    for (var bases of Object.values(memoBaseDict)) {
      base = bases.find((item) => item.dbrId === dbrId);
      if (base) {
        return base;
      }
    }
    return null;
  }

  function countText(baseName, roots) {
    let text = baseName;
    if (roots && roots.length > 0)
      text += " (" +
        roots.map((root) => {
          return `${root.rootName}${(root.rootHomonymIndex > 0) ? `^${root.rootHomonymIndex}` : ""}${
            (root.rootNote) ? ` ${root.rootNote}` : ""}`;
        }).sort().join("; ") +
        ")";
    return text;
  }


  return (
    <div>
      <OneStrongLabel controlId="mainParametersLabel" label="ფუძეები და არჩევითი მორფემები" />

      {ranges.map((range, index) => {
        const selectable = forInflection ? range.mrInflectionSelectable : range.mrSelectable;
        if ( selectable ) {
          return (<OneComboBoxControl key={index} controlId={`${morphemesdPath}[${index}]`}
          label={`${range.mrPosition+1}. ${range.mrName}`} value={morphemes[index]} 
          dataMember={morphemesQuery.filter(mph=>mph.morphemeRangeId === range.mrId && mph.mrpNom >= range.minNom && mph.mrpNom <= range.maxNom)} 
          valueMember="mrpId" displayMember="mrpName" sortByDisplayMember={false}
          getError={getError} onChangeValue={(fieldPath, value) => {
            onMorphemeChange(index, value);
          }} />);
        }

        //აქ თუ მოვიდა კოდი, არჩევითი მორფემის არჩევის მაგივრად საჭიროა ფუძის არჩევა

        //console.log("BaseFreeMorphsChoicer render predecessors=", predecessors);

        const curDerivPre = predecessors
          ? (predecessors.find((depre) => depre.baseNom === range.mrBaseNom))
          : null;

        //console.log("BaseFreeMorphsChoicer render curDerivPre=", curDerivPre);

        const parentBranchId = curDerivPre ? curDerivPre.parentBranchId : null;
        const phoneticsChangeId = curDerivPre ? curDerivPre.phoneticsChangeId : null;
        const phoneticsTypeId = curDerivPre ? curDerivPre.phoneticsTypeId : null;
        //console.log("BaseFreeMorphsChoicer render parentBranchId=", parentBranchId);

        return (
          <div key={index}>

            <Form.Group className="mb-1" as={Row} controlId={`${morphemesdPath}[${index}]`}>
              <Form.Label column sm="4" className="text-right">{`${range.mrPosition+1}. ${range.mrName}`}</Form.Label>
              <Col sm="6">

              <TextBoxAutoComplete listElementComponent={BaseLink} placeholder={range.mrName} formControlClassName="mr-sm-2"
                  value={parentBranchId}
                  memodict={memoBasesDict}
                  onTextChange={(searchValue) => {
                    //range.text = searchValue;
                    onPredecessorChange(range.mrBaseNom, searchValue); /*setBaseSearchValue(searchValue)*/
                  }}
                  loadDropDown={(searchValue) => props.GetBasesForDropDown(searchValue)}
                  // loading={basesForDropdownloading}
                  onSelectedElement={(item) => {
                    //console.log("BaseFreeMorphsChoicer onSelectedElement {range, item}=", { range, item });
                    onPredecessorChange(range.mrBaseNom, item);
                  }}
                  getTextByValue={(val) => {
                    //console.log("BaseFreeMorphsChoicer getTextByValue val=", val);
                    let text = "";
                    const listItemBase = getListItemById(memoBasesDict, val);
                    if (listItemBase) {
                      const { roots } = listItemBase;
                      text = countText(listItemBase.dbrBaseName, roots);
                    } else {
                      const preDerivBranch = getBranchByIdFromStore(rootsRepo, val);
                      const predRootIds = funPredRoots(preDerivBranch, rootsRepo);
                      const roots = predRootIds.map((rootId) => rootsRepo[rootId].root);
                      text = countText(preDerivBranch ? preDerivBranch.dbrBaseName : "", roots);
                    }
                    //console.log("BaseFreeMorphsChoicer getTextByValue results text=", text);
                    return text;
                  }}
                  />
              </Col>
              <Col sd="2">
                  {basesForDropdownloading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Col>              
            </Form.Group>

            { parentBranchId && parentBranchId > 0 && selectPhoneticsType &&
              <OneComboBoxControl controlId={`phoneticsTypeId${range.mrBaseNom}`} 
                label={"ფონეტიკური ტიპი"} 
                value={phoneticsTypeId} 
                dataMember={phoneticsTypes} 
                valueMember="phtId" displayMember="phtName" sortByDisplayMember={true}
                firstItem={{ id: 0, name: "უცვლელი" }}
                firstItemIsSelectable
                getError={getError} onChangeValue={(fieldPath, value) => {
                  onPredecessorPhoneticsTypeChange(range.mrBaseNom, value);
                }} />
            }

            { parentBranchId && parentBranchId > 0 && !selectPhoneticsType &&
              <OneComboBoxControl controlId={`phoneticsChangeId${range.mrBaseNom}`} 
                label={`ფონეტიკური ${autoPhonetics?"ტიპი":"ცვლილება"}`} 
                value={phoneticsChangeId} 
                dataMember={phoneticsChangesQuery.filter(fc => autoPhonetics === fc.onlyPhoneticsType)} 
                valueMember="phcId" displayMember="phcName" sortByDisplayMember={true}
                firstItem={{ id: 0, name: "უცვლელი" }}
                firstItemIsSelectable
                getError={getError} onChangeValue={(fieldPath, value) => {
                  onPredecessorPhoneticsTypeChange(range.mrBaseNom, value);
                }} />
            }

          </div>
        );

      })}

    </div>
  );
}

function mapStateToProps(state) {
  const { rootsRepo, memoBasesDict, basesForDropdownloading } = state.derivTree;
  return { rootsRepo, memoBasesDict, basesForDropdownloading };
}

function mapDispatchToProps(dispatch) {
  return {
    GetBasesForDropDown: (val) => dispatch(DerivTreeActions.GetBasesForDropDown(val))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasesAndFreeMorphemes);
