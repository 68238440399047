//MdLookupColumn.js

//import React from 'react';

const MdLookupColumn = (props) => {

  const { dataTable, valueMember, displayMember, value } = props;

  //console.log("MdLookupColumn props=", props);
  //console.log("MdLookupColumn value=", value);

  if ( !!valueMember && !!displayMember && !!dataTable) {
    const fval = dataTable.find((mdItm) => mdItm[valueMember] === value);
    if (!!fval && !!fval[displayMember])
      return fval[displayMember];
    }
  
  // if ( !!rowSource ) {
  //   var rsarr = rowSource.split(";");
  //   var ind = rsarr.indexOf(value.toString()) + 1;
  //   if ( ind > 0 && ind < rsarr.length && !! rsarr[ind] )
  //     return rsarr[ind];
  // }
  return value;
}

export default MdLookupColumn;
