import React from 'react';

import TopNavRoutes from './TopNavRoutes';
import AuthAppRoutes from './AuthAppRoutes';

const ProjectContext = React.createContext();

const ProjectProvider = (props) => {
  const appName = "ენის მოდელის რედაქტორი";
  const baseUrl = process.env.NODE_ENV === 'development'
    //? window.location.origin + '/api'
    //? 'http://192.168.10.30:5011/api'
    ? 'http://dev.grammar.ge/api'
    : 'https://www.grammar.ge/api';

  //console.log("ProjectProvider baseUrl=", baseUrl);

  return (
    <ProjectContext.Provider value={{ TopNavRoutes, AuthAppRoutes, appName, baseUrl }}>
      {props.children}
    </ProjectContext.Provider>
  );
}

export { ProjectContext, ProjectProvider };