//UserStore.js

//import { history } from '../common/History';
//import { useHistory } from "react-router-dom";

import apiClient from '../modules/ApiClient';
import { NzInt } from '../common/myFunctions'

//constants
const REQUEST_LOGIN = 'REQUEST_LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';

const REQUEST_REGISTRATION = 'REQUEST_REGISTRATION';
const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

const REQUEST_DELETE_REGISTRATION = 'REQUEST_DELETE_REGISTRATION';
const DELETE_REGISTRATION_SUCCESS = 'DELETE_REGISTRATION_SUCCESS';
const DELETE_REGISTRATION_FAILURE = 'DELETE_REGISTRATION_FAILURE';

const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

const REQUEST_CHANGE_PROFILE = 'REQUEST_CHANGE_PROFILE';
const CHANGE_PROFILE_SUCCESS = 'CHANGE_PROFILE_SUCCESS';
const CHANGE_PROFILE_FAILURE = 'CHANGE_PROFILE_FAILURE';

const USER_LOGOUT = 'USER_LOGOUT';
const REQUEST_CHECK_CURRENT_USER_IS_VALID = 'REQUEST_CHECK_CURRENT_USER_IS_VALID';
const SET_CURRENT_USER_IS_VALID = 'SET_CURRENT_USER_IS_VALID';



const localStorageuser = JSON.parse(localStorage.getItem('user'));
const lastTwId = NzInt(localStorage.getItem('lastTwId')) + 1;
localStorage.setItem('lastTwId', lastTwId.toString())

const initialState = {
  userValidationChecked: false,
  loggingIn: false,
  registering: false,
  deletingRegistation: false,
  changingPassword: false,
  passwordChanged: false,
  changingProfile: false,
  user: (localStorageuser ? localStorageuser : null),
  tabWindowId: lastTwId 
};




export const actionCreators = {

  logout: () => {
    localStorage.removeItem('user');
    return { type: USER_LOGOUT };
  },

  isCurrentUserValid: () => async (dispatch, getState) => {
    const accessToken = getState().authentication.user.token;
    if (accessToken === null) {
      actionCreators.logout();
      //history.push('/login');
      return;
    }

    //console.log('UserActions.js apiClient.Get /userrights/iscurrentuservalid');
    await apiClient.Get('/userrights/iscurrentuservalid',
      { get: REQUEST_CHECK_CURRENT_USER_IS_VALID, 
        set: SET_CURRENT_USER_IS_VALID, 
        failure: LOGIN_FAILURE },
      dispatch, getState, true, null, false );
  },

  login: (user) => async (dispatch, getState) => {   


    //console.log('UserActions.js apiClient.Post /authentication/login');
    await apiClient.Post('/authentication/login',
      { get: REQUEST_LOGIN, 
        set: LOGIN_SUCCESS, 
        failure: LOGIN_FAILURE },
      dispatch, getState, false, null, true, user, (user) => user.token );

    const usr = getState().authentication.user;
    if ( usr && usr.token) {
      localStorage.setItem('user', JSON.stringify(usr));
    }
  },

  registration: (history, regData) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('UserActions.js apiClient.Post /authentication/registration');
    await apiClient.Post('/authentication/registration',
      { get: REQUEST_REGISTRATION, 
        set: REGISTRATION_SUCCESS, 
        failure: REGISTRATION_FAILURE },
      dispatch, getState, false, null, true, regData, (user) => user.token );

    const usr = getState().authentication.user;
    if ( usr && usr.token) {
      localStorage.setItem('user', JSON.stringify(usr));
      history.push('/');
    }
  },

  deleteCurentUser: (history) => async (dispatch, getState) => {
    //const history = useHistory();
    if (await apiClient.Delete(`/userrights/deletecurrentuser/${getState().authentication.user.userName}`,
      {
        get: REQUEST_DELETE_REGISTRATION,
        set: DELETE_REGISTRATION_SUCCESS,
        failure: DELETE_REGISTRATION_FAILURE
      }, dispatch, getState)) {
      //actionCreators.logout();
      history.push('/login');
      //window.location.reload(true);
    }
  },

  changePassword: (chpData) => async (dispatch, getState) => {

    await apiClient.Put("/userrights/changepassword",
      {
        get: REQUEST_CHANGE_PASSWORD,
        set: CHANGE_PASSWORD_SUCCESS,
        failure: CHANGE_PASSWORD_FAILURE
      }, dispatch, getState, true, null, chpData);


  },

  changeProfile: (chpData) => async (dispatch, getState) => {

    if (await apiClient.Put("/userrights/changeprofile",
      {
        get: REQUEST_CHANGE_PROFILE,
        set: CHANGE_PROFILE_SUCCESS,
        failure: CHANGE_PROFILE_FAILURE
      }, dispatch, getState, true, chpData, chpData)) {
      const usr = getState().authentication.user;
      if (usr && usr.token) {
        localStorage.setItem('user', JSON.stringify(usr));
      }

    }

  }


};


export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === REQUEST_CHECK_CURRENT_USER_IS_VALID) {
    return { 
      ...state,
      userValidationChecked: false
    };
  }

  if (action.type === SET_CURRENT_USER_IS_VALID) {
    return { 
      ...state,
      userValidationChecked: true
    };
  }

  if (action.type === USER_LOGOUT) {
    return { 
      ...state,
      userValidationChecked: true, 
      loggingIn: false, 
      user: null
    };
  }

  if (action.type === REQUEST_LOGIN) {
    return { 
      ...state,
      loggingIn: true, 
      user: null
    };
  }

  if (action.type === LOGIN_SUCCESS) {
    return { 
      ...state,
      userValidationChecked: true, 
      loggingIn: false, 
      user: action.payload.data
    };
  }

  if (action.type === LOGIN_FAILURE) {
    return { 
      ...state,
      userValidationChecked: true, 
      loggingIn: false, 
      user: null
    };
  }

  if (action.type === REQUEST_REGISTRATION) {
    return { 
      ...state,
      registering: true, 
      user: null
    };
  }

  if (action.type === REGISTRATION_SUCCESS) {
    return { 
      ...state,
      userValidationChecked: true, 
      registering: false, 
      user: action.payload.data
    };
  }

  if (action.type === REGISTRATION_FAILURE) {
    return { 
      ...state,
      userValidationChecked: true, 
      registering: false, 
      user: null
    };
  }

  if (action.type === REQUEST_DELETE_REGISTRATION) {
    return { 
      ...state,
      deletingRegistation: true
    };
  }

  if (action.type === DELETE_REGISTRATION_SUCCESS) {
    return { 
      ...state,
      //userValidationChecked: true,
      deletingRegistation: false
      //user: null
    };
  }

  if (action.type === DELETE_REGISTRATION_FAILURE) {
    return { 
      ...state,
      deletingRegistation: false
    };
  }

  if (action.type === REQUEST_CHANGE_PASSWORD) {
    return { 
      ...state,
      changingPassword: true,
      passwordChanged: false
    };
  }

  if (action.type === CHANGE_PASSWORD_SUCCESS) {
    return { 
      ...state,
      changingPassword: false,
      passwordChanged: true
    };
  }

  if (action.type === CHANGE_PASSWORD_FAILURE) {
    return { 
      ...state,
      changingPassword: false,
      passwordChanged: false
    };
  }

  if (action.type === REQUEST_CHANGE_PROFILE) {
    return { 
      ...state,
      changingProfile: true
    };
  }

  if (action.type === CHANGE_PROFILE_SUCCESS) {
    const oldUser = state.user;
    const paramUser = action.payload.params;
    const newUser = { ...paramUser, userId: oldUser.userId, userName: oldUser.userName, token: oldUser.token, roleName: oldUser.roleName };
    return { 
      ...state,
      changingProfile: false,
      user: newUser
    };
  }

  if (action.type === CHANGE_PROFILE_FAILURE) {
    return { 
      ...state,
      changingProfile: false
    };
  }





  return state;
};

