//MasterDataStore.js
//import { history } from '../common/History';
//import { useHistory } from "react-router-dom";
import apiClient from '../modules/ApiClient';
// import { lowerNormalizeOneTableName } from '../common/myFunctions';

//const MD_ADD_REQUIRED_TABLES = 'MD_ADD_REQUIRED_TABLES';

const mdSetItemEditorTables = 'MD_SET_ITEM_EDITOR_TABLES'

const mdStartLoadingTables = 'MD_START_LOADING_TABLES';
const mdFinishLoadingTables = 'MD_FINISH_LOADING_TABLES';

const mdStartClearingTables = 'MD_START_CLEARING_TABLES';
const mdFinishClearingTables = 'MD_FINISH_CLEARING_TABLES';

const mdStartLoadingListData = 'MD_START_LOADING_LIST_DATA';
const mdFinishLoadingListData = 'MD_FINISH_LOADING_LIST_DATA';

const mdStartSaving = 'MD_START_SAVING';
const mdFinishSaving = 'MD_FINISH_SAVING';

//const mdRequestLoadTable = 'MD_REQUEST_LOAD_TABLE';
const mdRequestData = 'MD_REQUEST_DATA';
//const mdLoadTableSuccess = 'MD_LOAD_TABLE_SUCCESS';
const mdLoadTablesSuccess = 'MD_LOAD_TABLES_SUCCESS';
const mdLoadTablesFailure = 'MD_LOAD_TABLES_FAILURE';

const mdLoadGridRuleSuccess = 'MD_LOAD_GRID_RULE_SUCCESS';
const mdLoadGridRuleFailure = 'MD_LOAD_GRID_RULE_FAILURE';

const mdLoadMultipleGridRuleSuccess = 'MD_LOAD_MULTIPLE_GRID_RULE_SUCCESS';
const mdLoadMultipleGridRuleFailure = 'MD_LOAD_MULTIPLE_GRID_RULE_FAILURE';

const mdRequestDataTypes = 'MD_REQUEST_DATATYPES';
const mdLoadDataTypesSuccess = 'MD_LOAD_DATATYPES_SUCCESS';
const mdLoadDataTypesFailure = 'MD_LOAD_DATATYPES_FAILURE';

const mdCreateRecordSuccess = 'MD_CREATE_RECORD_SUCCESS';
const mdUpdateRecordSuccess = 'MD_UPDATE_RECORD_SUCCESS';
const mdRequestRecordDelete = 'MD_REQUEST_RECORD_DELETE';
const mdDeleteRecordSuccess = 'MD_DELETE_RECORD_SUCCESS';
const mdDeleteRecordFailure = 'MD_DELETE_RECORD_FAILURE';

const mdClearTablesFromRepo = 'MD_CLEAR_TABLES_FROM_REPO';

const mdClearDeleteFailure = 'MD_CLEAR_DELETE_FAILURE';
const mdSaveReturnPageName = 'MD_SAVE_RETURN_PAGE_NAME';


const initialState = {
  returnPageName: null,
  mdWorkingOnLoad: false,
  mdWorkingOnLoadingTables: false,
  mdWorkingOnClearingTables: false,
  mdWorkingOnLoadingListData: false,
  mdWorkingOnSave: false,
  mdRepo: {},
  gridRules: {},
  datatypes: null,
  datatypesLoading: false,
  deletingKey: null,//წაშლისას გამოიყენება deletingKey იმისათვის, რომ ცნობილი იყოს კონკრეტულად რომელი ჩანაწერი იშლება
  DeleteFailure: false,
  justCreatedId: null,
  itemEditorTables: null
};

function isTableNameInRepo(tableName, getState) {

  return (tableName in getState().masterData.mdRepo);
}

function isGridNameIngridRules(gridName, getState) {

  return (gridName in getState().masterData.gridRules);
}

// function lowerNormalizeTableNames(tableNames) {
//   return tableNames.map( tableName => lowerNormalizeOneTableName(tableName) );
// }

// function upperNormalizeOneTableName(tableName) {
//   return tableName.charAt(0).toUpperCase() + tableName.slice(1);
// }

async function funCheckLoadMdTables(dispatch, getState, tableNames) {

  //debugger;
  
  const realyNeedTables = tableNames.filter((tableName) => !isTableNameInRepo(tableName, getState));

  //console.log("funCheckLoadMdTables tableNames=", tableNames);
  //console.log("funCheckLoadMdTables getState().masterData.mdRepo=", getState().masterData.mdRepo);
  //console.log("funCheckLoadMdTables realyNeedTables=", realyNeedTables);

  //await realyNeedTables.map(async(tableName) => await funLoadTable(dispatch, getState, tableName));

  if ( !realyNeedTables || realyNeedTables.length < 1 )
    return;

  const uripart = "/masterdata/gettables?" + realyNeedTables.map(tablename=> `tables=${tablename}`).join('&');

  //console.log('MasterData.js apiClient.Get uripart = ', uripart);
  await apiClient.Get(uripart,
    {
      get: mdRequestData,
      set: mdLoadTablesSuccess,
      failure: mdLoadTablesFailure
    },
    dispatch, getState, true,
    realyNeedTables);
}

// async function funLoadTable(dispatch, getState, tableName) {

//   const masterData = getState().masterData;
//   if (tableName in masterData.mdRepo) {
//     return;
//   }

//   if ( !startWorkingOnLoad(dispatch, getState) )
//     return;

//   //console.log('MasterData.js apiClient.Get', `/masterdata/${tableName}`);
//   await apiClient.Get(`/masterdata/${tableName}`,
//     {
//       get: mdRequestLoadTable,
//       set: mdLoadTableSuccess,
//       failure: mdLoadTableFailure
//     },
//     dispatch, getState, true,
//     tableName);
// }

// function setLoadingFlag(dispatch, getState) {
//   const mdWorkingOnLoad = getState().masterData.mdWorkingOnLoad;
//   if (!mdWorkingOnLoad) {
//     dispatch({ type: mdStartLoading });
//     return true;
//   }
//   return false
// }

function startWorkingOnLoad(dispatch, getState, loadingType, LoadFlagName) {
  const LoadFlag = getState().masterData[LoadFlagName];
  if (!LoadFlag) {
    dispatch({ type: loadingType });
    return true;
  }
  return false
}

// function finishWorkingOnLoad(dispatch, getState) {
//   const mdWorkingOnLoad = getState().masterData.mdWorkingOnLoad;
//   if (mdWorkingOnLoad) {
//     dispatch({ type: mdFinishLoading });
//   }
// }

async function loadDatatypes(dispatch, getState) {

  //if datatypes is already loaded, do not reload
  //must care refresh!!!
  //console.log('MasterData.js loadDatatypes getState().masterData.datatypes = ', getState().masterData.datatypes);
  if (getState().masterData.datatypes !== null)
    return true;

  //console.log('MasterData.js loadDatatypes apiClient.Get /datatypes/getdatatypes');
  return await apiClient.Get('/datatypes/getdatatypes',
    { get: mdRequestDataTypes, set: mdLoadDataTypesSuccess, failure: mdLoadDataTypesFailure },
    dispatch, getState);
}

async function loadGridRules(dispatch, getState, gridName) {

  if (gridName === null || gridName in getState().masterData.gridRules) {
    return true;
  }

  //startWorkingOnLoad(dispatch, getState);
  //console.log('MasterData.js apiClient.Get', `/datatypes/getgridmodel/${gridName}`);
  return await apiClient.Get(`/datatypes/getgridmodel/${gridName}`,
    { get: mdRequestData, set: mdLoadGridRuleSuccess, failure: mdLoadGridRuleFailure },
    dispatch, getState, true, gridName);
}



async function loadGridMultipleRules(dispatch, getState, gridNames) {

  const realyNeedGrids = gridNames.filter((gridName) => !isGridNameIngridRules(gridName, getState));
  if ( realyNeedGrids.length === 0 )
      return;

  const uripart = `/datatypes/getmultiplegridrules/?` + realyNeedGrids.map(gridName=> `grids=${gridName}`).join('&');

  //startWorkingOnLoad(dispatch, getState);
  //console.log('MasterData.js apiClient.Get', uripart);
  await apiClient.Get(uripart,
    { get: mdRequestData, set: mdLoadMultipleGridRuleSuccess, failure: mdLoadMultipleGridRuleFailure },
    dispatch, getState, true, realyNeedGrids );

}


function toCamelCase(key, value) {
  if (value && typeof value === 'object'){
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
}

export const actionCreators = {

  saveReturnPageName: (pageName) => {
    return { type: mdSaveReturnPageName, payload: pageName };
  },

  clearDeletingFailure: () => {
    return { type: mdClearDeleteFailure };
  },

  clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => async (dispatch, getState) => {

    if ( !startWorkingOnLoad(dispatch, getState, mdStartClearingTables, "mdWorkingOnClearingTables") )
      return;

    dispatch({ type: mdClearTablesFromRepo, payload: tableNamesForClear });

    await funCheckLoadMdTables(dispatch, getState, tableNamesForLoad);

    //finishWorkingOnLoad(dispatch, getState);
    dispatch({ type: mdFinishClearingTables });

  },



  checkLoadDataTypes: () => async (dispatch, getState) => {

    await loadDatatypes(dispatch, getState);

  },


  checkLoadMdTables: (tableNames) => async (dispatch, getState) => {


    if ( !startWorkingOnLoad(dispatch, getState, mdStartLoadingTables, "mdWorkingOnLoadingTables") )
      return;

    if ( ! await loadDatatypes(dispatch, getState) ) {
      return;
    }

    await funCheckLoadMdTables(dispatch, getState, tableNames);

    //finishWorkingOnLoad(dispatch, getState);
    dispatch({ type: mdFinishLoadingTables });


  },

  loadListData: (gridName, tableName) => async (dispatch, getState) => {

    if ( !startWorkingOnLoad(dispatch, getState, mdStartLoadingListData, "mdWorkingOnLoadingListData") )
      return;

    if ( ! await loadDatatypes(dispatch, getState) ) {
      dispatch({ type: mdFinishLoadingListData });
      return;
    }

    //console.log('MasterData.js loadListData getState().masterData.datatypes = ', getState().masterData.datatypes);


    //load grid rules for table
    if ( ! await loadGridRules(dispatch, getState, gridName) ) {
      dispatch({ type: mdFinishLoadingListData });
      return;
    }

    //load table records
    //ეს ჩატვირთვა საჭირო აღარ არის, რადგან ყველა ცხრილი ერთად ჩაიტვირთება, როგორც კი სია დადგინდება
    // await funLoadTable(dispatch, getState, tableName);

    const requiredMdNames = [tableName];

    const { gridRules } = getState().masterData;

    if (gridName in gridRules && gridRules[gridName]) {
      const dmTableNames = gridRules[gridName].cells
        .filter((cell) => cell.dataMember).map((cell) => cell.dataMember);
      requiredMdNames.push(...dmTableNames);
    }

    dispatch({ type: mdSetItemEditorTables, payload: requiredMdNames });
    await funCheckLoadMdTables(dispatch, getState, requiredMdNames);

    // finishWorkingOnLoad(dispatch, getState);
    dispatch({ type: mdFinishLoadingListData });


  },

  loadMultipleListData: (gridNames, listTableNames, additionalTableNames) => async (dispatch, getState) => {

    if ( !startWorkingOnLoad(dispatch, getState, mdStartLoadingListData, "mdWorkingOnLoadingListData") )
      return;

    await loadDatatypes(dispatch, getState);

    //load grid rules for table
    await loadGridMultipleRules(dispatch, getState, gridNames);

    //load table records
    //ეს ჩატვირთვა საჭირო აღარ არის, რადგან ყბელა ცხრილი ერთად ჩაიტვირთება, როგორც კი სია დადგინდება
    // await funLoadTable(dispatch, getState, tableName);

    const requiredMdNames = [];
    if ( listTableNames )
      requiredMdNames.push(...listTableNames);
    if ( additionalTableNames )      
      requiredMdNames.push(...additionalTableNames);

    const { gridRules } = getState().masterData;

    gridNames.forEach(gridName => {
      if ( gridName in gridRules && gridRules[gridName] && gridRules[gridName].cells ) {
        const dmTableNames = gridRules[gridName].cells.filter((col) => col.dataMember).map((col) => col.dataMember);
        requiredMdNames.push(...dmTableNames);
      }
    });

    //console.log("loadMultipleListData requiredMdNames=", requiredMdNames);

    const distinctMdNames = [...new Set(requiredMdNames)];

    //console.log("loadMultipleListData distinctMdNames=", distinctMdNames);

    dispatch({ type: mdSetItemEditorTables, payload: distinctMdNames });
    await funCheckLoadMdTables(dispatch, getState, distinctMdNames);

    // finishWorkingOnLoad(dispatch, getState);
    dispatch({ type: mdFinishLoadingListData });


  },

  deleteMdItem: (history, tableName, idFielName, idValue) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js apiClient.Delete', `/masterdata/${tableName}/${idValue}`);
    if (await apiClient.Delete(`/masterdata/${tableName}/${idValue}`,
      { get: mdRequestRecordDelete, set: mdDeleteRecordSuccess, failure: mdDeleteRecordFailure },
      dispatch, getState, true,
      { tableName, idFielName, idValue })) {
        const returnPageName = getState().masterData.returnPageName;
        const realReturnPageName = returnPageName ? returnPageName : "mdList";
      history.push(`/${realReturnPageName}/${tableName}`);
    }
  },

  addMdItem: (history, tableName, idFielName, mdItem) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js apiClient.Post', `/masterdata/${tableName}`);
    if (await apiClient.Post(`/masterdata/${tableName}`,
      { get: mdStartSaving, set: mdCreateRecordSuccess, failure: mdFinishSaving },
      dispatch, getState, true, { tableName, idFielName }, true, mdItem)) {
        const idValue = getState().masterData.justCreatedId;
        const returnPageName = getState().masterData.returnPageName;
        const realReturnPageName = returnPageName ? returnPageName : "mdList";
        history.push(`/${realReturnPageName}/${tableName}/${idValue}`);
    }
  },

  updateMdItem: (history, tableName, idFielName, mdItem) => async (dispatch, getState) => {
    // const history = useHistory();
    const idValue = mdItem[idFielName];
    //console.log('MasterData.js apiClient.Put', `/masterdata/${tableName}/${idValue}`);
    if (await apiClient.Put(`/masterdata/${tableName}/${idValue}`,
      { get: mdStartSaving, set: mdUpdateRecordSuccess, failure: mdFinishSaving },
      dispatch, getState, true, { tableName, idFielName, mdItem }, mdItem)) {
        const returnPageName = getState().masterData.returnPageName;
        const realReturnPageName = returnPageName ? returnPageName : "mdList";
        history.push(`/${realReturnPageName}/${tableName}/${idValue}`);
    }
  }

}

export const reducer = (state, action) => {
  state = state || initialState;

  

  if (action.type === mdSaveReturnPageName) {
    return { ...state, returnPageName: action.payload };
  }

  if (action.type === mdClearDeleteFailure) {
    return { ...state, DeleteFailure: false };
  }

  // if (action.type === mdStartLoading) {
  //   return { ...state, mdWorkingOnLoad: true };
  // }

  if (action.type === mdStartLoadingTables) {
    return { ...state, mdWorkingOnLoadingTables: true };
  }

  if (action.type === mdStartClearingTables) {
    return { ...state, mdWorkingOnClearingTables: true };
  }

  if (action.type === mdStartLoadingListData) {
    return { ...state, mdWorkingOnLoadingListData: true };
  }

  if (action.type === mdFinishLoadingTables) {
    return { ...state, mdWorkingOnLoad: false, mdWorkingOnLoadingTables: false };
  }

  if (action.type === mdFinishClearingTables) {
    return { ...state, mdWorkingOnLoad: false, mdWorkingOnClearingTables: false };
  }

  if (action.type === mdFinishLoadingListData) {
    return { ...state, mdWorkingOnLoad: false, mdWorkingOnLoadingListData: false };
  }

  if (action.type === mdStartSaving) {
    return { ...state, mdWorkingOnSave: true, justCreatedId: null };
  }

  if (action.type === mdFinishSaving) {
    return { ...state, mdWorkingOnSave: false };
  }

  if (action.type === mdRequestData) {
    return { ...state, mdWorkingOnLoad: true };
  }

  if (action.type === mdLoadTablesSuccess) {
    const newmdRepo = state.mdRepo;
    const tablesData = action.payload.data;
    Object.keys(tablesData).forEach(tablename => {
      newmdRepo[tablename] = tablesData[tablename];  
    })
    return { ...state, mdRepo: newmdRepo };
  }

  if (action.type === mdLoadTablesFailure) {
    const newmdRepo = state.mdRepo;
    const { params } = action.payload;
    //console.log("MasterDataStore mdLoadTablesFailure action.payload=", action.payload);
    //console.log("MasterDataStore mdLoadTablesFailure params=", params);
    if ( params && Array.isArray(params) )
    {
      //console.log("MasterDataStore mdLoadTablesFailure params is array");
      params.forEach(tn => {
        newmdRepo[tn] = null;
      });
    }
    //console.log("MasterDataStore mdLoadTablesFailure newmdRepo=", newmdRepo);
    return { ...state, mdRepo: newmdRepo };
  }

  if (action.type === mdClearTablesFromRepo) {
    const newmdRepo = state.mdRepo;
    const tableNames = action.payload;
    if ( tableNames && Array.isArray(tableNames) )
    tableNames.forEach(tn => {
      if ( tn in newmdRepo )
        delete newmdRepo[tn];
    });
    return { ...state, mdRepo: newmdRepo };
  }

  if (action.type === mdLoadGridRuleSuccess) {
    const newgridRules = state.gridRules;
    const data = action.payload.data;
    const gridName = action.payload.params;

    newgridRules[gridName] = JSON.parse(data, toCamelCase);
    return { ...state, gridRules: newgridRules };
  }

  if (action.type === mdLoadGridRuleFailure) {
    const newgridRules = state.gridRules;
    const gridName = action.payload.params;
    newgridRules[gridName] = null;
    return { ...state, gridRules: newgridRules };
  }



  
  if (action.type === mdLoadMultipleGridRuleSuccess) {
    const newgridRules = state.gridRules;
    const data = action.payload.data;
    const realyNeedGrids = action.payload.params;

    realyNeedGrids.forEach(gridName => {
      if ( gridName in data && data[gridName] ) {
        newgridRules[gridName] = JSON.parse(data[gridName], toCamelCase);
      }
    });

    return { ...state, gridRules: newgridRules };
  }

  if (action.type === mdLoadMultipleGridRuleFailure) {
    const newgridRules = state.gridRules;
    const realyNeedGrids = action.payload.params;
    realyNeedGrids.forEach(gridName => {
      newgridRules[gridName] = null;
    });
    return { ...state, gridRules: newgridRules };
  }





  if (action.type === mdRequestDataTypes) {
    return { ...state, datatypesLoading: true };
  }

  if (action.type === mdLoadDataTypesSuccess) {
    return { ...state, datatypes: action.payload.data, datatypesLoading: false };
  }

  if (action.type === mdLoadDataTypesFailure) {
    return { ...state, datatypes: null, datatypesLoading: false };
  }

  if (action.type === mdCreateRecordSuccess) {

    const { tableName, idFielName } = action.payload.params;
    const newmdRepo = state.mdRepo;
    const newItem = action.payload.data;
    const newId = newItem[idFielName];
    newmdRepo[tableName] = [...(newmdRepo[tableName]), newItem];
    return { ...state, mdWorkingOnSave: false, mdRepo: newmdRepo, justCreatedId: newId };
  }

  if (action.type === mdUpdateRecordSuccess) {
    const { tableName, idFielName, mdItem } = action.payload.params;
    const newmdRepo = state.mdRepo;
    newmdRepo[tableName] = newmdRepo[tableName].map(mdItm => mdItm[idFielName] === mdItem[idFielName] ? mdItem : mdItm);
    return { ...state, mdWorkingOnSave: false, mdRepo: newmdRepo };
  }

  if (action.type === mdRequestRecordDelete) {
    const { params } = action.payload;
    return { ...state, deletingKey: params.tableName + params.idValue.toString(), DeleteFailure: false };
  }

  if (action.type === mdDeleteRecordSuccess) {
    const { tableName, idFielName, idValue } = action.payload.params;
    const newmdRepo = state.mdRepo;
    newmdRepo[tableName] = newmdRepo[tableName].filter(mditem => mditem[idFielName] !== idValue);
    //console.log("MasterDataStore.js action.type === mdDeleteRecordSuccess action.payload.params=", action.payload.params);
    //console.log("MasterDataStore.js action.type === mdDeleteRecordSuccess newmdRepo=", newmdRepo);
    return { ...state, deletingKey: null, mdRepo: newmdRepo, DeleteFailure: false };
  }

  if (action.type === mdDeleteRecordFailure) {
    return { ...state, deletingKey: null, DeleteFailure: true };
  }

  if (action.type === mdSetItemEditorTables) {
    const tableNames = action.payload;
    return { ...state, itemEditorTables: tableNames };
  }

  

  return state;

};
