import React, {useRef, useEffect, useState} from 'react';

const CybCheckBox = (props) => {
  
  const {checked, labelSelected, labelText} = props;

  const [usedSpace, setUsedSpace] = useState(false);
  const cbRef = useRef(null);

  useEffect(() => {
    if ( labelSelected )
      cbRef.current.focus();
  }, [labelSelected]);

  return (
    <div className="form-check">
      <input ref={cbRef} type="checkbox" checked={checked} onChange={(e) => {
        //console.log("CybCheckBox input onChange e.target.checked=", e.target.checked);
        //e.preventDefault();
        props.onChange(e, usedSpace);
        setUsedSpace(false);
      }} onKeyPress={(e) => {
        //e.preventDefault();
        //console.log("CybCheckBox input onKeyPress e.charCode=", e.charCode);
        setUsedSpace(true);
        //if ( e.charCode === 32 )
        //  props.onChangeByKeyboard();
      }} />
      <label title="" type="checkbox" className={"form-check-label" + (labelSelected ? " backLigth" : "")} onClick={() => {
        //console.log("CybCheckBox label onClick");
        props.onLabelClick();
        cbRef.current.focus();
      }}>{labelText}</label>
    </div>
  );
};

export default CybCheckBox;

