//VerbRowParadigmFormulas.js
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../carcass/common/Loading';
import { getFormulaVisual2 } from './FormulasModule';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { GetDisplayValue2 } from '../../carcass/modules/GetDisplayValue'
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt, filterByHeader } from '../../carcass/common/myFunctions';
import BsComboBox from '../../carcass/masterdata/BsComboBox';
import { useForman } from '../../carcass/common/MyHooks';
import * as yup from 'yup';


const VerbRowParadigmFormulas = (props) => {
  // const { mdWorkingOnLoad, morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, 
  //   morphemeRangesByInflectionBlocks, verbTypes, verbRows, verbParadigms, verbPersonMarkerParadigms, checkLoadMdTables,
  //   verbRowParadigmFormulas, verbRowParadigmFormulasLoading, checkloadVerbParadigmFormulas } = props;
  const { alert, masterData, verbRowParadigmFormulas, verbRowParadigmFormulasLoading, 
    checkLoadMdTables, checkloadVerbParadigmFormulas } = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, morphemeRangesByInflectionBlocks, 
    verbTypes, verbRows, verbParadigms, verbPersonMarkerParadigms } = masterData.mdRepo;


  const [curParadigmId, setCurParadigmId] = useState(null);


  //console.log("VerbRowParadigmFormulas props=", props);

  const paradigmId = NzInt(props.match.params.paradigmId);

  const tableNamesForLoad = useMemo(() => ["morphemeRanges", "morphemesQuery", "inflectionBlocks", "inflectionTypes", 
  "morphemeRangesByInflectionBlocks", "verbTypes", "verbRows", "verbParadigms", "verbPersonMarkerParadigms"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [checkLoadMdTables, tableNamesForLoad]);


  useEffect(() => {
    if (curParadigmId !== paradigmId) {
      setCurParadigmId(paradigmId);
      checkloadVerbParadigmFormulas(paradigmId);
    }

  }, [checkloadVerbParadigmFormulas, paradigmId, curParadigmId]); //, loading, loadingFailure, drParentsLoading, drChildsLoading, drWorkingOnSave

  const [curscrollTo, backLigth] = useScroller(NzInt(props.match.params.recName));

  const yupSchema = yup.object().shape({
    verbTypeId: yup.number().integer().default(-1),
    verbRowId: yup.number().integer().default(-1),
    verbPersonMarkerParadigmId: yup.number().integer().default(-1)
  });

  const [frm, changeField, getError, , , , ] = useForman(yupSchema);


  //console.log("VerbRowParadigmFormulas curscrollTo=", curscrollTo);
  if (mdWorkingOnLoad || verbRowParadigmFormulasLoading || datatypesLoading) {
    return (<Loading />);
  }

  if (!morphemeRanges || !morphemesQuery || !inflectionBlocks || !inflectionTypes || !morphemeRangesByInflectionBlocks || 
      !verbTypes || !verbRows || !verbParadigms || !verbPersonMarkerParadigms ||
      !(curParadigmId in verbRowParadigmFormulas) || !verbRowParadigmFormulas[curParadigmId] || 
      (props.match.params.recName && !curscrollTo) || !datatypes ) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }


  const inflectionBlock = inflectionBlocks.find(f=>f.inbKey === 'VerbRowsBlock');
  if ( ! inflectionBlock ) {
    return (<h5>არასწორი ფლექსიის ბლოკი</h5>);
  }

  const inflectionType = inflectionTypes.find(f=>f.iftId === inflectionBlock.inflectionTypeId);
  if ( ! inflectionType ) {
    return (<h5>არასწორი ფლექსიის ტიპი</h5>);
  }

  const MorphemeRangeIdsByIT = morphemeRangesByInflectionBlocks
    .filter(f=>f.inflectionBlockId === inflectionBlock.inbId)
    .map(m=>m.morphemeRangeId);

  const rangesInGroup = morphemeRanges
    .filter(f=>f.morphemeGroupId === inflectionType.morphemeGroupId && MorphemeRangeIdsByIT.includes(f.mrId))
    .sort((a,b) => a.mrPosition-b.mrPosition);


  function getParadigmName() {
    const verbRowParadigm = verbParadigms.find(f=>f.vpnId === curParadigmId);
    return `${verbRowParadigm.vpnKey} - ${verbRowParadigm.vpnName}`
  }

  //აქ ითვლება, რომ თუ პარადიგმის სახელების დარედაქტირების უფლება აქვს მომხმარებელს
  //მაშინ პარადიგმებზეც უნდა ჰქონდეს.
  //ასე იმიტომ გაკეთდა, რომ არ გამხდარიყო საჭირო პარადიგმების მწკრივის ცხრილის დარეგისტრირება DataType-ებში
  //თუ მომავალში ამ ორი უფლების გამიჯვნა გახდა საჭირო, მოგვიწევს DataType-ებში დავარეგისტრიროთ პარადიგმების მწკრივის ცხრილი
  const dataType = datatypes.find(f=>f.dtTable === "verbParadigms");

  return (
    <div>
      <Row>
        <Col sm="10">
          <Link to= {`/verbRowParadigmsOverview/verbParadigms/${paradigmId}`} className="btn btn-primary">
            <FontAwesomeIcon icon="chevron-left" /> უკან პარადიგმების სიაში დაბრუნება
          </Link>

          <h4>ზმნის მწკრივების პარადიგმა {getParadigmName()} ({verbRowParadigmFormulas[curParadigmId].length})</h4>
        </Col>
        <Col sm="2" align="right">
        { dataType.create && (<Link to= {`/verbRowParadigmFormulaEdit`} className="btn btn-primary">
            <FontAwesomeIcon icon="plus" /> ახალი
          </Link>) }
        </Col>
      </Row>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>ნიმუში</th>
            <th>ფორმულა</th>
            <th>ზმნის ტიპი
              <BsComboBox name="verbTypeId" 
                value={frm ? frm.verbTypeId : null} dataMember={verbTypes.sort((a, b) => a.sortId-b.sortId)} 
                valueMember="vtpId" displayMember="vtpName" getError={getError} onChangeValue={changeField} 
                sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable />
            </th>
            <th>მწკრივი
              <BsComboBox name="verbRowId" 
                value={frm ? frm.verbRowId : null} dataMember={verbRows.sort((a, b) => a.sortId-b.sortId)} 
                valueMember="vrwId" displayMember="vrwName" getError={getError} onChangeValue={changeField} 
                sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable />
            </th>
            <th>პირის ნიშნების პარადიგმა
              <BsComboBox name="verbPersonMarkerParadigmId" 
                value={frm ? frm.verbPersonMarkerParadigmId : null} dataMember={verbPersonMarkerParadigms.sort((a, b) => a.sortId-b.sortId)} 
                valueMember="vpmpnId" displayMember="vpmpnKey" getError={getError} onChangeValue={changeField} 
                sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable />
            </th>
          </tr>
        </thead>
        <tbody>
          {verbRowParadigmFormulas[curParadigmId].filter(f=>filterByHeader(f,frm))
            .sort((a, b) => a.vprOrderInParadigm-b.vprOrderInParadigm)
            .map((vpr, index) => {
              const bl = (curscrollTo === vpr.vprId);
              return (
                <tr key={vpr.vprId.toString()} ref={ bl ? backLigth : null} >
                  <td className={ bl ? "backLigth" : null}>{index+1}</td>
                  <td className={ bl ? "backLigth" : null}>{vpr.vprSample}</td>
                  <td className={ bl ? "backLigth" : null}>{getFormulaVisual2(vpr.verbParadigmFormulaDetails.map(m=>m.morphemeId), rangesInGroup, morphemesQuery)}</td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbTypes, vpr.verbTypeId, {valueMember: "vtpId", displayMember: "vtpName", typeName: "Lookup"})}
                  </td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbRows, vpr.verbRowId, {valueMember: "vrwId", displayMember: "vrwName", typeName: "Lookup"})}
                  </td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbPersonMarkerParadigms, vpr.verbPersonMarkerParadigmId, {valueMember: "vpmpnId", displayMember: "vpmpnKey", typeName: "Lookup"})}
                  </td>
                  <td width="50px">
                    <div className="btn-toolbar pull-right">
                      { (dataType.update || dataType.delete) && 
                        (<Link to={`/verbRowParadigmFormulaEdit/${vpr.vprId}`} className="btn btn-primary">
                          <FontAwesomeIcon icon="edit" />
                        </Link>) }
                    </div>
                  </td>
                </tr>);
            })}
        </tbody>
      </Table>
    </div>
  );



}

// export function GetDisplayValue2(dataTable, value, col) {

//   const { dataMember, valueMember, displayMember, rowSource, columnType } = col;




function mapStateToProps(state) {
  const alert = state.alert;
  const masterData = state.masterData;
  const {verbRowParadigmFormulas, verbRowParadigmFormulasLoading} = state.formulasStore;

  return { alert, masterData, verbRowParadigmFormulas, verbRowParadigmFormulasLoading };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    checkloadVerbParadigmFormulas: (paradigmId) => dispatch(FormulasActions.checkloadVerbParadigmFormulas(paradigmId))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerbRowParadigmFormulas);
