import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form, Spinner,  } from 'react-bootstrap';
import * as yup from 'yup'; // for everything // or //import { string, object } from 'yup'; // for only what you need

import { actionCreators as alertActions } from '../store/AlertStore';
import { actionCreators as userActions } from './UserStore';
import { useForman } from '../common/MyHooks';

const ChangePasswordModal = (props) => {

  const { show, changingPassword, passwordChanged, user, alert, clear, onHide } = props;


  useEffect(() => {
    if (passwordChanged) {
      clear(); 
      onHide();
    }
  }, [passwordChanged, clear, onHide]);


  const schema = yup.object().shape({
    oldPassword: yup.string().required("ძველი პაროლი შევსებული უნდა იყოს"),
    newPassword: yup.string().required("პაროლი შევსებული უნდა იყოს"),
    newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), null], 'განმეორებითი პაროლი უნდა ემთხვეოდეს პაროლს')
  });

  const [frm, changeField, getError, haveErrors, clearToDefaults, 
    // eslint-disable-next-line
    setFormData
    ] = useForman(schema);

  function handleChange(e) {
    //console.log("ChangePasswordModal handleChange e.target=", e.target);
    const { id, value } = e.target;
    changeField(id, value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (haveErrors())
      return;
    //console.log("ChangePasswordModal handleSubmit user=", user);
    props.changePassword({ ...frm, userName: user.userName, userid: user.userId });
  }

  //console.log("ChangePasswordModal getError(confirmPassword)=", getError("confirmPassword"));

  
  function getOneTextControl(id, label, type, value) {
    const error = getError(id);
    return (
      <Form.Group className="mb-0" controlId={id}>
        <Form.Label>{label}</Form.Label>
        <Form.Control type={type} value={value} onChange={handleChange} className={!!error && "is-invalid"} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
    );
  }


  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          პაროლის შეცვლა
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
        <Form>
          {getOneTextControl("oldPassword", "ძველი პაროლი", "password", frm.oldPassword)}
          {getOneTextControl("newPassword", "ახალი პაროლი", "password", frm.newPassword)}
          {getOneTextControl("newPasswordConfirm", "ახალი პაროლი განმეორებით", "password", frm.newPasswordConfirm)}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}> შეცვლა
          {changingPassword && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        </Button>
        <Button variant="secondary" onClick={()=> { 
          clearToDefaults();
          props.clear(); 
          props.onHide();
        }}> არა</Button>
      </Modal.Footer>
    </Modal>
  );
}


function mapStateToProps(state) {
  const { changingPassword, passwordChanged, user } = state.authentication;
  const alert = state.alert;
  return { changingPassword, passwordChanged, user, alert };
}

function mapDispatchToProps(dispatch) {
  return {
    changePassword: (chpData) => dispatch(userActions.changePassword(chpData)),
    clear: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal);

