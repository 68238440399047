//ModelOverviewsStore.js
import apiClient from '../../carcass/modules/ApiClient';

//forRecountVerbPersonMarkers
const requestLoadforRecountVerbPersonMarkers = 'REQUEST_LOAD_FORRECOUNTVERBPERSONMARKERS';
const setForRecountVerbPersonMarkers = 'SET_FORRECOUNTVERBPERSONMARKERS';
const getForRecountVerbPersonMarkersFailure = 'GET_FORRECOUNTVERBPERSONMARKERS_FAILURE';



const initialState = { 
  loadingForRecountVerbPersonMarkers: false, 
  forRecountVerbPersonMarkers: [],
  filters: []
};


export const actionCreators = {

  //forRecountVerbPersonMarkers
  getFilteredForRecountVerbPersonMarkers: (verbPersonMarkerCombinationId, dominantActantId, morphemeId) => async (dispatch, getState) => {

    const filterName = `/filtered/forRecountVerbPersonMarkers/${verbPersonMarkerCombinationId?verbPersonMarkerCombinationId:-1}/${dominantActantId?dominantActantId:-1}/${morphemeId?morphemeId:-1}`

    if ( getState().modelOverviewsStore.filters.includes(filterName) )
      return;

      await apiClient.Get(filterName,
      { get: requestLoadforRecountVerbPersonMarkers, 
        set: setForRecountVerbPersonMarkers, 
        failure: getForRecountVerbPersonMarkersFailure },
      dispatch, getState, true, filterName );
  }

}



export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestLoadforRecountVerbPersonMarkers) {
    return { ...state, loadingForRecountVerbPersonMarkers: true };
  }

  //forRecountVerbPersonMarkers
  if (action.type === setForRecountVerbPersonMarkers) {
    const data = action.payload.data;
    const filterName = action.payload.params;
    const newFilters = state.filters;
    newFilters.push(filterName);
    const newForRecountVerbPersonMarkers = state.forRecountVerbPersonMarkers;
    data.forEach(fe=> {
      //fe.frvpmId
      const index = newForRecountVerbPersonMarkers.findIndex(fi => fi.frvpmId === fe.frvpmId);
      if ( index > -1 )
        newForRecountVerbPersonMarkers.splice(index, 1);
      newForRecountVerbPersonMarkers.push(fe);
    });
    return { ...state, loadingForRecountVerbPersonMarkers: false, 
      forRecountVerbPersonMarkers: newForRecountVerbPersonMarkers, filters: newFilters };
  }

  if (action.type === getForRecountVerbPersonMarkersFailure) {
    return { ...state, loadingForRecountVerbPersonMarkers: false };
  }


  return state;

}