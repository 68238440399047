//PhoneticsTypeEdit.js
import React, {useState, useEffect, useMemo} from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as ModelEditorActions } from './ModelEditorStore';
//import { useHistory } from "react-router-dom";
import { useForman } from '../../carcass/common/MyHooks';
import WaitPage from '../../carcass/common/WaitPage';
import { GetOnePhoneticsTypeProhibitionDescription } from './PhoneticsTypeModule';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OneTextControl from '../../carcass/editorParts/OneTextControl';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneNumberControl from '../../carcass/editorParts/OneNumberControl';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import OnePlusButton from '../editorParts/OnePlusButton';
import OneEditDeleteButtons from '../editorParts/OneEditDeleteButtons';
import OneUpDownButtons from '../editorParts/OneUpDownButtons';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';

const PhoneticsTypeEdit = (props) => {

  //const history = useHistory();

  const [curPhtIdVal, setCurPhtIdVal] = useState(null); //1. იდენტიფიკატორი
  const [expandedProhibitionIndex, setExpandedProhibitionIndex] = useState(null); //გახსნილი აკრძალვის ნომერი

  //2. კომპონენტის თვისებები
  const { alert, mdWorkingOnLoad, datatypesLoading, datatypes, checkLoadMdTables, phoneticsOptions, savingPhoneticsType, 
    loadingPhoneticsType, workingOnDeletePhoneticsType, phoneticsTypeForEdit, getOnePhoneticsTypeById, DeleteFailure } = props;

  const { phtId } = props.match.params;

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => ["phoneticsTypes", "phoneticsTypeProhibitions", "phoneticsChanges"], []);

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["phoneticsOptions"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [checkLoadMdTables, tableNamesForLoad, mdWorkingOnLoad, datatypesLoading, datatypes]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    phoneticsType: yup.object().shape({
      phtId: yup.number().integer().default(0),
      phtName: yup.string().required("ფონეტიკური ტიპის სახელი შევსებული უნდა იყოს").default(""),
      phtLastLetter: yup.number().integer().default(2),
      phtDistance: yup.number().integer().required("დისტანცია შევსებული უნდა იყოს").default(1),
      phtNote: yup.string().nullable().default(""),
      phtSlab: yup.number().integer().default(0), //.required("მარცვლების რაოდენობის განსაზღვრის წესი არჩეული უნდა იყოს")
      phtSlabCount: yup.number().integer().required("მარცვლების რაოდენობა შევსებული უნდა იყოს").default(0)
    }),
    //ფონეტიკური ვარიანტები
    phoneticsOptionIds: yup.array().required("ფონეტიკური ვარიანტი ერთი მაინც უნდა იყოს არჩეული").ensure()
      .min(1, "ერთი მაინც ფონეტიკური ვარიანტი არჩეული უნდა იყოს")
      .of(yup.number().integer().positive("ფონეტიკური ვარიანტი არჩეული უნდა იყოს").required("ფონეტიკური ვარიანტი არჩეული უნდა იყოს")),
    //ფონეტიკური შეზღუდვები
    phoneticsTypeProhibitions: yup.array().ensure().of(yup.object().shape({
      phtpId: yup.number().integer().default(0),
      phtpProhOrd: yup.number().integer().default(0), //შეზღუდვის რიგითი ნომერი
      phtpProhId: yup.number().integer().default(0), //შეზღუდვის ტიპი 0-იყოს;1-იყოს ერთ-ერთი;2-არ იყოს
      phtpOrient: yup.number().integer().default(0), //ორიენტაცია 0-ბოლოდან; 1-თავიდან
      phtpStart: yup.number().integer().default(0), //აღნიშნავს იმ სიმბოლოს ნომერს, რომლიდანაც განვიხილავთ სიმბოლოებს
      phtpCount: yup.number().integer().default(1), //აღნიშნავს ჩვენთვის საინტერესო სომბოლოების რაოდენობას.
      phtpObject: yup.number().integer().default(0), //ობიექტის ტიპი: 0-ბგერა; 1-ხმოვანი; 2-თანხმოვანი
      phtpNew: yup.string().default("").when(['phtpProhId', 'phtpObject'], (phtpProhId, phtpObject, schema) => {
        if ( phtpProhId === 1 || !phtpObject )
          return schema.required('როცა შეზღუდვის ტიპი არჩეულია "იყოს ერთ-ერთი", ან ობიექტი არჩეულია "ბგერა", სიმბოლო(ები) შევსებული უნდა იყოს');
        return schema;
      } )
    }))
  });

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    props.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
  }

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const phtIdVal = phtId ? parseInt(phtId) : 0;
    //console.log("PhoneticsTypeEdit useEffect phtIdVal=", phtIdVal);

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curPhtIdVal !== phtIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurPhtIdVal(phtIdVal);
      if (phtIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOnePhoneticsTypeById(phtIdVal);
        return;
      }
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }

    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთული ობიექტი მაინც შევამოწმოთ
    if ( loadingPhoneticsType || !phtId || !phoneticsTypeForEdit)
      return;

    //ჩატვირთული ინფორმაცია მივცეთ ფორმის მენეჯერს
    setFormData(phoneticsTypeForEdit);

  }, [clearToDefaults, curPhtIdVal, phtId, setFormData, phoneticsTypeForEdit, loadingPhoneticsType, getOnePhoneticsTypeById]);

  //8. ჩატვირთვის შემოწმება
  if ( loadingPhoneticsType || mdWorkingOnLoad || datatypesLoading) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
    return (<WaitPage />);

  //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( (curPhtIdVal && !phoneticsTypeForEdit) || !phoneticsOptions || !datatypes ) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }
  
  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert()
    if (haveErrors)
      return;
    if ( curPhtIdVal )
      props.updatePhoneticsType(props.history, frm);
    else
      props.createPhoneticsType(props.history, frm);
    clearUsedTables();
    }

  function renumProhibitions(phoneticsTypeProhibitions)
  {
    frm.phoneticsTypeProhibitions
      .sort((a,b) => a.phtpProhOrd - b.phtpProhOrd)
      .forEach((e,i) => e.phtpProhOrd = i);
  }

  function goProhibitionDown(index)
  {
    renumProhibitions(frm.phoneticsTypeProhibitions);
    frm.phoneticsTypeProhibitions[index].phtpProhOrd = index + 1;
    frm.phoneticsTypeProhibitions[index+1].phtpProhOrd = index;
  }

  const dataType = datatypes.find(f=>f.dtTable === "phoneticsTypes");

  return (
    <Row>
      <Col sm="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          <EditorHeader curIdVal={curPhtIdVal} EditorName="ფონეტიკური ტიპი" EditorNameGenitive="ფონეტიკური ტიპის"
            EditedObjectName={(frm && frm.phoneticsType && frm.phoneticsType.phtName) ? frm.phoneticsType.phtName : ""}
            workingOnDeletePhoneticsType={workingOnDeletePhoneticsType} DeleteFailure={DeleteFailure}
            onDelete={() => {props.deletePhoneticsType(props.history, curPhtIdVal); clearUsedTables();}}
            onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={dataType.delete} />
          <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />
          <OneTextControl controlId="phoneticsType.phtName" label="დასახელება" value={frm.phoneticsType.phtName} 
            getError={getError} onChangeValue={changeField} />
          <OneComboBoxControl controlId="phoneticsType.phtLastLetter" label="ბოლო ბგერა" value={frm.phoneticsType.phtLastLetter} 
            dataMember={[{id: 0, name: "თანხმოვანი"}, {id: 1, name: "ხმოვანი"}, {id: 2, name: "სულერთია"}]} 
            valueMember="id" displayMember="name" getError={getError} onChangeValue={changeField} />
          <OneNumberControl controlId="phoneticsType.phtDistance" label="დისტანცია" 
            value={frm.phoneticsType.phtDistance} getError={getError} onChangeValue={changeField} />
          <OneTextControl controlId="phoneticsType.phtNote" label="შენიშვნა" value={frm.phoneticsType.phtNote} 
            getError={getError} onChangeValue={changeField} />
          <OneComboBoxControl controlId="phoneticsType.phtSlab" label="მარცვალების" value={frm.phoneticsType.phtSlab} 
            dataMember={[{id: 0, name: "მინიმუმ"}, {id: 1, name: "ზუსტად"}, {id: 2, name: "მაქსიმუმ"}]} 
            valueMember="id" displayMember="name" getError={getError} onChangeValue={changeField} />
          <OneNumberControl controlId="phoneticsType.phtSlabCount" label="რაოდენობა" 
            value={frm.phoneticsType.phtSlabCount} getError={getError} onChangeValue={changeField} minv={0} />
          <OneStrongLabel controlId="phoneticsOptionsLabel" label="ფონეტიკური ვარიანტები" />
          {frm && frm.phoneticsOptionIds && frm.phoneticsOptionIds.map((poId, index) => {
            return (<OneComboBoxControl key={index} controlId={`phoneticsOptionIds[${index}]`} 
              label={`ფონეტიკური ვარიანტი ${index+1}`} value={frm.phoneticsOptionIds[index]} 
            dataMember={phoneticsOptions} valueMember="phoId" displayMember="phoName" 
            firstItem={{ id: 0, name: "აირჩიეთ ფონეტიკური ვარიანტი" }} getError={getError} 
            onChangeValue={changeField} onTrashButtonClick={() => {
              const newFrm = {...frm};
              newFrm.phoneticsOptionIds.splice(index,1);
              setFormData(newFrm);
            }} />);
          
          })}

          <OnePlusButton onClick={(e) => {
            const newFrm = {...frm};
            newFrm.phoneticsOptionIds.push(0);
            setFormData(newFrm);
          }} />

          <OneStrongLabel controlId="prohibitionsLabel" label="შეზღუდვები" />

          <ol>
            {frm && frm.phoneticsTypeProhibitions && frm.phoneticsTypeProhibitions
              .sort((a,b) => a.phtpProhOrd-b.phtpProhOrd)
              .map((phtp, index) => {

              return (
                <li key={index} >
                  <OneEditDeleteButtons controlId={index} label={GetOnePhoneticsTypeProhibitionDescription(phtp)} 
                    onEditClick={(e) => {
                      e.preventDefault();
                      if ( expandedProhibitionIndex === index )
                        setExpandedProhibitionIndex(null);
                      setExpandedProhibitionIndex(index);
                    }}
                    onDeleteClick={(e) => {
                      e.preventDefault();
                      const newFrm = {...frm};
                      newFrm.phoneticsTypeProhibitions.splice(index,1);
                      setFormData(newFrm);
                    }} />
                  {expandedProhibitionIndex === index && 
                    <div>
                      <OneUpDownButtons controlId={index} label="პოზიციის ცვლილება" 
                        enableUp={index>0} enableDown={index<frm.phoneticsTypeProhibitions.length-1} 
                        onUpClick={(e) => {
                          e.preventDefault();
                          goProhibitionDown(index-1);
                          setExpandedProhibitionIndex(index-1);
                        }} onDownClick={(e) => {
                          e.preventDefault();
                          goProhibitionDown(index);
                          setExpandedProhibitionIndex(index+1);
                        }} />
                      {/* <Form.Label column sm="10">რედაქტირდება აკრძალვა ინდექსით {index}</Form.Label> */}
                      {/* {getOneNumberControl(`phoneticsTypeProhibitions[${index}].phtpProhOrd`, "რიგირთი ნომერი", frm.phoneticsTypeProhibitions[index].phtpProhOrd, 0)} */}
                      <OneComboBoxControl controlId={`phoneticsTypeProhibitions[${index}].phtpProhId`} 
                        label="შეზღუდვის ტიპი" value={frm.phoneticsTypeProhibitions[index].phtpProhId} 
                        dataMember={[{id: 0, name: "იყოს"}, {id: 1, name: "იყოს ერთ-ერთი"}, {id: 2, name: "არ იყოს"}]} 
                        valueMember="id" displayMember="name" getError={getError} onChangeValue={changeField} />
                      <OneComboBoxControl controlId={`phoneticsTypeProhibitions[${index}].phtpOrient`} 
                        label="ორიენტაცია" value={frm.phoneticsTypeProhibitions[index].phtpOrient} 
                        dataMember={[{id: 0, name: "ბოლოდან"}, {id: 1, name: "თავიდან"}]} 
                        valueMember="id" displayMember="name" getError={getError} onChangeValue={changeField} />
                      <OneNumberControl controlId={`phoneticsTypeProhibitions[${index}].phtpStart`} label="პირველი სიმბოლოს ნომერი" 
                        value={frm.phoneticsTypeProhibitions[index].phtpStart} getError={getError} onChangeValue={changeField} minv={0} />
                      <OneNumberControl controlId={`phoneticsTypeProhibitions[${index}].phtpCount`} label="სიმბოლოების რაოდენობა" 
                        value={frm.phoneticsTypeProhibitions[index].phtpCount} getError={getError} onChangeValue={changeField} minv={1} />
                      <OneComboBoxControl controlId={`phoneticsTypeProhibitions[${index}].phtpObject`} 
                        label="ობიექტის ტიპი" value={frm.phoneticsTypeProhibitions[index].phtpObject} 
                        dataMember={[{id: 0, name: "ბგერა"}, {id: 1, name: "ხმოვანი"}, {id: 2, name: "თანხმოვანი"}]} 
                        valueMember="id" displayMember="name" getError={getError} onChangeValue={changeField} />
                      <OneTextControl controlId={`phoneticsTypeProhibitions[${index}].phtpNew`} 
                        label="სიმბოლოები" value={frm.phoneticsTypeProhibitions[index].phtpNew} 
                        getError={getError} onChangeValue={changeField} />
                    </div>
                  }
                </li>
            )})}
          </ol>

          <OnePlusButton onClick={(e) => {
            e.preventDefault();
            const newFrm = {...frm};
            const newPtp = yup.reach(yupSchema, 'phoneticsTypeProhibitions[0]').default();
            newPtp.phtpProhOrd = newFrm.phoneticsTypeProhibitions.length + 1;
            newFrm.phoneticsTypeProhibitions.push(newPtp);
            setFormData(newFrm);
            setExpandedProhibitionIndex(newFrm.phoneticsTypeProhibitions.length-1);
          }} />

          <OneSaveCancelButtons curIdVal={curPhtIdVal} haveErrors={haveErrors} savingNow={savingPhoneticsType} onCloseClick={() => { 
            props.clearAlert(); props.history.goBack(); }} allowEdit={dataType.update} />

          <OneErrorRow allErrors={allErrors} />

        </Form>
      </Col>
    </Row>
  );
}

function mapStateToProps(state) {
  const alert = state.alert;
  const { mdWorkingOnLoad, datatypesLoading, datatypes } = state.masterData;
  const { savingPhoneticsType, loadingPhoneticsType, workingOnDeletePhoneticsType, phoneticsTypeForEdit, DeleteFailure } = state.modelEditorStore
  const phoneticsOptions = state.masterData.mdRepo.phoneticsOptions;
  return { alert, mdWorkingOnLoad, datatypesLoading, datatypes, phoneticsOptions,
    savingPhoneticsType, loadingPhoneticsType, workingOnDeletePhoneticsType, phoneticsTypeForEdit, DeleteFailure
   };
}

function mapDispatchToProps(dispatch) {
  return {
    getOnePhoneticsTypeById: (phtId) => dispatch(ModelEditorActions.getOnePhoneticsTypeById(phtId)),
    updatePhoneticsType: (history, pht) => dispatch(ModelEditorActions.updatePhoneticsType(history, pht)),
    createPhoneticsType: (history, pht) => dispatch(ModelEditorActions.createPhoneticsType(history, pht)),
    deletePhoneticsType: (history, phtId) => dispatch(ModelEditorActions.deletePhoneticsType(history, phtId)),
    clearDeletingFailure: () => dispatch(ModelEditorActions.clearDeletingFailure()),
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    clearTablesFromRepo: (tableNamesFroClear, tableNamesFroLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesFroClear, tableNamesFroLoad)),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneticsTypeEdit);

