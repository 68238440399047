//FormulasStore.js
import apiClient from '../../carcass/modules/ApiClient';

const clearDerivFormulas = 'CLEAR_DERIV_FORMULAS';
const requestDerivformulas = 'REQUEST_DERIV_FORMULAS';
const setDerivformulas = 'SET_DERIV_FORMULAS';
const getDerivformulasFailure = 'GET_DERIV_FORMULAS_FAILURE';

const clearNounParadigmNames = 'CLEAR_NOUN_PARADIGM_NAMES';
const requestNounParadigmNames = 'REQUEST_NOUN_PARADIGM_NAMES';
const setNounParadigmNames = 'SET_NOUN_PARADIGM_NAMES';
const getNounParadigmNamesFailure = 'GET_NOUN_PARADIGM_NAMES';

const clearNounParadigmFormulas = 'CLEAR_NOUN_PARADIGM_FORMULAS';
const requestNounParadigmFormulas = 'REQUEST_NOUN_PARADIGM_FORMULAS';
const setNounParadigmFormulas = 'SET_NOUN_PARADIGM_FORMULAS';
const getNounParadigmFormulasFailure = 'GET_NOUN_PARADIGM_FORMULAS_FAILURE';

const clearVerbRowParadigmNames = 'CLEAR_VERB_PARADIGM_NAMES';
const requestVerbRowParadigmNames = 'REQUEST_VERB_PARADIGM_NAMES';
const setVerbRowParadigmNames = 'SET_VERB_PARADIGM_NAMES';
const getVerbRowParadigmNamesFailure = 'GET_VERB_PARADIGM_NAMES';

const clearVerbRowParadigmFormulas = 'CLEAR_VERB_PARADIGM_FORMULAS';
const requestVerbRowParadigmFormulas = 'REQUEST_VERB_PARADIGM_FORMULAS';
const setVerbRowParadigmFormulas = 'SET_VERB_PARADIGM_FORMULAS';
const getVerbRowParadigmFormulasFailure = 'GET_VERB_PARADIGM_FORMULAS_FAILURE';

const clearVerbPersonMarkerParadigmNames = 'CLEAR_VERB_PERSON_MARKER_PARADIGM_NAMES';
const requestVerbPersonMarkerParadigmNames = 'REQUEST_VERB_PERSON_MARKER_PARADIGM_NAMES';
const setVerbPersonMarkerParadigmNames = 'SET_VERB_PERSON_MARKER_PARADIGM_NAMES';
const getVerbPersonMarkerParadigmNamesFailure = 'GET_VERB_PERSON_MARKER_PARADIGM_NAMES';

const clearVerbPersonMarkerFormulas = 'CLEAR_VERB_PERSON_MARKER_PARADIGM_FORMULAS';
const requestVerbPersonMarkerFormulas = 'REQUEST_VERB_PERSON_MARKER_PARADIGM_FORMULAS';
const setVerbPersonMarkerFormulas = 'SET_VERB_PERSON_MARKER_PARADIGM_FORMULAS';
const getVerbPersonMarkerFormulasFailure = 'GET_VERB_PERSON_MARKER_PARADIGM_FORMULAS_FAILURE';

// const clearVerbPersonMarkerCombinationFormulas = 'CLEAR_VERB_PERSON_MARKER_COMBINATION_FORMULAS';
const requestVerbPersonMarkerCombinationFormulas = 'REQUEST_VERB_PERSON_MARKER_COMBINATION_FORMULAS';
const setVerbPersonMarkerCombinationFormulas = 'SET_VERB_PERSON_MARKER_COMBINATION_FORMULAS';
const getVerbPersonMarkerCombinationFormulasFailure = 'GET_VERB_PERSON_MARKER_COMBINATION_FORMULAS_FAILURE';




const initialState = { 
  derivFormulasLoading: false, derivationFormulas: null,
  nounParadigmNamesLoading: false, nounParadigmNames: null,
  nounParadigmFormulasLoading: false, nounParadigmFormulas: {},
  verbRowParadigmNamesLoading: false, verbRowParadigmNames: null,
  verbRowParadigmFormulasLoading: false, verbRowParadigmFormulas: {},
  verbPersonMarkerParadigmNamesLoading: false, verbPersonMarkerParadigmNames: null,
  verbPersonMarkerFormulasLoading: false, verbPersonMarkerFormulas: {},
  verbPersonMarkerCombinationFormulasLoading: false, verbPersonMarkerCombinationFormulas: [], vpmcFilters: []
};




export const actionCreators = {

  clearDerivFormulas: () => {
    return { type: clearDerivFormulas };
  },

  clearNounParadigmNames: () => {
    return { type: clearNounParadigmNames };
  },

  clearNounParadigmFormulas: (paradigmIdsForClear) => {
    return { type: clearNounParadigmFormulas, payload: paradigmIdsForClear };
  },

  clearVerbRowParadigmNames: () => {
    return { type: clearVerbRowParadigmNames };
  },

  clearVerbRowParadigmFormulas: (paradigmIdsForClear) => {
    return { type: clearVerbRowParadigmFormulas, payload: paradigmIdsForClear };
  },

  clearVerbPersonMarkerParadigmNames: () => {
    return { type: clearVerbPersonMarkerParadigmNames };
  },

  clearVerbPersonMarkerFormulas: (paradigmIdsForClear) => {
    return { type: clearVerbPersonMarkerFormulas, payload: paradigmIdsForClear };
  },

  checkLoadDerivFormulas: () => async (dispatch, getState) => {

    //თუ უკვე იტვირთება, აღარ ჩავტვირთოთ
    if (getState().formulasStore.derivFormulasLoading)
      return;
    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
    if (getState().formulasStore.derivationFormulas)
      return; //წესით ასეი შემთხვევა არ უნდა მოხდეს, მაგრამ მაინც
    //console.log('FormulasStore.js apiClient.Get', "/formulas/derivation");
    await apiClient.Get("/formulas/derivation",
      { get: requestDerivformulas, 
        set: setDerivformulas, 
        failure: getDerivformulasFailure },
      dispatch, getState, true );
  },

  CheckLoadNounParadigmNames: () => async (dispatch, getState) => {

    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
    if (getState().formulasStore.nounParadigmNames)
      return; //წესით ასეი შემთხვევა არ უნდა მოხდეს, მაგრამ მაინც
    //console.log('FormulasStore.js apiClient.Get', "/formulas/nounparadigmnames");
    await apiClient.Get("/formulas/nounparadigmnames",
      { get: requestNounParadigmNames, 
        set: setNounParadigmNames, 
        failure: getNounParadigmNamesFailure },
      dispatch, getState, true );
  },

  checkLoadVerbRowParadigmNames: () => async (dispatch, getState) => {

    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
    if (getState().formulasStore.verbRowParadigmNames)
      return; //წესით ასეი შემთხვევა არ უნდა მოხდეს, მაგრამ მაინც
    //console.log('FormulasStore.js apiClient.Get', "/formulas/verbparadigmnames");
    await apiClient.Get("/formulas/verbparadigmnames",
      { get: requestVerbRowParadigmNames, 
        set: setVerbRowParadigmNames, 
        failure: getVerbRowParadigmNamesFailure },
      dispatch, getState, true );
  },

  checkLoadVerbPersonMarkerParadigmNames: () => async (dispatch, getState) => {

    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
    if (getState().formulasStore.verbPersonMarkerParadigmNames)
      return; //წესით ასეი შემთხვევა არ უნდა მოხდეს, მაგრამ მაინც
    //console.log('FormulasStore.js apiClient.Get', "/formulas/verbpersonmarkerparadigmnames");
    await apiClient.Get("/formulas/verbpersonmarkerparadigmnames",
      { get: requestVerbPersonMarkerParadigmNames, 
        set: setVerbPersonMarkerParadigmNames, 
        failure: getVerbPersonMarkerParadigmNamesFailure },
      dispatch, getState, true );
  },

  CheckloadNounParadigmFormulas: (paradigmId) => async (dispatch, getState) => {
    const nounParadigmFormulas = getState().formulasStore.nounParadigmFormulas

    //console.log("FormulasStore.js CheckloadNounParadigmFormulas nounParadigmFormulas=", nounParadigmFormulas);

    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
    if ( (paradigmId in nounParadigmFormulas) && nounParadigmFormulas[paradigmId] )
      return; 

    //console.log('FormulasStore.js apiClient.Get', `/formulas/noun/${paradigmId}`);

    await apiClient.Get(`/formulas/noun/${paradigmId}`,
      { get: requestNounParadigmFormulas, 
        set: setNounParadigmFormulas, 
        failure: getNounParadigmFormulasFailure },
      dispatch, getState, true, paradigmId );
  },

  checkloadVerbParadigmFormulas: (paradigmId) => async (dispatch, getState) => {
    const verbRowParadigmFormulas = getState().formulasStore.verbRowParadigmFormulas

    //console.log("FormulasStore.js checkloadVerbParadigmFormulas verbRowParadigmFormulas=", verbRowParadigmFormulas);

    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
    if ( (paradigmId in verbRowParadigmFormulas) && verbRowParadigmFormulas[paradigmId] )
      return; 

    //console.log('FormulasStore.js apiClient.Get', `/formulas/verb/${paradigmId}`);

    await apiClient.Get(`/formulas/verb/${paradigmId}`,
      { get: requestVerbRowParadigmFormulas, 
        set: setVerbRowParadigmFormulas, 
        failure: getVerbRowParadigmFormulasFailure },
      dispatch, getState, true, paradigmId );
  },

  checkloadVerbPersonMarkerFormulas: (paradigmId) => async (dispatch, getState) => {
    const verbPersonMarkerFormulas = getState().formulasStore.verbPersonMarkerFormulas

    //console.log("FormulasStore.js checkloadVerbPersonMarkerFormulas verbPersonMarkerFormulas=", verbPersonMarkerFormulas);

    //თუ უკვე ჩატვირთულია, აღარ ჩავტვირთოთ
    //წესით ასეთი სიტუაცია იშვიათად ხდება, მაგრამ მაინც
    if ( (paradigmId in verbPersonMarkerFormulas) && verbPersonMarkerFormulas[paradigmId] )
      return; 

    //console.log('FormulasStore.js apiClient.Get', `/formulas/verbpersonmarker/${paradigmId}`);

    await apiClient.Get(`/formulas/verbpersonmarker/${paradigmId}`,
      { get: requestVerbPersonMarkerFormulas, 
        set: setVerbPersonMarkerFormulas, 
        failure: getVerbPersonMarkerFormulasFailure },
      dispatch, getState, true, paradigmId );
  },

  checkloadVerbPersonMarkerCombinationFormulas: (verbPluralityTypeId, verbPersonMarkerParadigmId, verbTypeId, verbSeriesId ) => async (dispatch, getState) => {

    const filterName = `/formulas/verbpersonmarkercombination/${verbPluralityTypeId?verbPluralityTypeId:-1}/${verbPersonMarkerParadigmId?verbPersonMarkerParadigmId:-1}/${verbTypeId?verbTypeId:-1}/${verbSeriesId?verbSeriesId:-1}`

    if ( getState().formulasStore.vpmcFilters.includes(filterName) )
      return;

      await apiClient.Get(filterName,
      { get: requestVerbPersonMarkerCombinationFormulas, 
        set: setVerbPersonMarkerCombinationFormulas, 
        failure: getVerbPersonMarkerCombinationFormulasFailure },
      dispatch, getState, true, filterName );
  }


}


export const reducer = (state, action) => {
  state = state || initialState;


//Derivation
  
  if (action.type === clearDerivFormulas) {
    return { ...state, derivationFormulas: null, derivFormulasLoading: false };
  }

  if (action.type === requestDerivformulas) {
    return { ...state, derivFormulasLoading: true };
  }
  
  if (action.type === setDerivformulas) {
    const data = action.payload.data;
    return { ...state, derivationFormulas: data, derivFormulasLoading: false };
  }
  
  if (action.type === getDerivformulasFailure) {
    return { ...state, derivationFormulas: null, derivFormulasLoading: false };
  }


//noun
  
  if (action.type === requestNounParadigmFormulas) {
    const newNounParadigmFormulas = state.nounParadigmFormulas;
    newNounParadigmFormulas[action.payload.params] = null;
    return { ...state, nounParadigmFormulas: newNounParadigmFormulas, nounParadigmFormulasLoading: true };
  }
  
  if (action.type === setNounParadigmFormulas) {
    const newNounParadigmFormulas = state.nounParadigmFormulas;
    newNounParadigmFormulas[action.payload.params] = action.payload.data;
    return { ...state, nounParadigmFormulas: newNounParadigmFormulas, nounParadigmFormulasLoading: false };
  }
  
  if (action.type === getNounParadigmFormulasFailure) {

    const newNounParadigmFormulas = state.nounParadigmFormulas;
    newNounParadigmFormulas[action.payload.params] = null;
    return { ...state, nounParadigmFormulas: newNounParadigmFormulas, nounParadigmFormulasLoading: false };
  }

  if (action.type === clearNounParadigmFormulas) {
    const paradigmIdsForClear = action.payload;
    const newNounParadigmFormulas = state.nounParadigmFormulas;
    paradigmIdsForClear.forEach(pid=> delete newNounParadigmFormulas[pid]);
    return { ...state, nounParadigmFormulas: newNounParadigmFormulas };
  }

  if (action.type === clearNounParadigmNames) {
    return { ...state, nounParadigmNames: null, nounParadigmNamesLoading: false };
  }

  if (action.type === requestNounParadigmNames) {
    return { ...state, nounParadigmNamesLoading: true };
  }
  
  if (action.type === setNounParadigmNames) {
    const data = action.payload.data;
    return { ...state, nounParadigmNames: data, nounParadigmNamesLoading: false };
  }
  
  if (action.type === getNounParadigmNamesFailure) {
    return { ...state, nounParadigmNames: null, nounParadigmNamesLoading: false };
  }


//verb
  
if (action.type === requestVerbRowParadigmFormulas) {
  const newVerbRowParadigmFormulas = state.verbRowParadigmFormulas;
  newVerbRowParadigmFormulas[action.payload.params] = null;
  return { ...state, verbRowParadigmFormulas: newVerbRowParadigmFormulas, verbRowParadigmFormulasLoading: true };
}

if (action.type === setVerbRowParadigmFormulas) {
  const newVerbRowParadigmFormulas = state.verbRowParadigmFormulas;
  newVerbRowParadigmFormulas[action.payload.params] = action.payload.data;
  return { ...state, verbRowParadigmFormulas: newVerbRowParadigmFormulas, verbRowParadigmFormulasLoading: false };
}

if (action.type === getVerbRowParadigmFormulasFailure) {

  const newVerbRowParadigmFormulas = state.verbRowParadigmFormulas;
  newVerbRowParadigmFormulas[action.payload.params] = null;
  return { ...state, verbRowParadigmFormulas: newVerbRowParadigmFormulas, verbRowParadigmFormulasLoading: false };
}

if (action.type === clearVerbRowParadigmFormulas) {
  const paradigmIdsForClear = action.payload;
  const newVerbRowParadigmFormulas = state.verbRowParadigmFormulas;
  paradigmIdsForClear.forEach(pid=> delete newVerbRowParadigmFormulas[pid]);
  return { ...state, verbRowParadigmFormulas: newVerbRowParadigmFormulas };
}

if (action.type === clearVerbRowParadigmNames) {
  return { ...state, verbRowParadigmNames: null, verbRowParadigmNamesLoading: false };
}

if (action.type === requestVerbRowParadigmNames) {
  return { ...state, verbRowParadigmNamesLoading: true };
}

if (action.type === setVerbRowParadigmNames) {
  const data = action.payload.data;
  return { ...state, verbRowParadigmNames: data, verbRowParadigmNamesLoading: false };
}

if (action.type === getVerbRowParadigmNamesFailure) {
  return { ...state, verbRowParadigmNames: null, verbRowParadigmNamesLoading: false };
}


//VerbPersonMarker
  
if (action.type === requestVerbPersonMarkerFormulas) {
  const newVerbPersonMarkerFormulas = state.verbPersonMarkerFormulas;
  newVerbPersonMarkerFormulas[action.payload.params] = null;
  return { ...state, verbPersonMarkerFormulas: newVerbPersonMarkerFormulas, verbPersonMarkerFormulasLoading: true };
}

if (action.type === setVerbPersonMarkerFormulas) {
  const newVerbPersonMarkerFormulas = state.verbPersonMarkerFormulas;
  newVerbPersonMarkerFormulas[action.payload.params] = action.payload.data;
  return { ...state, verbPersonMarkerFormulas: newVerbPersonMarkerFormulas, verbPersonMarkerFormulasLoading: false };
}

if (action.type === getVerbPersonMarkerFormulasFailure) {

  const newVerbPersonMarkerFormulas = state.verbPersonMarkerFormulas;
  newVerbPersonMarkerFormulas[action.payload.params] = null;
  return { ...state, verbPersonMarkerFormulas: newVerbPersonMarkerFormulas, verbPersonMarkerFormulasLoading: false };
}

if (action.type === clearVerbPersonMarkerFormulas) {
  const paradigmIdsForClear = action.payload;
  const newVerbPersonMarkerFormulas = state.verbPersonMarkerFormulas;
  paradigmIdsForClear.forEach(pid=> delete newVerbPersonMarkerFormulas[pid]);
  return { ...state, verbPersonMarkerFormulas: newVerbPersonMarkerFormulas };
}

if (action.type === clearVerbPersonMarkerParadigmNames) {
  return { ...state, verbPersonMarkerParadigmNames: null, verbPersonMarkerParadigmNamesLoading: false };
}

if (action.type === requestVerbPersonMarkerParadigmNames) {
  return { ...state, verbPersonMarkerParadigmNamesLoading: true };
}

if (action.type === setVerbPersonMarkerParadigmNames) {
  const data = action.payload.data;
  return { ...state, verbPersonMarkerParadigmNames: data, verbPersonMarkerParadigmNamesLoading: false };
}

if (action.type === getVerbPersonMarkerParadigmNamesFailure) {
  return { ...state, verbPersonMarkerParadigmNames: null, verbPersonMarkerParadigmNamesLoading: false };
}





// const requestVerbPersonMarkerCombinationFormulas = 'REQUEST_VERB_PERSON_MARKER_COMBINATION_FORMULAS';
// const setVerbPersonMarkerCombinationFormulas = 'SET_VERB_PERSON_MARKER_COMBINATION_FORMULAS';
// const getVerbPersonMarkerCombinationFormulasFailure = 'GET_VERB_PERSON_MARKER_COMBINATION_FORMULAS_FAILURE';
//verbPersonMarkerCombinationFormulasLoading: false, verbPersonMarkerCombinationFormulas: {}, vpmcFilters: []

if (action.type === requestVerbPersonMarkerCombinationFormulas) {
  return { ...state, verbPersonMarkerCombinationFormulasLoading: true };
}

if (action.type === setVerbPersonMarkerCombinationFormulas) {
  const data = action.payload.data;
  const filterName = action.payload.params;
  const newvpmcFilters = state.vpmcFilters;
  newvpmcFilters.push(filterName);
  const newverbPersonMarkerCombinationFormulas = state.verbPersonMarkerCombinationFormulas;
  data.forEach(fe=> {
    //fe.frvpmId
    const index = newverbPersonMarkerCombinationFormulas.findIndex(fi => fi.vpmcId === fe.vpmcId);
    if ( index > -1 )
      newverbPersonMarkerCombinationFormulas.splice(index, 1);
    newverbPersonMarkerCombinationFormulas.push(fe);
  });
  return { ...state, verbPersonMarkerCombinationFormulasLoading: false, 
    verbPersonMarkerCombinationFormulas: newverbPersonMarkerCombinationFormulas, vpmcFilters: newvpmcFilters };
}

if (action.type === getVerbPersonMarkerCombinationFormulasFailure) {
  return { ...state, verbPersonMarkerCombinationFormulasLoading: false };
}






  return state;
}