import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Col, Spinner, Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import WaitPage from '../../carcass/common/WaitPage';
import { NzInt } from '../../carcass/common/myFunctions';
import MessageBox from '../../carcass/common/MessageBox';

import './Paradigm.css';


const Paradigm = (props) => {

  const [currentInflId, setCurrentInflId] = useState(null);
  const [currentIvcId, setCurrentIvcId] = useState(null);
  const [samples, setSamples] = useState(null);

  const { inflectionWorkingOnLoadParadigm, paradigm, GetParadigm, GetVerbCompositionParadigm, prdShowhyphens, prdShowFormulas, prdIspMorphemeNom,
    mdWorkingOnLoad, pronouns, user, savingParadigmSamplePositions,
    saveVerbCompositionParadigmSamplePositions,
    saveParadigmSamplePositions    
   } = props;

  const [showSaveSamplePositionsConfirmMessage, setShowSaveSamplePositionsConfirmMessage] = useState(false);

  const infId = NzInt(props.match.params.infId);
  const ivcId = NzInt(props.match.params.ivcId);

  //console.log("Paradigm props=", props);

  //const tableNamesForLoad = useMemo(() => ["pronouns"], []);

  useEffect(() => {
    //დავადგინოთ მიმდინარე იდენტიფიკატორი
    if (currentInflId !== infId || currentIvcId !== ivcId) {
      if (currentInflId !== infId) {
        setCurrentInflId(infId);
      }
      if (currentIvcId !== ivcId) {
        setCurrentIvcId(ivcId);
      }
      if ( ivcId === 0 ){
        //console.log("Paradigm useEffect GetParadigm infId=", infId);
        GetParadigm(infId);
      }
      else {
        //console.log("Paradigm useEffect GetVerbCompositionParadigm ivcId=", ivcId);
        GetVerbCompositionParadigm(ivcId);
      }
    }

  if ( mdWorkingOnLoad || inflectionWorkingOnLoadParadigm || !paradigm ) 
    return;
  
  //console.log("useEffect setSamples=", paradigm.samplePositions);
  setSamples(paradigm.samplePositions)


  }, [infId, ivcId, GetParadigm, GetVerbCompositionParadigm, currentInflId, 
    currentIvcId, mdWorkingOnLoad, inflectionWorkingOnLoadParadigm, paradigm]);

  // useEffect(() => { 
  //   console.log("Paradigm useEffect checkLoadMdTables tableNamesForLoad=", tableNamesForLoad);
  //   checkLoadMdTables(tableNamesForLoad); 
  // }, [checkLoadMdTables, tableNamesForLoad]);

  if ( mdWorkingOnLoad ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
    return (<WaitPage />);
  if ( !pronouns || !paradigm ) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  function handleSample(segmentId,sequentialNumber) {
    //console.log("handleSample {si,wi, samples}=", {si,wi, samples});
    const newSamples = [...samples]
    const sposIndex = newSamples ? newSamples.findIndex(itm => { 
      return itm && itm.segmentId === segmentId && itm.sequentialNumber === sequentialNumber 
    }) : -1;
    if ( sposIndex !== -1 )
      newSamples.splice(sposIndex, 1); 
    else {
      //console.log("handleSample newSamples=", newSamples);
      while (newSamples.length>2) {
        newSamples.shift();
      }
      newSamples.push({segmentId:segmentId, sequentialNumber:sequentialNumber});
    }
    setSamples(newSamples);
  }


  //pronouns ცხრილი უნდა დალაგდეს იდენტიფიკატორებით და შეიქმნას მასივი, რომელშიც შესაძლებელი იქნება ნომრით, როგორც ინდექსით
  //ნაცვალსახელების ამოღება უნდა მოხდეს ამ მასივიდან

  const prons = [];
  pronouns.forEach(e => {
    prons[e.sortId] = e.prnName
  });

  // if ( paradigm )
  //   for(let i = 0; i < paradigm.samplePositions.length; i++)
  //     paradigm.segments[paradigm.samplePositions[i].segmentId].words[paradigm.samplePositions[i].sequentialNumber].sampleNom = i+1;

  //console.log("Paradigm pronouns=", pronouns);
  //console.log("Paradigm prons=", prons);

  let isp = '';
  const ispMorpheme = paradigm.ispMorphemes.find((item) => item.ispMorphemeNom === prdIspMorphemeNom);
  if ( ispMorpheme && ispMorpheme.ispMorphemeNom > 0 )
    isp = ispMorpheme.ispMorpheme



  return (
    <Col md="4" id="inflection-paradigm">
      <div className="paradigm-scroll">
        {inflectionWorkingOnLoadParadigm && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
        {(!paradigm || !paradigm.header) && <h5>პარადიგმა</h5>}

        {user.appClaims.some(s=>s === 'SaveSamples') &&
        <div>

          <Button variant="warning" className="btn-space" onClick={(e) => {
            e.preventDefault();
            setSamples([]);
          }} ><FontAwesomeIcon icon="trash" /> გასუფთავება </Button>

          <Button variant="warning" className="btn-space" onClick={(e) => {
            e.preventDefault();
            setShowSaveSamplePositionsConfirmMessage(true);
          }} ><FontAwesomeIcon icon="file-export" /> შენახვა
            {savingParadigmSamplePositions && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}          
          </Button>

          <MessageBox show={showSaveSamplePositionsConfirmMessage} title="ნიმუშების პოზიციების შენახვა"
                      text={"ნიმუშების ძველი პოზიციების ადგილას მოხდება ახალი პოზიციების შენახვა, მაინც გსურთ ნიმუშების პოზიციების შენახვა?"}
                      primaryButtonText="დიახ" secondaryButtonText="არა" onConfirmed={() => {
                        setShowSaveSamplePositionsConfirmMessage(false);
                        if ( currentIvcId )
                          saveVerbCompositionParadigmSamplePositions(currentIvcId, samples);
                        else
                          saveParadigmSamplePositions(currentInflId, samples);
                      }} onClosed={() => setShowSaveSamplePositionsConfirmMessage(false)} />
        </div>
      }




        {paradigm && paradigm.header && <h5>paradigm.header</h5>}
        {paradigm && paradigm.notes && <p>paradigm.notes</p>}
        {paradigm && paradigm.segments && <ul>{paradigm.segments.map((s,si)=>(
          <li key={`ps${si}`}>
            {s.header && <h6>{s.header}</h6>}

            {s.words && <ol>{s.words.filter((w)=>prdIspMorphemeNom === 0 || w.allowIsp).map((w,wi)=>{ 

              const sposIndex = samples ? samples.findIndex(itm => {
                 return itm && itm.segmentId === s.segmentId && 
                   itm.sequentialNumber === w.sequentialNumber 
                }) : -1;
              //const spos = sposIndex === -1 ? null : paradigm.samplePositions[sposIndex];
              
              const wordView = w.morphemes.join(prdShowhyphens?"-":"") + (prdShowhyphens && !isp.startsWith('-') ?"-":"") + isp;

              return (
              <li key={`ps${si}w${wi}`} className={sposIndex !== -1 ? "backLigth" : "word"}
                onClick={() => handleSample(s.segmentId,w.sequentialNumber)}>
                {wordView}
                {prdShowFormulas && <span>&nbsp;&nbsp;&nbsp;{w.formulaView}</span> }
                {w.pronouns && <span>&nbsp;&nbsp;&nbsp;{w.pronouns.map((p)=>prons[p]).join(" ")}</span> }
                {sposIndex !== -1 && <span>&nbsp;&nbsp;&nbsp;(ნიმუში {sposIndex+1})</span>}
                {w.sampleNom && <span>&nbsp;&nbsp;&nbsp;(ნიმუში {w.sampleNom})</span>}
              </li>);})
            }</ol>}

          </li>))
          }</ul>
        }
      </div>
    </Col>
  );
}



function mapStateToProps(state) {
  const { inflectionWorkingOnLoadParadigm, paradigm, prdShowhyphens, prdShowFormulas, prdIspMorphemeNom, savingParadigmSamplePositions } = state.derivTree;
  const { mdWorkingOnLoad } = state.masterData;
  const {pronouns} = state.masterData.mdRepo;
  const { user } = state.authentication;
  return { inflectionWorkingOnLoadParadigm, paradigm, prdShowhyphens, prdShowFormulas, prdIspMorphemeNom, mdWorkingOnLoad, pronouns, user, savingParadigmSamplePositions };
}

function mapDispatchToProps(dispatch) {
  return {
    GetParadigm: (infId) => dispatch(DerivTreeActions.GetParadigm(infId)),
    GetVerbCompositionParadigm: (ivcId) => dispatch(DerivTreeActions.GetVerbCompositionParadigm(ivcId)),
    saveParadigmSamplePositions: (infId, samples) => dispatch(DerivTreeActions.saveParadigmSamplePositions(infId, samples)),
    saveVerbCompositionParadigmSamplePositions: (ivcId, samples) => dispatch(DerivTreeActions.saveVerbCompositionParadigmSamplePositions(ivcId, samples))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Paradigm);

