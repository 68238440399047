import React from 'react';
import { connect } from 'react-redux';

import NavMenu from '../menu/NavMenu';
import TopNavMenu from '../menu/TopNavMenu';

import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import 'bootstrap/dist/css/bootstrap.css';
import './Layout.css'

const Layout = (props) => {

  const { active } = props;
    return (
      <div className="wrapper">
        <NavMenu />
        <div id="content" className={active ? 'active': null}>
          <TopNavMenu />
          {props.children}
        </div>
      </div>
    );
}

function mapStateToProps(state) {
  const { active } = state.navMenu;
  return { active };
}

export default connect(
  mapStateToProps,
  null
)(Layout);

