//InflectionVerbCompositionEdit.js
import React, {useEffect, useState, useMemo} from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';

import * as yup from 'yup';

import Paradigm from './Paradigm'
import WaitPage from '../../carcass/common/WaitPage';
import { useForman } from '../../carcass/common/MyHooks';
//import { useHistory } from "react-router-dom";
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OnePlaintextRow from '../editorParts/OnePlaintextRow';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import InflectionVerbCompositionPredecessors from './InflectionVerbCompositionPredecessors';
import StatusConfirmRejectPart from './StatusConfirmRejectPart';

import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as RootEditorActions } from './RootEditorStore';


const InflectionVerbCompositionEdit = (props) => {

  //const history = useHistory();
  
  //1. იდენტიფიკატორი
  const [curIvcIdVal, setCurIvcIdVal] = useState(null); 
  const [curInfIdVal, setCurInfIdVal] = useState(null); 
  const [curDbrIdVal, setCurDbrIdVal] = useState(null); 
  const [currentRootId, setCurrentRootId] = useState(null);
  

  //2. კომპონენტის თვისებები
  const { alert, user, masterData, 
    savingInflectionVerbComposition, loadingInflectionVerbComposition, 
    workingOnDeleteInflectionVerbComposition, inflectionVerbCompositionForEdit, 
    getOneInflectionVerbCompositionById, DeleteFailure, rootLoading, checkLoadMdTables, 
    CheckLoadRootsByInflectionVerbCompositionId, clearForConfirmRootsPagesMemo,
    clearTablesFromRepo, clearRoot, clearMemo,
    workingOnConfirmRejectInflectionVerbCompositionChange, confirmRejectFailure,
    confirmRejectInflectionVerbCompositionChange, clearConfirmRejectFailure

  } = props;

  const { mdWorkingOnLoad } = masterData;
  const { pronouns, morphemeRanges, classifiers } = masterData.mdRepo;

  //console.log("InflectionVerbCompositionEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = [];

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["pronouns", "morphemeRanges", "classifiers"], []);
                                                        

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [tableNamesForLoad, checkLoadMdTables]);
                                                      

  // useEffect(() => {
  //   if ( !verbRowParadigmsByVerbTypesLoading && !verbRowParadigmsByVerbTypesLoadingFailure && !verbRowParadigmsByVerbTypes )
  //     GetVerbRowParadigmsByVerbTypes();
  // }, [verbRowParadigmsByVerbTypesLoading, verbRowParadigmsByVerbTypesLoadingFailure, 
  //   verbRowParadigmsByVerbTypes, GetVerbRowParadigmsByVerbTypes]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    inflectionVerbComposition: yup.object().shape({
      ivcId: yup.number().integer().default(0).required(),
      ivcName: yup.string().default(""),
      ivcSamples: yup.string().default(""),
      classifierId: yup.number().integer().default(0)
    }),
    inflectionVerbCompositionPredecessors: yup.array().ensure().of(yup.object().shape({
      parentNom: yup.number().integer().default(0).required(),
      parentInflectionId: yup.number().integer().positive("წინაპარი ზმნა არჩეული უნდა იყოს")
        .required("წინაპარი ზმნა არჩეული უნდა იყოს").default(0),
      lastMorphemeRangeId: yup.number()//.transform((v, o) => o === null ? 0 : v).integer().min(0)
        .when('parentNom', {
          is: (val) => val === 0, 
          then: yup.number().transform((v, o) => o === null ? 0 : v).integer().min(0).default(3), 
          otherwise: yup.number().transform((v, o) => o === null ? 0 : v).integer().min(0).default(0) })
    }))
    // ,
    // formData: yup.object().shape({
    //   ranges: yup.array().ensure(),
    //   morphemes: yup.array().ensure().of(yup.number().integer().positive("მორფემა არჩეული უნდა იყოს").default(0).required())
    // })
  });

  //console.log("InflectionVerbCompositionEdit yupSchema=", yupSchema);
  //console.log("InflectionVerbCompositionEdit yupSchema.fields=", yupSchema.fields);
  //console.log("InflectionVerbCompositionEdit yupSchema.fields.verb=", yupSchema.fields.verb);
  //console.log("InflectionVerbCompositionEdit yupSchema.fields.verb.fields=", yupSchema.fields.verb.fields);
  //console.log("InflectionVerbCompositionEdit yupSchema.fields.verb.fields.keys()=", Object.keys(yupSchema.fields.verb.fields));
  
  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    clearMemo();
    clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
    clearRoot(currentRootId);
    clearForConfirmRootsPagesMemo();
  }

  const fromParamsrootId = props.match.params.rootId;
  const fromParamsDbrId = props.match.params.dbrId;
  const fromParamsInfId = props.match.params.infId;
  const fromParamsIvcId = props.match.params.ivcId;


  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {

    //დავადგინოთ ძირის იდენტიფიკატორი
    const rootIdVal = fromParamsrootId ? parseInt(fromParamsrootId) : 0;
    if (currentRootId !== rootIdVal)
      setCurrentRootId(rootIdVal);

    //დავადგინოთ ძირის იდენტიფიკატორი
    const dbrIdVal = fromParamsDbrId ? parseInt(fromParamsDbrId) : 0;
    if (curDbrIdVal !== dbrIdVal)
      setCurDbrIdVal(dbrIdVal);

      //დავადგინოთ ფლექსიის იდენტიფიკატორი
    const infIdVal = fromParamsInfId ? parseInt(fromParamsInfId) : 0;
    if (curInfIdVal !== infIdVal)
      setCurInfIdVal(infIdVal);

    const ivcIdVal = fromParamsIvcId ? parseInt(fromParamsIvcId) : 0;
      //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curIvcIdVal !== ivcIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurIvcIdVal(ivcIdVal);
      if (ivcIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneInflectionVerbCompositionById(ivcIdVal);
        CheckLoadRootsByInflectionVerbCompositionId(ivcIdVal);
        return;
      }

      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }

    //console.log("InflectionVerbCompositionEdit Check for load");

    //console.log("Before setInflectionType ", {loadingInflectionVerbComposition, infId, inflectionForEdit, mdWorkingOnLoad, 
    //   inflectionBlocks, inflectionTypes, morphemeGroups, morphemeRanges, morphemesQuery, morphemeRangesByInflectionBlocks, rootLoading})
    //console.log("Check for load", {loadingInflectionVerbComposition, fromParamsInfId, 
    //  inflectionVerbCompositionForEdit, mdWorkingOnLoad, pronouns, morphemeRanges, rootLoading});

    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთულიობიექტი მაინც შევამოწმოთ
    if ( loadingInflectionVerbComposition || !fromParamsInfId || 
      !inflectionVerbCompositionForEdit || 
      mdWorkingOnLoad || 
      !pronouns || !morphemeRanges || !classifiers ||
      rootLoading )
      return;

      //console.log("InflectionVerbCompositionEdit inflectionVerbCompositionForEdit=",inflectionVerbCompositionForEdit);

      setFormData(inflectionVerbCompositionForEdit);

  }, [
    pronouns, morphemeRanges, classifiers,
    curIvcIdVal, curInfIdVal, curDbrIdVal, currentRootId,
    inflectionVerbCompositionForEdit, 
    loadingInflectionVerbComposition, mdWorkingOnLoad, 
    fromParamsrootId, fromParamsDbrId, fromParamsInfId, fromParamsIvcId, 
    rootLoading, 
    getOneInflectionVerbCompositionById, clearToDefaults, 
    CheckLoadRootsByInflectionVerbCompositionId, setFormData]);

  //8. ჩატვირთვის შემოწმება
  //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( 
    !pronouns || !morphemeRanges || !classifiers
     ) { 
    if ( loadingInflectionVerbComposition || mdWorkingOnLoad || rootLoading
        ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
      return (<WaitPage />);
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;

//გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
//წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას

    const currentForm = {...frm};
    delete currentForm.formData;

    currentForm.inflectionVerbCompositionPredecessors.forEach((pred, index, arr)=>{
      if ( pred.lastMorphemeRangeId === 0 )
        arr[index].lastMorphemeRangeId = null;
    })

    if ( curIvcIdVal )
      props.updateInflectionVerbComposition(props.history, currentForm, curInfIdVal, curDbrIdVal, currentRootId);
    else
      props.createInflectionVerbComposition(props.history, currentForm, curInfIdVal, curDbrIdVal, currentRootId);
    clearUsedTables();
  }

  //console.log("InflectionVerbCompositionEdit frm=",frm);

  const inflectionVerbCompositionResultName = (frm && frm.inflectionVerbComposition && frm.inflectionVerbComposition.ivcName) ? frm.inflectionVerbComposition.ivcName : "";
  const userHasConfirmRight = user.appClaims.some(s=>s === 'Confirm');
  const allowEditAndDelete = (userHasConfirmRight && frm.inflectionVerbComposition.recordStatusId !== 1 && frm.inflectionVerbComposition.recordStatusId !== 0) || 
    (!userHasConfirmRight && frm.inflectionVerbComposition.recordStatusId !== 1);


  const verbCompositionLastRanges = morphemeRanges
    .filter(f=>f.mrVerbCompositionSelectable )
    .sort((a,b) => a.mrPosition-b.mrPosition)
    .map(function(item) {return {mrId: item.mrId, mrKeyName: `${item.mrKey}-${item.mrName}`}; } );
    
// debugger;

  return (
    <Row className="root-editor-row">
      <Col md="8" className="root-editor-column">

        <div id="root-deriv-tree" className="root-editor-scroll">

          {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
          <Form onSubmit={handleSubmit} >
            <EditorHeader curIdVal={curDbrIdVal} EditorName="ზმნური კომპოზიცია" EditorNameGenitive="ზმნური კომპოზიციის"
              EditedObjectName={inflectionVerbCompositionResultName}
              workingOnDelete={workingOnDeleteInflectionVerbComposition} DeleteFailure={DeleteFailure}
              onDelete={() => {props.deleteInflectionVerbComposition(props.history, curIvcIdVal, curInfIdVal, curDbrIdVal, currentRootId); clearUsedTables();}}
              onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={allowEditAndDelete} />

            { userHasConfirmRight && frm.inflectionVerbComposition.recordStatusId !== undefined && 
              <StatusConfirmRejectPart recordStatusId={frm.inflectionVerbComposition.recordStatusId} creator={frm.inflectionVerbComposition.creator} 
                workingOnConfirmReject={workingOnConfirmRejectInflectionVerbCompositionChange}
                confirmRejectFailure={confirmRejectFailure}
                onConfirmRejectClick={(confirm, withAllDescendants) => {
                  confirmRejectInflectionVerbCompositionChange(props.history, curIvcIdVal, curInfIdVal, curDbrIdVal, currentRootId, confirm, withAllDescendants); 
                  clearUsedTables();
                }} 
                onClearConfirmRejectFailure={() => {clearConfirmRejectFailure();}} /> }

            <OnePlaintextRow controlId="result" label="შედეგი" text={inflectionVerbCompositionResultName} />          

            <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />

              <div>

              <OneComboBoxControl controlId="inflectionVerbComposition.classifierId" 
                  label="კლასიფიკატორი" value={frm.inflectionVerbComposition.classifierId} 
                  dataMember={classifiers} firstItem={{ id: 0, name: "არცერთი" }} 
                  valueMember="clfId" displayMember="clfName" sortByDisplayMember={true} getError={getError} 
                  firstItemIsSelectable 
                  onChangeValue={changeField} />

              <InflectionVerbCompositionPredecessors 
                predecessors={frm.inflectionVerbCompositionPredecessors}
                verbCompositionLastRanges={verbCompositionLastRanges}
                getError={getError}
                onPredecessorChange={(parentNom, item) => {
                  const newForm = {...frm};

                  let predByVerbComposition = newForm.inflectionVerbCompositionPredecessors.find((pred) => pred.parentNom === parentNom);
                  if (item) {
                    if (!predByVerbComposition) {
                      predByVerbComposition = {};
                      predByVerbComposition.parentNom = parentNom;
                      predByVerbComposition.parentInflectionId = item.infId;
                      predByVerbComposition.lastMorphemeRangeId = parentNom ? null : 3;
                      newForm.inflectionVerbCompositionPredecessors.push(predByVerbComposition);
                    } else {
                      predByVerbComposition.parentInflectionId = item.infId;
                    }
                  } else {
                    if (predByVerbComposition) {
                      newForm.inflectionVerbCompositionPredecessors = newForm.inflectionVerbCompositionPredecessors.filter((pred) => pred.parentNom !== parentNom);
                    }
                  }
                  //console.log("InflectionVerbCompositionEdit onPredecessorChange newForm=",newForm);
                  setFormData(newForm);

                }} 
                onPredecessorLastMorphemeRangeIdChange={(parentNom, value) => {
                  //console.log("!!!InflectionVerbCompositionEdit onPredecessorLastMorphemeRangeIdChange {parentNom, value}=",{parentNom, value});
                  const newForm = {...frm};
                  const predecessor = newForm.inflectionVerbCompositionPredecessors.find((pred) => pred.parentNom === parentNom);
                  if (!predecessor)
                    return;
                  predecessor.lastMorphemeRangeId = value;
                  //console.log("!!!InflectionVerbCompositionEdit onPredecessorLastMorphemeRangeIdChange newForm=",newForm);
                  setFormData(newForm);
                }}
/>



              </div>




            <OneSaveCancelButtons curIdVal={curIvcIdVal} haveErrors={haveErrors} savingNow={savingInflectionVerbComposition} onCloseClick={() => { 
              props.clearAlert(); props.history.goBack(); }} allowEdit={allowEditAndDelete} />
            <OneErrorRow allErrors={allErrors} />

          </Form>
        </div>
      </Col>

      <Route path='/inflVerbCompEdit/:ivcId/:infId?/:dbrId?/:rootId?' component={Paradigm} />

    </Row>
  );


}



function mapStateToProps(state) {
  const alert = state.alert;
  const { user } = state.authentication;
  const masterData = state.masterData;
  const { savingInflectionVerbComposition, loadingInflectionVerbComposition, workingOnDeleteInflectionVerbComposition, 
    inflectionVerbCompositionForEdit, getOneInflectionVerbCompositionById, DeleteFailure,
    confirmRejectFailure, workingOnConfirmRejectInflectionVerbCompositionChange } = state.rootEditorStore;
  const { rootLoading
  } = state.derivTree;

  return { alert, user, masterData, savingInflectionVerbComposition, loadingInflectionVerbComposition, workingOnDeleteInflectionVerbComposition, inflectionVerbCompositionForEdit, 
    getOneInflectionVerbCompositionById, DeleteFailure,
    confirmRejectFailure, workingOnConfirmRejectInflectionVerbCompositionChange,
    rootLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),

    getOneInflectionVerbCompositionById: (dbrId) => dispatch(RootEditorActions.getOneInflectionVerbCompositionById(dbrId, true)),
    updateInflectionVerbComposition: (history, InflectionVerbCompositionForSave, infId, dbrId, rootId) => dispatch(RootEditorActions.updateInflectionVerbComposition(history, InflectionVerbCompositionForSave, infId, dbrId, rootId)),
    createInflectionVerbComposition: (history, InflectionVerbCompositionForSave, infId, dbrId, rootId) => dispatch(RootEditorActions.createInflectionVerbComposition(history, InflectionVerbCompositionForSave, infId, dbrId, rootId)),
    deleteInflectionVerbComposition: (history, ivcId, infId, dbrId, rootId) => dispatch(RootEditorActions.deleteInflectionVerbComposition(history, ivcId, infId, dbrId, rootId)),

    clearDeletingFailure: () => dispatch(RootEditorActions.clearDeletingFailure()),
    clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad)),
    clearAlert: () => dispatch(alertActions.clear()),
    clearRoot: (rootId) => dispatch(DerivTreeActions.clearRoot(rootId)),
    clearMemo: () => dispatch(DerivTreeActions.clearMemo()),
    clearForConfirmRootsPagesMemo: () => dispatch(DerivTreeActions.clearForConfirmRootsPagesMemo()),

    CheckLoadRootsByInflectionVerbCompositionId : (infId) => dispatch(DerivTreeActions.CheckLoadRootsByInflectionVerbCompositionId(infId)),

    alertError: (errorMessage) => dispatch(alertActions.error(errorMessage)),

    confirmRejectInflectionVerbCompositionChange: (history, ivcId, infId, dbrId, rootId, confirm, withAllDescendants) => dispatch(RootEditorActions.confirmRejectInflectionVerbCompositionChange(history, ivcId, infId, dbrId, rootId, confirm, withAllDescendants)),

    clearConfirmRejectFailure: () => dispatch(RootEditorActions.clearConfirmRejectFailure()),


  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InflectionVerbCompositionEdit);
