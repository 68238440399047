//myFunctions.js


export function NzInt(value, defValue = 0) {
  try {
    if (value && !isNaN(value))
      return parseInt(value);
  }
  catch(err) {}
  return defValue;
}


// export function lowerNormalizeOneTableName(tableName) {
//   return tableName.charAt(0).toLowerCase() + tableName.slice(1);
// }


export function filterByHeader(record, headerFilter) {
  for(let propertyName in headerFilter) {
    const hf = headerFilter[propertyName];
    if ( !hf && hf !== 0 )
      return true;
    if ( hf !== -1 ) {
      if ( propertyName in record && record[propertyName] !== hf )
        return false;
    }
  }
  return true;}