import React from 'react';
import { Route } from 'react-router-dom';
// import { Nav } from 'react-bootstrap';

import BaseSearchForm from './derivationTreeEditor/BaseSearchForm';
import ParadigmTopNavMenu from './derivationTreeEditor/ParadigmTopNavMenu';
import ForConfirmRootsListTopForm from './derivationTreeEditor/ForConfirmRootsListTopForm';
import ForIssuesTopForm from './issues/ForIssuesTopForm';



const TopNavRoutes = (props) => {
  //console.log("TopNavRoutes props=", props);
  //const infId = props.match && props.match.params ? props.match.params.infId : null;


  return (
    <React.Fragment>
      <Route path='/basesearch' component={BaseSearchForm} />
      <Route path='/root/:rootId/:dbrId?' component={BaseSearchForm} />
      <Route path='/root/:rootId/:dbrId?/:infId?/:ivcId?' component={ParadigmTopNavMenu} />
      <Route path='/inflEdit/:infId/:dbrId?/:rootId?' component={ParadigmTopNavMenu} />
      <Route path='/inflVerbCompEdit/:ivcId/:infId?/:dbrId?/:rootId?' component={ParadigmTopNavMenu} />
      <Route path='/forConfirmRootsList' component={ForConfirmRootsListTopForm} />
      <Route path='/issues' component={ForIssuesTopForm} />
    </React.Fragment>      
  );
}

export default TopNavRoutes;


// { !!currentInflId && 
//   <Route path='/root/:rootId/:dbrId?/:infId?'><ParadigmTopNavMenu} />
// }
// { !!currentInflId && 
//   <Route path='/inflEdit/:infId/:dbrId?/:rootId?'><ParadigmTopNavMenu} />
// }
