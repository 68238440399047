import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect, Link, useLocation } from 'react-router-dom'
import { Button, Spinner, Form, Alert } from 'react-bootstrap';
import * as yup from 'yup'; // for everything // or //import { string, object } from 'yup'; // for only what you need
import { useForman } from './MyHooks';
import { actionCreators as userActions } from '../user/UserStore';
import { actionCreators as paramsActions } from '../store/ParamsStore';
//import OneTextControl from '../editorParts/OneTextControl';
import RegTextControl from '../editorParts/RegTextControl';

import { ProjectContext } from '../../project/ProjectProvider';

import './LoginPage.css';

const LoginPage = (props) => {

  //console.log("LoginPage props=", props);

  const { loggingIn, user, alert, setBaseUrl, logout } = props;
  const [submitted, setSubmitted] = useState(false);
  const { appName, baseUrl } = useContext(ProjectContext);

  //const history = useHistory();
  const location = useLocation();



  useEffect(() => {
    setBaseUrl(baseUrl);
    logout();
  }, [baseUrl, setBaseUrl, logout]);

  useEffect(() => {
    if ( ! loggingIn && ! user && submitted)
      setSubmitted(false);
  }, [loggingIn, user, submitted]);
  

  const schema = yup.object().shape({
    username: yup.string().required("მომხმარებლის სახელი შევსებული უნდა იყოს").max(256, "მომხმარებლის სახელის სიგრძე არ შეიძლება იყოს 256 სიმბოლოზე მეტი"),
    password: yup.string().required("პაროლი შევსებული უნდა იყოს")
  });

  const [frm, changeField, getError, haveErrors, 
    // eslint-disable-next-line
    clearToDefaults, 
    // eslint-disable-next-line
    setFormData
    ] = useForman(schema);

  function handleSubmit(e) {
    e.preventDefault();
    if (haveErrors())
      return;
    setSubmitted(true);
    //const { username, password } = formState.frm;
    const { username, password } = frm;
    frm.password = "";
    if (username && password) {
      props.login({ username, password });
    }
  }

  let { from } = location.state || { from: { pathname: "/" } };

  if (submitted && user) {
    //console.log("LoginPage submitted && user from=", from);
    return <Redirect to={from} />;
  }

  return (
    <div id="LoginPage">
      <div className="login-wrapper fadeInDown">
        <div id="formContent">
          {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
          <h2>{appName}</h2>
          <Form name="form" onSubmit={handleSubmit}>

            <RegTextControl controlId="username" label="მომხმარებელი" value={frm.username} 
              getError={getError} onChangeValue={changeField} />

            <RegTextControl controlId="password" label="პაროლი" value={frm.password} 
              getError={getError} onChangeValue={changeField} />

            <Button type="submit" className="fadeIn fourth">
              <FontAwesomeIcon icon="sign-in-alt" /> ავტორიზაცია
              {loggingIn && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            </Button>
            <Link to="/registration">რეგისტრაცია</Link>
          </Form>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { loggingIn, user } = state.authentication;
  const alert = state.alert;
  return { loggingIn, user, alert };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout()),
    login: (user) => dispatch(userActions.login(user)),
    setBaseUrl: (baseUrl) => dispatch(paramsActions.setBaseUrl(baseUrl))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);



