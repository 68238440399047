//OneStrongLabel.js
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const OneStrongLabel = (props) => {

  const { controlId, label } = props;

  return (
    <Form.Group className="mb-1" as={Row} controlId={controlId}>
      <Col sm="10">
        <Form.Label><strong>{label}</strong></Form.Label>
      </Col>
    </Form.Group>
  );
}

export default OneStrongLabel;