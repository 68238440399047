import React, {useEffect, useState, useReducer, useCallback} from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Col, Row, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from '../common/Loading';

import CybCheckBox from './CybCheckBox'
import WaitPage from '../common/WaitPage';

import { actionCreators as RightsActions, getChildrenDataTypes, createOneRight } from './RightsStore';

import './FrmRights.css'

const FrmRights = (props) => {

  // const [noData, setNoData] = useState(true);
  const [curRView, setCurRView] = useState(null);
  const [curChildViewKey, setCurChildViewKey] = useState(null);
  const [curChildChecksKey, setCurChildChecksKey] = useState(null);
  const [curParentDtKey, setCurParentDtKey] = useState(null);
  const [curKey, setCurKey] = useState(null);
  const [wasSaving, setWasSaving] = useState(false);
  const [selectedChildKey, setSelectedChildKey] = useState(null);
  const { isMenuLoading, flatMenu, drParentsLoading, drParentsRepo, drChildsLoading, drChildsRepo, drChecksLoading, drChecksRepo, 
    changedRights, drWithCodes, drLinear, drWorkingOnSave, searchText, setSelectedChildDataType, LoadChildsTreeDataAndChecks } = props;
  //console.log("FrmRights props=", props);
  
  const menLinkKey = props.match.url.split('/')[1];
  const {rView, dtKey, key} = props.match.params;

  const [expandedState, dispatchExpandedState] = useReducer((expandedState, action) => {
    const { type, payload } = action;
    switch (type) {
    case "turnExpanded": {
      const { forParent, nodeKey, expanded } = payload;
      const newExp = forParent ? expandedState.parExp : expandedState.chiExp;
      newExp[nodeKey] = expanded; 
      if ( forParent )
        return { ...expandedState, parExp: newExp };
      return { ...expandedState, chiExp: newExp };
    }
    default:
      throw new Error();
    }
  }, { parExp: [], chiExp: [] });


  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.some(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);



  useEffect(() => {

    if ( ! isValidPage() )
      return;

    const rViewKey = rView ? rView : "NormalView";
    const childViewKey = rViewKey + dtKey?dtKey:"";
    const childChecksKey = childViewKey + key?key:"";

    let sameKeiesChanged = false;
    if (curRView !== rViewKey) {
      setCurRView(rViewKey);
      sameKeiesChanged = true;
    }
    if (curChildViewKey !== childViewKey) {
      setCurChildViewKey(childViewKey);
      sameKeiesChanged = true;
    }

    let afterSave = false;
    if (wasSaving !== drWorkingOnSave) {
      setWasSaving(drWorkingOnSave);
      if (!drWorkingOnSave) {
        sameKeiesChanged = true;
        afterSave = true;
      }
    }

    if (curChildChecksKey !== childChecksKey || afterSave) {
      setCurChildChecksKey(childChecksKey);
      sameKeiesChanged = true;
    }

    if (curParentDtKey !== dtKey) {
      setCurParentDtKey(dtKey);
    }

    if (curKey !== key) {
      setCurKey(key);
      setSelectedChildKey(null);
      setSelectedChildDataType(null);
    }
      
    if (sameKeiesChanged) {
      LoadChildsTreeDataAndChecks(rViewKey, dtKey, key);
      // setNoData(false);
    }

  }, [isMenuLoading, flatMenu, isValidPage, setSelectedChildDataType, LoadChildsTreeDataAndChecks, rView, dtKey, key, curChildChecksKey, curChildViewKey, curKey, curParentDtKey, curRView, drWorkingOnSave, wasSaving]);


  function wrapText(text) {
    if (! searchText)
      return (<span>{text}</span>);
    const wparts = text.split(searchText);
    const strongpart = (<strong>{searchText}</strong>);
    return wparts.map((itm, indx) => {
      if (indx === 0)
        return (<span key={indx}>{itm}</span>);
      return (<span key={indx}>{strongpart}{itm}</span>);
    });
  }


  //console.log("FrmRights expandedState=", expandedState);
  //console.log("FrmRights { drParentsLoading, curRView, curParentDtKey, drParentsRepo }=", { drParentsLoading, curRView, curParentDtKey, drParentsRepo });

  if (!curRView || !drParentsRepo || !flatMenu ) {
    if (drParentsLoading || isMenuLoading ) {
      return (<Loading />);
    }
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }





  if ( ! isValidPage() ) {
    return (
      <div>
        <h5>ინფორმაციის ჩატვირთვა ვერ მოხერხდა</h5>
      </div>
    );
  }


  if (!drParentsRepo[curRView]) {
    return (
      <div>
        <h5>უფლებების ჩატვირთვა ვერ მოხერხდა</h5>
      </div>
    );
  }

  function getTopParentDataType(dataType) {
    let retDataType = dataType;
    if (!drLinear && curRView === "NormalView") {
      while (retDataType.dtParentDataTypeId) {
        const dtParentDataTypeId = retDataType.dtParentDataTypeId;
        retDataType = drChildsRepo[curRView][curParentDtKey].dataTypes.find((f)=>f.dtId === dtParentDataTypeId);
      }
    }
    return retDataType;
  }

  function getNextSiblingExpKey(dataType, ivalue) {
    const curIndex = dataType.returnValues.findIndex((fi)=>fi.ivalue === ivalue);
    if (curIndex < dataType.returnValues.length -1)
      return dataType.dtKey + dataType.returnValues[curIndex + 1].ivalue.toString();
    return null;
  }


  function getDataList(dataType, parentKey, parentValue) {

    //console.log("FrmRights getDataList {drChildsRepo, dataType, drLinear, curRView, curParentDtKey}=", { drChildsRepo, dataType, drLinear, curRView, curParentDtKey });
    const childrenDataTypes = getChildrenDataTypes(drChildsRepo, dataType, drLinear, curRView, curParentDtKey);
    //console.log("FrmRights getDataList childrenDataTypes=", childrenDataTypes);

    return (
      <ul key={parentKey} className={"tree list-unstyled collapse" + (expandedState.chiExp[parentKey] ? " show" : "")} >
        {(!dataType.returnValues || dataType.returnValues.length === 0) && <span>დეტალების ჩატვირთვა ვერ მოხერდა</span>}
        {dataType.returnValues && dataType.returnValues.length > 0 && dataType.returnValues
          .filter((f)=> parentValue === null || f.parentId === parentValue)
          .sort((a,b) => (!!a.name?a.name:"").localeCompare(!!b.name?b.name:""))
          .map((itm, ind) => {
          const expKey = dataType.dtKey + itm.ivalue.toString();

          let childrenCount = 0;
          childrenDataTypes.forEach((fe) => {
            childrenCount += fe.returnValues.filter((f) => f.parentId === itm.ivalue).length;
          });

          const oneRight = createOneRight(drParentsRepo, curRView, curParentDtKey, curKey, dataType, itm);

          const findedRight = changedRights.find((f) => f.parent.dtId === oneRight.parent.dtId &&
            f.parent.dKey === oneRight.parent.dKey &&
            f.child.dtId === oneRight.child.dtId &&
            f.child.dKey === oneRight.child.dKey);

          const checked = (!findedRight)
            ? (drChecksRepo[curRView][curParentDtKey][curKey] &&
              drChecksRepo[curRView][curParentDtKey][curKey].rights.find(
                (drf) => drf.dtId === dataType.dtId && drf.dKey === itm.key)
              ? true
              : false)
            : findedRight.checked;

          return (
            <li key={expKey}>
              <span>
                { childrenCount > 0 && (<FontAwesomeIcon icon={(expandedState.chiExp[expKey] ? "minus-square" : "plus-square")} onClick={() => {
                const expanded = expandedState.chiExp[expKey] ? false : true;
                dispatchExpandedState( { type: "turnExpanded", payload: { forParent: false, nodeKey: expKey, expanded } } );
              }} />)} 
                <span className={childrenCount > 0 ? "padleftwithplus" : "padleft"} />

                <CybCheckBox checked={checked} labelSelected={expKey === selectedChildKey} labelText={`${drWithCodes ? `${itm.key}-` : ""}${itm.name}`} onChange={(e, usedSpace) => {
                  //console.log("FrmRights getDataList CybCheckBox onChange e.target.checked=", e.target.checked);
                  oneRight.checked = e.target.checked;
                  props.addOneRightAndChildren(dataType, oneRight, curRView, curParentDtKey);
                  if (usedSpace) {
                    const nextExpKey = getNextSiblingExpKey(dataType, itm.ivalue);
                    if (nextExpKey) {
                      setSelectedChildKey(nextExpKey);
                      props.setSelectedChildDataType(getTopParentDataType(dataType));
                    }
                  }
                }} onLabelClick={() => {
                  //console.log("FrmRights getDataList CybCheckBox onLabelClick");
                  setSelectedChildKey(expKey);
                  props.setSelectedChildDataType(getTopParentDataType(dataType));
                }} />

                </span>

              { childrenDataTypes.map((chdt) => {
                return getDataList(chdt, expKey, itm.ivalue);
              })  }

            </li>

          );
        })}
      </ul>
    );
  }

  function getChildsRender() {
    if (drChildsLoading || drChecksLoading)
      return (<WaitPage />);

    if (!drChildsRepo[curRView] || !drChildsRepo[curRView][curParentDtKey] || !drChecksRepo[curRView] || !drChecksRepo[curRView][curParentDtKey]) {
      return (
        <div>
        <h5>აირჩიეთ უფლების მშობელი</h5>
      </div>
      );
    }

    const zeroLevelDataTypes = drChildsRepo[curRView][curParentDtKey].dataTypes.filter((w) => (
      drLinear ||
      curRView === "ReverseView" ||
      w.dtParentDataTypeId === null ||
      w.dtId === w.dtParentDataTypeId));



      
    return (
      <Form>
        <div id="data-rights-tree" className="root-editor-scroll">
          <ul className="list-unstyled">
            {zeroLevelDataTypes.map((item, index) => {
              return (

                <li key={item.dtId}>
                  <span onClick={() => {
                    const expanded = expandedState.chiExp[item.dtKey] ? false : true;
                    dispatchExpandedState( { type: "turnExpanded", payload: { forParent: false, nodeKey: item.dtKey, expanded } } );
                  }}><FontAwesomeIcon icon={(expandedState.chiExp[item.dtKey]?"minus-square":"plus-square")} /></span>
                  <span className={(item.dtKey === selectedChildKey ? "backLigth" : "")} onClick={() => {
                    setSelectedChildKey(item.dtKey);
                    props.setSelectedChildDataType(item);
                  }}> {drWithCodes?`${item.dtKey}-`:""}{item.dtName}</span>

                  {getDataList(item, item.dtKey, null)}

                </li>
              ); })}
          </ul>
        </div>
      </Form>
    );

  }

  function getExpByKey(dtKey) {
    let stateExp = expandedState.parExp[dtKey];
    let exp = stateExp;
    if (dtKey === curParentDtKey && stateExp !== true && stateExp !== false )
      exp = true;
    return exp;
  }

  return (
    <div>
      <Row className="root-editor-row">
      <Col sm="5" className="root-editor-column">
        <div id="data-rights-tree" className="root-editor-scroll">
          <ul className="list-unstyled">
            {drParentsRepo[curRView].dataTypes.map((item, index) => {
              const exp = getExpByKey(item.dtKey);
              return (
                <li key={item.dtId} onClick={() => {      
                  setSelectedChildKey(null);
                  props.setSelectedChildDataType(null);
                }}>
                  <span onClick={() => {
                  const expanded = !getExpByKey(item.dtKey);
                  dispatchExpandedState( { type: "turnExpanded", payload: { forParent: true, nodeKey: item.dtKey, expanded } } );
                }}><FontAwesomeIcon icon={(exp?"minus-square":"plus-square")} /> {wrapText(`${drWithCodes?`${item.dtKey}-`:""}${item.dtName}`)}</span>
                  <ul className={"tree list-unstyled collapse" + (exp?" show":"")} >
                    {item.returnValues
                    .sort((a,b) => (!!a.name?a.name:"").localeCompare(!!b.name?b.name:""))
                    .map((itm, ind) => {
                      return (
                        <li key={itm.ivalue} onClick={() => {      
                          setSelectedChildKey(null);
                          props.setSelectedChildDataType(null);
                        }}>
                          <Link  className={(itm.key === curKey ? "backLigth" : "")} 
                            to={`/Rights/${curRView}/${item.dtKey}/${itm.key}`}>{wrapText(`${drWithCodes?`${itm.key}-`:""}${itm.name}`)}</Link>
                        </li>
                      ); })}
                  </ul>
                </li>
              ); })}
          </ul>
        </div>
      </Col>
      <Col sm="5">
        {getChildsRender()}
      </Col>
      </Row>
    </div>
  );

}




function mapStateToProps(state) {
  const { drParentsLoading, drParentsRepo, drChildsLoading, drChildsRepo, drChecksLoading, drChecksRepo, changedRights, 
    drWithCodes, drLinear, drWorkingOnSave, searchText
  } = state.rights;
  const { isMenuLoading, flatMenu } = state.navMenu;

  return { isMenuLoading, flatMenu, drParentsLoading, drParentsRepo, drChildsLoading, drChildsRepo, drChecksLoading, 
    drChecksRepo, changedRights, drWithCodes, drLinear, drWorkingOnSave, searchText
  };
}

function mapDispatchToProps(dispatch) {
  return {
    LoadChildsTreeData: (rView, dtKey) => dispatch(RightsActions.LoadChildsTreeData(rView, dtKey)),
    LoadChildsTreeDataAndChecks: (rView, dtKey, key) => dispatch(RightsActions.LoadChildsTreeDataAndChecks(rView, dtKey, key, false)),
    addOneRightAndChildren: (dataType, oneRight, curRView, curParentDtKey) => dispatch(RightsActions.addOneRightAndChildren(dataType, oneRight, curRView, curParentDtKey)),
    setSelectedChildDataType: (dataType) => dispatch(RightsActions.setSelectedChildDataType(dataType))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrmRights);
