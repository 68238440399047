//RootEditorStore.js
import apiClient from '../../carcass/modules/ApiClient';
//import { history } from '../../carcass/common/History';
//import { useHistory } from "react-router-dom";


import { funAfterSaveBranch, funAfterSaveInflection, funAfterSaveInflectionVerbComposition } from './DerivationTreeStore'

const clearDeleteFailure = 'CLEAR_DELETE_FAILURE';
const clearConfirmRejectFailure = 'CLEAR_CONFIRM_REJECT_FAILURE';

//Roots
const requestLoadRoot = 'REQUEST_LOAD_ROOT';
const setRoot = 'SET_ROOT';
const getRootFailure = 'GET_ROOT_FAILURE';

const startSavingRoot = 'START_SAVING_ROOT';
const updateRootSuccess = 'UPDATE_ROOT_SUCCESS';
const updateRootFailure = 'UPDATE_ROOT_FAILURE';
const createRootSuccess = 'CREATE_ROOT_SUCCESS';
const createRootFailure = 'CREATE_ROOT_FAILURE'

const requestDeleteRoot = 'REQUEST_DELETE_ROOT';
const deleteRootSuccess = 'DELETE_ROOT_SUCCESS';
const deleteRootFailure = 'DELETE_ROOT_FAILURE';

const requestConfirmRejectRootChange = 'REQUEST_CONFIRM_REJECT_ROOT';
const confirmRejectRootChangeSuccess = 'CONFIRM_REJECT_ROOT_SUCCESS';
const confirmRejectRootChangeFailure = 'CONFIRM_REJECT_ROOT_FAILURE';

//DerivationBranchs
const requestLoadDerivationBranch = 'REQUEST_LOAD_DERIVATION_BRANCH';
const setDerivationBranch = 'SET_DERIVATION_BRANCH';
const getDerivationBranchFailure = 'GET_DERIVATION_BRANCH_FAILURE';

const startSavingDerivationBranch = 'START_SAVING_DERIVATION_BRANCH';
const updateDerivationBranchSuccess = 'UPDATE_DERIVATION_BRANCH_SUCCESS';
const updateDerivationBranchFailure = 'UPDATE_DERIVATION_BRANCH_FAILURE';
const createDerivationBranchSuccess = 'CREATE_DERIVATION_BRANCH_SUCCESS';
const createDerivationBranchFailure = 'CREATE_DERIVATION_BRANCH_FAILURE'

const requestDeleteDerivationBranch = 'REQUEST_DELETE_DERIVATION_BRANCH';
const deleteDerivationBranchSuccess = 'DELETE_DERIVATION_BRANCH_SUCCESS';
const deleteDerivationBranchFailure = 'DELETE_DERIVATION_BRANCH_FAILURE';

const requestConfirmRejectDerivationBranchChange = 'REQUEST_CONFIRM_REJECT_DERIVATION_BRANCH';
const confirmRejectDerivationBranchChangeSuccess = 'CONFIRM_REJECT_DERIVATION_BRANCH_SUCCESS';
const confirmRejectDerivationBranchChangeFailure = 'CONFIRM_REJECT_DERIVATION_BRANCH_FAILURE';


//Inflections
const requestLoadInflection = 'REQUEST_LOAD_INFLECTION';
const setInflection = 'SET_INFLECTION';
const getInflectionFailure = 'GET_INFLECTION_FAILURE';

const startSavingInflection = 'START_SAVING_INFLECTION';
const updateInflectionSuccess = 'UPDATE_INFLECTION_SUCCESS';
const updateInflectionFailure = 'UPDATE_INFLECTION_FAILURE';

const createInflectionSuccess = 'CREATE_INFLECTION_SUCCESS';
const createInflectionFailure = 'CREATE_INFLECTION_FAILURE'

const requestDeleteInflection = 'REQUEST_DELETE_INFLECTION';
const deleteInflectionSuccess = 'DELETE_INFLECTION_SUCCESS';
const deleteInflectionFailure = 'DELETE_INFLECTION_FAILURE';

const requestConfirmRejectInflectionChange = 'REQUEST_CONFIRM_REJECT_INFLECTION';
const confirmRejectInflectionChangeSuccess = 'CONFIRM_REJECT_INFLECTION_SUCCESS';
const confirmRejectInflectionChangeFailure = 'CONFIRM_REJECT_INFLECTION_FAILURE';


//InflectionVerbCompositions
const requestLoadInflectionVerbComposition = 'REQUEST_LOAD_INFLECTION_VERB_COMPOSITION';
const setInflectionVerbComposition = 'SET_INFLECTION_VERB_COMPOSITION';
const getInflectionVerbCompositionFailure = 'GET_INFLECTION_VERB_COMPOSITION_FAILURE';

const startSavingInflectionVerbComposition = 'START_SAVING_INFLECTION_VERB_COMPOSITION';
const updateInflectionVerbCompositionSuccess = 'UPDATE_INFLECTION_VERB_COMPOSITION_SUCCESS';
const updateInflectionVerbCompositionFailure = 'UPDATE_INFLECTION_VERB_COMPOSITION_FAILURE';

const createInflectionVerbCompositionSuccess = 'CREATE_INFLECTION_VERB_COMPOSITION_SUCCESS';
const createInflectionVerbCompositionFailure = 'CREATE_INFLECTION_VERB_COMPOSITION_FAILURE'

const requestDeleteInflectionVerbComposition = 'REQUEST_DELETE_INFLECTION_VERB_COMPOSITION';
const deleteInflectionVerbCompositionSuccess = 'DELETE_INFLECTION_VERB_COMPOSITION_SUCCESS';
const deleteInflectionVerbCompositionFailure = 'DELETE_INFLECTION_VERB_COMPOSITION_FAILURE';

const requestConfirmRejectInflectionVerbCompositionChange = 'REQUEST_CONFIRM_REJECT_INFLECTION_VERB_COMPOSITION';
const confirmRejectInflectionVerbCompositionChangeSuccess = 'CONFIRM_REJECT_INFLECTION_VERB_COMPOSITION_SUCCESS';
const confirmRejectInflectionVerbCompositionChangeFailure = 'CONFIRM_REJECT_INFLECTION_VERB_COMPOSITION_FAILURE';



const initialState = { 
    DeleteFailure: false, 
    confirmRejectFailure: false, 
    savingRoot: false, loadingRoot: false, workingOnDeleteRoot: false, rootForEdit: null, duplicateRootId: null,
    savingDerivationBranch: false, loadingDerivationBranch: false, workingOnDeleteDerivationBranch: false, derivationBranchForEdit: null, duplicateDerivationBranchId: null,
    savingInflection: false, loadingInflection: false, workingOnDeleteInflection: false, inflectionForEdit: null, duplicateInflectionId: null,
    savingInflectionVerbComposition: false, loadingInflectionVerbComposition: false, workingOnDeleteInflectionVerbComposition: false, inflectionVerbCompositionForEdit: null, duplicateInflectionVerbCompositionId: null,
    workingOnConfirmRejectRootChange: false,
    workingOnConfirmRejectDerivationBranchChange: false,
    workingOnConfirmRejectInflectionChange: false,
    workingOnConfirmRejectInflectionVerbCompositionChange: false
  };
  
  

  export const actionCreators = {

    clearDeletingFailure: () => {
      return { type: clearDeleteFailure };
    },

    clearConfirmRejectFailure: () => {
      return { type: clearConfirmRejectFailure };
    },
  
    //Roots
    getOneRootById: (rootId) => async (dispatch, getState) => {
      //console.log('RootEditorStore.js apiClient.Get', "/roots/root/${phtId}");
      await apiClient.Get(`/roots/root/${rootId}`,
        { get: requestLoadRoot, 
          set: setRoot, 
          failure: getRootFailure },
        dispatch, getState, true );
    },
  
    createRoot: (history, root) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js createRoot root=', root);
    //   const rootName = root.root.rootName;
      if (await apiClient.Post('/roots/root',
        { get: startSavingRoot, set: createRootSuccess, failure: createRootFailure },
        dispatch, getState, true, null, true, root)){
          const rootId = getState().rootEditorStore.rootForEdit.root.rootId;
          history.push(`/root/${rootId}`);
        }
    },
  
    updateRoot: (history, root) => async (dispatch, getState) => {
      // const history = useHistory();
      const rootId = root.root.rootId;
    //   const phtName = root.root.phtName;
      //console.log('RootEditorStore.js apiClient.Put', `/roots/root/${phtId}`);
      if (await apiClient.Put(`/roots/root/${rootId}`,
        { get: startSavingRoot, set: updateRootSuccess, failure: updateRootFailure },
        dispatch, getState, true, root, root))
      {
        const duplicateRootId = getState().rootEditorStore.duplicateRootId;
        if ( duplicateRootId )
          history.push(`/root/${duplicateRootId}`);
        else
          history.push(`/root/${rootId}`);
      }
    },
  
    deleteRoot: (history, rootId) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js apiClient.Delete', `/roots/root/${rootId}`);
      if (await apiClient.Delete(`/roots/root/${rootId}`,
        { get: requestDeleteRoot, set: deleteRootSuccess, failure: deleteRootFailure },
        dispatch, getState, true, rootId)) {
        history.push(`/root/${rootId}`);
      }
    },
  
    confirmRejectRootChange: (history, rootId, confirm, withAllDescendants) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js apiClient.confirmRejectRootChange', `/roots/root/${rootId}/${confirm}/${withAllDescendants}`);
      if (await apiClient.Patch(`/roots/root/${rootId}/${confirm}/${withAllDescendants}`,
        { get: requestConfirmRejectRootChange, set: confirmRejectRootChangeSuccess, failure: confirmRejectRootChangeFailure },
        dispatch, getState, true, rootId)) {
          history.push(`/root/${rootId}`);
        }
    },
  

    //DerivationBranchs
    getOneDerivationBranchById: (dbrId) => async (dispatch, getState) => {
      //console.log('RootEditorStore.js apiClient.Get', "/roots/derivation/${phtId}");
      await apiClient.Get(`/roots/derivation/${dbrId}`,
        { get: requestLoadDerivationBranch, 
          set: setDerivationBranch, 
          failure: getDerivationBranchFailure },
        dispatch, getState, true );
    },
  
    createDerivationBranch: (history, derivationBranch, rootId) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js createDerivationBranch derivationBranch=', derivationBranch);
      if (await apiClient.Post('/roots/derivation',
        { get: startSavingDerivationBranch, set: createDerivationBranchSuccess, failure: createDerivationBranchFailure },
        dispatch, getState, true, null, true, derivationBranch)){
          const dbrId = getState().rootEditorStore.derivationBranchForEdit.derivationBranch.dbrId;
          await funAfterSaveBranch(history, dispatch, getState, rootId, dbrId);
        }
    },
  
    updateDerivationBranch: (history, derivationBranch, rootId) => async (dispatch, getState) => {
      //const history = useHistory();
      const dbrId = derivationBranch.derivationBranch.dbrId;
      //console.log('RootEditorStore.js apiClient.Put', `/roots/derivation/${dbrId}`);
      if (await apiClient.Put(`/roots/derivation/${dbrId}`,
        { get: startSavingDerivationBranch, set: updateDerivationBranchSuccess, failure: updateDerivationBranchFailure },
        dispatch, getState, true, derivationBranch, derivationBranch))
        await funAfterSaveBranch(history, dispatch, getState, rootId, dbrId);
    },
  
    deleteDerivationBranch: (history, dbrId, rootId) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js apiClient.Delete', `/roots/derivation/${dbrId}`);
      if (await apiClient.Delete(`/roots/derivation/${dbrId}`,
        { get: requestDeleteDerivationBranch, set: deleteDerivationBranchSuccess, failure: deleteDerivationBranchFailure },
        dispatch, getState, true, dbrId)) {
          await funAfterSaveBranch(history, dispatch, getState, rootId, null);
        }
    },
  
    confirmRejectDerivationBranchChange: (history, dbrId, rootId, confirm, withAllDescendants) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js apiClient.confirmRejectDerivationBranchChange', `/roots/derivation/${dbrId}/${confirm}/${withAllDescendants}`);
      if (await apiClient.Patch(`/roots/derivation/${dbrId}/${confirm}/${withAllDescendants}`,
        { get: requestConfirmRejectDerivationBranchChange, set: confirmRejectDerivationBranchChangeSuccess, failure: confirmRejectDerivationBranchChangeFailure },
        dispatch, getState, true, rootId)) {
          await funAfterSaveBranch(history, dispatch, getState, rootId, dbrId);
        }
    },
  
  
    //Inflections
    getOneInflectionById: (infId) => async (dispatch, getState) => {
      //console.log('RootEditorStore.js apiClient.Get', "/roots/inflection/${infId}");
      await apiClient.Get(`/roots/inflection/${infId}`,
        { get: requestLoadInflection, 
          set: setInflection, 
          failure: getInflectionFailure },
        dispatch, getState, true );
    },
  
    createInflection: (history, inflection, dbrId, rootId) => async (dispatch, getState) => {
      //console.log('RootEditorStore.js createInflection inflection=', inflection);
      //const history = useHistory();

      if (await apiClient.Post('/roots/inflection',
        { get: startSavingInflection, set: createInflectionSuccess, failure: createInflectionFailure },
        dispatch, getState, true, null, true, inflection)){
          const infId = getState().rootEditorStore.inflectionForEdit.inflection.infId;
          await funAfterSaveInflection(history, dispatch, getState, rootId, dbrId, infId);
        }
    },
  
    updateInflection: (history, inflection, dbrId, rootId) => async (dispatch, getState) => {
      //const history = useHistory();
      const infId = inflection.inflection.infId;
      //console.log('RootEditorStore.js apiClient.Put', `/roots/inflection/${infId}`);
      if (await apiClient.Put(`/roots/inflection/${infId}`,
        { get: startSavingInflection, set: updateInflectionSuccess, failure: updateInflectionFailure },
        dispatch, getState, true, inflection, inflection))
        await funAfterSaveInflection(history, dispatch, getState, rootId, dbrId, infId);
    },
  
    deleteInflection: (history, infId, dbrId, rootId) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js apiClient.Delete', `/roots/inflection/${infId}`);
      if (await apiClient.Delete(`/roots/inflection/${infId}`,
        { get: requestDeleteInflection, set: deleteInflectionSuccess, failure: deleteInflectionFailure },
        dispatch, getState, true, infId)) {
          await funAfterSaveInflection(history, dispatch, getState, rootId, dbrId, null);
        }
    },
  
    confirmRejectInflectionChange: (history, infId, dbrId, rootId, confirm, withAllDescendants) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js apiClient.confirmRejectInflectionChange', `/roots/inflection/${infId}/${confirm}/${withAllDescendants}`);
      if (await apiClient.Patch(`/roots/inflection/${infId}/${confirm}/${withAllDescendants}`,
        { get: requestConfirmRejectInflectionChange, set: confirmRejectInflectionChangeSuccess, failure: confirmRejectInflectionChangeFailure },
        dispatch, getState, true, rootId)) {
          await funAfterSaveInflection(history, dispatch, getState, rootId, dbrId, infId);
        }
    },
  
  







  
  
    //InflectionVerbCompositions
    getOneInflectionVerbCompositionById: (ivcId) => async (dispatch, getState) => {
      //console.log('RootEditorStore.js apiClient.Get', "/roots/inflectionverbcomposition/${ivcId}");
      await apiClient.Get(`/roots/inflectionverbcomposition/${ivcId}`,
        { get: requestLoadInflectionVerbComposition, 
          set: setInflectionVerbComposition, 
          failure: getInflectionVerbCompositionFailure },
        dispatch, getState, true );
    },
  
    createInflectionVerbComposition: (history, inflectionVerbComposition, infId, dbrId, rootId) => async (dispatch, getState) => {
      //console.log('RootEditorStore.js createInflectionVerbComposition inflectionVerbComposition=', inflectionVerbComposition);
      //const history = useHistory();

      if (await apiClient.Post('/roots/inflectionverbcomposition',
        { 
          get: startSavingInflectionVerbComposition, 
          set: createInflectionVerbCompositionSuccess, 
          failure: createInflectionVerbCompositionFailure 
        },
        dispatch, getState, true, null, true, inflectionVerbComposition)){
          const ivcId = getState().rootEditorStore.inflectionVerbCompositionForEdit.inflectionVerbComposition.ivcId;
          await funAfterSaveInflectionVerbComposition(history, dispatch, getState, rootId, dbrId, infId, ivcId);
        }
    },
  
    updateInflectionVerbComposition: (history, inflectionVerbComposition, infId, dbrId, rootId) => async (dispatch, getState) => {
      //const history = useHistory();
      const ivcId = inflectionVerbComposition.inflectionVerbComposition.ivcId;
      //console.log('RootEditorStore.js updateInflectionVerbComposition inflectionVerbComposition=', inflectionVerbComposition);
      //console.log('RootEditorStore.js apiClient.Put', `/roots/inflection/${infId}`);
      if (await apiClient.Put(`/roots/inflectionverbcomposition/${ivcId}`,
        { 
          get: startSavingInflectionVerbComposition, 
          set: updateInflectionVerbCompositionSuccess, 
          failure: updateInflectionVerbCompositionFailure },
        dispatch, getState, true, inflectionVerbComposition, inflectionVerbComposition))
        await funAfterSaveInflectionVerbComposition(history, dispatch, getState, rootId, dbrId, infId, ivcId);
    },
  
    deleteInflectionVerbComposition: (history, ivcId, infId, dbrId, rootId) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js apiClient.deleteInflectionVerbComposition', `/roots/inflectionverbcomposition/${ivcId}`);
      if (await apiClient.Delete(`/roots/inflectionverbcomposition/${ivcId}`,
        { 
          get: requestDeleteInflectionVerbComposition, 
          set: deleteInflectionVerbCompositionSuccess, 
          failure: deleteInflectionVerbCompositionFailure },
        dispatch, getState, true, ivcId)) {
          await funAfterSaveInflectionVerbComposition(history, dispatch, getState, rootId, dbrId, infId, null);
        }
    },
  
    confirmRejectInflectionVerbCompositionChange: (history, ivcId, infId, dbrId, rootId, confirm, withAllDescendants) => async (dispatch, getState) => {
      //const history = useHistory();
      //console.log('RootEditorStore.js apiClient.confirmRejectInflectionVerbCompositionChange', `/roots/inflectionverbcomposition/${ivcId}/${confirm}/${withAllDescendants}`);
      if (await apiClient.Patch(`/roots/inflectionverbcomposition/${ivcId}/${confirm}/${withAllDescendants}`,
        { 
          get: requestConfirmRejectInflectionVerbCompositionChange, 
          set: confirmRejectInflectionVerbCompositionChangeSuccess, 
          failure: confirmRejectInflectionVerbCompositionChangeFailure 
        },
        dispatch, getState, true, rootId)) {
          await funAfterSaveInflectionVerbComposition(history, dispatch, getState, rootId, dbrId, infId, ivcId); 
        }
    }
  
  









  }    

  
export const reducer = (state, action) => {
    state = state || initialState;
  
    if (action.type === clearDeleteFailure) {
      return { ...state, DeleteFailure: false };
    }
  
    if (action.type === clearConfirmRejectFailure) {
      return { ...state, confirmRejectFailure: false };
    }

    //Roots
    if (action.type === requestLoadRoot) {
      return { ...state, loadingRoot: true, rootForEdit: null };
    }
    
    if (action.type === setRoot) {
      const data = action.payload.data;
      return { ...state, loadingRoot: false, rootForEdit: data };
    }
    
    if (action.type === getRootFailure) {
      return { ...state, loadingRoot: false, rootForEdit: null };
    }
    
    if (action.type === startSavingRoot) {
      return { ...state, savingRoot: true, duplicateRootId: null };
    }
  
    if (action.type === updateRootSuccess) {
      const data = action.payload.data;
      return { ...state, savingRoot: false, duplicateRootId: data };
    }
  
    if (action.type === updateRootFailure) {
      return { ...state, savingRoot: false, duplicateRootId: null };
    }

    if (action.type === createRootSuccess) {
      const data = action.payload.data;
      return { ...state, savingRoot: false, rootForEdit: data };
    }
  
    if (action.type === createRootFailure) {
      return { ...state, savingRoot: false, rootForEdit: null };
    }
  
    if (action.type === requestDeleteRoot) {
      return { ...state, workingOnDeleteRoot: true, DeleteFailure: false };
    }
  
    if (action.type === deleteRootSuccess) {
      return { ...state, workingOnDeleteRoot: false, DeleteFailure: false };
    }
  
    if (action.type === deleteRootFailure) {
      return { ...state, workingOnDeleteRoot: false, DeleteFailure: true };
    }

    if (action.type === requestConfirmRejectRootChange) {
      return { ...state, workingOnConfirmRejectRootChange: true, confirmRejectFailure: false };
    }
  
    if (action.type === confirmRejectRootChangeSuccess) {
      return { ...state, workingOnConfirmRejectRootChange: false, confirmRejectFailure: false };
    }
  
    if (action.type === confirmRejectRootChangeFailure) {
      return { ...state, workingOnConfirmRejectRootChange: false, confirmRejectFailure: true };
    }

    //DerivationBranchs
    if (action.type === requestLoadDerivationBranch) {
      return { ...state, loadingDerivationBranch: true, derivationBranchForEdit: null };
    }
    
    if (action.type === setDerivationBranch) {
      const data = action.payload.data;
      return { ...state, loadingDerivationBranch: false, derivationBranchForEdit: data };
    }
    
    if (action.type === getDerivationBranchFailure) {
      return { ...state, loadingDerivationBranch: false, derivationBranchForEdit: null };
    }
    
    if (action.type === startSavingDerivationBranch) {
      return { ...state, savingDerivationBranch: true, duplicateDerivationBranchId: null };
    }
  
    if (action.type === updateDerivationBranchSuccess) {
      const data = action.payload.data;
      return { ...state, savingDerivationBranch: false, duplicateDerivationBranchId: data };
    }
  
    if (action.type === updateDerivationBranchFailure) {
      return { ...state, savingDerivationBranch: false, duplicateDerivationBranchId: null };
    }

    if (action.type === createDerivationBranchSuccess) {
      const data = action.payload.data;
      return { ...state, savingDerivationBranch: false, derivationBranchForEdit: data };
    }
  
    if (action.type === createDerivationBranchFailure) {
      return { ...state, savingDerivationBranch: false, derivationBranchForEdit: null };
    }
  
    if (action.type === requestDeleteDerivationBranch) {
      return { ...state, workingOnDeleteDerivationBranch: true, DeleteFailure: false };
    }
  
    if (action.type === deleteDerivationBranchSuccess) {
      return { ...state, workingOnDeleteDerivationBranch: false, DeleteFailure: false };
    }
  
    if (action.type === deleteDerivationBranchFailure) {
      return { ...state, workingOnDeleteDerivationBranch: false, DeleteFailure: true };
    }
  
    if (action.type === requestConfirmRejectDerivationBranchChange) {
      return { ...state, workingOnConfirmRejectDerivationBranchChange: true, confirmRejectFailure: false };
    }
  
    if (action.type === confirmRejectDerivationBranchChangeSuccess) {
      return { ...state, workingOnConfirmRejectDerivationBranchChange: false, confirmRejectFailure: false };
    }
  
    if (action.type === confirmRejectDerivationBranchChangeFailure) {
      return { ...state, workingOnConfirmRejectDerivationBranchChange: false, confirmRejectFailure: true };
    }

  






    
    //Inflections
    if (action.type === requestLoadInflection) {
      return { ...state, loadingInflection: true, inflectionForEdit: null };
    }
    
    if (action.type === setInflection) {
      const data = action.payload.data;
      return { ...state, loadingInflection: false, inflectionForEdit: data };
    }
    
    if (action.type === getInflectionFailure) {
      return { ...state, loadingInflection: false, inflectionForEdit: null };
    }
    
    if (action.type === startSavingInflection) {
      return { ...state, savingInflection: true, duplicateInflectionId: null };
    }
  
    if (action.type === updateInflectionSuccess) {
      const data = action.payload.data;
      return { ...state, savingInflection: false, duplicateInflectionId: data };
    }
  
    if (action.type === updateInflectionFailure) {
      return { ...state, savingInflection: false, duplicateInflectionId: null };
    }

    if (action.type === createInflectionSuccess) {
      const data = action.payload.data;
      return { ...state, savingInflection: false, inflectionForEdit: data };
    }
  
    if (action.type === createInflectionFailure) {
      return { ...state, savingInflection: false, inflectionForEdit: null };
    }
  
    if (action.type === requestDeleteInflection) {
      return { ...state, workingOnDeleteInflection: true, DeleteFailure: false };
    }
  
    if (action.type === deleteInflectionSuccess) {
      return { ...state, workingOnDeleteInflection: false, DeleteFailure: false };
    }
  
    if (action.type === deleteInflectionFailure) {
      return { ...state, workingOnDeleteInflection: false, DeleteFailure: true };
    }
  
    if (action.type === requestConfirmRejectInflectionChange) {
      return { ...state, workingOnConfirmRejectInflectionChange: true, confirmRejectFailure: false };
    }
  
    if (action.type === confirmRejectInflectionChangeSuccess) {
      return { ...state, workingOnConfirmRejectInflectionChange: false, confirmRejectFailure: false };
    }
  
    if (action.type === confirmRejectInflectionChangeFailure) {
      return { ...state, workingOnConfirmRejectInflectionChange: false, confirmRejectFailure: true };
    }

  






    
    //InflectionVerbCompositions
    if (action.type === requestLoadInflectionVerbComposition) {
      return { ...state, loadingInflectionVerbComposition: true, inflectionVerbCompositionForEdit: null };
    }
    
    if (action.type === setInflectionVerbComposition) {
      const data = action.payload.data;
      //console.log("RootEditorStore reducer setInflectionVerbComposition data=", data );
      return { ...state, loadingInflectionVerbComposition: false, inflectionVerbCompositionForEdit: data };
    }
    
    if (action.type === getInflectionVerbCompositionFailure) {
      return { ...state, loadingInflectionVerbComposition: false, inflectionVerbCompositionForEdit: null };
    }
    
    if (action.type === startSavingInflectionVerbComposition) {
      return { ...state, savingInflectionVerbComposition: true, duplicateInflectionVerbCompositionId: null };
    }
  
    if (action.type === updateInflectionVerbCompositionSuccess) {
      const data = action.payload.data;
      return { ...state, savingInflectionVerbComposition: false, duplicateInflectionVerbCompositionId: data };
    }
  
    if (action.type === updateInflectionVerbCompositionFailure) {
      return { ...state, savingInflectionVerbComposition: false, duplicateInflectionVerbCompositionId: null };
    }

    if (action.type === createInflectionVerbCompositionSuccess) {
      const data = action.payload.data;
      return { ...state, savingInflectionVerbComposition: false, inflectionVerbCompositionForEdit: data };
    }
  
    if (action.type === createInflectionVerbCompositionFailure) {
      return { ...state, savingInflectionVerbComposition: false, inflectionVerbCompositionForEdit: null };
    }
  
    if (action.type === requestDeleteInflectionVerbComposition) {
      return { ...state, workingOnDeleteInflectionVerbComposition: true, DeleteFailure: false };
    }
  
    if (action.type === deleteInflectionVerbCompositionSuccess) {
      return { ...state, workingOnDeleteInflectionVerbComposition: false, DeleteFailure: false };
    }
  
    if (action.type === deleteInflectionVerbCompositionFailure) {
      return { ...state, workingOnDeleteInflectionVerbComposition: false, DeleteFailure: true };
    }
  
    if (action.type === requestConfirmRejectInflectionVerbCompositionChange) {
      return { ...state, workingOnConfirmRejectInflectionVerbCompositionChange: true, confirmRejectFailure: false };
    }
  
    if (action.type === confirmRejectInflectionVerbCompositionChangeSuccess) {
      return { ...state, workingOnConfirmRejectInflectionVerbCompositionChange: false, confirmRejectFailure: false };
    }
  
    if (action.type === confirmRejectInflectionVerbCompositionChangeFailure) {
      return { ...state, workingOnConfirmRejectInflectionVerbCompositionChange: false, confirmRejectFailure: true };
    }



    return state;
}  