//VerbRowParadigmFormulaEdit.js
import React, {useState, useEffect, useCallback, useMemo} from 'react';
//import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

//import { history } from '../../carcass/common/History';
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as ModelEditorActions } from './ModelEditorStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { useForman } from '../../carcass/common/MyHooks';
import WaitPage from '../../carcass/common/WaitPage';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
// import OneTextControl from '../../carcass/editorParts/OneTextControl';
// import OneNumberControl from '../../carcass/editorParts/OneNumberControl';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import OnePlaintextRow from '../editorParts/OnePlaintextRow';
import { getFormulaVisual2, createFormulaFormData } from './FormulasModule';
import { NzInt } from '../../carcass/common/myFunctions';

const VerbRowParadigmFormulaEdit = (props) => {


  //2. კომპონენტის თვისებები
  // const { alert, mdWorkingOnLoad, checkLoadMdTables, 
  //   morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, morphemeRangesByInflectionBlocks, verbParadigms, 
  //   verbTypes, verbRows, verbPersonMarkerParadigms,
  //   savingVerbRowParadigmFormula, loadingVerbRowParadigmFormula, workingOnDeleteVerbRowParadigmFormula, verbRowParadigmFormulaForEdit, 
  //   getOneVerbRowParadigmFormulaById, DeleteFailure } = props;

  const { alert, masterData, savingVerbRowParadigmFormula, loadingVerbRowParadigmFormula, 
    workingOnDeleteVerbRowParadigmFormula, verbRowParadigmFormulaForEdit, DeleteFailure, 
    checkLoadMdTables, getOneVerbRowParadigmFormulaById
  } = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, verbParadigms, 
    verbTypes, verbRows, verbPersonMarkerParadigms, morphemeRangesByInflectionBlocks } = masterData.mdRepo;


  //1. იდენტიფიკატორი
  const [curFormulaIdVal, setCurFormulaIdVal] = useState(null);
  const [paradigmIdsForClear, setParadigmIdsForClear] = useState([]); 
  const [rangesInGroup, setRangesInGroup] = useState(null);
  const [formDataPrepared, setFormDataPrepared] = useState(false);

  //const history = useHistory();

  //  console.log("VerbRowParadigmFormulaEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => ["VerbRowParadigmRows"], []);

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["morphemeRanges", "morphemesQuery", "inflectionBlocks", 
    "inflectionTypes", "morphemeRangesByInflectionBlocks", "verbParadigms", 
    "verbTypes", "verbRows", "verbPersonMarkerParadigms"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [tableNamesForLoad, checkLoadMdTables]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    verbParadigmFormula: yup.object().shape({
      vprId: yup.number().integer().default(0).required(),
      verbParadigmId: yup.number().integer().positive("პარადიგმა არჩეული უნდა იყოს")
        .required("პარადიგმა არჩეული უნდა იყოს").default(0),
      verbTypeId: yup.number().integer().default(0).positive("ზმნის ტიპი არჩეული უნდა იყოს")
        .required("ზმნის ტიპი არჩეული უნდა იყოს").default(0),
      verbRowId: yup.number().integer().positive("მწკრივი არჩეული უნდა იყოს")
        .required("მწკრივი არჩეული უნდა იყოს").default(0),
      verbPersonMarkerParadigmId: yup.number().integer().positive("პირის ნიშნების პარადიგმა არჩეული უნდა იყოს")
        .required("პირის ნიშნების პარადიგმა არჩეული უნდა იყოს").default(0)
      //   ,
      // nprSample: yup.string().required("ნიმუში შევსებული უნდა იყოს").default("-")
    }),
    // rangesLength: yup.number().integer().default(0),
    morphemeIds: yup.array().ensure().min(1, "ფორმულაში უნდა იყოს ერთი მაინც არანულოვანი მორფემა")
      .of(yup.number().integer().positive().default(0)
      .required()),
    formData: yup.object().shape({
      // morphemeGroupId: yup.number().default(0),
      ranges: yup.array().ensure(),
      morphemes: yup.array().ensure()
        .of(yup.number().integer().positive("მორფემა არჩეული უნდა იყოს").default(0)
        .required()),
    })
  });

  //console.log("VerbRowParadigmFormulaEdit yupSchema=", yupSchema);

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    props.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
    props.clearVerbRowParadigmFormulas(paradigmIdsForClear);
  }

  const copyMorphemsToMainData = useCallback((forForm) => {
    const newForm = {...forForm};

    //console.log("VerbRowParadigmFormulaEdit copyMorphemsToMainData forForm=", forForm);
    //console.log("VerbRowParadigmFormulaEdit copyMorphemsToMainData newForm=", newForm);

    newForm.morphemeIds = newForm.formData.morphemes.map((mrpId) => {
      return mrpId ? morphemesQuery.find((f)=> f.mrpId === mrpId) : null;
    }).filter(f=>f && f.mrpNom).map(m=>m.mrpId);
    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
    setFormData(newForm);
  },[morphemesQuery, setFormData]);

  const addForClearParadigmId = useCallback((paradigmId) => {
    if ( paradigmIdsForClear.includes(paradigmId) )
      return;
    const newParadigmIdsForClear = [...paradigmIdsForClear, paradigmId];
    setParadigmIdsForClear(newParadigmIdsForClear);
  }, [paradigmIdsForClear]);

  //ფლექსიის ბლოკის ეფექტი
  useEffect(() => {

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშIნ გაგრეძელებას აზრი არ აქვს
    if ( mdWorkingOnLoad || !inflectionBlocks || !inflectionTypes || !morphemeRanges || !morphemeRangesByInflectionBlocks )
      return;

    //მოვძებნოთ ფლექსიის ბლოკის შესაბამისი ობიექტი
    const inflectionBlock = inflectionBlocks.find(f=>f.inbKey === "VerbRowsBlock");

    //console.log("VerbRowParadigmFormulaEdit useEffect inflectionBlock=", inflectionBlock);

    //დერივაციის ტიპის პოვნას აზრი აქვს მხოლოდ იმ შემთხვევაში, თუ დერივაციიც ტიპების ცხრილი ჩატვირთულია
    //მოვძებნოთ დერივაციის ტიპის შესაბამისი ობიექტი
    const inflectionType = inflectionTypes.find(f=>f.iftId === inflectionBlock.inflectionTypeId);

    //console.log("VerbRowParadigmFormulaEdit useEffect inflectionType=", inflectionType);
    //console.log("VerbRowParadigmFormulaEdit useEffect morphemeRangesByInflectionBlocks=", morphemeRangesByInflectionBlocks);

    //ამოვკრიბოთ იმ რანგების შესახებ ინფორმაცია, რომლებიც მონაწილეობენ ამ ტიპის დერივაციის ფორმირებაში
    const MorphemeRangeIdsByIB = morphemeRangesByInflectionBlocks
      .filter(f=>f.inflectionBlockId === inflectionBlock.inbId)
      .map(m=>m.morphemeRangeId);

    //console.log("VerbRowParadigmFormulaEdit useEffect MorphemeRangeIdsByIB=", MorphemeRangeIdsByIB);

    const newRangesInGroup = morphemeRanges
      .filter(f=>f.morphemeGroupId === inflectionType.morphemeGroupId && MorphemeRangeIdsByIB.includes(f.mrId))
      .sort((a,b) => a.mrPosition-b.mrPosition);

    setRangesInGroup(newRangesInGroup);

  }, [mdWorkingOnLoad, inflectionBlocks, inflectionTypes, morphemeRanges, morphemeRangesByInflectionBlocks]);

  const prepareFormDataForEdit = useCallback((forForm) => {
    const newForm = forForm ? forForm : {...frm};
    newForm.formData = createFormulaFormData(newForm.formData, rangesInGroup, newForm.morphemeIds, morphemesQuery, false, true);
    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
    //console.log("VerbRowParadigmFormulaEdit prepareFormDataForEdit newForm=", newForm);
    copyMorphemsToMainData(newForm);
  }, [morphemesQuery, copyMorphemsToMainData, frm, rangesInGroup]);


  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const { formulaId } = props.match.params;
    const formulaIdVal = NzInt(formulaId);

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curFormulaIdVal !== formulaIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurFormulaIdVal(formulaIdVal);
      setFormDataPrepared(false);
      setParadigmIdsForClear([]);
      if (formulaIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneVerbRowParadigmFormulaById(formulaIdVal);
        return;
      }
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      //console.log("VerbRowParadigmFormulaEdit useEffect after clearToDefaults frm=", frm);
    }

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშIნ გაგრეძელებას აზრი არ აქვს
    if ( mdWorkingOnLoad || !morphemesQuery || !verbParadigms || !rangesInGroup )
      return;

    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთული ობიექტი მაინც შევამოწმოთ

    //დავამზადოთ ფორმის ახალი ობიექტი
    if ( formulaIdVal ) {
      //ტუ ინფომრაცია ჯერ ჩატვირთული არ არის, ვჩერდებით
      if ( loadingVerbRowParadigmFormula || !verbRowParadigmFormulaForEdit )
        return;
      addForClearParadigmId(verbRowParadigmFormulaForEdit.verbParadigmFormula.verbParadigmId);
      if ( !formDataPrepared ) {
        setFormDataPrepared(true);
        prepareFormDataForEdit(verbRowParadigmFormulaForEdit);
      }
    }
    else {
      if ( !formDataPrepared ) {
        setFormDataPrepared(true);
        prepareFormDataForEdit(null);
      }
    }


  }, [morphemesQuery, verbParadigms, addForClearParadigmId, clearToDefaults, curFormulaIdVal, 
    getOneVerbRowParadigmFormulaById, loadingVerbRowParadigmFormula, mdWorkingOnLoad, verbRowParadigmFormulaForEdit, 
    prepareFormDataForEdit, props.match.params, rangesInGroup, formDataPrepared]);
    //setFormData


  // clearToDefaults, curFormulaIdVal, props.match.params, setFormData, verbRowParadigmFormulaForEdit, 
  // loadingVerbRowParadigmFormula, getOneVerbRowParadigmFormulaById,
  // inflectionBlocks, inflectionTypes, morphemeRanges, morphemeRangesByInflectionBlocks, morphemesQuery, verbParadigms,
  // copyMorphemsToMainData, mdWorkingOnLoad, addForClearParadigmId, prepareFormDataForEdit, rangesInGroup  




  //8. ჩატვირთვის შემოწმება
  if ( loadingVerbRowParadigmFormula || mdWorkingOnLoad || datatypesLoading ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
    return (<WaitPage />);
 //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( (curFormulaIdVal && !verbRowParadigmFormulaForEdit) || !morphemeRanges || !morphemesQuery || !inflectionBlocks || 
      !inflectionTypes || !morphemeRangesByInflectionBlocks || !verbParadigms || 
      !verbTypes || !verbRows || !verbPersonMarkerParadigms || !datatypes ) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }
  
  //console.log("VerbRowParadigmFormulaEdit frm=", frm);

  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");


  // function getParadigmName() {
  //   const verbRowParadigm = verbParadigms.find(f=>f.vpnId === frm.verbParadigmFormula.verbParadigmId);
  //   return verbRowParadigm.vpnName
  // }



  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;

//გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
//წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას

    const currentForm = {...frm};
    copyMorphemsToMainData(currentForm);
    delete currentForm.formData;
    // const paradigmName = getParadigmName();
    if ( curFormulaIdVal )
      props.updateVerbRowParadigmFormula(props.history, currentForm, frm.verbParadigmFormula.verbParadigmId);
    else
      props.createVerbRowParadigmFormula(props.history, currentForm, frm.verbParadigmFormula.verbParadigmId);
    clearUsedTables();
  }

  //აქ ითვლება, რომ თუ პარადიგმის სახელების დარედაქტირების უფლება აქვს მომხმარებელს
  //მაშინ პარადიგმებზეც უნდა ჰქონდეს.
  //ასე იმიტომ გაკეთდა, რომ არ გამხდარიყო საჭირო პარადიგმების მწკრივის ცხრილის დარეგისტრირება DataType-ებში
  //თუ მომავალში ამ ორი უფლების გამიჯვნა გახდა საჭირო, მოგვიწევს DataType-ებში დავარეგისტრიროთ პარადიგმების მწკრივის ცხრილი
  const dataType = datatypes.find(f=>f.dtTable === "verbParadigms");

  return (
    <Row>
      <Col md="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          <EditorHeader curIdVal={curFormulaIdVal} EditorName="ზმნის მწკრივის პარადიგმის ფორმულა" EditorNameGenitive="ზმნის მწკრივის პარადიგმის ფორმულის"
            EditedObjectName={(frm && frm.verbParadigmFormula && frm.verbParadigmFormula.formulaName) ? frm.verbParadigmFormula.formulaName : ""}
            workingOnDelete={workingOnDeleteVerbRowParadigmFormula} DeleteFailure={DeleteFailure}
            onDelete={() => {
              // const paradigmName = getParadigmName();
              props.deleteVerbRowParadigmFormula(props.history, curFormulaIdVal, frm.verbParadigmFormula.vprId); 
              clearUsedTables();
            }}
            onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={dataType.delete} />
          <OnePlaintextRow controlId="result" label="შედეგი" 
            text={getFormulaVisual2(frm.morphemeIds, frm.formData.ranges, morphemesQuery)} />          
          <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />
          <OneComboBoxControl controlId="verbParadigmFormula.verbParadigmId" 
            label="პარადიგმა" value={frm.verbParadigmFormula.verbParadigmId} 
            dataMember={verbParadigms.map(m => { return {id: m.vpnId, name: `${m.vpnKey} - ${m.vpnName}` }})} 
            valueMember="id" displayMember="name"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ პარადიგმა" }} />
          <OneComboBoxControl controlId="verbParadigmFormula.verbTypeId" 
            label="ზმნის ტიპი" value={frm.verbParadigmFormula.verbTypeId} 
            dataMember={verbTypes} valueMember="vtpId" displayMember="vtpName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ ზმნის ტიპი" }} />
          <OneComboBoxControl controlId="verbParadigmFormula.verbRowId" 
            label="მწკრივი" value={frm.verbParadigmFormula.verbRowId} 
            dataMember={verbRows} valueMember="vrwId" displayMember="vrwName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "აირჩიეთ მწკრივი" }} />
          <OneComboBoxControl controlId="verbParadigmFormula.verbPersonMarkerParadigmId" 
            label="პირის ნიშნების პარადიგმა" value={frm.verbParadigmFormula.verbPersonMarkerParadigmId} 
            dataMember={verbPersonMarkerParadigms.sort((a, b) => a.sortId-b.sortId)} valueMember="vpmpnId" displayMember="vpmpnKey"
            getError={getError} onChangeValue={changeField} sortByDisplayMember={false} 
            firstItem={{ id: 0, name: "აირჩიეთ პირის ნიშნების პარადიგმა" }} />
          {/* <OneTextControl controlId="verbParadigmFormula.nprSample" label="ნიმუში" value={frm.verbParadigmFormula.nprSample} 
            getError={getError} onChangeValue={changeField} /> */}
          <OneStrongLabel controlId="morphemes" label="მორფემები რანგების მიხედვით" />
          {frm.formData.ranges.map((range, index) => {
            return (<OneComboBoxControl key={index} controlId={`frm.formData.morphemes[${index}]`}
            label={`${range.mrPosition+1}. ${range.mrName}`} value={frm.formData.morphemes[index]} 
            dataMember={morphemesQuery.filter(mph=>mph.morphemeRangeId === range.mrId && mph.mrpNom >= range.minNom && mph.mrpNom <= range.maxNom)} 
            valueMember="mrpId" displayMember="mrpName" sortByDisplayMember={false}
            getError={getError} onChangeValue={(fieldPath, value) => {
              const newForm = {...frm};
              newForm.formData.morphemes[index] = value;
              copyMorphemsToMainData(newForm);
            }} />);
          })}
          <OneSaveCancelButtons curIdVal={curFormulaIdVal} haveErrors={haveErrors} savingNow={savingVerbRowParadigmFormula} onCloseClick={() => { 
            // const paradigmName = getParadigmName();
            props.clearAlert(); 
            props.history.push(`/verbRowParadigmFormulas/${frm.verbParadigmFormula.verbParadigmId}/${curFormulaIdVal}`);
          }} allowEdit={dataType.update} />
          <OneErrorRow allErrors={allErrors} />
        </Form>
      </Col>
    </Row>
  );

}

function mapStateToProps(state) {
  const alert = state.alert;
  const masterData = state.masterData;
  const { savingVerbRowParadigmFormula, loadingVerbRowParadigmFormula, workingOnDeleteVerbRowParadigmFormula, 
    verbRowParadigmFormulaForEdit, DeleteFailure } = state.modelEditorStore

  return { alert, masterData, savingVerbRowParadigmFormula, loadingVerbRowParadigmFormula, 
    workingOnDeleteVerbRowParadigmFormula, verbRowParadigmFormulaForEdit, DeleteFailure
   };
}

function mapDispatchToProps(dispatch) {
  return {
    getOneVerbRowParadigmFormulaById: (vpfId) => dispatch(ModelEditorActions.getOneVerbRowParadigmFormulaById(vpfId)),
    updateVerbRowParadigmFormula: (history, vpf, paradigmName) => dispatch(ModelEditorActions.updateVerbRowParadigmFormula(history, vpf, paradigmName)),
    createVerbRowParadigmFormula: (history, vpf, paradigmName) => dispatch(ModelEditorActions.createVerbRowParadigmFormula(history, vpf, paradigmName)),
    deleteVerbRowParadigmFormula: (history, vpfId, paradigmName) => dispatch(ModelEditorActions.deleteVerbRowParadigmFormula(history, vpfId, paradigmName)),
    clearDeletingFailure: () => dispatch(ModelEditorActions.clearDeletingFailure()),
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad)),
    clearVerbRowParadigmFormulas: (paradigmIdsForClear) => dispatch(FormulasActions.clearVerbRowParadigmFormulas(paradigmIdsForClear)),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerbRowParadigmFormulaEdit);

