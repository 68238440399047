//OneDateBoxControl.js
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import moment from 'moment';

const OneDateBoxControl = (props) => {

  //onChange=handleChange
  const { controlId, label, value, showDate, showTime, getError, onChangeValue } = props;

  //console.log("OneDateBoxControl props=", props);

  const error = getError(controlId);

  function handleChange(e) {
    const { id, value } = e.target;
    onChangeValue(id, value);
  }


  const strFormat = `${showDate ? "YYYY-MM-DD" : ""}${showTime ? "THH:mm:ss" : ""}`
  // return moment(value).format(strFormat);
  const showValue = moment(value).format(strFormat);
  const inputType = `${showDate ? "date" : ""}${showTime ? "time" : ""}`

  // let showValue = value;
  // if ( dateOnly )
  //   showValue = moment(value).format("YYYY-MM-DD");

  return (
    <Form.Group className="mb-1" as={Row} controlId={controlId}>
      <Form.Label column sm="4" className="text-right">{label}</Form.Label>
      <Col sm="6">
        <Form.Control type={inputType} value={showValue} onChange={handleChange} 
          className={!!error && "is-invalid"} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}

export default OneDateBoxControl;
