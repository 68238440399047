//MorphemeEdit.js
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

//import { useHistory } from "react-router-dom";
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as ModelEditorActions } from './ModelEditorStore';
import { useForman } from '../../carcass/common/MyHooks';
import WaitPage from '../../carcass/common/WaitPage';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OneTextControl from '../../carcass/editorParts/OneTextControl';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import OneNumberControl from '../../carcass/editorParts/OneNumberControl';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import PhoneticsCombEditor from '../editorParts/PhoneticsCombEditor';


const MorphemeEdit = (props) => {

  //const history = useHistory();

  //1. იდენტიფიკატორი
  const [curMrpIdVal, setCurMrpIdVal] = useState(null); 

  //2. კომპონენტის თვისებები
  // const { alert, mdWorkingOnLoad, checkLoadMdTables, 
  //   phoneticsTypes, morphemeRanges, morphemesQuery, phoneticsChanges, phoneticsChangesQuery, phoneticsOptions, 
  //   savingMorpheme, loadingMorpheme, workingOnDeleteMorpheme, morphemeForEdit, getOneMorphemeById, DeleteFailure } = props;

  const { alert, masterData, savingMorpheme, loadingMorpheme, workingOnDeleteMorpheme, morphemeForEdit, DeleteFailure, 
    checkLoadMdTables, getOneMorphemeById} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const {phoneticsTypes, morphemeRanges, morphemesQuery, phoneticsChanges, phoneticsChangesQuery, phoneticsOptions} = masterData.mdRepo;

  //console.log("MorphemeEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => ["morphemes", "morphPhoneticsOption", "morphPhoneticsOccasion", "morphemesQuery"], []);

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["phoneticsTypes", "morphemeRanges", "morphemesQuery", "phoneticsChanges", 
    "phoneticsChangesQuery", "phoneticsOptions"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [tableNamesForLoad, checkLoadMdTables, mdWorkingOnLoad, datatypesLoading, datatypes]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    morpheme: yup.object().shape({
      mrpId: yup.number().default(0),
      morphemeRangeId: yup.number().integer().positive("რანგი არჩეული უნდა იყოს").required("რანგი არჩეული უნდა იყოს").default(0),
      mrpNom: yup.number().integer().required("მორფემის ნომერი შევსებული უნდა იყოს").default(0),
      mrpMorpheme: yup.string().required("მორფემა შევსებული უნდა იყოს").default(""),
      phoneticsTypeId: yup.number().transform((v, o) => o === null ? 0 : v).integer().min(0).default(0)
    }),
    phoneticsChangesLength: yup.number().default(0),
    phoneticsOptionMorphemeIds: yup.array().ensure()
      .when('phoneticsChangesLength', (phoneticsChangesLength, schema) => { 
        const lengt = phoneticsChangesLength ? phoneticsChangesLength : 0;
        return schema.min(lengt, `მორფემების ფონეტიკური ვარიანტები შევსებული უნდა იყოს`)
          .max(lengt, `მორფემების ფონეტიკური ვარიანტები შევსებული უნდა იყოს`); 
      })
    .of(yup.number().integer().positive("მორფემის ფონეტიკური ვარიანტი არჩეული უნდა იყოს").default(0)
      .required("მორფემის ფონეტიკური ვარიანტი არჩეული უნდა იყოს")),
    morphemeOccasionPhoneticsChangeIds: yup.array().ensure()
      .of(yup.number().integer().positive("მორფემის ფონეტიკური შესაძლებლობა არჩეული უნდა იყოს")
        .default(0).required("მორფემის ფონეტიკური შესაძლებლობა არჩეული უნდა იყოს"))
  });

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    props.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
  }

  const setPhoneticsType = useCallback((curForm, newPhoneticsTypeId) => {
  //function setPhoneticsType(curForm, newPhoneticsTypeId){
    const pcLength = (!phoneticsChanges || !newPhoneticsTypeId) ? 0 : 
      phoneticsChanges.filter(f=>f.phoneticsTypeId === newPhoneticsTypeId && f.phoneticsOptionId).length;
    const newForm = {...curForm};
    newForm.phoneticsChangesLength = pcLength;
    if ( !newForm.phoneticsOptionMorphemeIds )
      newForm.phoneticsOptionMorphemeIds = [];
    while ( newForm.phoneticsOptionMorphemeIds.length < pcLength )
      newForm.phoneticsOptionMorphemeIds.push(0);
    newForm.phoneticsOptionMorphemeIds.length = pcLength;
    newForm.morpheme.phoneticsTypeId = newPhoneticsTypeId==null?0:newPhoneticsTypeId;
    setFormData(newForm);
  },[phoneticsChanges, setFormData]);

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const { mrpId } = props.match.params;
    const mrpIdVal = mrpId ? parseInt(mrpId) : 0;

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curMrpIdVal !== mrpIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurMrpIdVal(mrpIdVal);
      if (mrpIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneMorphemeById(mrpIdVal);
        return;
      }
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }
    
    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთული ობიექტი მაინც შევამოწმოთ
    if ( loadingMorpheme || !mrpId || !morphemeForEdit || mdWorkingOnLoad || !phoneticsChanges)
      return;

      setPhoneticsType(morphemeForEdit, morphemeForEdit.morpheme.phoneticsTypeId);
  }, [clearToDefaults, curMrpIdVal, props.match.params, setFormData, morphemeForEdit, loadingMorpheme, getOneMorphemeById, 
    phoneticsChanges, mdWorkingOnLoad, setPhoneticsType]);

  //console.log("MorphemeEdit curMrpIdVal=", curMrpIdVal);
  //console.log("MorphemeEdit frm=", frm);

  //8. ჩატვირთვის შემოწმება
  if ( loadingMorpheme || mdWorkingOnLoad || datatypesLoading ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
    return (<WaitPage />);
  //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( (curMrpIdVal && !morphemeForEdit) || !phoneticsTypes || !morphemeRanges || !morphemesQuery || !phoneticsChanges 
      || !phoneticsChangesQuery || !phoneticsOptions || !datatypes ) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }
  
  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert()
    if (haveErrors)
      return;

    //გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
    //წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას

    const currentForm = {...frm};
    // delete currentForm.formData;
    if ( currentForm.morpheme.phoneticsTypeId === 0 )
      currentForm.morpheme.phoneticsTypeId = null;

    if ( curMrpIdVal )
      props.updateMorpheme(props.history, frm);
    else
      props.createMorpheme(props.history, frm);
    clearUsedTables();
  }

  //დავიანგარიშოთ იმ მორფემების სია, რომლებიც შეიძლება ჩაენაცვლოს სარედაქტირებელ მორფემას, როგორც ფონეტიკური ვარიანტები
  //პირველ ეტაპზე ავიღოთ მხოლოდ 0-ზე მეტი ნომრით იმავე რანგის მორფემები, გარდა დასარედაქტირებელისა.
  //შემდგომში როცა ფონეტიკური ტიპის არჩევა მოხდება. მორფემების არჩევა თვითონ უნდა მოხდეს ფონეტიკური დაანგარიშების საშუალებით
  //და რედაქტირების დროსაც სხვა არჩევანის საშუალება არ უნდა მოგვცეს.
  const substMorphemes = morphemesQuery
    .filter(f=>f.morphemeRangeId === frm.morpheme.morphemeRangeId && f.mrpId !== curMrpIdVal && f.mrpNom > -1)
    .sort((a,b) => parseInt(a.mrpNom)-parseInt(b.mrpNom));

  //console.log("MorphemeEdit morphemesQuery=", morphemesQuery);
  //console.log("MorphemeEdit substMorphemes=", substMorphemes);

  const dataType = datatypes.find(f=>f.dtTable === "morphemes");

  return (
    <Row>
      <Col md="6">
      {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      <Form onSubmit={handleSubmit} >
        <EditorHeader curIdVal={curMrpIdVal} EditorName="მორფემა" EditorNameGenitive="მორფემის"
          EditedObjectName={(frm && frm.morpheme && frm.morpheme.mrpMorpheme) ? frm.morpheme.mrpMorpheme : ""}
          workingOnDelete={workingOnDeleteMorpheme} DeleteFailure={DeleteFailure}
          onDelete={() => {props.deleteMorpheme(props.history, curMrpIdVal); clearUsedTables();}}
          onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={dataType.delete} />
        <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />
        <OneComboBoxControl controlId="morpheme.morphemeRangeId" 
          label="რანგი" value={frm.morpheme.morphemeRangeId} 
          dataMember={morphemeRanges} valueMember="mrId" displayMember="mrKey" secondDisplayMember="mrName"
          getError={getError} onChangeValue={changeField}
          firstItem={{ id: 0, name: "აირჩიეთ რანგი" }} />
        <OneNumberControl controlId="morpheme.mrpNom" label="რიგითი ნომერი" 
          value={frm.morpheme.mrpNom} getError={getError} onChangeValue={changeField} />
        <OneTextControl controlId="morpheme.mrpMorpheme" label="მორფემა" value={frm.morpheme.mrpMorpheme} 
          getError={getError} onChangeValue={changeField} />
        <OneComboBoxControl controlId="morpheme.phoneticsTypeId" 
          label="ფონეტიკური ტიპი" value={frm.morpheme.phoneticsTypeId} 
          dataMember={phoneticsTypes} valueMember="phtId" displayMember="phtName"
          getError={getError} onChangeValue={(fieldPath, value) => {
            setPhoneticsType(frm, value);
          }}
          firstItem={{ id: 0, name: "უცვლელი" }} />
        <OneStrongLabel controlId="phoneticsOptions" label="ფონეტიკური ვარიანტები" />
        { !!frm.morpheme.phoneticsTypeId && phoneticsChanges
          .filter(pc => pc.phoneticsTypeId === frm.morpheme.phoneticsTypeId && pc.phoneticsOptionId)
          .sort((a,b) => a.phoneticsTypeByOptionSequentialNumber-b.phoneticsTypeByOptionSequentialNumber)
          .map((m, index) => {
            const phoName = phoneticsOptions.find(f => f.phoId === m.phoneticsOptionId).phoName;
            const fieldName = `phoneticsOptionMorphemeIds[${index}]`;
            const value = (frm.phoneticsOptionMorphemeIds && index < frm.phoneticsOptionMorphemeIds.length) ? frm.phoneticsOptionMorphemeIds[index] : 0;
            //console.log("MorphemeEdit frm.phoneticsOptionMorphemeIds=", frm.phoneticsOptionMorphemeIds);
            return (<OneComboBoxControl key={index} controlId={fieldName} 
              label={`ვარიანტი ${index+1}. ${phoName}`} value={value} 
              dataMember={substMorphemes} valueMember="mrpId" displayMember="mrpName"
              getError={getError}  onChangeValue={changeField}
              firstItem={{ id: 0, name: "აირჩიეთ მორფემა" }} sortByDisplayMember={false} />);
            })
        }

        <PhoneticsCombEditor controlGroupId="morphemeOccasionPhoneticsChangeIds" label="ფონეტიკური შესაძლებლობები"
          basePhoneticsChanges={frm.morphemeOccasionPhoneticsChangeIds} phoneticsChangesQuery={phoneticsChangesQuery} 
          getError={getError} onChangeValue={changeField} onTrashButtonClick={(index) => {
            const newFrm = {...frm};
            newFrm.morphemeOccasionPhoneticsChangeIds.splice(index,1);
            setFormData(newFrm);
          }} onPlusButtonClick={() => {
            const newFrm = {...frm};
            newFrm.morphemeOccasionPhoneticsChangeIds.push(0);
            setFormData(newFrm);
            }} />

        <OneSaveCancelButtons curIdVal={curMrpIdVal} haveErrors={haveErrors} savingNow={savingMorpheme} onCloseClick={() => { 
          props.clearAlert(); props.history.goBack(); }} allowEdit={dataType.update} />
        <OneErrorRow allErrors={allErrors} />
      </Form>
      </Col>
    </Row>
  );

}

function mapStateToProps(state) {
  const alert = state.alert;
  const { savingMorpheme, loadingMorpheme, workingOnDeleteMorpheme, morphemeForEdit, DeleteFailure } = state.modelEditorStore
  const masterData = state.masterData;

  return { alert, masterData,
    savingMorpheme, loadingMorpheme, workingOnDeleteMorpheme, morphemeForEdit, DeleteFailure
   };
}

function mapDispatchToProps(dispatch) {
  return {
    getOneMorphemeById: (mrpId) => dispatch(ModelEditorActions.getOneMorphemeById(mrpId)),
    updateMorpheme: (history, mrp) => dispatch(ModelEditorActions.updateMorpheme(history, mrp)),
    createMorpheme: (history, mrp) => dispatch(ModelEditorActions.createMorpheme(history, mrp)),
    deleteMorpheme: (history, mrpId) => dispatch(ModelEditorActions.deleteMorpheme(history, mrpId)),
    clearDeletingFailure: () => dispatch(ModelEditorActions.clearDeletingFailure()),
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    clearTablesFromRepo: (tableNamesFroClear, tableNamesFroLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesFroClear, tableNamesFroLoad)),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MorphemeEdit);

