//OnePlusButton,js
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const OnePlusButton = (props) => {

  const { onClick } = props;

  return (
    <Row className="mb-1 mt-1" >
      <Col sm="10" align="right">
        <Button onClick={(e) => { e.preventDefault(); onClick();} } ><FontAwesomeIcon icon="plus"/>
        </Button>
      </Col>
    </Row>
);
}

export default OnePlusButton;
