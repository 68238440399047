//InflectionEdit.js
import React, {useEffect, useState, useCallback, useMemo} from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';

import * as yup from 'yup';

import Paradigm from './Paradigm'
import WaitPage from '../../carcass/common/WaitPage';
import { useForman } from '../../carcass/common/MyHooks';
//import { useHistory } from "react-router-dom";
import OneTextControl from '../../carcass/editorParts/OneTextControl';
import OneNumberControl from '../../carcass/editorParts/OneNumberControl';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OnePlaintextRow from '../editorParts/OnePlaintextRow';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import BasesAndFreeMorphemes from './BasesAndFreeMorphemes';
import StatusConfirmRejectPart from './StatusConfirmRejectPart';

import { createFormulaFormData } from '../overviews/FormulasModule';

import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as RootEditorActions } from './RootEditorStore';


const InflectionEdit = (props) => {

  //const history = useHistory();
  
  //1. იდენტიფიკატორი
  const [curInfIdVal, setCurInfIdVal] = useState(null); 
  const [curDbrIdVal, setCurDbrIdVal] = useState(null); 
  const [currentRootId, setCurrentRootId] = useState(null);
  

  //2. კომპონენტის თვისებები

  const { alert, user, masterData, savingInflection, loadingInflection, workingOnDeleteInflection, inflectionForEdit, 
    getOneInflectionById, DeleteFailure,
    rootLoading, verbRowParadigmsByVerbTypesLoading, 
    verbRowParadigmsByVerbTypesLoadingFailure, verbRowParadigmsByVerbTypes,
    alertError, checkLoadMdTables, GetVerbRowParadigmsByVerbTypes, CheckLoadRootsByInflectionVerbCompositionId,
    clearForConfirmRootsPagesMemo,
    clearTablesFromRepo, clearRoot, clearMemo,
    workingOnConfirmRejectInflectionChange, confirmRejectFailure,
    confirmRejectInflectionChange, clearConfirmRejectFailure

  } = props;

  const { mdWorkingOnLoad } = masterData;
  const { morphemeRanges, pronouns, classifiers, morphemeGroups, morphemesQuery, inflectionTypes, inflectionBlocks, 
    morphemeRangesByInflectionBlocks, phoneticsTypes, nounParadigms, verbParadigms, verbTypes, verbPluralityTypes, 
    verbRowFilters, personVariabilityTypes } = masterData.mdRepo;



  //console.log("InflectionEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = [];

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["pronouns", "classifiers", "morphemeGroups", "morphemeRanges", "morphemesQuery", "phoneticsTypes", 
    "inflectionTypes", "inflectionBlocks", "morphemeRangesByInflectionBlocks", "nounParadigms", "verbParadigms", 
    "verbTypes", "verbPluralityTypes", "verbRowFilters", "personVariabilityTypes"], []);
                                                        

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [tableNamesForLoad, checkLoadMdTables]);
                                                      

  useEffect(() => {
    if ( !verbRowParadigmsByVerbTypesLoading && !verbRowParadigmsByVerbTypesLoadingFailure && !verbRowParadigmsByVerbTypes )
      GetVerbRowParadigmsByVerbTypes();
  }, [verbRowParadigmsByVerbTypesLoading, verbRowParadigmsByVerbTypesLoadingFailure, 
    verbRowParadigmsByVerbTypes, GetVerbRowParadigmsByVerbTypes]);
      

  // pronouns, morphemeGroups, morphemeRanges, morphemesQuery, phoneticsTypes, 
  // inflectionTypes, inflectionBlocks, morphemeRangesByInflectionBlocks, nounParadigms, verbParadigms, 
  // verbTypes, verbPluralityTypes, verbRowFilters, personVariabilityTypes]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    inflection: yup.object().shape({
      infId: yup.number().integer().default(0).required(),
      inflectionTypeId: yup.number().integer().positive("ფლექსიის ტიპი არჩეული უნდა იყოს")
        .required("ფლექსიის ტიპი არჩეული უნდა იყოს").default(0),
      classifierId: yup.number().integer().default(0),
      infName: yup.string().default(""),
      lemma: yup.string().default(""),
      infSamples: yup.string().default(""),
      infHomonymIndex: yup.number().integer().min(0, "ომონიმიის ინდექსი შეიძლება იყოს დადებითი, ან 0")
        .required("ომონიმიის ინდექსი შევსებული უნდა იყოს").default(0),
      infNote: yup.string().max(255, "შენიშვნის სიგრძე არ შეიძლება იყოს 255 სიმბოლოზე მეტი").default("").nullable(true),

    }),
    personVariabilityTypeIds: yup.array().ensure()
      .of(yup.number().integer().positive("გამოყოფილ უჯრაში პირცვალებადობა არჩეული უნდა იყოს").default(0)
      .required()),
    freeMorphemeIds: yup.array().ensure().of(yup.number().integer().positive().default(0).required()),
    inflectionPredecessors: yup.array().ensure().of(yup.object().shape({
      baseNom: yup.number().integer().default(0).required(),
      parentBranchId: yup.number().integer().positive("წინაპარი არჩეული უნდა იყოს")
        .required("წინაპარი არჩეული უნდა იყოს").default(0),
      phoneticsTypeId: yup.number().transform((v, o) => o === null ? 0 : v).integer().min(0).default(0)
    })),
    verb: yup.object().nullable(true).shape({
      verbTypeId: yup.number().integer().positive("ზმნის ტიპი არჩეული უნდა იყოს")
      .required("ზმნის ტიპი არჩეული უნდა იყოს").default(0),
      verbParadigmId: yup.number().integer().positive("ზმნის პარადიგმა არჩეული უნდა იყოს")
      .required("ზმნის პარადიგმა არჩეული უნდა იყოს").default(0),
      verbPluralityTypeId: yup.number().integer().positive("ზმნის მრავლობითობის ტიპი არჩეული უნდა იყოს")
      .required("ზმნის მრავლობითობის ტიპი არჩეული უნდა იყოს").default(0),
      verbRowFilterId: yup.number().integer().positive("ზმნის მწკრივების ფილტრი არჩეული უნდა იყოს")
      .required("ზმნის მწკრივების ფილტრი არჩეული უნდა იყოს").default(0)
    }).default(null),
    noun: yup.object().nullable(true).shape({
      nounParadigmId: yup.number().integer().positive("სახელის პარადიგმა არჩეული უნდა იყოს")
      .required("სახელის პარადიგმა არჩეული უნდა იყოს").default(0)
    }).default(null),
    formData: yup.object().shape({
      ranges: yup.array().ensure(),
      morphemes: yup.array().ensure().of(yup.number().integer().positive("მორფემა არჩეული უნდა იყოს").default(0).required())
    })
  });

  //console.log("InflectionEdit yupSchema=", yupSchema);
  //console.log("InflectionEdit yupSchema.fields=", yupSchema.fields);
  //console.log("InflectionEdit yupSchema.fields.verb=", yupSchema.fields.verb);
  //console.log("InflectionEdit yupSchema.fields.verb.fields=", yupSchema.fields.verb.fields);
  //console.log("InflectionEdit yupSchema.fields.verb.fields.keys()=", Object.keys(yupSchema.fields.verb.fields));
  
  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    clearMemo();
    clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
    clearRoot(currentRootId);
    clearForConfirmRootsPagesMemo();
  }

  const copyMorphemsToMainData = useCallback((forForm) => {
    const newForm = {...forForm};
    newForm.freeMorphemeIds = newForm.formData.morphemes.map((mrpId) => {
      return mrpId ? morphemesQuery.find((f)=> f.mrpId === mrpId) : null;
    }).filter((f, index)=> f && newForm.formData.ranges[index].mrBaseNom === null && f.mrpNom).map(m=>m.mrpId);

    //console.log("InflectionEdit copyMorphemsToMainData newForm=", newForm);

    return newForm;
  },[morphemesQuery]);

  const setVerbType = useCallback((curForm, newVerbTypeId) => {

    // //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშინ გაგრეძელებას აზრი არ აქვს
    if ( !verbTypes || !personVariabilityTypes || !verbParadigms || !verbRowParadigmsByVerbTypes )
      return curForm;

      //დავამზადოთ ფორმის ახალი ობიექტი
    const newForm = {...curForm};

    //დავაფიქსიროთ ზმნის ტიპი
    newForm.verb.verbTypeId = newVerbTypeId;

    //ზმინს ტიპის იდენტიფიკატორის მიხედვით მოხდეს ზმინს ტიპის პოვნა
    const verbType = verbTypes.find(f=>f.vtpId === newVerbTypeId);
    if ( !verbType ) {
      //console.log("InflectionEdit alertError ზმნის ტიპი ვერ მოიძებნა");
      alertError("ზმნის ტიპი ვერ მოიძებნა");
      return newForm;
    }

    //დავიმახსოვროთ პირების რაოდეონობა
    const verbPersonsCount = verbType ? verbType.vtpVerbPersonsCount : 0;

    // const oldFormData = newForm.formData;
    // //თუ არ არის შევქმნათ ფორმის ინფორმაცია, თუ არის დავაკოპიროთ ლოკალურ ცვლადში
    // const formData = oldFormData ? {...oldFormData} : {};

    //დავაფიქსიროთ პირების რაოდენობის მიხედვით პირცვალებადობების მასივის სიგრძე
    newForm.personVariabilityTypeIds.length = verbPersonsCount;

    //თუ რომელიმე განუსაზღვრელია, ან არ მოიძებნება იდენტიფიკატორი პირცვალებადობების ტიპების სიაში, მაშინ მივანიჭოთ 0
    for(let i=0; i<verbPersonsCount; i++) {
      if ( newForm.personVariabilityTypeIds[i] === undefined || 
        personVariabilityTypes.find(f=>f.pvtId === newForm.personVariabilityTypeIds[i]) === undefined )
        newForm.personVariabilityTypeIds[i]=0;
    }

    // const formData = newForm.formData ? {...newForm.formData} : {};
    // formData.verbRowParadigmsdataMember = verbParadigms
    // .filter(x => {
    //   if ( newVerbTypeId )
    //     return verbRowParadigmsByVerbTypes[newVerbTypeId.toString()].includes(x.vpnId);
    //   return false;
    // })
    //   .map(m => { return {vpnId: m.vpnId, vpnFullName: `${m.vpnKey} ${m.vpnName}` };});
    // newForm.formData = formData;



    //console.log("InflectionEdit setVerbType newForm=", newForm);

    return newForm;

  },[personVariabilityTypes, verbTypes, alertError, verbParadigms, verbRowParadigmsByVerbTypes]);

  const setInflectionType = useCallback((curForm, newInflectionTypeId) => {

    //console.log("InflectionEdit setInflectionType Start", {inflectionBlocks, inflectionTypes, morphemeGroups, 
    //   morphemeRanges, morphemesQuery, morphemeRangesByInflectionBlocks});

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშiინ გაგრეძელებას აზრი არ აქვს
    if ( !inflectionBlocks || !inflectionTypes || !morphemeGroups || 
      !morphemeRanges || !morphemesQuery || !morphemeRangesByInflectionBlocks )
      return curForm;

      //დავამზადოთ ფორმის ახალი ობიექტი
    let newForm = {...curForm};
    //console.log("InflectionEdit setInflectionType newForm=", newForm); 

    //ფლექსიის ტიპის იდენტიფიკატორის მიხედვით მოხდეს ფლექსიის ტიპის პოვნა
    const inflectionType = inflectionTypes.find(f=>f.iftId === newInflectionTypeId);
    if ( !inflectionType ) {
      //console.log("InflectionEdit alertError ფლექსიის ტიპი ვერ მოიძებნა");
      alertError("ფლექსიის ტიპი ვერ მოიძებნა");
      return newForm;
    }
    //console.log("InflectionEdit setInflectionType inflectionType=", inflectionType);

    //ფლექსიის ტიპის მიხედვით დადგინდეს მორფემების ჯგუფი
    const morphemeGroup = morphemeGroups.find(mog => mog.mogId === inflectionType.morphemeGroupId);
    if ( !morphemeGroup ) {
      //console.log("InflectionEdit alertError მორფემების ჯგუფი ვერ მოიძებნა");
      alertError("მორფემების ჯგუფი ვერ მოიძებნა");
      return newForm;
    }
    //console.log("InflectionEdit setInflectionType morphemeGroup=", morphemeGroup);

    //ფლექსიის ტიპის იდენტიფიკატორის მიხედვით მოხდეს ფლექსიის ბლოკის პოვნა
    const inflectionBlock = inflectionBlocks.find(f=>f.inflectionTypeId === newInflectionTypeId && f.inbContainsNecessaryBase );
    if ( !inflectionBlock ) {
      //console.log("InflectionEdit alertError ფლექსიის ბლოკი ვერ მოიძებნა");
      alertError("ფლექსიის ბლოკი ვერ მოიძებნა");
      return newForm;
    }
    //console.log("InflectionEdit setInflectionType inflectionBlock=", inflectionBlock);

    //ამოვკრიბოთ იმ რანგების შესახებ ინფორმაცია, რომლებიც მონაწილეობენ ამ ტიპის ფლექსიის ფორმირებაში
    const morphemeRangeIdsByDT = morphemeRangesByInflectionBlocks
      .filter(f=>f.inflectionBlockId === inflectionBlock.inbId)
      .map(m=>m.morphemeRangeId);
    //console.log("InflectionEdit setInflectionType morphemeRangeIdsByDT=", morphemeRangeIdsByDT);

    //ფლექსიის ტიპში მითითებული ჯგუფის მიხედვით ამოვკრიბოთ რანგების შესაბამისი ობიექტები
    //თან გავითვალისწინოთ რომ ამ ფლექსიის ტიპში ეს რანგი უნდა მონაწილეობდეს თუ არა
    const rangesInGroup = morphemeRanges
      .filter(f=>f.morphemeGroupId === inflectionType.morphemeGroupId && morphemeRangeIdsByDT.includes(f.mrId) && 
        (f.mrInflectionSelectable || f.mrBaseNom !== null) )
      .sort((a,b) => a.mrPosition-b.mrPosition);
    //console.log("InflectionEdit setInflectionType rangesInGroup=", rangesInGroup);

    //დავაფიქსიროთ ფლექსიის ტიპი
    newForm.inflection.inflectionTypeId = newInflectionTypeId;
    
    if ( inflectionType.iftKey === 'verb' ) {
      if ( newForm.noun !== null)
        newForm.noun = null;
      if ( newForm.verb === null ) {
        // newForm.verb = {};
        // Object.keys(yupSchema.fields.verb.fields).forEach(f=> {
        //   newForm.verb[f] = yupSchema.fields.verb.fields[f].default();
        // });
        newForm.verb = { verbTypeId: 0, verbParadigmId: 0, verbPluralityTypeId: 0, verbRowFilterId: 0 };
      }
    }
    
    if ( inflectionType.iftKey === 'noun' ) {
      if ( newForm.verb !== null ) {
        newForm.verb = null;
        newForm.personVariabilityTypeIds = [];
      }
      if ( newForm.noun === null ) {
        // newForm.noun = {};
        // Object.keys(yupSchema.fields.noun.fields).forEach(f=> {
        //   newForm.noun[f] = yupSchema.fields.noun.fields[f].default();
        // });
        newForm.noun = { nounParadigmId: 0 };
      }
   }

    //console.log("InflectionEdit setInflectionType before createFormulaFormData newForm=", newForm);

    newForm.formData = createFormulaFormData(newForm.formData, rangesInGroup, newForm.freeMorphemeIds, morphemesQuery, true, true);
    //newForm.formData.creator = newForm.inflection.creator;
    // switch(newForm.inflection.recordStatusId)
    // {
    //   case 0:
    //     newForm.formData.editStatus = 'ახალი';
    //     newForm.formData.editStatusColor = 'blue';
    //     break
    //   case 1:
    //     newForm.formData.editStatus = 'წაშლის კანდიდატი';
    //     newForm.formData.editStatusColor = 'red';
    //     break
    //   case 2:
    //     newForm.formData.editStatus = 'დამოწმებული';
    //     newForm.formData.editStatusColor = 'green';
    //     break
    //   }


    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.

    newForm = copyMorphemsToMainData(newForm);

    //console.log("InflectionEdit setInflectionType newForm=", newForm);

    return newForm;

  },[inflectionBlocks, inflectionTypes, morphemeGroups, morphemeRanges, morphemesQuery, morphemeRangesByInflectionBlocks, 
    alertError, copyMorphemsToMainData]);
  //, yupSchema.fields.verb.fields, yupSchema.fields.noun.fields




  const fromParamsrootId = props.match.params.rootId;
  const fromParamsDbrId = props.match.params.dbrId;
  const fromParamsInfId = props.match.params.infId;


  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {

    //დავადგინოთ ძირის იდენტიფიკატორი
    const rootIdVal = fromParamsrootId ? parseInt(fromParamsrootId) : 0;
    if (currentRootId !== rootIdVal)
      setCurrentRootId(rootIdVal);

    //დავადგინოთ ძირის იდენტიფიკატორი
    const dbrIdVal = fromParamsDbrId ? parseInt(fromParamsDbrId) : 0;
    if (curDbrIdVal !== dbrIdVal)
      setCurDbrIdVal(dbrIdVal);

      //დავადგინოთ ფლექსიის იდენტიფიკატორი
    const infIdVal = fromParamsInfId ? parseInt(fromParamsInfId) : 0;

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curInfIdVal !== infIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurInfIdVal(infIdVal);
      if (infIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneInflectionById(infIdVal);
        CheckLoadRootsByInflectionVerbCompositionId(infIdVal);
        return;
      }

      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }


    //console.log("Before setInflectionType ", {loadingInflection, infId, inflectionForEdit, mdWorkingOnLoad, 
    //   inflectionBlocks, inflectionTypes, morphemeGroups, morphemeRanges, morphemesQuery, morphemeRangesByInflectionBlocks, rootLoading})

    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთულიობიექტი მაინც შევამოწმოთ
    if ( loadingInflection || !fromParamsInfId || !inflectionForEdit || 
      mdWorkingOnLoad || !inflectionBlocks || !inflectionTypes || !pronouns || !classifiers || 
      !morphemeGroups || !morphemeRanges || !morphemesQuery || 
      !morphemeRangesByInflectionBlocks || !verbTypes || !personVariabilityTypes || 
      
      rootLoading )
      return;

    // setFormData(inflectionForEdit);

    //console.log("InflectionEdit Call setInflectionType inflectionTypeId=",inflectionForEdit.inflection.inflectionTypeId);
    let newForm = setInflectionType(inflectionForEdit, inflectionForEdit.inflection.inflectionTypeId);
    if ( newForm.verb )
      newForm = setVerbType(newForm, newForm.verb ? newForm.verb.verbTypeId : 0);
    //console.log("InflectionEdit after Call setInflectionType newForm=",newForm);
    setFormData(newForm);

  }, [pronouns, classifiers, morphemeGroups, morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, morphemeRangesByInflectionBlocks, 
    verbTypes, personVariabilityTypes,
    curInfIdVal, curDbrIdVal, currentRootId, inflectionForEdit, 
    loadingInflection, mdWorkingOnLoad, fromParamsrootId, fromParamsDbrId, fromParamsInfId, rootLoading, 
    getOneInflectionById, clearToDefaults, CheckLoadRootsByInflectionVerbCompositionId, setInflectionType, setFormData, setVerbType]);

  //console.log("InflectionEdit.js on check Load ", 
  //   {morphemeGroups, morphemeRanges, morphemesQuery, phoneticsTypes, inflectionTypes, inflectionBlocks, 
  //     morphemeRangesByInflectionBlocks, curDbrIdVal, curInfIdVal, currentRootId, inflectionForEdit, 
  //     loadingInflection, mdWorkingOnLoad, rootLoading});

//(curDbrIdVal && !inflectionForEdit) || 
  //8. ჩატვირთვის შემოწმება
  //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( !pronouns || !classifiers || !morphemeGroups || !morphemeRanges || !morphemesQuery || 
    !phoneticsTypes || !inflectionTypes || !inflectionBlocks || !morphemeRangesByInflectionBlocks ||
      !nounParadigms || !verbParadigms || !verbTypes || !verbPluralityTypes || !verbRowFilters || 
      !personVariabilityTypes || !verbRowParadigmsByVerbTypes ) { 
    if ( loadingInflection || mdWorkingOnLoad || rootLoading || verbRowParadigmsByVerbTypesLoading ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
      return (<WaitPage />);
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  //morphemeGroups, morphemeRanges, morphemesQuery, phoneticsTypes, inflectionTypes, inflectionBlocks, morphemeRangesByInflectionBlocks

  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;

//გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
//წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას

    const currentForm = {...frm};
    delete currentForm.formData;

    currentForm.inflectionPredecessors.forEach((pred, index, arr)=>{
      if ( pred.phoneticsTypeId === 0 )
        arr[index].phoneticsTypeId = null;
    })

    if ( curInfIdVal )
      props.updateInflection(props.history, currentForm, curDbrIdVal, currentRootId);
    else
      props.createInflection(props.history, currentForm, curDbrIdVal, currentRootId);
    clearUsedTables();
  }

  //console.log("InflectionEdit frm=",frm);

  const inflectionResultName = ((frm && frm.inflection && frm.inflection.infName) 
    ? `${frm.inflection.infName}` : "") + 
    ((frm.inflection.infSamples) ? ` (${frm.inflection.infSamples})` : "");
  const lemma = ((frm && frm.inflection && frm.inflection.lemma) 
    ? `${frm.inflection.lemma}` : "");
  const userHasConfirmRight = user.appClaims.some(s=>s === 'Confirm');
  const allowEditAndDelete = (userHasConfirmRight && frm.inflection.recordStatusId !== 1 && frm.inflection.recordStatusId !== 0) || 
    (!userHasConfirmRight && frm.inflection.recordStatusId !== 1);

    
// debugger;

  return (
    <Row className="root-editor-row">
      <Col md="8" className="root-editor-column">

        <div id="root-deriv-tree" className="root-editor-scroll">

          {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
          <Form onSubmit={handleSubmit} >
            <EditorHeader curIdVal={curDbrIdVal} EditorName="ფლექსია" EditorNameGenitive="ფლექსიის"
              EditedObjectName={inflectionResultName}
              workingOnDelete={workingOnDeleteInflection} DeleteFailure={DeleteFailure}
              onDelete={() => {props.deleteInflection(props.history, curInfIdVal, curDbrIdVal, currentRootId); clearUsedTables();}}
              onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={allowEditAndDelete} />

            { userHasConfirmRight && frm.inflection.recordStatusId !== undefined && 
              <StatusConfirmRejectPart recordStatusId={frm.inflection.recordStatusId} creator={frm.inflection.creator} 
                workingOnConfirmReject={workingOnConfirmRejectInflectionChange}
                confirmRejectFailure={confirmRejectFailure}
                onConfirmRejectClick={(confirm, withAllDescendants) => {
                  confirmRejectInflectionChange(props.history, curInfIdVal, curDbrIdVal, currentRootId, confirm, withAllDescendants); 
                  clearUsedTables();
                }} 
                onClearConfirmRejectFailure={() => {clearConfirmRejectFailure();}} /> }

            <OnePlaintextRow controlId="result" label="შედეგი" text={inflectionResultName} />          

            <OnePlaintextRow controlId="lemma" label="ლემა" text={lemma} />   

            <OneNumberControl controlId="inflection.infHomonymIndex" label="ომონიმიის სორტირების ნომერი" 
              value={frm.inflection.infHomonymIndex} minv={0} getError={getError} onChangeValue={changeField} />
            <OneTextControl controlId="inflection.infNote" label="შენიშვნა" value={frm.inflection.infNote} 
              getError={getError} onChangeValue={changeField} />


            <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />

            <OneComboBoxControl controlId="inflection.classifierId" 
                  label="კლასიფიკატორი" value={frm.inflection.classifierId} 
                  dataMember={classifiers} firstItem={{ id: 0, name: "არცერთი" }} 
                  valueMember="clfId" displayMember="clfName" sortByDisplayMember={true} getError={getError} 
                  firstItemIsSelectable 
                  onChangeValue={changeField} />

            <OneComboBoxControl controlId="inflection.inflectionTypeId" 
              label="ფლექსიის მოდელი" value={frm.inflection.inflectionTypeId} 
              dataMember={inflectionTypes} firstItem={{ id: 0, name: "აირჩიე ფლექსიის მოდელი" }} 
              valueMember="iftId" displayMember="iftName" sortByDisplayMember={true} getError={getError} 
              onChangeValue={(fieldPath, value) => {
                if (value === 0)
                  return;
                const newForm = setInflectionType(frm, value);
                //console.log("InflectionEdit onChangeValue inflection.inflectionTypeId newForm=",newForm);
                setFormData(newForm);
              }} />

            { !!frm.inflection.inflectionTypeId && 
            
              <div>

              {frm.noun && 

                <OneComboBoxControl controlId="noun.nounParadigmId" 
                  label="სახელის პარადიგმა" value={frm.noun.nounParadigmId} 
                  dataMember={nounParadigms} firstItem={{ id: 0, name: "აირჩიე სახელის პარადიგმა" }} 
                  valueMember="npnId" displayMember="npnName" sortByDisplayMember={true} getError={getError} 
                  onChangeValue={changeField} />

              }

              {frm.verb && 

                <div>

                  <OneComboBoxControl controlId="verb.verbTypeId" 
                    label="ზმნის ტიპი" value={frm.verb.verbTypeId} 
                    dataMember={verbTypes.sort((a,b)=>a.sortId-b.sortId)} firstItem={{ id: 0, name: "აირჩიე ზმნის ტიპი" }} 
                    valueMember="vtpId" displayMember="vtpName" sortByDisplayMember={false} getError={getError} 
                    onChangeValue={(fieldPath, value) => {
                      const newForm = setVerbType(frm, value);
                      //console.log("InflectionEdit onChangeValue verb.verbTypeId newForm=",newForm);
                      setFormData(newForm);
                    }} />

                  <OneComboBoxControl controlId="verb.verbParadigmId" 
                    label="ზმნის პარადიგმა" value={frm.verb.verbParadigmId} 
                    dataMember={verbParadigms.filter(x => {
                      if ( frm.verb.verbTypeId )
                        return verbRowParadigmsByVerbTypes[frm.verb.verbTypeId.toString()].includes(x.vpnId);
                      return false;
                    })
                      .map(m => { return {vpnId: m.vpnId, vpnFullName: `${m.vpnKey} ${m.vpnName}` };})} 
                    firstItem={{ id: 0, name: "აირჩიე ზმნის პარადიგმა" }} 
                    valueMember="vpnId" displayMember="vpnFullName" sortByDisplayMember={true} getError={getError} 
                    onChangeValue={changeField} />

                  <OneComboBoxControl controlId="verb.verbPluralityTypeId" 
                    label="ზმნის მრავლობითობის ტიპი" value={frm.verb.verbPluralityTypeId} 
                    dataMember={verbPluralityTypes} firstItem={{ id: 0, name: "აირჩიე ზმნის მრავლობითობის ტიპი" }} 
                    valueMember="vptId" displayMember="vptName" sortByDisplayMember={true} getError={getError} 
                    onChangeValue={changeField} />

                  <OneComboBoxControl controlId="verb.verbRowFilterId" 
                    label="ზმნის მწკრივების ფილტრი" value={frm.verb.verbRowFilterId} 
                    dataMember={verbRowFilters} firstItem={{ id: 0, name: "აირჩიე მწკრივების ფილტრი" }} 
                    valueMember="vrfId" displayMember="vrfName" sortByDisplayMember={true} getError={getError} 
                    onChangeValue={changeField} />


                  {frm.personVariabilityTypeIds.length >0 && 

                    <div>
          
                    <OneStrongLabel controlId="personVariability" label="პირების ცვალებადობა" />
                  
                    {frm.personVariabilityTypeIds.map( (m, i) => 
                      (<OneComboBoxControl key={i} controlId={`personVariabilityTypeIds[${i}]`} 
                        label={`აქტანტი ${i+1}`} value={frm.personVariabilityTypeIds[i]} 
                        dataMember={personVariabilityTypes} firstItem={{ id: 0, name: "აირჩიე პირცვალებადობის ტიპი" }} 
                        valueMember="pvtId" displayMember="pvtName" sortByDisplayMember={true} getError={getError} 
                        onChangeValue={changeField} />)
                    )}

                    </div>

                  }

                </div>
              }

              <BasesAndFreeMorphemes ranges={frm.formData.ranges} morphemes={frm.formData.morphemes} 
                morphemesQuery={morphemesQuery} forInflection phoneticsTypes={phoneticsTypes} 
                morphemesdPath="frm.formData.morphemes" 
                getError={getError} selectPhoneticsType={true}
                predecessors={frm.inflectionPredecessors} 
                onMorphemeChange={(index, value) => {
                  let newForm = {...frm};
                  newForm.formData.morphemes[index] = value;
                  // //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
                  newForm = copyMorphemsToMainData(newForm);
                  //console.log("InflectionEdit onMorphemeChange newForm=",newForm);
                  setFormData(newForm);
                } }
                onPredecessorChange={(baseNom, item) => {
                  const newForm = {...frm};

                  let predByBase = newForm.inflectionPredecessors.find((pred) => pred.baseNom === baseNom);
                  if (item) {
                    if (!predByBase) {
                      predByBase = {};
                      predByBase.baseNom = baseNom;
                      predByBase.parentBranchId = item.dbrId;
                      predByBase.phoneticsTypeId = null;
                      newForm.inflectionPredecessors.push(predByBase);
                    } else {
                      predByBase.parentBranchId = item.dbrId;
                    }
                  } else {
                    if (predByBase) {
                      newForm.inflectionPredecessors = newForm.inflectionPredecessors.filter((pred) => pred.baseNom !== baseNom);
                    }
                  }
                  //console.log("InflectionEdit onPredecessorChange newForm=",newForm);
                  setFormData(newForm);

                }} 
                onPredecessorPhoneticsTypeChange={(baseNom, value) => {
                  const newForm = {...frm};
                  const predByBase = newForm.inflectionPredecessors.find((pred) => pred.baseNom === baseNom);
                  if (!predByBase)
                    return;
                  predByBase.phoneticsTypeId = value;
                  //console.log("InflectionEdit onPredecessorPhoneticsTypeChange newForm=",newForm);
                  setFormData(newForm);
                }}
                />

              </div>

            }



            <OneSaveCancelButtons curIdVal={curInfIdVal} haveErrors={haveErrors} savingNow={savingInflection} onCloseClick={() => { 
              props.clearAlert(); props.history.goBack(); }} allowEdit={allowEditAndDelete} />
            <OneErrorRow allErrors={allErrors} />

          </Form>
        </div>
      </Col>

      <Route path='/inflEdit/:infId/:dbrId?/:rootId?' component={Paradigm} />

    </Row>
  );


}



function mapStateToProps(state) {
  const alert = state.alert;
  const { user } = state.authentication;
  const masterData = state.masterData;
  const { savingInflection, loadingInflection, workingOnDeleteInflection, 
    inflectionForEdit, getOneInflectionById, DeleteFailure,
    confirmRejectFailure, workingOnConfirmRejectInflectionChange } = state.rootEditorStore;
  const { rootLoading, verbRowParadigmsByVerbTypesLoading, verbRowParadigmsByVerbTypesLoadingFailure, verbRowParadigmsByVerbTypes } = state.derivTree;

  return { alert, user, masterData, savingInflection, loadingInflection, workingOnDeleteInflection, inflectionForEdit, 
    getOneInflectionById, DeleteFailure,
    confirmRejectFailure, workingOnConfirmRejectInflectionChange,
    rootLoading, verbRowParadigmsByVerbTypesLoading, 
    verbRowParadigmsByVerbTypesLoadingFailure, verbRowParadigmsByVerbTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    getOneInflectionById: (infId) => dispatch(RootEditorActions.getOneInflectionById(infId, true)),
    updateInflection: (history, inflectionForSave, rootId) => dispatch(RootEditorActions.updateInflection(history, inflectionForSave, rootId)),
    createInflection: (history, inflectionForSave, dbrId, rootId) => dispatch(RootEditorActions.createInflection(history, inflectionForSave, dbrId, rootId)),
    deleteInflection: (history, infId, dbrId, rootId) => dispatch(RootEditorActions.deleteInflection(history, infId, dbrId, rootId)),
    clearDeletingFailure: () => dispatch(RootEditorActions.clearDeletingFailure()),
    clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad)),
    clearAlert: () => dispatch(alertActions.clear()),
    clearRoot: (rootId) => dispatch(DerivTreeActions.clearRoot(rootId)),
    clearMemo: () => dispatch(DerivTreeActions.clearMemo()),
    clearForConfirmRootsPagesMemo: () => dispatch(DerivTreeActions.clearForConfirmRootsPagesMemo()),
    CheckLoadRootsByInflectionVerbCompositionId: (infId) => dispatch(DerivTreeActions.CheckLoadRootsByInflectionVerbCompositionId(infId)),
    alertError: (errorMessage) => dispatch(alertActions.error(errorMessage)),
    GetVerbRowParadigmsByVerbTypes: () => dispatch(DerivTreeActions.GetVerbRowParadigmsByVerbTypes()),

    confirmRejectInflectionChange: (history, infId, dbrId, rootId, confirm, withAllDescendants) => dispatch(RootEditorActions.confirmRejectInflectionChange(history, infId, dbrId, rootId, confirm, withAllDescendants)),
    clearConfirmRejectFailure: () => dispatch(RootEditorActions.clearConfirmRejectFailure()),


  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InflectionEdit);
