//VerbInflectionLink.js
import React from 'react';
import { Link } from 'react-router-dom';

const VerbInflectionLink = (props) => {
  const { searchVal, item } = props;
  //console.log("VerbInflectionLink Start props=", props);

  /*make the matching letters bold:*/
  const wparts = item.infName.split(searchVal);
  const strongpart = (<strong>{searchVal}</strong>);

  function wrapLink(source, href, key) {
    if (href) {
      if (key)
        return (<Link key={key} to={href}>{source}</Link>);
      else
        return (<Link key={0} to={href}>{source}</Link>);
    }
    return source;
  }


  return (
    <span>
      {wrapLink(wparts.map((itm, indx) => {
        if (indx === 0)
          return (<span key={indx}>{itm}</span>);
        return (<span key={indx}>{strongpart}{itm}</span>);
      }), null )}
    </span>
  );
}

export default VerbInflectionLink;

