//Issues.js

import React, {useEffect, useMemo, useCallback} from 'react';
//import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';

import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as IssuesActions } from './IssuesStore';
import Loading from '../../carcass/common/Loading';
//import { useScroller } from '../../carcass/common/MyHooks';
//import { NzInt } from '../../carcass/common/myFunctions';
import GridView from './GridView';
import DateTimeColumn from './DateTimeColumn';
import MdLookupColumn from './MdLookupColumn';
import LinkColumn from './LinkColumn';
import CustomColumn from './CustomColumn';



const Issues = (props) => {

  const {alert, isMenuLoading, flatMenu, masterData, 
    insideChanging,
    checkLoadMdTables, 
    issuesLoading, issues, issuesCount, issuesLoadingFailure,
    loadIssues, getIssuesCount, createIssuesFilterSort, tabWindowId
  } = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { issueKinds, issuePriorities, issueStatuses } = masterData.mdRepo;

  //console.log("Issues props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["issueKinds", "issuePriorities", "issueStatuses"], []);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    checkLoadMdTables(tableNamesForLoad);
    //loadIssues();
    getIssuesCount();
  }, [isMenuLoading, flatMenu, isValidPage, checkLoadMdTables, tableNamesForLoad, getIssuesCount]);

  // const [curscrollTo, backLigth] = useScroller({
  //   tabKey: props.match.params.tabKey, 
  //   recName: NzInt(props.match.params.recName)
  // });

  if ( issuesLoadingFailure )
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );


  if (mdWorkingOnLoad || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }

  //|| !curscrollTo
  if (!issueKinds || !issuePriorities || !issueStatuses || !datatypes || !issuesCount) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  // const derivationTypesDataType = datatypes.find(f=>f.dtTable === "derivationTypes");
  // const derivationFormulasDataType = datatypes.find(f=>f.dtTable === "derivationFormulas");

  const gridColumns = [
    {caption: "#", visible: true, sortable: true, fieldName: "issId", isKey: true,
      control: (<CustomColumn onGetCell={(value)=>{
        return (<span>#{value}</span>);
      }} > </CustomColumn>)},
    {caption: "სათაური", visible: true, sortable: true, fieldName: "issTitle", 
      control: (<LinkColumn linkBase="issuework" idFieldName="issId" ></LinkColumn>)},
    {caption: "ტიპი", visible: true, sortable: true, fieldName: "issueKindId", 
      control: (<MdLookupColumn dataTable={issueKinds} valueMember="iskId" displayMember="iskName" ></MdLookupColumn>)},
    {caption: "პრიორიტეტი", visible: true, sortable: true, fieldName: "issuePriorityId", 
      control: (<MdLookupColumn dataTable={issuePriorities} valueMember="ispId" displayMember="ispName" ></MdLookupColumn>)},
    {caption: "სტატუსი", visible: true, sortable: true, fieldName: "issueStatusId", 
      control: (<MdLookupColumn dataTable={issueStatuses} valueMember="istId" displayMember="istName" ></MdLookupColumn>)},
    {caption: "ავტორი", visible: true, sortable: true, fieldName: "issCreatorUserName"},
    {caption: "მიწერა", visible: true, sortable: true, fieldName: "issAssignedUserName"},
    {caption: "ძ", visible: true, sortable: true, fieldName: "rootsDetailsCount"},
    {caption: "დ", visible: true, sortable: true, fieldName: "derivationBranchesDetailsCount"},
    {caption: "ფ", visible: true, sortable: true, fieldName: "inflectionsDetailsCount"},
    {caption: "-", visible: true, sortable: true, fieldName: "detailsCount"},
      {caption: "შექმნა", visible: true, sortable: true, fieldName: "issCreateDate", 
      control: (<DateTimeColumn showDate showTime ></DateTimeColumn>)},
    {caption: "განახლება", visible: true, sortable: true, fieldName: "IssUpdateDate", 
      control: (<DateTimeColumn showDate showTime ></DateTimeColumn>)},
  ];

  const issuesTableName = "issues";

  return (
    <div>
      <GridView showCountColumn columns={gridColumns} rows={issues} 
        allRowsCount={issuesCount} 
        onLoad={(offset, rowsCount) => loadIssues(offset, rowsCount)}
        onFilterSortChange={(filterFields, sortFields, rowCount) => 
          createIssuesFilterSort({tabWindowId, tableName: issuesTableName, filterByFields: filterFields ?? [], sortByFields: sortFields ?? [] }, rowCount)} 
        loading={issuesLoading} >
      </GridView>
      {insideChanging && <span>/</span>}
    </div>
  );
}


function mapStateToProps(state) {

  const alert = state.alert;
  const masterData = state.masterData;
  const {isMenuLoading, flatMenu} = state.navMenu;
  const { insideChanging, issuesLoading, issues, issuesCount, issuesLoadingFailure } = state.issuesStore;
  const {tabWindowId} = state.authentication;

  return {alert, isMenuLoading, flatMenu, masterData, 
    insideChanging,
    issuesLoading, issues, issuesCount, issuesLoadingFailure, tabWindowId };

}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    loadIssues: (offset, rowsCount) => dispatch(IssuesActions.loadIssues(offset, rowsCount)),
    getIssuesCount: () => dispatch(IssuesActions.getIssuesCount()),
    createIssuesFilterSort: (filterSortObject, rowCount) => dispatch(IssuesActions.createIssuesFilterSort(filterSortObject, rowCount)),
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Issues);
