//projectReducers.js

import * as DerivationTreeStore from '../derivationTreeEditor/DerivationTreeStore';
import * as RootEditorStore from '../derivationTreeEditor/RootEditorStore';
import * as FormulasStore from '../overviews/FormulasStore';
import * as RecountsStore from '../recounters/RecountsStore';
import * as IssuesStore from '../issues/IssuesStore';
import * as ModelEditorStore from '../overviews/ModelEditorStore';
import * as ModelOverviewsStore from '../overviews/ModelOverviewsStore';

const projectReducers = {
  derivTree: DerivationTreeStore.reducer,
  rootEditorStore: RootEditorStore.reducer,
  formulasStore: FormulasStore.reducer,
  recountsStore: RecountsStore.reducer,
  issuesStore: IssuesStore.reducer,
  modelEditorStore: ModelEditorStore.reducer,
  modelOverviewsStore: ModelOverviewsStore.reducer
};
  
export default projectReducers;
  