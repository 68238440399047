//LinkColumn.js

import React from 'react';
import { Link } from 'react-router-dom';


const LinkColumn = (props) => {

  const { linkBase, idFieldName, value, index, offset, showRows, record, onGetLink, onGetLinkText, onClick } = props;

  //console.log("LinkColumn props=", props);
  //console.log("LinkColumn value=", value);
  //console.log("LinkColumn onGetLink=", onGetLink);

  let linkTo = null;
  let linkText = value;

  if ( onGetLink )
  {
    linkTo = onGetLink(record);
  }


  if ( onGetLinkText )
  {
    linkText = onGetLinkText(record);
  }

  if( !linkTo && linkBase && idFieldName && record && idFieldName in record ) {
    linkTo = linkBase;
    if ( ! linkTo.startsWith('/') )
      linkTo = '/' + linkTo;
    if ( ! linkTo.endsWith('/') )
      linkTo = linkTo + '/';
    linkTo = linkTo + record[idFieldName];
  }

  if ( linkTo )
    return (<Link to={linkTo} onClick={(e) => {
      // e.preventDefault(); ეს საჭირო არ არის, რადგან კლინკზე აღარ გადადის
      if ( onClick )
        onClick(record, index, offset, showRows);
    }} >{linkText}</Link>);

  return linkText;
}

export default LinkColumn;
