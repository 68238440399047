import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import FrmRights from '../rights/FrmRights';
import Home from '../../project/Home';
import Layout from './Layout';
import MdItemEdit from '../masterdata/MdItemEdit';
import MdList from '../masterdata/MdList';
import Profile from '../user/Profile';

import { ProjectContext } from '../../project/ProjectProvider';

const AuthApp = () => {

  //console.log("AuthApp");

  const { AuthAppRoutes } = useContext(ProjectContext);
  
  return (
    <Layout>
      <div className="content-wrapper">
          <Route exact path='/' component={Home} />
          <Route path='/mdList/:dataType/:recName?' component={MdList} />
          <Route path='/mdItemEdit/:dataType/:mdIdValue?' component={MdItemEdit} />
          <Route path='/Rights/:rView?/:dtKey?/:key?' component={FrmRights} />
          <Route path='/profile' component={Profile} />
          <AuthAppRoutes />
      </div>

    </Layout>
  );
}


export default AuthApp;

