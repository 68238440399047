  //MdListView.js
import React, { useEffect, useState, useCallback } from 'react';
import { Form, Table, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GetDisplayValue } from '../modules/GetDisplayValue'
import BsComboBox from './BsComboBox';
import { useForman } from '../common/MyHooks';
import { NzInt, filterByHeader } from '../common/myFunctions';




const MdListView = (props) => {

const { dataType, table, gridColumns, masterData, curscrollTo, backLigth, firstFilter, readOnly, filterChanged } = props;

  //console.log("MdListView props=", props)

  const [curTableName, setCurTableName] = useState(null);

  const {dtTable} = dataType;

  const  getFilterFirstItem = useCallback(() => {
    const firstItem = {...table[0]};
    if ( firstFilter ) {
      for(let propertyName in firstItem) {
        if ( ! firstFilter[propertyName] ) {
          firstItem[propertyName] = -1;
        }
      }
    }
    else {
      for(let propertyName in firstItem) {
        firstItem[propertyName] = -1;
      }
    }
    return firstItem;
  },[firstFilter, table]);

  const [frm, changeField, getError, , , setFormData, ] = useForman(null);

  useEffect(() => {
    if (curTableName !== dtTable) {
      setCurTableName(dtTable);
      setFormData(getFilterFirstItem());
      return;
    }
  },[curTableName, dtTable, setFormData, getFilterFirstItem]);

  //console.log("MdListView frm=", frm)

  if ( ! gridColumns )
  {
    return (
      <div>
        ინფორმაციის ჩატვირთვის პრობლემა
      </div>
    );
  }


  return (
    <div>
      <Row>
        <Col sm="10" >
          <h4>{dataType.dtName}</h4>
        </Col>
        <Col sm="2" align="right">
          { !readOnly && dataType.create && 
          <Link to= {`/mdItemEdit/${dataType.dtTable}`} className="btn btn-primary">
            <FontAwesomeIcon icon="plus" /> ახალი</Link>
          }
        </Col>
      </Row>
      {(table === undefined || table === null ) && <div>ცხრილი ჩატვირთული არ არის</div>}
      {(table !== undefined && table !== null && table.length === 0) && <div>ცხრილში ჩანაწერები არ არის</div>}
      {(table !== undefined && table !== null && table.length > 0) &&
        <Table striped bordered hover responsive="sm" className="table table-sm table-bordered">
          <thead>
            <tr>
              {gridColumns.filter(col => col.visible).map(col => {

                const caption = col.caption ? col.caption : "";
                const fieldName = col.fieldName ? col.fieldName : "";
                switch (col.name) {
                  case "Lookup":
                    if ( col.dataMember && col.valueMember && col.displayMember )
                      return (
                        <th key={caption}>{caption}
                          <BsComboBox name={fieldName} value={frm ? frm[fieldName] : null} 
                            dataMember={masterData.mdRepo[col.dataMember]} valueMember={col.valueMember} 
                            displayMember={col.displayMember} getError={getError} 
                            sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } 
                            firstItemIsSelectable  onChangeValue={(name, newValue) => {
                              changeField(name, newValue);
                              const tfrm = {...frm};
                              tfrm[fieldName] = newValue;
                              if ( filterChanged )
                                filterChanged(tfrm);
                            }} />
                        </th>                            
                      );
                    if ( col.rowSource ) {
                      const rows = [];
                      const rsarr = col.rowSource.split(";");
                      rsarr.forEach((item, index) => {
                        if (index % 2 === 0)
                          return;
                        rows.push({ val: rsarr[index-1], disp: item });
                      });
                      return (
                        <th key={caption}>{caption}
                          <BsComboBox name={fieldName} value={frm ? frm[fieldName] : null} dataMember={rows} 
                            valueMember="val" displayMember="disp" getError={getError} 
                            sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable 
                            onChangeValue={(name, newValue) => {
                              changeField(name, newValue);
                              const tfrm = {...frm};
                              tfrm[fieldName] = newValue;
                              if ( filterChanged )
                                filterChanged(tfrm);
                            }} />
                        </th>                            
                      );
                    }
                    break;
                  //case "boolean":
                  //  return (<OneCheckBoxControl key={fieldName} controlId={fieldName} label={caption} value={frm[fieldName]} 
                  //  getError={getError} onChangeValue={changeField} />);
                  case "Integer": {
                    //ამოვიღოთ ველის ყველა მნიშვნელობა გამეორებების გარეშე და გადავიყვანოთ კომბოსთვის საჭირო ფორმაში
                    //const rows = [...new Set(table.map(m=> m[fieldName]))].map(m=> {return { val: m }});
                    return (
                      <th key={caption}>{caption}
                        <Form.Control type="number" value={frm ? frm[fieldName] : ""} onChange={(e) => {
                          const { value } = e.target;
                          changeField(fieldName, NzInt(value,-1));
                          const tfrm = {...frm};
                          tfrm[fieldName] = NzInt(value,-1);
                          if ( filterChanged )
                            filterChanged(tfrm);
                        }} autoComplete="off" />
                      

                        {/* <BsComboBox key={fieldName} name={fieldName} 
                          label={caption} value={frm ? frm[fieldName] : null} 
                          dataMember={rows} valueMember="val" displayMember="val"
                          getError={getError} onChangeValue={changeField} 
                          sortByDisplayMember={false} 
                          firstItem={ { id: -1, name: "ყველა" } }
                          firstItemIsSelectable /> */}
                      </th>                            
                    );
                  }
                  //   if ( col.isSortId )
                  //     return (<OneNumberControl key={fieldName} controlId={fieldName} label={caption} 
                  //       value={frm[fieldName]+1} getError={getError} onChangeValue={(id, value) => {
                  //         changeField(id, value-1);
                  //       }} />);
                  //   else
                  //     return (<OneNumberControl key={fieldName} controlId={fieldName} label={caption} 
                  //       value={frm[fieldName]} getError={getError} onChangeValue={changeField} />);
                  default:
                    break;
                }
                return (<th key={caption}>{caption}</th>);
              }) }
            </tr>
          </thead>
          <tbody>
            {table.filter(f=>filterByHeader(f,frm)).map(mdItem => {
              const bl = (curscrollTo === mdItem[dataType.idFieldName]);
              return (
                <tr key={mdItem[dataType.idFieldName]} ref={ bl ? backLigth : null} >
                  {gridColumns.filter(col=>col.visible).map((col, index) => (
                    <td key={col.fieldName} className={ bl ? "backLigth" : null}>{GetDisplayValue(masterData, mdItem, col)}</td>
                  ))}
                  { !readOnly && (dataType.update || dataType.delete) &&
                    <td width="50px">
                      <div className="btn-toolbar pull-right">
                        <Link to={`/mdItemEdit/${dataType.dtTable}/${mdItem[dataType.idFieldName]}`} 
                          className="btn btn-primary">
                            <FontAwesomeIcon icon="edit" /></Link>
                      </div>
                    </td>
                  }
                </tr>
            );})}
          </tbody>
        </Table>
      }
    </div>
  );




}

export default MdListView