//NameListEditor.js
import React from 'react';
import { Link } from 'react-router-dom';


const NameListEditor = (props) => {

  const { dataType, tableForEdit, curscrollTo, backLigth, saveReturnPageName } = props;

  //console.log("NameListEditor props=", props);

  const tableName = dataType.dtTable;
  const {idFieldName, nameFieldName, keyFieldName} = dataType;
  const caption= dataType.dtName;

  return (
    <div>
      <h4>{caption} ({tableForEdit.length}) {dataType.create && (<Link to={`/mdItemEdit/${tableName}`}> + </Link>)}</h4>
      <ol>
        {tableForEdit
          .sort((a, b) => a.sortId - b.sortId)
          .map(m => {
            const bl = (curscrollTo.tabKey === tableName && curscrollTo.recId === m[idFieldName]);
            //console.log("NameListEditor map m=", m);
            //console.log("NameListEditor map idFieldName=", idFieldName);
            return (
              <li key={m[idFieldName].toString()} ref={ bl ? backLigth : null} >
                {!dataType.update && !dataType.delete && (m[nameFieldName]??m[keyFieldName])}
                {(dataType.update || dataType.delete) && <Link to={`/mdItemEdit/${tableName}/${m[idFieldName]}`} 
                  className={ bl ? "backLigth" : null} onClick={(e) => {
                  // e.preventDefault(); ეს საჭირო არ არის, რადგან ამ ინსტრუქციის არსებობისას ლინკზე აღარ გადადის.
                  //ვინახავთ გვერდის სახელს, საიდანაც მოხდა რედაქტორის გახსნა. იმისათვის, რომ კორექტულად მოხდეს უკან დაბრუნება
                  //props.saveReturnPageName(props.match.url.split('/')[1]);
                  saveReturnPageName();
                  }} >{m[nameFieldName]??m[keyFieldName]}</Link>}
              </li>
            );
          })}
      </ol>
    </div>
  );
}

export default NameListEditor;
