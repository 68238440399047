import React from 'react';

const Home = () => {

  return (
    <div>
    <h1>გამარჯობა</h1>
    <p>კეთილი იყოს შენი მობრძანება</p>
    <p>ეს არის ქართული ენის მოდელის რედაქტორი</p>
    <p>მომავალში აქ დაემატება ხშირად გამოყენებადი ბმულები</p>
  </div>
  );
}

export default Home;

//<ul>
//      <li><a href='/'>ბმული 1</a></li>
//      <li><a href='/'>ბმული 2</a></li>
//      <li><a href='/'>ბმული 3</a></li>
//    </ul>
