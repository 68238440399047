import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';


const MessageBox = (props) => {

  const [showMsg, setShowMsg] = useState(false);

  useEffect(() => {
    const { show } = props;
    setShowMsg(show);
  }, [props]);

  function handleClose() {
    setShowMsg(false);
    props.onClosed();
  }

  function handleConfirmed() {
    setShowMsg(false);
    props.onConfirmed();
  }

  //function handleShow() {
  //  setShowMsg(true);
  //}

  const { title, text, primaryButtonText, secondaryButtonText } = props;
  return (
    <Modal show={showMsg} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirmed}>
          {primaryButtonText}
        </Button>
        { secondaryButtonText && 
          <Button variant="secondary" onClick={handleClose}>
            {secondaryButtonText}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );

}

export default MessageBox;

