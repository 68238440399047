//ModelEditorStore.js
import apiClient from '../../carcass/modules/ApiClient';
//import { history } from '../../carcass/common/History';
//import { useHistory } from "react-router-dom";

const clearDeleteFailure = 'CLEAR_DELETE_FAILURE';

//phoneticsTypes
const requestLoadPhoneticsType = 'REQUEST_LOAD_PHONETICS_TYPE';
const setPhoneticsType = 'SET_PHONETICS_TYPE';
const getPhoneticsTypeFailure = 'GET_PHONETICS_TYPE_FAILURE';

const startSavingPhoneticsType = 'START_SAVING_PHONETICS_TYPE';
const finishedSavePhoneticsType = 'FINISH_SAVING_PHONETICS_TYPE';
const createPhoneticsTypeSuccess = 'CREATE_PHONETICS_TYPE_SUCCESS';
const createPhoneticsTypeFailure = 'CREATE_PHONETICS_TYPE_FAILURE'


const requestDeletePhoneticsType = 'REQUEST_DELETE_PHONETICS_TYPE';
const deletePhoneticsTypeSuccess = 'DELETE_PHONETICS_TYPE_SUCCESS';
const deletePhoneticsTypeFailure = 'DELETE_PHONETICS_TYPE_FAILURE';

//phoneticsOptions
const requestLoadPhoneticsOption = 'REQUEST_LOAD_PHONETICS_OPTION';
const setPhoneticsOption = 'SET_PHONETICS_OPTION';
const getPhoneticsOptionFailure = 'GET_PHONETICS_OPTION_FAILURE';

const startSavingPhoneticsOption = 'START_SAVING_PHONETICS_OPTION';
const finishedSavePhoneticsOption = 'FINISH_SAVING_PHONETICS_OPTION';
const createPhoneticsOptionSuccess = 'CREATE_PHONETICS_OPTION_SUCCESS';
const createPhoneticsOptionFailure = 'CREATE_PHONETICS_OPTION_FAILURE'

const requestDeletePhoneticsOption = 'REQUEST_DELETE_PHONETICS_OPTION';
const deletePhoneticsOptionSuccess = 'DELETE_PHONETICS_OPTION_SUCCESS';
const deletePhoneticsOptionFailure = 'DELETE_PHONETICS_OPTION_FAILURE';

//morphemes
const requestLoadMorpheme = 'REQUEST_LOAD_MORPHEME';
const setMorpheme = 'SET_MORPHEME';
const getMorphemeFailure = 'GET_MORPHEME_FAILURE';

const startSavingMorpheme = 'START_SAVING_MORPHEME';
const finishedSaveMorpheme = 'FINISH_SAVING_MORPHEME';
const createMorphemeSuccess = 'CREATE_MORPHEME_SUCCESS';
const createMorphemeFailure = 'CREATE_MORPHEME_FAILURE'

const requestDeleteMorpheme = 'REQUEST_DELETE_MORPHEME';
const deleteMorphemeSuccess = 'DELETE_MORPHEME_SUCCESS';
const deleteMorphemeFailure = 'DELETE_MORPHEME_FAILURE';

//DerivationFormulas
const requestLoadDerivationFormula = 'REQUEST_LOAD_DERIVATION_FORMULA';
const setDerivationFormula = 'SET_DERIVATION_FORMULA';
const getDerivationFormulaFailure = 'GET_DERIVATION_FORMULA_FAILURE';

const startSavingDerivationFormula = 'START_SAVING_DERIVATION_FORMULA';
const finishedSaveDerivationFormula = 'FINISH_SAVING_DERIVATION_FORMULA';
const createDerivationFormulaSuccess = 'CREATE_DERIVATION_FORMULA_SUCCESS';
const createDerivationFormulaFailure = 'CREATE_DERIVATION_FORMULA_FAILURE'

const requestDeleteDerivationFormula = 'REQUEST_DELETE_DERIVATION_FORMULA';
const deleteDerivationFormulaSuccess = 'DELETE_DERIVATION_FORMULA_SUCCESS';
const deleteDerivationFormulaFailure = 'DELETE_DERIVATION_FORMULA_FAILURE';

//NounParadigm

//NounParadigmFormulas
const requestLoadNounParadigmFormula = 'REQUEST_LOAD_NOUN_PARADIGM_FORMULA';
const setNounParadigmFormula = 'SET_NOUN_PARADIGM_FORMULA';
const getNounParadigmFormulaFailure = 'GET_NOUN_PARADIGM_FORMULA_FAILURE';

const startSavingNounParadigmFormula = 'START_SAVING_NOUN_PARADIGM_FORMULA';
const finishedSaveNounParadigmFormula = 'FINISH_SAVING_NOUN_PARADIGM_FORMULA';
const createNounParadigmFormulaSuccess = 'CREATE_NOUN_PARADIGM_FORMULA_SUCCESS';
const createNounParadigmFormulaFailure = 'CREATE_NOUN_PARADIGM_FORMULA_FAILURE'

const requestDeleteNounParadigmFormula = 'REQUEST_DELETE_NOUN_PARADIGM_FORMULA';
const deleteNounParadigmFormulaSuccess = 'DELETE_NOUN_PARADIGM_FORMULA_SUCCESS';
const deleteNounParadigmFormulaFailure = 'DELETE_NOUN_PARADIGM_FORMULA_FAILURE';

//VerbRowParadigmFormulas
const requestLoadVerbRowParadigmFormula = 'REQUEST_LOAD_VERB_ROW_PARADIGM_FORMULA';
const setVerbRowParadigmFormula = 'SET_VERB_ROW_PARADIGM_FORMULA';
const getVerbRowParadigmFormulaFailure = 'GET_VERB_ROW_PARADIGM_FORMULA_FAILURE';

const startSavingVerbRowParadigmFormula = 'START_SAVING_VERB_ROW_PARADIGM_FORMULA';
const finishedSaveVerbRowParadigmFormula = 'FINISH_SAVING_VERB_ROW_PARADIGM_FORMULA';
const createVerbRowParadigmFormulaSuccess = 'CREATE_VERB_ROW_PARADIGM_FORMULA_SUCCESS';
const createVerbRowParadigmFormulaFailure = 'CREATE_VERB_ROW_PARADIGM_FORMULA_FAILURE'

const requestDeleteVerbRowParadigmFormula = 'REQUEST_DELETE_VERB_ROW_PARADIGM_FORMULA';
const deleteVerbRowParadigmFormulaSuccess = 'DELETE_VERB_ROW_PARADIGM_FORMULA_SUCCESS';
const deleteVerbRowParadigmFormulaFailure = 'DELETE_VERB_ROW_PARADIGM_FORMULA_FAILURE';

//VerbPersonMarkerFormulas
const requestLoadVerbPersonMarkerFormula = 'REQUEST_LOAD_VERB_PERSON_MARKER_FORMULA';
const setVerbPersonMarkerFormula = 'SET_VERB_PERSON_MARKER_FORMULA';
const getVerbPersonMarkerFormulaFailure = 'GET_VERB_PERSON_MARKER_FORMULA_FAILURE';

const startSavingVerbPersonMarkerFormula = 'START_SAVING_VERB_PERSON_MARKER_FORMULA';
const finishedSaveVerbPersonMarkerFormula = 'FINISH_SAVING_VERB_PERSON_MARKER_FORMULA';
const createVerbPersonMarkerFormulaSuccess = 'CREATE_VERB_PERSON_MARKER_FORMULA_SUCCESS';
const createVerbPersonMarkerFormulaFailure = 'CREATE_VERB_PERSON_MARKER_FORMULA_FAILURE'

const requestDeleteVerbPersonMarkerFormula = 'REQUEST_DELETE_VERB_PERSON_MARKER_FORMULA';
const deleteVerbPersonMarkerFormulaSuccess = 'DELETE_VERB_PERSON_MARKER_FORMULA_SUCCESS';
const deleteVerbPersonMarkerFormulaFailure = 'DELETE_VERB_PERSON_MARKER_FORMULA_FAILURE';


const initialState = { 
  DeleteFailure: false, 
  savingPhoneticsType: false, loadingPhoneticsType: false, workingOnDeletePhoneticsType: false, phoneticsTypeForEdit: null,
  savingPhoneticsOption: false, loadingPhoneticsOption: false, workingOnDeletePhoneticsOption: false, phoneticsOptionForEdit: null,
  savingMorpheme: false, loadingMorpheme: false, workingOnDeleteMorpheme: false, morphemeForEdit: null,
  savingDerivationFormula: false, loadingDerivationFormula: false, workingOnDeleteDerivationFormula: false, derivationFormulaForEdit: null,
  savingNounParadigmFormula: false, loadingNounParadigmFormula: false, workingOnDeleteNounParadigmFormula: false, nounParadigmFormulaForEdit: null,
  savingVerbRowParadigmFormula: false, loadingVerbRowParadigmFormula: false, workingOnDeleteVerbRowParadigmFormula: false, verbRowParadigmFormulaForEdit: null,
  savingVerbPersonMarkerFormula: false, loadingVerbPersonMarkerFormula: false, workingOnDeleteVerbPersonMarkerFormula: false, verbPersonMarkerFormulaForEdit: null
};

export const actionCreators = {

  clearDeletingFailure: () => {
    return { type: clearDeleteFailure };
  },

  //phoneticsTypes
  getOnePhoneticsTypeById: (phtId) => async (dispatch, getState) => {
    //console.log('ModelEditorStore.js apiClient.Get', "/modeleditor/phoneticstype/${phtId}");
    await apiClient.Get(`/modeleditor/phoneticstype/${phtId}`,
      { get: requestLoadPhoneticsType, 
        set: setPhoneticsType, 
        failure: getPhoneticsTypeFailure },
      dispatch, getState, true );
  },

  createPhoneticsType: (history, pht) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js createPhoneticsType pht=', pht);
    //const phtName = pht.phoneticsType.phtName;
    if (await apiClient.Post('/modeleditor/phoneticstype',
      { get: startSavingPhoneticsType, set: createPhoneticsTypeSuccess, failure: createPhoneticsTypeFailure },
      dispatch, getState, true, null, true, pht)){
        const phtId = getState().modelEditorStore.phoneticsTypeForEdit.phoneticsType.phtId;
        history.push(`/phoneticsTypesOverview/phoneticstype/${phtId}`);
    }
  },

  updatePhoneticsType: (history, pht) => async (dispatch, getState) => {
    //const history = useHistory();
    const phtId = pht.phoneticsType.phtId;
    //const phtName = pht.phoneticsType.phtName;
    //console.log('MasterData.js apiClient.Put', `/modeleditor/phoneticstype/${phtId}`);
    if (await apiClient.Put(`/modeleditor/phoneticstype/${phtId}`,
      { get: startSavingPhoneticsType, set: finishedSavePhoneticsType, failure: finishedSavePhoneticsType },
      dispatch, getState, true, pht, pht))
      history.push(`/phoneticsTypesOverview/phoneticstype/${phtId}`);
  },

  deletePhoneticsType: (history, phtId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('ModelEditorStore.js apiClient.Delete', `/modeleditor/phoneticstype/${phtId}`);
    if (await apiClient.Delete(`/modeleditor/phoneticstype/${phtId}`,
      { get: requestDeletePhoneticsType, set: deletePhoneticsTypeSuccess, failure: deletePhoneticsTypeFailure },
      dispatch, getState, true, phtId)) {
        history.push('/phoneticsTypesOverview');
    }
  },

  //phoneticsOptions
  getOnePhoneticsOptionById: (phoId) => async (dispatch, getState) => {
    //console.log('ModelEditorStore.js apiClient.Get', "/modeleditor/phoneticsoption/${phoId}");
    await apiClient.Get(`/modeleditor/phoneticsoption/${phoId}`,
      { get: requestLoadPhoneticsOption, 
        set: setPhoneticsOption, 
        failure: getPhoneticsOptionFailure },
      dispatch, getState, true );
  },

  createPhoneticsOption: (history, pho) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js createPhoneticsoption pho=', pho);
    //const phoName = pho.phoneticsOption.phoName;
    if (await apiClient.Post('/modeleditor/phoneticsoption',
      { get: startSavingPhoneticsOption, set: createPhoneticsOptionSuccess, failure: createPhoneticsOptionFailure },
      dispatch, getState, true, null, true, pho)){
        const phoId = getState().modelEditorStore.phoneticsOptionForEdit.phoneticsOption.phoId;
        history.push(`/phoneticsTypesOverview/phoneticsoption/${phoId}`);
    }
  },

  updatePhoneticsOption: (history, pho) => async (dispatch, getState) => {
    //const history = useHistory();
    const phoId = pho.phoneticsOption.phoId;
    //const phoName = pho.phoneticsOption.phoName;
    //console.log('MasterData.js apiClient.Put', `/modeleditor/phoneticsoption/${phoId}`);
    if (await apiClient.Put(`/modeleditor/phoneticsoption/${phoId}`,
      { get: startSavingPhoneticsOption, set: finishedSavePhoneticsOption, failure: finishedSavePhoneticsOption },
      dispatch, getState, true, pho, pho))
      history.push(`/phoneticsTypesOverview/phoneticsoption/${phoId}`);
  },

  deletePhoneticsOption: (history, phoId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('ModelEditorStore.js apiClient.Delete', `/modeleditor/phoneticsoption/${phoId}`);
    if (await apiClient.Delete(`/modeleditor/phoneticsoption/${phoId}`,
      { get: requestDeletePhoneticsOption, set: deletePhoneticsOptionSuccess, failure: deletePhoneticsOptionFailure },
      dispatch, getState, true, phoId)) {
        history.push('/phoneticsTypesOverview');
    }
  },

  //morphemes
  getOneMorphemeById: (mrpId) => async (dispatch, getState) => {
    //console.log('ModelEditorStore.js apiClient.Get', "/modeleditor/morpheme/${mrpId}");
    await apiClient.Get(`/modeleditor/morpheme/${mrpId}`,
      { get: requestLoadMorpheme, 
        set: setMorpheme, 
        failure: getMorphemeFailure },
      dispatch, getState, true );
  },

  createMorpheme: (history, mrp) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js createMorpheme pho=', pho);
    //const mrpId = mrp.morpheme.mrpId;
    if (await apiClient.Post('/modeleditor/morpheme',
      { get: startSavingMorpheme, set: createMorphemeSuccess, failure: createMorphemeFailure },
      dispatch, getState, true, null, true, mrp)) {
      const mrpId = getState().modelEditorStore.morphemeForEdit.morpheme.mrpId;
      history.push(`/morphemesOverview/mrp/${mrpId}`);
    }
  },

  updateMorpheme: (history, mrp) => async (dispatch, getState) => {
    //const history = useHistory();
    const mrpId = mrp.morpheme.mrpId;
    //console.log('MasterData.js apiClient.Put', `/modeleditor/morpheme/${mrpId}`);
    if (await apiClient.Put(`/modeleditor/morpheme/${mrpId}`,
      { get: startSavingMorpheme, set: finishedSaveMorpheme, failure: finishedSaveMorpheme },
      dispatch, getState, true, mrp, mrp))
      history.push(`/morphemesOverview/mrp/${mrpId}`);
  },

  deleteMorpheme: (history, mrpId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('ModelEditorStore.js apiClient.Delete', `/modeleditor/morpheme/${mrpId}`);
    if (await apiClient.Delete(`/modeleditor/morpheme/${mrpId}`,
      { get: requestDeleteMorpheme, set: deleteMorphemeSuccess, failure: deleteMorphemeFailure },
      dispatch, getState, true, mrpId)) {
        history.push('/morphemesOverview');
    }
  },

  //DerivationFormulas
  getOneDerivationFormulaById: (dfId) => async (dispatch, getState) => {
    //console.log('ModelEditorStore.js apiClient.Get', "/modeleditor/derivationformula/${dfId}");
    await apiClient.Get(`/modeleditor/derivationformula/${dfId}`,
      { get: requestLoadDerivationFormula, 
        set: setDerivationFormula, 
        failure: getDerivationFormulaFailure },
      dispatch, getState, true );
  },

  createDerivationFormula: (history, df) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js createDerivationFormula pho=', pho);
    //const dfId = df.derivationFormula.dfId;
    if (await apiClient.Post('/modeleditor/derivationformula',
      { get: startSavingDerivationFormula, set: createDerivationFormulaSuccess, failure: createDerivationFormulaFailure },
      dispatch, getState, true, null, true, df)) {
      const dfId = getState().modelEditorStore.derivationFormulaForEdit.derivationFormula.dfId;
      history.push(`/derivationFormulasOverview/df/${dfId}`);
    }
  },

  updateDerivationFormula: (history, df) => async (dispatch, getState) => {
    //const history = useHistory();
    const dfId = df.derivationFormula.dfId;
    //console.log('MasterData.js apiClient.Put', `/modeleditor/derivationformula/${dfId}`);
    if (await apiClient.Put(`/modeleditor/derivationformula/${dfId}`,
      { get: startSavingDerivationFormula, set: finishedSaveDerivationFormula, failure: finishedSaveDerivationFormula },
      dispatch, getState, true, df, df))
      history.push(`/derivationFormulasOverview/df/${dfId}`);
  },

  deleteDerivationFormula: (history, dfId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('ModelEditorStore.js apiClient.Delete', `/modeleditor/derivationformula/${dfId}`);
    if (await apiClient.Delete(`/modeleditor/derivationformula/${dfId}`,
      { get: requestDeleteDerivationFormula, set: deleteDerivationFormulaSuccess, failure: deleteDerivationFormulaFailure },
      dispatch, getState, true, dfId)) {
        history.push('/derivationFormulasOverview');
    }
  },

  //NounParadigmFormulas
  getOneNounParadigmFormulaById: (nprId) => async (dispatch, getState) => {
    //console.log('ModelEditorStore.js apiClient.Get', "/modeleditor/nounparadigmformula/${nprId}");
    await apiClient.Get(`/modeleditor/nounparadigmformula/${nprId}`,
      { get: requestLoadNounParadigmFormula, 
        set: setNounParadigmFormula, 
        failure: getNounParadigmFormulaFailure },
      dispatch, getState, true );
  },

  createNounParadigmFormula: (history, npr, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js createNounParadigmFormula pho=', pho);
    if (await apiClient.Post('/modeleditor/nounparadigmformula',
      { get: startSavingNounParadigmFormula, set: createNounParadigmFormulaSuccess, failure: createNounParadigmFormulaFailure },
      dispatch, getState, true, null, true, npr)) {
      const nprId = getState().modelEditorStore.nounParadigmFormulaForEdit.nounParadigmFormula.nprId;
      history.push(`/nounParadigmFormulas/${paradigmId}/${nprId}`);
    }
  },

  updateNounParadigmFormula: (history, npr, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    const nprId = npr.nounParadigmFormula.nprId;
    //console.log('MasterData.js apiClient.Put', `/modeleditor/nounparadigmformula/${nprId}`);
    if (await apiClient.Put(`/modeleditor/nounparadigmformula/${nprId}`,
      { get: startSavingNounParadigmFormula, set: finishedSaveNounParadigmFormula, failure: finishedSaveNounParadigmFormula },
      dispatch, getState, true, npr, npr))
      history.push(`/nounParadigmFormulas/${paradigmId}/${nprId}`);
  },

  deleteNounParadigmFormula: (history, nprId, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('ModelEditorStore.js apiClient.Delete', `/modeleditor/nounparadigmformula/${nprId}`);
    if (await apiClient.Delete(`/modeleditor/nounparadigmformula/${nprId}`,
      { get: requestDeleteNounParadigmFormula, set: deleteNounParadigmFormulaSuccess, failure: deleteNounParadigmFormulaFailure },
      dispatch, getState, true, nprId)) {
        history.push(`/nounParadigmFormulas/${paradigmId}`);
    }
  },

  //VerbRowParadigmFormulas
  getOneVerbRowParadigmFormulaById: (vprId) => async (dispatch, getState) => {
    //console.log('ModelEditorStore.js apiClient.Get', "/modeleditor/verbparadigmformula/${vprId}");
    await apiClient.Get(`/modeleditor/verbparadigmformula/${vprId}`,
      { get: requestLoadVerbRowParadigmFormula, 
        set: setVerbRowParadigmFormula, 
        failure: getVerbRowParadigmFormulaFailure },
      dispatch, getState, true );
  },

  createVerbRowParadigmFormula: (history, vpr, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js createVerbRowParadigmFormula pho=', pho);
    if (await apiClient.Post('/modeleditor/verbparadigmformula',
      { get: startSavingVerbRowParadigmFormula, set: createVerbRowParadigmFormulaSuccess, failure: createVerbRowParadigmFormulaFailure },
      dispatch, getState, true, null, true, vpr)) {
      const vprId = getState().modelEditorStore.verbRowParadigmFormulaForEdit.verbParadigmFormula.vprId;
      history.push(`/verbRowParadigmFormulas/${paradigmId}/${vprId}`);
    }
  },

  updateVerbRowParadigmFormula: (history, vpr, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    const vprId = vpr.verbParadigmFormula.vprId;
    //console.log('MasterData.js apiClient.Put', `/modeleditor/verbparadigmformula/${vprId}`);
    if (await apiClient.Put(`/modeleditor/verbparadigmformula/${vprId}`,
      { get: startSavingVerbRowParadigmFormula, set: finishedSaveVerbRowParadigmFormula, failure: finishedSaveVerbRowParadigmFormula },
      dispatch, getState, true, vpr, vpr))
      history.push(`/verbRowParadigmFormulas/${paradigmId}/${vprId}`);
  },

  deleteVerbRowParadigmFormula: (history, vprId, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('ModelEditorStore.js apiClient.Delete', `/modeleditor/verbparadigmformula/${vprId}`);
    if (await apiClient.Delete(`/modeleditor/verbparadigmformula/${vprId}`,
      { get: requestDeleteVerbRowParadigmFormula, set: deleteVerbRowParadigmFormulaSuccess, failure: deleteVerbRowParadigmFormulaFailure },
      dispatch, getState, true, vprId)) {
        history.push(`/verbRowParadigmFormulas/${paradigmId}`);
    }
  },


  //VerbPersonMarkerFormulas
  getOneVerbPersonMarkerFormulaById: (vpmprId) => async (dispatch, getState) => {
    //console.log('ModelEditorStore.js apiClient.Get', "/modeleditor/verbpersonmarkerformula/${vpmprId}");
    await apiClient.Get(`/modeleditor/verbpersonmarkerformula/${vpmprId}`,
      { get: requestLoadVerbPersonMarkerFormula, 
        set: setVerbPersonMarkerFormula, 
        failure: getVerbPersonMarkerFormulaFailure },
      dispatch, getState, true );
  },

  createVerbPersonMarkerFormula: (history, vpmpr, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('MasterData.js createVerbPersonMarkerFormulas pho=', pho);
    if (await apiClient.Post('/modeleditor/verbpersonmarkerformula',
      { get: startSavingVerbPersonMarkerFormula, set: createVerbPersonMarkerFormulaSuccess, failure: createVerbPersonMarkerFormulaFailure },
      dispatch, getState, true, null, true, vpmpr)) {
      const vpmprId = getState().modelEditorStore.verbPersonMarkerFormulasForEdit.verbPersonMarkerFormula.vpmprId;
      history.push(`/verbPersonMarkerFormulas/${paradigmId}/${vpmprId}`);
    }
  },

  updateVerbPersonMarkerFormula: (history, vpmpr, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    const vpmprId = vpmpr.verbPersonMarkerFormula.vpmprId;
    if (await apiClient.Put(`/modeleditor/verbpersonmarkerformula/${vpmprId}`,
      { get: startSavingVerbPersonMarkerFormula, set: finishedSaveVerbPersonMarkerFormula, failure: finishedSaveVerbPersonMarkerFormula },
      dispatch, getState, true, vpmpr, vpmpr))
      history.push(`/verbPersonMarkerFormulas/${paradigmId}/${vpmprId}`);
  },

  deleteVerbPersonMarkerFormula: (history, vpmprId, paradigmId) => async (dispatch, getState) => {
    //const history = useHistory();
    //console.log('ModelEditorStore.js apiClient.Delete', `/modeleditor/verbpersonmarkerformula/${vpmprId}`);
    if (await apiClient.Delete(`/modeleditor/verbpersonmarkerformula/${vpmprId}`,
      { get: requestDeleteVerbPersonMarkerFormula, set: deleteVerbPersonMarkerFormulaSuccess, failure: deleteVerbPersonMarkerFormulaFailure },
      dispatch, getState, true, vpmprId)) {
        history.push(`/verbPersonMarkerFormulas/${paradigmId}`);
    }
  }




  
}


export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === clearDeleteFailure) {
    return { ...state, DeleteFailure: false };
  }

  //phoneticsTypes
  if (action.type === requestLoadPhoneticsType) {
    return { ...state, loadingPhoneticsType: true, phoneticsTypeForEdit: null };
  }
  
  if (action.type === setPhoneticsType) {
    const data = action.payload.data;
    return { ...state, loadingPhoneticsType: false, phoneticsTypeForEdit: data };
  }
  
  if (action.type === getPhoneticsTypeFailure) {
    return { ...state, loadingPhoneticsType: false, phoneticsTypeForEdit: null };
  }
  
  if (action.type === startSavingPhoneticsType) {
    return { ...state, savingPhoneticsType: true };
  }

  if (action.type === finishedSavePhoneticsType) {
    return { ...state, savingPhoneticsType: false };
  }

  if (action.type === createPhoneticsTypeSuccess) {
    const data = action.payload.data;
    return { ...state, savingPhoneticsType: false, phoneticsTypeForEdit: data };
  }

  if (action.type === createPhoneticsTypeFailure) {
    return { ...state, savingPhoneticsType: false, phoneticsTypeForEdit: null };
  }

if (action.type === requestDeletePhoneticsType) {
    return { ...state, workingOnDeletePhoneticsType: true, DeleteFailure: false };
  }

  if (action.type === deletePhoneticsTypeSuccess) {
    return { ...state, workingOnDeletePhoneticsType: false, DeleteFailure: false };
  }

  if (action.type === deletePhoneticsTypeFailure) {
    return { ...state, workingOnDeletePhoneticsType: false, DeleteFailure: true };
  }



  //phoneticsOptions
  if (action.type === requestLoadPhoneticsOption) {
    return { ...state, loadingPhoneticsOption: true, phoneticsOptionForEdit: null };
  }
  
  if (action.type === setPhoneticsOption) {
    const data = action.payload.data;
    return { ...state, loadingPhoneticsOption: false, phoneticsOptionForEdit: data };
  }
  
  if (action.type === getPhoneticsOptionFailure) {
    return { ...state, loadingPhoneticsOption: false, phoneticsOptionForEdit: null };
  }
  
  if (action.type === startSavingPhoneticsOption) {
    return { ...state, savingPhoneticsOption: true };
  }

  if (action.type === createPhoneticsOptionSuccess) {
    const data = action.payload.data;
    return { ...state, savingPhoneticsOption: false, phoneticsOptionForEdit: data };
  }
  
  if (action.type === createPhoneticsOptionFailure) {
    return { ...state, savingPhoneticsOption: false, phoneticsOptionForEdit: null };
  }

if (action.type === finishedSavePhoneticsOption) {
    return { ...state, savingPhoneticsOption: false };
  }

  if (action.type === requestDeletePhoneticsOption) {
    return { ...state, workingOnDeletePhoneticsOption: true, DeleteFailure: false };
  }

  if (action.type === deletePhoneticsOptionSuccess) {
    return { ...state, workingOnDeletePhoneticsOption: false, DeleteFailure: false };
  }

  if (action.type === deletePhoneticsOptionFailure) {
    return { ...state, workingOnDeletePhoneticsOption: false, DeleteFailure: true };
  }



  //morphemes
  if (action.type === requestLoadMorpheme) {
    return { ...state, loadingMorpheme: true, morphemeForEdit: null };
  }
  
  if (action.type === setMorpheme) {
    const data = action.payload.data;
    //console.log("ModelEditorStore setMorpheme action.payload=", action.payload)
    return { ...state, loadingMorpheme: false, morphemeForEdit: data };
  }
  
  if (action.type === getMorphemeFailure) {
    return { ...state, loadingMorpheme: false, morphemeForEdit: null };
  }
  
  if (action.type === startSavingMorpheme) {
    return { ...state, savingMorpheme: true };
  }

  if (action.type === createMorphemeSuccess) {
    const data = action.payload.data;
    return { ...state, savingMorpheme: false, morphemeForEdit: data };
  }

  if (action.type === createMorphemeFailure) {
    //const data = action.payload.data;
    return { ...state, savingMorpheme: false, morphemeForEdit: null };
  }

  if (action.type === finishedSaveMorpheme) {
    return { ...state, savingMorpheme: false };
  }

  if (action.type === requestDeleteMorpheme) {
    return { ...state, workingOnDeleteMorpheme: true, DeleteFailure: false };
  }

  if (action.type === deleteMorphemeSuccess) {
    return { ...state, workingOnDeleteMorpheme: false, DeleteFailure: false };
  }

  if (action.type === deleteMorphemeFailure) {
    return { ...state, workingOnDeleteMorpheme: false, DeleteFailure: true };
  }



  //DerivationFormulas
  if (action.type === requestLoadDerivationFormula) {
    return { ...state, loadingDerivationFormula: true, derivationFormulaForEdit: null };
  }
  
  if (action.type === setDerivationFormula) {
    const data = action.payload.data;
    //console.log("ModelEditorStore setDerivationFormula action.payload=", action.payload)
    return { ...state, loadingDerivationFormula: false, derivationFormulaForEdit: data };
  }
  
  if (action.type === getDerivationFormulaFailure) {
    return { ...state, loadingDerivationFormula: false, derivationFormulaForEdit: null };
  }
  
  if (action.type === startSavingDerivationFormula) {
    return { ...state, savingDerivationFormula: true };
  }

  if (action.type === createDerivationFormulaSuccess) {
    const data = action.payload.data;
    return { ...state, savingDerivationFormula: false, derivationFormulaForEdit: data };
  }

  if (action.type === createDerivationFormulaFailure) {
    //const data = action.payload.data;
    return { ...state, savingDerivationFormula: false, derivationFormulaForEdit: null };
  }

  if (action.type === finishedSaveDerivationFormula) {
    return { ...state, savingDerivationFormula: false };
  }

  if (action.type === requestDeleteDerivationFormula) {
    return { ...state, workingOnDeleteDerivationFormula: true, DeleteFailure: false };
  }

  if (action.type === deleteDerivationFormulaSuccess) {
    return { ...state, workingOnDeleteDerivationFormula: false, DeleteFailure: false };
  }

  if (action.type === deleteDerivationFormulaFailure) {
    return { ...state, workingOnDeleteDerivationFormula: false, DeleteFailure: true };
  }




  //NounParadigmFormulas
  if (action.type === requestLoadNounParadigmFormula) {
    return { ...state, loadingNounParadigmFormula: true, nounParadigmFormulaForEdit: null };
  }
  
  if (action.type === setNounParadigmFormula) {
    const data = action.payload.data;
    //console.log("ModelEditorStore setNounParadigmFormula action.payload=", action.payload)
    return { ...state, loadingNounParadigmFormula: false, nounParadigmFormulaForEdit: data };
  }
  
  if (action.type === getNounParadigmFormulaFailure) {
    return { ...state, loadingNounParadigmFormula: false, nounParadigmFormulaForEdit: null };
  }
  
  if (action.type === startSavingNounParadigmFormula) {
    return { ...state, savingNounParadigmFormula: true };
  }

  if (action.type === createNounParadigmFormulaSuccess) {
    const data = action.payload.data;
    return { ...state, savingNounParadigmFormula: false, nounParadigmFormulaForEdit: data };
  }

  if (action.type === createNounParadigmFormulaFailure) {
    //const data = action.payload.data;
    return { ...state, savingNounParadigmFormula: false, nounParadigmFormulaForEdit: null };
  }

  if (action.type === finishedSaveNounParadigmFormula) {
    return { ...state, savingNounParadigmFormula: false };
  }

  if (action.type === requestDeleteNounParadigmFormula) {
    return { ...state, workingOnDeleteNounParadigmFormula: true, DeleteFailure: false };
  }

  if (action.type === deleteNounParadigmFormulaSuccess) {
    return { ...state, workingOnDeleteNounParadigmFormula: false, DeleteFailure: false };
  }

  if (action.type === deleteNounParadigmFormulaFailure) {
    return { ...state, workingOnDeleteNounParadigmFormula: false, DeleteFailure: true };
  }




  //VerbRowParadigmFormulas
  if (action.type === requestLoadVerbRowParadigmFormula) {
    return { ...state, loadingVerbRowParadigmFormula: true, verbRowParadigmFormulaForEdit: null };
  }
  
  if (action.type === setVerbRowParadigmFormula) {
    const data = action.payload.data;
    //console.log("ModelEditorStore setVerbRowParadigmFormula action.payload=", action.payload)
    return { ...state, loadingVerbRowParadigmFormula: false, verbRowParadigmFormulaForEdit: data };
  }
  
  if (action.type === getVerbRowParadigmFormulaFailure) {
    return { ...state, loadingVerbRowParadigmFormula: false, verbRowParadigmFormulaForEdit: null };
  }
  
  if (action.type === startSavingVerbRowParadigmFormula) {
    return { ...state, savingVerbRowParadigmFormula: true };
  }

  if (action.type === createVerbRowParadigmFormulaSuccess) {
    const data = action.payload.data;
    return { ...state, savingVerbRowParadigmFormula: false, verbRowParadigmFormulaForEdit: data };
  }

  if (action.type === createVerbRowParadigmFormulaFailure) {
    //const data = action.payload.data;
    return { ...state, savingVerbRowParadigmFormula: false, verbRowParadigmFormulaForEdit: null };
  }

  if (action.type === finishedSaveVerbRowParadigmFormula) {
    return { ...state, savingVerbRowParadigmFormula: false };
  }

  if (action.type === requestDeleteVerbRowParadigmFormula) {
    return { ...state, workingOnDeleteVerbRowParadigmFormula: true, DeleteFailure: false };
  }

  if (action.type === deleteVerbRowParadigmFormulaSuccess) {
    return { ...state, workingOnDeleteVerbRowParadigmFormula: false, DeleteFailure: false };
  }

  if (action.type === deleteVerbRowParadigmFormulaFailure) {
    return { ...state, workingOnDeleteVerbRowParadigmFormula: false, DeleteFailure: true };
  }




  //VerbPersonMarkerFormulas
  if (action.type === requestLoadVerbPersonMarkerFormula) {
    return { ...state, loadingVerbPersonMarkerFormula: true, verbPersonMarkerFormulaForEdit: null };
  }
  
  if (action.type === setVerbPersonMarkerFormula) {
    const data = action.payload.data;
    //console.log("ModelEditorStore setVerbPersonMarkerFormula action.payload=", action.payload)
    return { ...state, loadingVerbPersonMarkerFormula: false, verbPersonMarkerFormulaForEdit: data };
  }
  
  if (action.type === getVerbPersonMarkerFormulaFailure) {
    return { ...state, loadingVerbPersonMarkerFormula: false, verbPersonMarkerFormulaForEdit: null };
  }
  
  if (action.type === startSavingVerbPersonMarkerFormula) {
    return { ...state, savingVerbPersonMarkerFormula: true };
  }

  if (action.type === createVerbPersonMarkerFormulaSuccess) {
    const data = action.payload.data;
    return { ...state, savingVerbPersonMarkerFormula: false, verbPersonMarkerFormulaForEdit: data };
  }

  if (action.type === createVerbPersonMarkerFormulaFailure) {
    //const data = action.payload.data;
    return { ...state, savingVerbPersonMarkerFormula: false, verbPersonMarkerFormulaForEdit: null };
  }

  if (action.type === finishedSaveVerbPersonMarkerFormula) {
    return { ...state, savingVerbPersonMarkerFormula: false };
  }

  if (action.type === requestDeleteVerbPersonMarkerFormula) {
    return { ...state, workingOnDeleteVerbPersonMarkerFormula: true, DeleteFailure: false };
  }

  if (action.type === deleteVerbPersonMarkerFormulaSuccess) {
    return { ...state, workingOnDeleteVerbPersonMarkerFormula: false, DeleteFailure: false };
  }

  if (action.type === deleteVerbPersonMarkerFormulaFailure) {
    return { ...state, workingOnDeleteVerbPersonMarkerFormula: false, DeleteFailure: true };
  }




  return state;
}