//NounParadigmFormulas.js
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../carcass/common/Loading';
import { getFormulaVisual2 } from './FormulasModule';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { GetDisplayValue2 } from '../../carcass/modules/GetDisplayValue'
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt, filterByHeader } from '../../carcass/common/myFunctions';
import BsComboBox from '../../carcass/masterdata/BsComboBox';
import { useForman } from '../../carcass/common/MyHooks';
import * as yup from 'yup';


const NounParadigmFormulas = (props) => {

  const { alert, masterData, nounParadigmFormulas, nounParadigmFormulasLoading,
    checkLoadMdTables, CheckloadNounParadigmFormulas } = props;
  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, morphemeRangesByInflectionBlocks, 
    grammarCases, nounNumbers, nounParadigms } = masterData.mdRepo;


  // const { mdWorkingOnLoad, morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, 
  //   morphemeRangesByInflectionBlocks, grammarCases, nounNumbers, nounParadigms, checkLoadMdTables,
  //   nounParadigmFormulas, nounParadigmFormulasLoading, CheckloadNounParadigmFormulas } = props;

  // console.log("NounParadigmFormulas props=", props);

  const [curParadigmId, setCurParadigmId] = useState(null);

  const paradigmId = NzInt(props.match.params.paradigmId);

  const tableNamesForLoad = useMemo(() => ["morphemeRanges", "morphemesQuery", "inflectionBlocks", "inflectionTypes", 
  "morphemeRangesByInflectionBlocks", "grammarCases", "nounNumbers", "nounParadigms"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [checkLoadMdTables, tableNamesForLoad]);


  useEffect(() => {
    if (curParadigmId !== paradigmId) {
      setCurParadigmId(paradigmId);
      CheckloadNounParadigmFormulas(paradigmId);
    }

  }, [CheckloadNounParadigmFormulas, paradigmId, curParadigmId]); //, loading, loadingFailure, drParentsLoading, drChildsLoading, drWorkingOnSave

  const [curscrollTo, backLigth] = useScroller(NzInt(props.match.params.recName));

  const yupSchema = yup.object().shape({
    grammarCaseId: yup.number().integer().default(-1),
    nounNumberId: yup.number().integer().default(-1)
  });

  const [frm, changeField, getError, , , , ] = useForman(yupSchema);


  //console.log("NounParadigmFormulas curscrollTo=", curscrollTo);
  if (mdWorkingOnLoad || nounParadigmFormulasLoading || datatypesLoading) {
    return (<Loading />);
  }

  if (!morphemeRanges || !morphemesQuery || !inflectionBlocks || !inflectionTypes || !morphemeRangesByInflectionBlocks || 
      !grammarCases || !nounNumbers || !nounParadigms ||
      !(curParadigmId in nounParadigmFormulas) || !nounParadigmFormulas[curParadigmId] || 
      (props.match.params.recName && !curscrollTo) || !datatypes ) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }


  const inflectionBlock = inflectionBlocks.find(f=>f.inbKey === 'NounRowsBlock');
  if ( ! inflectionBlock ) {
    return (<h5>არასწორი ფლექსიის ბლოკი</h5>);
  }

  const inflectionType = inflectionTypes.find(f=>f.iftId === inflectionBlock.inflectionTypeId);
  if ( ! inflectionType ) {
    return (<h5>არასწორი ფლექსიის ტიპი</h5>);
  }

  const MorphemeRangeIdsByIT = morphemeRangesByInflectionBlocks
    .filter(f=>f.inflectionBlockId === inflectionBlock.inbId)
    .map(m=>m.morphemeRangeId);

  const rangesInGroup = morphemeRanges
    .filter(f=>f.morphemeGroupId === inflectionType.morphemeGroupId && MorphemeRangeIdsByIT.includes(f.mrId))
    .sort((a,b) => a.mrPosition-b.mrPosition);


  function getParadigmName() {
    const nounParadigm = nounParadigms.find(f=>f.npnId === curParadigmId);
    return nounParadigm.npnName
  }

  //აქ ითვლება, რომ თუ პარადიგმის სახელების დარედაქტირების უფლება აქვს მომხმარებელს
  //მაშინ პარადიგმებზეც უნდა ჰქონდეს.
  //ასე იმიტომ გაკეთდა, რომ არ გამხდარიყო საჭირო პარადიგმების მწკრივის ცხრილის დარეგისტრირება DataType-ებში
  //თუ მომავალში ამ ორი უფლების გამიჯვნა გახდა საჭირო, მოგვიწევს DataType-ებში დავარეგისტრიროთ პარადიგმების მწკრივის ცხრილი
  const dataType = datatypes.find(f=>f.dtTable === "nounParadigms");

  return (
    <div>
      <Row>
        <Col sm="10">
          <Link to= {`/nounParadigmsOverview/nounParadigms/${paradigmId}`} className="btn btn-primary">
            <FontAwesomeIcon icon="chevron-left" /> უკან პარადიგმების სიაში დაბრუნება
          </Link>

          <h4>სახელის პარადიგმა {getParadigmName()} ({nounParadigmFormulas[curParadigmId].length})</h4>
        </Col>
        <Col sm="2" align="right">
          { dataType.create && (<Link to= {`/nounParadigmFormulaEdit`} className="btn btn-primary">
            <FontAwesomeIcon icon="plus" /> ახალი
          </Link>) }
        </Col>
      </Row>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>ნიმუში</th>
            <th>ფორმულა</th>
            <th>ბრუნვა
              <BsComboBox name="grammarCaseId" 
                value={frm ? frm.grammarCaseId : null} dataMember={grammarCases.sort((a, b) => a.sortId-b.sortId)} 
                valueMember="grcId" displayMember="grcName" getError={getError} onChangeValue={changeField} 
                sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable />
            </th>
            <th>რიცხვი
              <BsComboBox name="nounNumberId" 
                value={frm ? frm.nounNumberId : null} dataMember={nounNumbers.sort((a, b) => a.sortId-b.sortId)} 
                valueMember="nnnId" displayMember="nnnName" getError={getError} onChangeValue={changeField} 
                sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable />
            </th>
          </tr>
        </thead>
        <tbody>
          {nounParadigmFormulas[curParadigmId].filter(f=>filterByHeader(f,frm))
            .sort((a, b) => a.nprOrderInParadigm-b.nprOrderInParadigm)
            .map((npr, index) => {
              const bl = (curscrollTo === npr.nprId);
              return (
                <tr key={npr.nprId.toString()} ref={ bl ? backLigth : null} >
                  <td className={ bl ? "backLigth" : null}>{index+1}</td>
                  <td className={ bl ? "backLigth" : null}>{npr.nprSample}</td>
                  <td className={ bl ? "backLigth" : null}>{getFormulaVisual2(npr.nounParadigmFormulaDetails.map(m=>m.morphemeId), rangesInGroup, morphemesQuery)}</td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(grammarCases, npr.grammarCaseId, {valueMember: "grcId", displayMember: "grcName", typeName: "Lookup"})}
                  </td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(nounNumbers, npr.nounNumberId, {valueMember: "nnnId", displayMember: "nnnName", typeName: "Lookup"})}</td>
                  <td width="50px">
                    <div className="btn-toolbar pull-right">
                      { (dataType.update || dataType.delete) && 
                        (<Link to={`/nounParadigmFormulaEdit/${npr.nprId}`} className="btn btn-primary">
                          <FontAwesomeIcon icon="edit" />
                        </Link>) }
                    </div>
                  </td>
                </tr>);
            })}
        </tbody>
      </Table>
    </div>
  );

}


function mapStateToProps(state) {
  const alert = state.alert;
  const masterData = state.masterData;
  const {nounParadigmFormulas, nounParadigmFormulasLoading} = state.formulasStore;

  return { alert, masterData, nounParadigmFormulas, nounParadigmFormulasLoading };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    CheckloadNounParadigmFormulas: (paradigmId) => dispatch(FormulasActions.CheckloadNounParadigmFormulas(paradigmId))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NounParadigmFormulas);
