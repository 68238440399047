//DerivationFormulaEdit.js
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

// import { history } from '../../carcass/common/History';
//import { useHistory } from "react-router-dom";
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as ModelEditorActions } from './ModelEditorStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { useForman } from '../../carcass/common/MyHooks';
import WaitPage from '../../carcass/common/WaitPage';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OneTextControl from '../../carcass/editorParts/OneTextControl';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import OneCheckBoxControl from '../../carcass/editorParts/OneCheckBoxControl';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import OnePlaintextRow from '../editorParts/OnePlaintextRow';
import { getFormulaVisual2, createFormulaFormData } from './FormulasModule';

const DerivationFormulaEdit = (props) => {

  //const history = useHistory();

  //1. იდენტიფიკატორი
  const [curDfIdVal, setCurDfIdVal] = useState(null); 

  //2. კომპონენტის თვისებები
  const { alert, masterData, savingDerivationFormula, loadingDerivationFormula, workingOnDeleteDerivationFormula, 
    derivationFormulaForEdit, DeleteFailure, getOneDerivationFormulaById, checkLoadMdTables} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { morphemeRanges, morphemesQuery, derivationTypes, phoneticsTypes, morphemeRangesByDerivationTypes } = masterData.mdRepo;

  //console.log("DerivationFormulaEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => ["derivationFormula"], []);

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["morphemeRanges", "morphemesQuery", "derivationTypes", 
    "phoneticsTypes", "morphemeRangesByDerivationTypes"], []);

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
  }, [tableNamesForLoad, checkLoadMdTables]);

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    derivationFormula: yup.object().shape({
      dfId: yup.number().integer().default(0).required(),
      derivationTypeId: yup.number().integer().positive("დერივაციის ტიპი არჩეული უნდა იყოს")
        .required("დერივაციის ტიპი არჩეული უნდა იყოს").default(0),
      dfAutoNounInflection: yup.boolean().default(false),
      dfAutoPhoneticsType: yup.number().transform((v, o) => o === null ? 0 : v).integer().min(0).default(0),
      formulaName: yup.string().required("ფორმულის სახელი შევსებული უნდა იყოს").default("")
    }),
    morphemeIds: yup.array().ensure().min(1, "ფორმულაში უნდა იყოს ერთი მაინც არანულოვანი მორფემა")
      .of(yup.number().integer().positive().default(0)
      .required()),
    formData: yup.object().shape({
      ranges: yup.array().ensure(),
      morphemes: yup.array().ensure()
        .of(yup.number().integer().positive("მორფემა არჩეული უნდა იყოს").default(0)
        .required()),
    })
  });

  //console.log("DerivationFormulaEdit yupSchema=", yupSchema);

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    props.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
    props.clearDerivFormulas();
  }


  const copyMorphemsToMainData = useCallback((forForm) => {
    const newForm = {...forForm};
    newForm.morphemeIds = newForm.formData.morphemes.map((mrpId) => {
      return mrpId ? morphemesQuery.find((f)=> f.mrpId === mrpId) : null;
    }).filter(f=>f && f.mrpNom).map(m=>m.mrpId);
    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
    setFormData(newForm);
  },[morphemesQuery, setFormData]);

  const setDerivationType = useCallback((curForm, newDerivationTypeId) => {

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშIნ გაგრეძელებას აზრი არ აქვს
    if ( !derivationTypes || !morphemeRanges || !morphemesQuery || !morphemeRangesByDerivationTypes )
      return;

    //დავამზადოთ ფორმის ახალი ობიექტი
    const newForm = {...curForm};
    //დერივაციის ტიპის პოვნას აზრი აქვს მხოლოდ იმ შემთხვევაში, თუ დერივაციიც ტიპების ცხრილი ჩატვირთულია
    //მოვძებნოთ დერივაციის ტიპის შესაბამისი ობიექტი
    const derivType = derivationTypes.find(f=>f.drtId === newDerivationTypeId);
    //ამოვკრიბოთ იმ რანგების შესახებ ინფორმაცია, რომლებიც მონაწილეობენ ამ ტიპის დერივაციის ფორმირებაში
    const MorphemeRangeIdsByDT = morphemeRangesByDerivationTypes
      .filter(f=>f.derivationTypeId === newDerivationTypeId)
      .map(m=>m.morphemeRangeId);
    //დერივაციის ტიპში მითითებული ჯგუფის მიხედვით ამოვკრიბოთ რანგების შესაბამისი ობიექტები
    //თან გავითვალისწინოთ რომ ამ დერივაციის ტიპში ეს რანგი უნდა მონაწილეობდეს თუ არა
    const rangesInGroup = morphemeRanges
      .filter(f=>f.morphemeGroupId === derivType.morphemeGroupId && MorphemeRangeIdsByDT.includes(f.mrId))
      .sort((a,b) => a.mrPosition-b.mrPosition);

      //დავაფიქსიროთ დერივაციის ტიპი
    newForm.derivationFormula.derivationTypeId = newDerivationTypeId;

    newForm.formData = createFormulaFormData(newForm.formData, rangesInGroup, newForm.morphemeIds, morphemesQuery);

    //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.

    //console.log("DerivationFormulaEdit setDerivationType newForm=", newForm);
    copyMorphemsToMainData(newForm);
    //setFormData(newForm);
  },[derivationTypes, morphemeRanges, morphemesQuery, morphemeRangesByDerivationTypes, copyMorphemsToMainData]);

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const { dfId } = props.match.params;
    const dfIdVal = dfId ? parseInt(dfId) : 0;

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curDfIdVal !== dfIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurDfIdVal(dfIdVal);
      if (dfIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOneDerivationFormulaById(dfIdVal);
        return;
      }
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }
    
    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთული ობიექტი მაინც შევამოწმოთ
    if ( loadingDerivationFormula || !dfId || !derivationFormulaForEdit || mdWorkingOnLoad || !derivationTypes || !morphemeRanges 
        || !morphemesQuery || !morphemeRangesByDerivationTypes )
      return;

      setDerivationType(derivationFormulaForEdit, derivationFormulaForEdit.derivationFormula.derivationTypeId);
  }, [clearToDefaults, curDfIdVal, props.match.params, setFormData, derivationFormulaForEdit, loadingDerivationFormula, getOneDerivationFormulaById, 
    mdWorkingOnLoad, setDerivationType, derivationTypes, morphemeRanges, morphemesQuery, morphemeRangesByDerivationTypes]);

  //8. ჩატვირთვის შემოწმება
  if ( loadingDerivationFormula || mdWorkingOnLoad || datatypesLoading ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
    return (<WaitPage />);

//თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( (curDfIdVal && !derivationFormulaForEdit) || !morphemeRanges || !morphemesQuery || !derivationTypes || 
      !phoneticsTypes || !morphemeRangesByDerivationTypes || !datatypes ) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }
  
  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;

//გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
//წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას

    const currentForm = {...frm};
    delete currentForm.formData;
    if ( currentForm.derivationFormula.dfAutoPhoneticsType === 0 )
      currentForm.derivationFormula.dfAutoPhoneticsType = null;

    if ( curDfIdVal )
      props.updateDerivationFormula(props.history, currentForm);
    else
      props.createDerivationFormula(props.history, currentForm);
    clearUsedTables();
  }

  const dataType = datatypes.find(f=>f.dtTable === "derivationFormulas");

  return (
    <Row>
      <Col md="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          <EditorHeader curIdVal={curDfIdVal} EditorName="დერივაციის ფორმულა" EditorNameGenitive="დერივაციის ფორმულის"
            EditedObjectName={(frm && frm.derivationFormula && frm.derivationFormula.formulaName) ? frm.derivationFormula.formulaName : ""}
            workingOnDelete={workingOnDeleteDerivationFormula} DeleteFailure={DeleteFailure}
            onDelete={() => {props.deleteDerivationFormula(props.history, curDfIdVal); clearUsedTables();}}
            onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={dataType.delete} />
          <OnePlaintextRow controlId="result" label="შედეგი" 
            text={getFormulaVisual2(frm.morphemeIds, frm.formData.ranges, morphemesQuery)} />          
          <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />
          <OneComboBoxControl controlId="derivationFormula.derivationTypeId" 
            label="დერივაციის ტიპი" value={frm.derivationFormula.derivationTypeId} 
            dataMember={derivationTypes.filter(f=>!f.drtAutomatic)} valueMember="drtId" displayMember="drtName"
            getError={getError} onChangeValue={(fieldPath, value) => {
              setDerivationType(frm, value);
            }}
            firstItem={{ id: 0, name: "აირჩიეთ დერივაციის ტიპი" }} />
          <OneCheckBoxControl controlId="derivationFormula.dfAutoNounInflection" label="სახელის ავტომატური ფლექსია" value={frm.derivationFormula.dfAutoNounInflection} 
            getError={getError} onChangeValue={changeField} />
          <OneComboBoxControl controlId="derivationFormula.dfAutoPhoneticsType" 
            label="ავტომატური ფონეტიკური ტიპი" value={frm.derivationFormula.dfAutoPhoneticsType} 
            dataMember={phoneticsTypes} valueMember="phtId" displayMember="phtName"
            getError={getError} onChangeValue={changeField}
            firstItem={{ id: 0, name: "უცვლელი" }} />
          <OneTextControl controlId="derivationFormula.formulaName" label="ფორმულის სახელი" value={frm.derivationFormula.formulaName} 
            getError={getError} onChangeValue={changeField} />
          <OneStrongLabel controlId="morphemes" label="მორფემები რანგების მიხედვით" />
          {frm.formData.ranges.map((range, index) => {
            return (<OneComboBoxControl key={index} controlId={`frm.formData.morphemes[${index}]`}
            label={`${range.mrPosition+1}. ${range.mrName}`} value={frm.formData.morphemes[index]} 
            dataMember={morphemesQuery.filter(mph=>mph.morphemeRangeId === range.mrId && mph.mrpNom >= range.minNom && mph.mrpNom <= range.maxNom)} 
            valueMember="mrpId" displayMember="mrpName" sortByDisplayMember={false}
            getError={getError} onChangeValue={(fieldPath, value) => {
              const newForm = {...frm};
              newForm.formData.morphemes[index] = value;
              // //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
              copyMorphemsToMainData(newForm);
            }} />);
          })}
          <OneSaveCancelButtons curIdVal={curDfIdVal} haveErrors={haveErrors} savingNow={savingDerivationFormula} onCloseClick={(e) => { 
            props.clearAlert(); props.history.goBack(); }} allowEdit={dataType.update} />
          <OneErrorRow allErrors={allErrors} />
        </Form>
      </Col>
    </Row>
  );


}

function mapStateToProps(state) {
  const alert = state.alert;
  const masterData = state.masterData;
  const { savingDerivationFormula, loadingDerivationFormula, workingOnDeleteDerivationFormula, 
    derivationFormulaForEdit, DeleteFailure } = state.modelEditorStore

  return { alert, masterData, savingDerivationFormula, loadingDerivationFormula, 
    workingOnDeleteDerivationFormula, derivationFormulaForEdit, DeleteFailure};
}

function mapDispatchToProps(dispatch) {
  return {
    getOneDerivationFormulaById: (dfId) => dispatch(ModelEditorActions.getOneDerivationFormulaById(dfId)),
    updateDerivationFormula: (history, df) => dispatch(ModelEditorActions.updateDerivationFormula(history, df)),
    createDerivationFormula: (history, df) => dispatch(ModelEditorActions.createDerivationFormula(history, df)),
    deleteDerivationFormula: (history, dfId) => dispatch(ModelEditorActions.deleteDerivationFormula(history, dfId)),
    clearDeletingFailure: () => dispatch(ModelEditorActions.clearDeletingFailure()),
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad)),
    clearDerivFormulas: () => dispatch(FormulasActions.clearDerivFormulas()),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DerivationFormulaEdit);

