//FormulasModule.js

export function getDerivFormulaVisual(derivationTypeId, morphemeIds, derivationTypes, morphemeRanges, morphemesQuery) {

  const derivType = derivationTypes.find(f=>f.drtId === derivationTypeId);
  if ( ! derivType )
    return "(null)";
    return getFormulaVisual(derivType.morphemeGroupId, morphemeIds, morphemeRanges, morphemesQuery)
}

export function getNounFormulaVisual(InflectionTypeId, morphemeIds, inflectionTypes, morphemeRanges, morphemesQuery) {

  const InflectionType = inflectionTypes.find(f=>f.iftId === InflectionTypeId);
  if ( ! InflectionType )
    return "(null)";
  
  return getFormulaVisual(InflectionType.morphemeGroupId, morphemeIds, morphemeRanges, morphemesQuery)
}

export function getFormulaVisual(morphemeGroupId, morphemeIds, morphemeRanges, morphemesQuery) {
  const mranges = morphemeRanges.filter(f=>f.morphemeGroupId === morphemeGroupId).sort((a,b)=> a.mrPosition-b.mrPosition);
  return getFormulaVisual2(morphemeIds, mranges, morphemesQuery)
}

export function getFormulaVisual2(morphemeIds, mranges, morphemesQuery) {

  //console.log("getFormulaVisual2 {morphemeIds, mranges, morphemesQuery}=", {morphemeIds, mranges, morphemesQuery});

  let morphemes = morphemeIds.map((mId) => {
    return morphemesQuery.find(m => m.mrpId === mId);
  });

  morphemes = morphemes.filter(f=>f !== undefined);

  morphemes = mranges.map((range) => {
    return morphemes.find(f=>f.morphemeRangeId === range.mrId);
  });

  morphemes = morphemes.filter(f=>f !== undefined);

  const result = morphemes.map((morpheme, ind) => {
    return `${morpheme.mrpName ? morpheme.mrpName.trim() : "(null)"}`;
  }).join("-");

  //console.log("FormulasModule getDerivFormulaVisual result=", result);

  return result;

}

export function createFormulaFormData(oldFormData, rangesInGroup, morphemeIds, morphemesQuery, forFreeMorphems = false, forInflection = false) {

  //  console.log("FormulasModule createFormulaFormData {oldFormData, rangesInGroup, morphemeIds, morphemesQuery}=", {oldFormData, rangesInGroup, morphemeIds, morphemesQuery});


    //თუ არ არის შევქმნათ ფორმის ინფორმაცია, თუ არის დავაკოპიროთ ლოკალურ ცვლადში
    const formData = oldFormData ? {...oldFormData} : {};

    // const filteredRanges = forFreeMorphems ? rangesInGroup.filter(f=>f.mrBaseNom === null) : rangesInGroup;
    //დავიმახსოვროთ რანგების რაოდეონობა
    const mrLength = rangesInGroup.length;

    //დავითვალოთ დასაშვები მინიმუმები და მაქსიმუბი
    //თან გზაში უნდა შემოწმდეს რამდენიმე რამე:
    //1. მორფემა უნდა ეკუთვნოდეს ერთერთ რანგს, რომელიც შეესაბამება დერივაციის ტიპის ჯგუფს
    //2. თუ მორფემა რანგის მიხედვით ფუძეა, მისი ნომერი უნდა იყოს ერთი. მაგრამ თუ არააუცილებელი მორფემაა მაშინ შეიძლება იყოს 0-იც
    //3. თუ მორფმა არჩევითია, იმის ნომერი შეიძლება იყოს ან 0 ან (-1). 
    rangesInGroup.forEach((range, index, arr) => {
      const selectable = forInflection ? range.mrInflectionSelectable : range.mrSelectable;
      if ( !formData.errorData ) {
        //რადგან ვიცით, რომ ზოგი რანგი შეიძლება არ იყოს რანგების სიაში, ამიტომ შემდეგ შემოწმებას აზრი ეკარგება
        // if ( range.mrPosition !== index )
        //   formData.errorData = "არასწორი მოდელი, გამოტოვებულია რანგი";
        if ( range.mrBaseNom !== null && selectable ) 
          formData.errorData = "არასწორი მოდელი, ერთერთი რანგი გამოცხადებულია როგორც ფუძე და ერთდროულად როგორც ასარჩევი მორფემა";
      }
      if ( range.mrBaseNom !== null ) {
        if ( range.mrBaseRequired ) {
          arr[index].minNom = 1;
          arr[index].maxNom = 1;
        }
        else {
          arr[index].minNom = 0;
          arr[index].maxNom = 1;
        }
      }
      else {
        if ( selectable ) {
          arr[index].minNom = forFreeMorphems ? 0 : -1;
          arr[index].maxNom = 0;
          if ( forFreeMorphems ) {
            const mfrnoms = morphemesQuery.filter(mf=>mf.morphemeRangeId === range.mrId).map(mf=>mf.mrpNom);
            arr[index].maxNom =  Math.max(...mfrnoms);
          }
        }
        else {
          arr[index].minNom = 0;
          arr[index].maxNom = 0;
          if ( ! forFreeMorphems ) {
            const mfrnoms = morphemesQuery.filter(mf=>mf.morphemeRangeId === range.mrId).map(mf=>mf.mrpNom);
            arr[index].maxNom =  Math.max(...mfrnoms);
          }
        }
      }

    });

    //დავაფიქსიროთ რანგების მასივი
    formData.ranges = rangesInGroup;
    //მორფემების მასივი თუ ჯერ არ არსებობს შევქმნათ
    if ( ! formData.morphemes ) {
      formData.morphemes = [];
    }
    //დავაფიქსიროთ რანგების რაოდენობის მიხედვით მორფემების მასივის სიგრძე
    formData.morphemes.length = mrLength;
    //console.log("DerivationFormulaEdit setDerivationType formData.morphemes=", formData.morphemes);
    for(let i=0; i<mrLength; i++) {
      if ( formData.morphemes[i] === undefined )
        formData.morphemes[i]=0;
      //console.log("DerivationFormulaEdit setDerivationType formData.morphemes[i]=", formData.morphemes[i]);
    }
    // formData.morphemes.forEach((item, index, arr) => {
    //   //console.log("DerivationFormulaEdit setDerivationType formData.morphemes.forEach.item=", item);
    // });
    

    //ძირითადი ობიექტის მორფემების იდენტიფიკატორის მასივიდან სათითაოდ მოვათავსოთ იდენტიფიკატორიები ფორმის მასივში
    //, index, arr
    morphemeIds && morphemeIds.forEach((item) => {
      const morpheme = item ? morphemesQuery.find((f)=> f.mrpId === item) : null;
      const rangeIndex = morpheme ? rangesInGroup.findIndex((f)=> f.mrId === morpheme.morphemeRangeId ) : null;
      const range = rangeIndex != null ? rangesInGroup[rangeIndex] : null;
      //თუ მორფემის იდენტიფიკატორის მიხედვით მორფემაც მოიშებნა და რანგიც
      //და ამავე დროს რანგში მორფემის ნომერი დასაშვებ ზღვრებშია
      if ( morpheme && range && morpheme.mrpNom >= range.minNom && morpheme.mrpNom <= range.maxNom ) { 
        //მაშინ ეს იდენტიფიკატორი გადავინახოთ რედაქტორის შესაბამის უჯრაში
        formData.morphemes[rangeIndex] = item;
      }
      // else {//თუ მორფმეის იდენტიფიკატორი უვარგისია, გადავაგდოთ
      //   //ანუ საწყის მონაცემებშიც წავშალოთ
      //   arr[index] = null;
      // }
    });

    //თუ რომელიმე გაუქმდა ამოიყაროს მასივიდან
    //newForm.morphemeIds = newForm.morphemeIds.filter(f=>f !== null);

    //შემოწმდეს მორფემების მასივში შემავალი ელემენტები სათითაოდ
    formData.morphemes.forEach((item, index, arr) => {
      //და თუ რომელიმე ელემენტი ცარიელია, ან შეიცავს არაშესაბამისი რანგის მორფემას, ჩავსვათ შესაბამისი მორფემის იდენტიფიკატორი.
      const mrp = item ? morphemesQuery.find((f)=> f.mrpId === item) : null;
      //const range = mrp ? rangesInGroup.find((f)=> f.mrId === mrp.morphemeRangeId ) : null;
      const range = rangesInGroup[index];

      //console.log("DerivationFormulaEdit setDerivationType mrp=", mrp);
      //console.log("DerivationFormulaEdit setDerivationType range=", range);

      if ( !mrp || mrp.morphemeRangeId !== range.mrId 
        || mrp.mrpNom < range.minNom 
        || mrp.mrpNom > range.maxNom ) {
        //თუ მინიმუმსა და მაქსიმუმს შორი 0 არ არის, მაშინ ავიღოთ მოდულით ნაკლები
        //თავიდან დავუშვათ, რომ 0 ყველაზე კარგი ვარიანტია
        let newMrpNom = 0;
        if ( range.maxNom < 0 )
          newMrpNom = range.maxNom;
        if ( range.minNom > 0 )
          newMrpNom = range.minNom;
        const mrp2 = morphemesQuery.find((f)=> f.morphemeRangeId === range.mrId && f.mrpNom === newMrpNom );
        //console.log("DerivationFormulaEdit setDerivationType mrp2=", mrp2);
        if ( mrp2 )
          arr[index] = mrp2.mrpId;
      }
    });
  return formData;
}
