//DerivationFormulasOverview.js
import React, {useEffect, useMemo, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';

import Loading from '../../carcass/common/Loading';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { getFormulaVisual } from './FormulasModule';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';

const DerivationFormulasOverview = (props) => {

  const {alert, isMenuLoading, flatMenu, masterData, derivFormulasLoading, derivationFormulas, 
    checkLoadMdTables, checkLoadDerivFormulas} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const { derivationTypes, morphemeGroups, morphemeRanges, morphemesQuery } = masterData.mdRepo;

  //console.log("DerivationFormulasOverview props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["derivationTypes", "morphemeGroups", "morphemeRanges", "morphemesQuery"], []);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    checkLoadMdTables(tableNamesForLoad);
    checkLoadDerivFormulas();
  }, [isMenuLoading, flatMenu, isValidPage, checkLoadMdTables, checkLoadDerivFormulas, tableNamesForLoad]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recName: NzInt(props.match.params.recName)
  });

  if (mdWorkingOnLoad || derivFormulasLoading || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }

  if (!derivationTypes || !morphemeGroups || !morphemeRanges || !morphemesQuery || !derivationFormulas || !curscrollTo || !datatypes) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  const derivationTypesDataType = datatypes.find(f=>f.dtTable === "derivationTypes");
  const derivationFormulasDataType = datatypes.find(f=>f.dtTable === "derivationFormulas");

  return (
    <div>
      <h3>დერივაციის ფორმულების მიმოხილვა</h3>
      <h4>ტერმინები</h4>
      <p><strong>დერივაციის ტიპი</strong> - დერივაციის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>დერივაციის ფორმულა</strong> - დერივაციის ფორმულის განმარტება (გასაკეთებელია).</p>
      <h4>დერივაციის ტიპები ({derivationTypes.length}) {derivationTypesDataType.create && (<Link to={`/mdItemEdit/derivationTypes`}> + </Link>)}</h4>
      <p>დერივაციის ტიპის სახელი =&gt; მორფემების ჯგუფი</p>
      <ol>
        {derivationTypes
          .sort((a, b) => a.drtName.localeCompare(b.drtName))
          .map(drt => {
            const morphgrp = morphemeGroups.find(mog => mog.mogId === drt.morphemeGroupId);
            const bl = (curscrollTo.tabKey === 'derivationTypes' && curscrollTo.recName === drt.drtId);
            return (
              <li key={drt.drtId.toString()} ref={ bl ? backLigth : null} >
                { !derivationTypesDataType.update && (<span>{drt.drtName}</span>)}
                { derivationTypesDataType.update && (<Link to={`/mdItemEdit/derivationTypes/${drt.drtId}`} className={ bl ? "backLigth" : null} onClick={(e) => {
                  // e.preventDefault(); ეს საჭირო არ არის, რადგან კლინკზე აღარ გადადის
                  //ვინახავთ გვერდის სახელს, საიდანაც მოხდა რედაქტორის გახსნა. იმისათვისმ რომ კორექტულად მოხდეს უკან დაბრუნება
                  props.saveReturnPageName(props.match.url.split('/')[1]);
                }} >{drt.drtName}</Link>)} =&gt; {morphgrp.mogName}
              </li>
            );
          })}
      </ol>
      <h4>დერივაციის ფორმულები ტიპების მიხედვით ({derivationFormulas.length})</h4>
      { derivationFormulasDataType.create && <Link to={"/derivationFormulaEdit"}>დერივაციის ახალი ფორმულის შექმნა</Link> }
      <p>დერივაციის სახელი =&gt; ფორმულა</p>
      {derivationTypes
        .sort((a, b) => a.drtName.localeCompare(b.drtName))
        .map(drt => {
          const dfsf = derivationFormulas.filter((df) => df.derivationTypeId === drt.drtId);
          //console.log("derivationFormulaEdit curTabKey=", curTabKey);
          //console.log("derivationFormulaEdit curRecName=", curRecName);
          return (
            <div key={drt.drtId.toString()}>
              <h5>{drt.drtName} ({dfsf.length})</h5>
              <ol>
                {dfsf
                  .sort((a, b) => a.formulaName.localeCompare(b.formulaName))
                  .map(df => {
                    const bl = (curscrollTo.tabKey === 'df' && curscrollTo.recName === df.dfId);
                    return (
                      <li key={df.dfId.toString()} ref={ bl ? backLigth : null} >
                        { !derivationFormulasDataType.update && (<span>{df.formulaName}</span>)}

                        { derivationFormulasDataType.update && (<Link to={`/derivationFormulaEdit/${df.dfId}`} className={ bl ? "backLigth" : null} onClick={(e) => {
                          // e.preventDefault(); ეს საჭირო არ არის, რადგან კინკზე აღარ გადადის
                          //ვინახავთ გვერდის სახელს, საიდანაც მოხდა რედაქტორის გახსნა. იმისათვისმ რომ კორექტულად მოხდეს უკან დაბრუნება
                          props.saveReturnPageName(props.match.url.split('/')[1]);
                        }} >
                          <q>{df.formulaName}</q>
                        </Link>)} =&gt; {getFormulaVisual(drt.morphemeGroupId, df.derivationFormulaDetails.map(m=>m.morphemeId), morphemeRanges, morphemesQuery)}
                      </li>);
                  })}
              </ol>
            </div>
          );
        })}
    </div>
  );


}

function mapStateToProps(state) {

  const alert = state.alert;
  const masterData = state.masterData;
  const {isMenuLoading, flatMenu} = state.navMenu;
  const { derivFormulasLoading, derivationFormulas } = state.formulasStore;

  return {alert, isMenuLoading, flatMenu, masterData, derivFormulasLoading, derivationFormulas};

}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    checkLoadDerivFormulas: () => dispatch(FormulasActions.checkLoadDerivFormulas()),
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DerivationFormulasOverview);
