//CheckboxColumn.js

import React from 'react';
import { Spinner } from 'react-bootstrap';

const CheckboxColumn = (props) => {

  const { idFieldName, changing, value, record, onChangeValue } = props;
  //console.log("CheckboxColumn props=", props);


  
  function handleChange(e) {
    const { checked } = e.target;
    onChangeValue(record[idFieldName], checked);
  }

  if ( changing )
    return (
      <Spinner as="span" animation="border" role="status" aria-hidden="true" />
    );

  return (
    <input type="checkbox" className="form-check-input" checked={value}  onChange={handleChange} ></input>
  );
}

export default CheckboxColumn;
