//OnePlaintextRow.js
import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const OnePlaintextRow = (props) => {

  //onChange=handleChange
  const { controlId, label, text, color } = props;
  // const styleText = color ? `color:${color};` : null;
  //console.log("OnePlaintextRow props=", props);
  //console.log("OnePlaintextRow styleText=", styleText);


  return (
    <Form.Group className="mb-1" as={Row} controlId={controlId}>
      <Form.Label column md="2">{label}</Form.Label>
      <Col md="8">
      { !!color && <Form.Control plaintext readOnly defaultValue={text} style={{color: `${color}`}} /> }
      { !color && <Form.Control plaintext readOnly defaultValue={text} /> }
      </Col>
    </Form.Group>
  );
}

export default OnePlaintextRow;
