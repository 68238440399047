//CreateVerbPersonMarkerCombinations.js
import React, {useEffect, useMemo, useCallback} from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap'

import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';
import { checkDataLoaded } from '../../carcass/modules/CheckDataLoaded'
import Loading from '../../carcass/common/Loading';
import NameListEditor from './NameListEditor';
import MdListView from '../../carcass/masterdata/MdListView';

const CreateVerbPersonMarkerCombinations = (props) => {

  const {alert, isMenuLoading, flatMenu, masterData, loadMultipleListData} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = props.masterData;
  const { actantGrammarCasesByActantTypes, actantTypesByVerbTypes, pluralityChangesByVerbTypes, verbPersonMarkerCombinations, 
      actantGrammarCases, actantPositions, actantTypes, actantGroups, verbTransitions, verbPluralityTypes, verbTypes, 
      verbSeries, verbPersonMarkerParadigms
     } = masterData.mdRepo;
  
  //console.log("CreateVerbPersonMarkerCombinations props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["actantGrammarCases", "actantPositions", "actantTypes", "actantGroups", 
    "verbTransitions", "verbPluralityTypes", "verbTypes", "verbSeries", "verbPersonMarkerParadigms"], []);

  const listTableNames = useMemo(() => ["actantGrammarCasesByActantTypes", "actantTypesByVerbTypes", 
    "pluralityChangesByVerbTypes", "verbPersonMarkerCombinations"], []);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

        loadMultipleListData(listTableNames, tableNamesForLoad);
  }, [isMenuLoading, flatMenu, isValidPage, loadMultipleListData, listTableNames, tableNamesForLoad]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recId: NzInt(props.match.params.recName), 
    recName: props.match.params.recName
  });
  
  function saveReturnPageName() {
    props.saveReturnPageName(props.match.url.split('/')[1]);
  }

  const chackedDataTypes =[];
  const GridRules =[];
  let allListsLoaded = true;
  
  listTableNames.forEach(listTableName => {
    const checkResult = checkDataLoaded(masterData, listTableName, listTableName);
    if (checkResult) {
      const { dataType, gridRules } = checkResult;
      chackedDataTypes[listTableName] = dataType;
      GridRules[listTableName] = gridRules;
    }
    else
      allListsLoaded = false;
  });

  if (mdWorkingOnLoad || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }
if (!allListsLoaded || !curscrollTo || 
    !actantGrammarCasesByActantTypes || !actantTypesByVerbTypes || !pluralityChangesByVerbTypes || 
    !verbPersonMarkerCombinations || !actantGrammarCases || !actantPositions || !actantTypes || !actantGroups || 
    !verbTransitions || !verbPluralityTypes || !verbTypes || !verbSeries || !verbPersonMarkerParadigms || !datatypes ) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  const actantTypesDict = {};

  actantTypes.forEach(element => {
    actantTypesDict[element.attId] = element;
  });

  const verbSeriesDict = {};

  verbSeries.forEach(element => {
    verbSeriesDict[element.vsrId] = element;
  });

  const verbTypesDict = {};

  verbTypes.forEach(element => {
    verbTypesDict[element.vtpId] = element;
  });

  const actantPositionsDict = {};

  actantPositions.forEach(element => {
    actantPositionsDict[element.apnId] = element;
  });

  const verbPluralityTypesDict = {};

  verbPluralityTypes.forEach(element => {
    verbPluralityTypesDict[element.vptId] = element;
  });

  const verbPersonMarkerParadigmsDict = {};

  verbPersonMarkerParadigms.forEach(element => {
    verbPersonMarkerParadigmsDict[element.vpmpnId] = element;
  });

  const listEditorTableNames = ["actantGrammarCases", "actantPositions", "actantTypes", "actantGroups", 
    "verbTransitions", "verbPluralityTypes", "verbTypes", "verbPersonMarkerParadigms", "verbSeries"];
  
  return (
    <div>
      <h3>პირის ნიშნების კომბინაციების დათვლა</h3>
      <h4>ტერმინები</h4>
      <p><strong>აქტანტის ბრუნვა</strong> - აქტანტის ბრუნვის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის პოზიცია</strong> - აქტანტის პოზიციის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის ტიპი</strong> - აქტანტის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>აქტანტის ჯგუფი</strong> - აქტანტის ჯგუფის განმარტება (გასაკეთებელია).</p>
      <p><strong>გარდამავლობა</strong> - გარდამავლობის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის მრავლობითობის ტიპი</strong> - ზმნის მრავლობითობის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის ტიპი</strong> - ზმნის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის ცალი პირის ნიშნების პარადიგმა</strong> - ზმნის ცალი პირის ნიშნების პარადიგმის განმარტება (გასაკეთებელია).</p>
      <p><strong>სერია</strong> - სერიის განმარტება (გასაკეთებელია).</p>

      {listEditorTableNames.map(tn => {
        const dataType = datatypes.find(f=>f.dtTable === tn);

        return (<NameListEditor key={dataType.dtName} dataType={dataType} tableForEdit={masterData.mdRepo[tn]} 
          curscrollTo={curscrollTo} backLigth={backLigth} saveReturnPageName={saveReturnPageName} />);
      } )}

      <MdListView dataType={chackedDataTypes["actantGrammarCasesByActantTypes"]} 
        table={actantGrammarCasesByActantTypes
          .sort((a, b) => {
            const f = actantTypesDict[a.actantTypeId].sortId-actantTypesDict[b.actantTypeId].sortId;
            if ( f ) 
              return f;
            return verbSeriesDict[a.verbSeriesId].sortId-verbSeriesDict[b.verbSeriesId].sortId;
          })
        } gridColumns={GridRules["actantGrammarCasesByActantTypes"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <MdListView dataType={chackedDataTypes["actantTypesByVerbTypes"]} 
        table={actantTypesByVerbTypes
          .sort((a, b) => {
            const f = verbTypesDict[a.verbTypeId].sortId-verbTypesDict[b.verbTypeId].sortId;
            if ( f ) 
              return f;
          return actantPositionsDict[a.actantPositionId].sortId-actantPositionsDict[b.actantPositionId].sortId;
          })
        } gridColumns={GridRules["actantTypesByVerbTypes"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <MdListView dataType={chackedDataTypes["pluralityChangesByVerbTypes"]} 
        table={pluralityChangesByVerbTypes
          .sort((a, b) => {
            let f = verbPluralityTypesDict[a.pluralityTypeIdStart].sortId-verbPluralityTypesDict[b.pluralityTypeIdStart].sortId;
            if ( f ) 
              return f;
            f = verbTypesDict[a.verbTypeId].sortId-verbTypesDict[b.verbTypeId].sortId;
            if ( f ) 
              return f;
            return verbSeriesDict[a.verbSeriesId].sortId-verbSeriesDict[b.verbSeriesId].sortId;
          })
        } gridColumns={GridRules["pluralityChangesByVerbTypes"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />

      <h4>პროცესის აღწერა</h4>
      <p>აქტანტების თითოეული კომბინაციისთვის ხდება შემდეგი კომბინაციის დაანგარიშება:<br/>
        ზმნის მრავლობითობის ტიპი, ზმნის პირის პარადიგმა, ზმნის ტიპი, სერია, აქტანტების კომბინაცია.<br/>
      ამ ინფორმაციის დასაანგარიშებლად გამოიყენება:  
      </p>
      <ul>
        <li>ზმნის მრავლობითობის, ზმნის ტიპისა და სერიების კომბინაცია. რომელიც გვაძლევს ინფორმაციას საბოლოოდ რა მარვლობითობის ტიპი მიიღება.</li>
        <li>ზმნის პირის ნიშნების პარადიგმის ზტრიქონები: ზმნის მრავლობითობა, ზმნის პირის პარადიგმა, პირი, რიცხვი.</li>
        <li>ზმნის ტიპისა და პოზიციის მიხედვით აქტანტის ტიპის განსაზღვრა.</li>
      </ul>

      <h4>შედეგი</h4>

      <MdListView readOnly dataType={chackedDataTypes["verbPersonMarkerCombinations"]} 
        table={verbPersonMarkerCombinations
          .sort((a, b) => {
            let f = verbPluralityTypesDict[a.verbPluralityTypeId].sortId-verbPluralityTypesDict[b.verbPluralityTypeId].sortId;
            if ( f ) 
              return f;
            f = verbPersonMarkerParadigmsDict[a.verbPersonMarkerParadigmId].sortId-verbPersonMarkerParadigmsDict[b.verbPersonMarkerParadigmId].sortId;
            if ( f ) 
              return f;
            f = verbTypesDict[a.verbTypeId].sortId-verbTypesDict[b.verbTypeId].sortId;
            if ( f ) 
              return f;
            return verbSeriesDict[a.verbSeriesId].sortId-verbSeriesDict[b.verbSeriesId].sortId
          })
        } gridColumns={GridRules["verbPersonMarkerCombinations"].cells} 
        masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth}
        firstFilter={ { verbPluralityTypeId: true, verbPersonMarkerParadigmId: true, verbTypeId: true, verbSeriesId: true } }
         />






    </div>
  );
}

function mapStateToProps(state) {
  const alert = state.alert;
  const { isMenuLoading, flatMenu } = state.navMenu;
  const masterData = state.masterData;

  return { alert, isMenuLoading, flatMenu, masterData };

}

function mapDispatchToProps(dispatch) {
  return {
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    loadMultipleListData: (listTableNames, tableNamesForLoad) => dispatch(MasterDataActions.loadMultipleListData(listTableNames, listTableNames, tableNamesForLoad))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVerbPersonMarkerCombinations);
