//VerbRowParadigmsOverview.js

import React, {useEffect, useMemo, useCallback} from 'react';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';

import Loading from '../../carcass/common/Loading';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as FormulasActions } from './FormulasStore';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';
import NameListEditor from './NameListEditor';
import ParadigmListEditor from './ParadigmListEditor';

const VerbRowParadigmsOverview = (props) => {

  const {alert, isMenuLoading, flatMenu, masterData, checkLoadMdTables, 
    verbRowParadigmNamesLoading, verbRowParadigmNames, checkLoadVerbRowParadigmNames} = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const {verbTransitions, verbTypes, verbSeries, verbRows} = masterData.mdRepo;
  
  //console.log("VerbRowParadigmsOverview props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["verbTransitions", "verbTypes", "verbSeries", "verbRows"], []);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    checkLoadMdTables(tableNamesForLoad);
    checkLoadVerbRowParadigmNames();
  }, [isMenuLoading, flatMenu, isValidPage, checkLoadMdTables, checkLoadVerbRowParadigmNames, tableNamesForLoad]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recId: NzInt(props.match.params.recName), 
    recName: props.match.params.recName
  });

  function saveReturnPageName() {
    props.saveReturnPageName(props.match.url.split('/')[1]);
  }


  //console.log("VerbRowParadigmsOverview onCheckLoad ", {verbTransitions, verbTypes, verbSeries, verbRows, verbRowParadigmNames, curscrollTo, mdWorkingOnLoad, verbRowParadigmNamesLoading});

  if (mdWorkingOnLoad || verbRowParadigmNamesLoading || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }

  if (!verbTransitions || !verbTypes || !verbSeries || !verbRows || !verbRowParadigmNames || !curscrollTo || !datatypes) { 
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  const listEditorTableNames = ["verbTransitions", "verbTypes", "verbSeries", "verbRows"];

  const verbParadigmsDataType = datatypes.find(f=>f.dtTable === "verbParadigms");

  return (
    <div>
      <h3>ზმნის მწკრივების პარადიგმების მიმოხილვა</h3>
      <h4>ტერმინები</h4>
      <p><strong>გარდამავლობა</strong> - გარდამავლობის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის ტიპი</strong> - ზმნის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>სერია</strong> - სერიის განმარტება (გასაკეთებელია).</p>
      <p><strong>მწკრივი</strong> - მწკრივის განმარტება (გასაკეთებელია).</p>
      {/* <p><strong>აქტანტების ჯგუფი</strong> - აქტანტების ჯგუფის განმარტება (გასაკეთებელია).</p>
      <p><strong>პირის ნიშნების პარადიგმა</strong> - პირის ნიშნების პარადიგმის განმარტება (გასაკეთებელია).</p> */}
      <p><strong>მწკრივის პარადიგმა</strong> - მწკრივის პარადიგმის განმარტება (გასაკეთებელია).</p>

      {listEditorTableNames.map(tn => {
        const dataType = datatypes.find(f=>f.dtTable === tn);

        return (<NameListEditor key={dataType.dtName} dataType={dataType} tableForEdit={masterData.mdRepo[tn]} 
          curscrollTo={curscrollTo} backLigth={backLigth} saveReturnPageName={saveReturnPageName} />);
      } )}

      <ParadigmListEditor dataType={verbParadigmsDataType} paradigmNamesTable={verbRowParadigmNames} 
        formulasTableName="verbRowParadigmFormulas" curscrollTo={curscrollTo} 
        backLigth={backLigth} saveReturnPageName={saveReturnPageName}  />

    </div>
  );


}

function mapStateToProps(state) {

  const alert = state.alert;
  const masterData = state.masterData;
  const {isMenuLoading, flatMenu} = state.navMenu;
  const { verbRowParadigmNamesLoading, verbRowParadigmNames } = state.formulasStore;

  return {alert, isMenuLoading, flatMenu, masterData, verbRowParadigmNamesLoading, verbRowParadigmNames};
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    checkLoadVerbRowParadigmNames: () => dispatch(FormulasActions.checkLoadVerbRowParadigmNames())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerbRowParadigmsOverview);
