//PhoneticsOptionEdit.js
import React, {useState, useEffect, useMemo} from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as ModelEditorActions } from './ModelEditorStore';
//import { history } from '../../carcass/common/History';
//import { useHistory } from "react-router-dom";
import { useForman } from '../../carcass/common/MyHooks';
import WaitPage from '../../carcass/common/WaitPage';
import { GetOnephoneticsOptionDetailDescription } from './PhoneticsTypeModule';
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OneTextControl from '../../carcass/editorParts/OneTextControl';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneNumberControl from '../../carcass/editorParts/OneNumberControl';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl'; 
import OnePlusButton from '../editorParts/OnePlusButton';
import OneEditDeleteButtons from '../editorParts/OneEditDeleteButtons';
import OneUpDownButtons from '../editorParts/OneUpDownButtons';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';

const PhoneticsOptionEdit = (props) => {

  //const history = useHistory();

  const [curPhoIdVal, setCurPhoIdVal] = useState(null); //1. იდენტიფიკატორი
  const [expandedActionIndex, setExpandedActionIndex] = useState(null); //გახსნილი მოქმედების ნომერი

  //2. კომპონენტის თვისებები
  const { alert, datatypesLoading, datatypes, checkLoadDataTypes,
    savingPhoneticsOption, loadingPhoneticsOption, DeleteFailure, workingOnDeletePhoneticsOption, 
    phoneticsOptionForEdit, getOnePhoneticsOptionById } = props;

  const { phoId } = props.match.params;

    //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => ["phoneticsOptions", "phoneticsOptionDetails"], []);

  //4. ამ რედაქტორს არ სჭირდება ინფორმაცია ცხრილებიდან
  // const tableNamesFroLoad = [];

  //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    phoneticsOption: yup.object().shape({
      phoId: yup.number().default(0),
      phoName: yup.string().required("ფონეტიკური ვარიანტის სახელი შევსებული უნდა იყოს").default("")
    }),
    //ფონეტიკური შეზღუდვები
    phoneticsOptionDetails: yup.array().required("ფონეტიკური ვარიანტის ერთი მაინც დეტალი უნდა არსებობდეს").ensure()
    .min(1, "უნდა არსებობდეს ერთი მაინც ფონეტიკური მოქმდების დეტალი")
    .of(yup.object().shape({
      phodId: yup.number().default(0),
      phodActOrd: yup.number().default(0), //მოქმედების რიგითი ნომერი
      phodActId: yup.number().default(0), //მოქმედების ტიპი 0-იკარგება/ჩაენაცვლება; 1-ჩნდება
      phodOrient: yup.number().default(0), //ორიენტაცია 0-ბოლოდან; 1-თავიდან
      phodStart: yup.number().default(0), //აღნიშნავს იმ სიმბოლოს ნომერს, რომლიდანაც განვიხილავთ სიმბოლოებს
      phodCount: yup.number().default(1), //აღნიშნავს ჩვენთვის საინტერესო სომბოლოების რაოდენობას.
      phodObject: yup.number().default(0), //ობიექტის ტიპი: 0-ბგერა; 1-ხმოვანი; 2-თანხმოვანი
      //ჩანმაცვლებელი, ან ახალი სიმბოლოების თანმიმდევრობა
      phodNew: yup.string().nullable(true).default("").when(['phodActId', 'phodObject'], (phodActId, phodObject, schema) => {
        if ( phodActId === 1 || !phodObject )
          return schema.required('როცა მოქმედების ტიპი არჩეულია "ჩნდება", ან ობიექტი არჩეულია "ბგერა", სიმბოლო(ები) შევსებული უნდა იყოს');
        return schema;
      } )
    }))
  });

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    props.clearTablesFromRepo(tableNamesForClear);
  }

  useEffect(() => {
    checkLoadDataTypes();
  }, [checkLoadDataTypes, datatypesLoading, datatypes]);

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {
    const phoIdVal = phoId ? parseInt(phoId) : 0;

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curPhoIdVal !== phoIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurPhoIdVal(phoIdVal);
      if (phoIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        getOnePhoneticsOptionById(phoIdVal);
        return;
      }
      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }
    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთულიობიექტი მაინც შევამოწმოთ
    if ( loadingPhoneticsOption || !phoId || !phoneticsOptionForEdit )
      return;

    //ჩატვირთული ინფორმაცია მივცეთ ფორმის მენეჯერს
    setFormData(phoneticsOptionForEdit);

  }, [clearToDefaults, curPhoIdVal, phoId, setFormData, phoneticsOptionForEdit, loadingPhoneticsOption, getOnePhoneticsOptionById, checkLoadDataTypes]);
  
  if ( loadingPhoneticsOption || datatypesLoading ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
    return (<WaitPage />);

//8. ჩატვირთვის შემოწმება
  if ( (curPhoIdVal && !phoneticsOptionForEdit) || !datatypes ) { //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }
  
  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert()
    if (haveErrors)
      return;
    if ( curPhoIdVal )
      props.updatePhoneticsOption(props.history, frm);
    else
      props.createPhoneticsOption(props.history, frm);
    clearUsedTables();
    }

  function renumDetails(phoneticsOptionDetails)
  {
    frm.phoneticsOptionDetails
      .sort((a,b) => a.phodActOrd - b.phodActOrd)
      .forEach((e,i) => e.phodActOrd = i);
  }

  function goDetailDown(index)
  {
    renumDetails(frm.phoneticsOptionDetails);
    frm.phoneticsOptionDetails[index].phodActOrd = index + 1;
    frm.phoneticsOptionDetails[index+1].phodActOrd = index;
  }

  const dataType = datatypes.find(f=>f.dtTable === "phoneticsOptions");
  
  return (
    <Row>
      <Col sm="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          <EditorHeader curIdVal={curPhoIdVal} EditorName="ფონეტიკური ვარიანტი" EditorNameGenitive="ფონეტიკური ვარიანტის"
              EditedObjectName={(frm && frm.phoneticsOption && frm.phoneticsOption.phoName) ? frm.phoneticsOption.phoName : ""}
              workingOnDeletePhoneticsType={workingOnDeletePhoneticsOption} DeleteFailure={DeleteFailure}
              onDelete={() => {props.deletePhoneticsOption(props.history, curPhoIdVal); clearUsedTables();}}
              onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={dataType.delete} />
          <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />
          <OneTextControl controlId="phoneticsOption.phoName" label="დასახელება" value={frm.phoneticsOption.phoName} 
              getError={getError} onChangeValue={changeField} />
          <OneStrongLabel controlId="phoneticsOptionDetailsLabel" label="დეტალები" />
          <ol>
            {frm && frm.phoneticsOptionDetails && frm.phoneticsOptionDetails
              .sort((a,b) => a.phtpProhOrd-b.phtpProhOrd)
              .map((phod, index) => {
              return (
                <li key={index} >
                  <OneEditDeleteButtons controlId={index} label={GetOnephoneticsOptionDetailDescription(phod)} 
                      onEditClick={(e) => {
                        e.preventDefault();
                        if ( expandedActionIndex === index )
                          setExpandedActionIndex(null);
                        setExpandedActionIndex(index);
                      }}
                      onDeleteClick={(e) => {
                        e.preventDefault();
                        const newFrm = {...frm};
                        newFrm.phoneticsOptionDetails.splice(index,1);
                        setFormData(newFrm);
                      }} />
                  {expandedActionIndex === index && 
                    <div>
                      <OneUpDownButtons controlId={index} label="პოზიციის ცვლილება" 
                        enableUp={index>0} enableDown={index<frm.phoneticsOptionDetails.length-1} 
                        onUpClick={(e) => {
                          e.preventDefault();
                            goDetailDown(index-1);
                            setExpandedActionIndex(index-1);
                        }} onDownClick={(e) => {
                          e.preventDefault();
                          goDetailDown(index);
                          setExpandedActionIndex(index+1);
                      }} />
                      {/* <Form.Label column sm="10">რედაქტირდება აკრძალვა ინდექსით {index}</Form.Label> */}
                      {/* {getOneNumberControl(`phoneticsOptionDetails[${index}].phodActOrd`, "რიგირთი ნომერი", frm.phoneticsOptionDetails[index].phodActOrd, 0)} */}
                      <OneComboBoxControl controlId={`phoneticsOptionDetails[${index}].phodActId`} 
                          label="მოქმედების ტიპი" value={frm.phoneticsOptionDetails[index].phodActId} 
                          dataMember={[{id: 0, name: "იკარგება/ჩაენაცვლება"}, {id: 1, name: "ჩნდება"}]} 
                          valueMember="id" displayMember="name" getError={getError} onChangeValue={changeField} />
                      <OneComboBoxControl controlId={`phoneticsOptionDetails[${index}].phodOrient`} 
                          label="ორიენტაცია" value={frm.phoneticsOptionDetails[index].phodOrient} 
                          dataMember={[{id: 0, name: "ბოლოდან"}, {id: 1, name: "თავიდან"}]} 
                          valueMember="id" displayMember="name" getError={getError} onChangeValue={changeField} />
                      <OneNumberControl controlId={`phoneticsOptionDetails[${index}].phodStart`} label="პირველი სიმბოლოს ნომერი" 
                        value={frm.phoneticsOptionDetails[index].phodStart} getError={getError} onChangeValue={changeField} minv={0} />
                      <OneNumberControl controlId={`phoneticsOptionDetails[${index}].phodCount`} label="სიმბოლოების რაოდენობა" 
                        value={frm.phoneticsOptionDetails[index].phodCount} getError={getError} onChangeValue={changeField} minv={1} />
                      <OneComboBoxControl controlId={`phoneticsOptionDetails[${index}].phodObject`} 
                          label="ობიექტის ტიპი" value={frm.phoneticsOptionDetails[index].phodObject} 
                          dataMember={[{id: 0, name: "ბგერა"}, {id: 1, name: "ხმოვანი"}, {id: 2, name: "თანხმოვანი"}]} 
                          valueMember="id" displayMember="name" getError={getError} onChangeValue={changeField} />
                      <OneTextControl controlId={`phoneticsOptionDetails[${index}].phodNew`} label="სიმბოლოები" 
                        value={frm.phoneticsOptionDetails[index].phodNew} getError={getError} onChangeValue={changeField} />
                    </div>
                  }
                </li>
            )})}
          </ol>

          { dataType.update &&
            <OnePlusButton onClick={() => {
              const newFrm = {...frm};
              const newPod = yup.reach(yupSchema, 'phoneticsOptionDetails[0]').default();
              newPod.phodActOrd = newFrm.phoneticsOptionDetails.length + 1;
              newFrm.phoneticsOptionDetails.push(newPod);
              setFormData(newFrm);
              setExpandedActionIndex(newFrm.phoneticsOptionDetails.length-1);
            }} />
          }

          <OneSaveCancelButtons curIdVal={curPhoIdVal} haveErrors={haveErrors} savingNow={savingPhoneticsOption} onCloseClick={(e) => { 
              props.clearAlert(); props.history.goBack(); }} allowEdit={dataType.update} />

          <OneErrorRow allErrors={allErrors} />

        </Form>
      </Col>
    </Row>
  );

}

function mapStateToProps(state) {
  const { datatypesLoading, datatypes } = state.masterData;
  const { DeleteFailure, savingPhoneticsOption, loadingPhoneticsOption, workingOnDeletePhoneticsOption, phoneticsOptionForEdit } = state.modelEditorStore;
  const alert = state.alert;

  return { alert, datatypesLoading, datatypes, DeleteFailure, savingPhoneticsOption, loadingPhoneticsOption, workingOnDeletePhoneticsOption, phoneticsOptionForEdit };
}

function mapDispatchToProps(dispatch) {
  return {
    getOnePhoneticsOptionById: (phoId) => dispatch(ModelEditorActions.getOnePhoneticsOptionById(phoId)),
    updatePhoneticsOption: (history, pho) => dispatch(ModelEditorActions.updatePhoneticsOption(history, pho)),
    createPhoneticsOption: (history, pho) => dispatch(ModelEditorActions.createPhoneticsOption(history, pho)),
    deletePhoneticsOption: (history, phoId) => dispatch(ModelEditorActions.deletePhoneticsOption(history, phoId)),
    clearDeletingFailure: () => dispatch(ModelEditorActions.clearDeletingFailure()),
    clearTablesFromRepo: (tableNamesForClear) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, [])),
    checkLoadDataTypes: () => dispatch(MasterDataActions.checkLoadDataTypes()),
    clearAlert: () => dispatch(alertActions.clear())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneticsOptionEdit);

