//StatusPart.js

import React from 'react';
// import { Form, Row, Col, Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import OnePlaintextRow from '../editorParts/OnePlaintextRow';


const StatusPart = (props) => {

  // const { recordStatusId, creator, onConfirmClick, onRejectClick } = props;
  const { recordStatusId, creator } = props;
  //console.log("StatusPart props=", props);


  // let editStatus = "";
  let editStatusColor = "";
  // let confirmButtonText = "";
  // let rejectButtonText = "";
  switch(recordStatusId)
  {
    case 0:
      // editStatus = 'ახალი';
      editStatusColor = 'blue';
      // confirmButtonText = "დადასტურება";
      // rejectButtonText = "უარყოფა";
      break;
    case 1:
      // editStatus = 'წაშლის კანდიდატი';
      editStatusColor = 'red';
      // confirmButtonText = "წაშლის დადასტურება";
      // rejectButtonText = "წაშლის უარყოფა";
      break;
    case 2:
      // editStatus = 'დამოწმებული';
      editStatusColor = 'green';
      break;
    default:
      break;
  }

  return (
    <span style={{color: `${editStatusColor}`}}>({creator})</span>
  );
//{editStatus} 
  // return (

  //   <div>
              
  //     <OnePlaintextRow controlId="editStatus" label="სტატუსი" 
  //     text={editStatus} color={editStatusColor} />          
      
  //     <OnePlaintextRow controlId="creator" label="შემქმნელი" 
  //     text={creator} />          

  //     {/* <OneConfirmRejectButtons controlId="confirmRejectButtons" label="" recordStatusId={recordStatusId} /> */}
    
  //     { (recordStatusId === 0 || recordStatusId === 1) &&
  //       <Form.Group className="mb-1" as={Row}>
  //         <Col sm="10" align="right">
  //           <Button className="mr-1" onClick={onConfirmClick} >
  //             <FontAwesomeIcon icon="check" /> {confirmButtonText}
  //           </Button>
  //           <Button variant="danger" onClick={onRejectClick} >
  //             <FontAwesomeIcon icon="times" /> {rejectButtonText}
  //           </Button>
  //         </Col>
  //       </Form.Group>
  //     }


  //   </div>

  // );

}
//{ userHasConfirmRight && 
export default StatusPart;
