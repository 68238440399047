//RecountsDashboard
import React, {useState, useEffect, useContext} from 'react';
import { Button, Spinner, ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { HubConnectionBuilder } from '@aspnet/signalr';

import { actionCreators as RecountsActions } from './RecountsStore';
import { ProjectContext } from '../ProjectProvider';



const RecountsDashboard = (props) => {

  const { flatMenu, 
    rcWorkingOnRecountBases, failureRecountBases, RecountBases,
    rcWorkingOnRecountInflectionSamples, failureRecountInflectionSamples, RecountInflectionSamples,
    rcWorkingOnRecountFindDerivationBranchesWithoutDescendants, failureFindDerivationBranchesWithoutDescendants, RecountFindDerivationBranchesWithoutDescendants
  } = props;
  //console.log("RecountsDashboard props=", props);

  const [procName, setProcName] = useState('');
  const [levelName, setLevelName] = useState('');
  const [checkBase, setCheckBase] = useState('');
  const [changedBase, setChangedBase] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [procLength, setProcLength] = useState(0);
  const [procPosition, setProcPosition] = useState(0);

  const [byLevelLength, setByLevelLength] = useState(0);
  const [byLevelPosition, setByLevelPosition] = useState(0);


  const [alertMessage, setAlertMessage] = useState('');
  const [hubConnection, setHubConnection] = useState();
  const { baseUrl } = useContext(ProjectContext);


  // Set the Hub Connection on mount.
  useEffect(() => {

    // Set the initial SignalR Hub Connection.
    const createHubConnection = async () => {

      // Build new Hub Connection, url is currently hard coded.
      const hubConnect = new HubConnectionBuilder()
        .withUrl(`${baseUrl}/recountmessages`)
        .build();
      try {
        await hubConnect.start();
        //console.log('hub Connection successful!');


        // Bind event handlers to the hubConnection.
        hubConnect.on('sendtoall', (receivedData) => {
          //console.log("RecountsDashboard receivedMessage=", receivedData);
          if ( receivedData == null ) {
            setProcName('');
            setLevelName('');
            setCheckBase('');
            setChangedBase('');
            setErrorMessage('');
            setProcLength(0);
            setProcPosition(0);
            setByLevelLength(0);
            setByLevelPosition(0);
            return;
          }
          const { strData, intData } = receivedData;
          if ( strData !== null )
          {
            if ( strData.procName || strData.procName === '' )
              setProcName(strData.procName);
            if ( strData.levelName || strData.levelName === '' )
              setLevelName(strData.levelName);
            if ( strData.checkBase || strData.checkBase === '' )
              setCheckBase(strData.checkBase);
            if ( strData.changedBase || strData.changedBase === '' )
              setChangedBase(strData.changedBase);
            if ( strData.error || strData.error === '' )
              setErrorMessage(strData.error);
          }
          if ( intData !== null )
          {
            if ( intData.procLength || intData.procLength === '' )
              setProcLength(intData.procLength);
            if ( intData.procPosition || intData.procPosition === '' )
              setProcPosition(intData.procPosition);
            if ( intData.byLevelLength || intData.byLevelLength === '' )
              setByLevelLength(intData.byLevelLength);
            if ( intData.byLevelPosition || intData.byLevelPosition === '' )
              setByLevelPosition(intData.byLevelPosition);
          }
          //setLastMessage(receivedMessage);
        });

        // hubConnect.on('newuserconnected', () => {
        //   console.log("RecountsDashboard newuserconnected");
        // });

        // hubConnect.off('userdisconnected', () => {
        //   console.log("RecountsDashboard newuserconnected");
        // });

      }
      catch (err) {
        setAlertMessage(err.message);
        //console.log('Error while establishing connection: ' + { err });
      }
      setHubConnection(hubConnect);
    }

    createHubConnection();

    return ( () => { hubConnection && hubConnection.stop(); });



  }, 
    
    //async () => { hubConnection && await hubConnection.stop(); },
// eslint-disable-next-line
    [baseUrl]); //hubConnection, 




  function isValidPage() {
    if (!flatMenu) {
      return true;
    }
    return flatMenu.some(f=>f.menLinkKey === props.match.url.split('/')[1]);
  }

  if ( ! isValidPage() )
    return (<h3>ინფორმაციის ჩატვირთა ვერ მოხერხდა</h3>);

  const procPercentage = Math.round(procPosition / procLength * 100);
  const byLevelPercentage = Math.round(byLevelPosition / byLevelLength * 100);

  //console.log("RecountsDashboard procPercentage=", procPercentage);
  //console.log("RecountsDashboard byLevelPosition=", byLevelPosition);
  //console.log("RecountsDashboard byLevelLength=", byLevelLength);
  //console.log("RecountsDashboard byLevelPercentage=", byLevelPercentage);

  return (
    <div>
      <h3>გადაანგარიშებები</h3>
      <Button className="mr-1 mb-1" type="button" onClick={(e) => {
        e.preventDefault();
        RecountBases();
      }} >{rcWorkingOnRecountBases && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}ფუძეების გადათვლა</Button>
      {failureRecountBases && <p>ფუძეების გადათვლის გაშვების ბოლო მცდელობა წარუმატებლად დასრულდა.</p>}
      <Button className="mr-1 mb-1" type="button" onClick={(e) => {
        e.preventDefault();
        RecountInflectionSamples();
      }} >{rcWorkingOnRecountInflectionSamples && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}ფლექსიის ნიმუშების გადათვლა</Button>
      {failureRecountInflectionSamples && <p>ფლექსიის ნიმუშების გადათვლის გაშვების ბოლო მცდელობა წარუმატებლად დასრულდა.</p>}
      <Button className="mr-1 mb-1" type="button" onClick={(e) => {
        e.preventDefault();
        RecountFindDerivationBranchesWithoutDescendants();
      }} >{rcWorkingOnRecountFindDerivationBranchesWithoutDescendants && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}უშვილო დერივაციების პოვნა</Button>
      {failureFindDerivationBranchesWithoutDescendants && <p>უშვილო დერივაციების პოვნის პროცესის გაშვების ბოლო მცდელობა წარუმატებლად დასრულდა.</p>}
      { !!procName && <div>
          <p className="mt-1 mb-0">{procName}</p>
          {!!procLength && <ProgressBar now={procPercentage} label={`${procPercentage}%`} />}
          <p className="mt-3 mb-0">{levelName}</p>
          {!!byLevelLength && <ProgressBar now={byLevelPercentage} label={`${byLevelPercentage}%`} />}
          <p>{checkBase}</p>
          <p>{changedBase}</p>
          <p>{errorMessage}</p>
        </div>
      }
      <p>{alertMessage}</p>
    </div>
  );
};

function mapStateToProps(state) {
  const { rcWorkingOnRecountBases, failureRecountBases, 
    rcWorkingOnRecountInflectionSamples, failureRecountInflectionSamples, 
    rcWorkingOnRecountFindDerivationBranchesWithoutDescendants, failureFindDerivationBranchesWithoutDescendants } = state.recountsStore;
  const { flatMenu } = state.navMenu;
  return { flatMenu, rcWorkingOnRecountBases, failureRecountBases, 
    rcWorkingOnRecountInflectionSamples, failureRecountInflectionSamples, 
    rcWorkingOnRecountFindDerivationBranchesWithoutDescendants, failureFindDerivationBranchesWithoutDescendants };
}

function mapDispatchToProps(dispatch) {
  return {
    RecountBases: () => dispatch(RecountsActions.RecountBases()),
    RecountInflectionSamples: () => dispatch(RecountsActions.RecountInflectionSamples()),
    RecountFindDerivationBranchesWithoutDescendants: () => dispatch(RecountsActions.RecountFindDerivationBranchesWithoutDescendants())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecountsDashboard);

