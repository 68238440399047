//GridView.js

import React, {useState, useEffect, useCallback} from 'react';
import { Table, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

import { NzInt } from '../../carcass/common/myFunctions'
import Loading from '../../carcass/common/Loading';
import './GridView.css'


const GridView = (props) => {

  // const { columns, rows, offset, showRows, allRowsCount, showCountColumn, onChangeOffsetAndShowRows, onFilterSortChange } = props;
  const { columns, rows, offset, showRows, allRowsCount, showCountColumn, onLoad, 
    onFilterSortChange, onChangeOffsetAndShowRows, filterSortObject, filterSortId, loading, curscrollTo, backLigth } = props;
    //, filterSortId
  //console.log("GridView props=", props);

  const [curOffset, setCurOffset] = useState(0); //წანაცვლება
  const [curShowRows, setCurShowRows] = useState(10); //ცხრილში საჩვენებელი სტრიქონების რაოდენობა
  const [curFilterSortId, setCurFilterSortId] = useState(-1); //ფილტრაციისა და სორტირების მიმდინარე რეჟიმის ნომერი

  const [curOffsetInputWith, setCurOffsetInputWith] = useState(50); //წანაცვლების ველის სიგანე
  const [curSortFieldNames, setCurSortFieldNames] = useState([]); //სორტირების ველების სახელები



  let gvSortFieldNames = curSortFieldNames;
  if ( filterSortObject && filterSortObject.sortByFields )
    gvSortFieldNames = filterSortObject.sortByFields;


  const gvFilterSortId = filterSortId ?? curFilterSortId;
  const gvOffset = offset ?? curOffset;
  const gvShowRows = showRows ?? curShowRows;
  const gvAllRowsCount = (allRowsCount || allRowsCount === 0) ? allRowsCount : rows.length;
  //console.log("GridView gvAllRowsCount=", gvAllRowsCount);

  // useEffect(() => {
  //   // if ( filterSortId !== curFilterSortId) {
  //   //   console.log("GridView useEffect {filterSortId, curFilterSortId}=", {filterSortId, curFilterSortId});
  //     // setCurFilterSortId(filterSortId);
  //     // changeOffsetAndShowRows(gvOffset, gvShowRows, true);
  //   // }
  //     changeOffsetAndShowRows(gvOffset, gvShowRows);
    
  // }, [changeOffsetAndShowRows, gvOffset, gvShowRows]);//filterSortId  , gvAllRowsCount, gvOffset, gvShowRows, changeOffsetAndShowRows, rows


  //console.log("GridView {gvOffset, gvShowRows}=", {gvOffset, gvShowRows});

  // const gvOffset = curOffset;
  // const gvShowRows = curShowRows;

  const changeOffsetAndShowRows = useCallback((targetOffset, targetShowRows) => {

    // debugger;

    //console.log("GridView changeOffsetAndShowRows {targetOffset, targetShowRows}=", {targetOffset, targetShowRows});

    let haveChanges = false;// forceLoad;

    if ( targetShowRows !== gvShowRows) {
      setCurShowRows(targetShowRows);
      //console.log("GridView setCurShowRows targetShowRows=", targetShowRows);
      haveChanges = true;
    }

    let newOffset = targetOffset;
    if ( newOffset > gvAllRowsCount - targetShowRows )
      newOffset = gvAllRowsCount - targetShowRows;
    if ( newOffset < 0 )
      newOffset = 0;

    if ( newOffset !== gvOffset) {
      setCurOffset(newOffset);
      //console.log("GridView setCurOffset newOffset=", newOffset);
      setCurOffsetInputWith((Math.floor(Math.log10(newOffset))+1)*9+31);
      haveChanges = true;
    }

    if ( haveChanges && onChangeOffsetAndShowRows ) {
      //console.log("GridView changeOffsetAndShowRows onChangeOffsetAndShowRows {newOffset, targetShowRows}=", {newOffset, targetShowRows});
      onChangeOffsetAndShowRows(newOffset, targetShowRows);
    }

    //if ( (haveChanges || forceLoad) && onLoad ) {
    if ( onLoad ) {
      //console.log("GridView changeOffsetAndShowRows {newOffset, targetShowRows}=", {newOffset, targetShowRows});

      let needsToLoad = false;
      //console.log("GridView changeOffsetAndShowRows needsToLoad=", needsToLoad);
      if ( ! needsToLoad && ! rows ) {
        //console.log("GridView changeOffsetAndShowRows needsToLoad because {rows}=", {rows});
        needsToLoad = true;
      }

      if ( ! needsToLoad ) {
        for (let i = 0; i < targetShowRows; i++) {
          const index = newOffset + i;
          if ( !(index in rows) || rows[index] === undefined || rows[index] === null ) {
            //console.log("GridView changeOffsetAndShowRows needsToLoad because {index, rows[index]}=", {index, val: rows[index]});
            needsToLoad = true;
            break;
          }
        }
      }
    
      if ( needsToLoad ){
        //console.log("GridView changeOffsetAndShowRows call");
        onLoad(newOffset, targetShowRows);
      }
    }

  },[gvAllRowsCount, gvOffset, gvShowRows, onChangeOffsetAndShowRows, onLoad, rows]);

  useEffect(() => {

    // debugger;

    if ( curFilterSortId === -1 || gvFilterSortId !== curFilterSortId || curOffset !== gvOffset || curShowRows !== gvShowRows ) {
      let nextFilterSortId = gvFilterSortId;
      if ( nextFilterSortId < 0 )
        nextFilterSortId = 0;
      setCurFilterSortId(nextFilterSortId);
      changeOffsetAndShowRows(gvOffset, gvShowRows);
    }
  }, [changeOffsetAndShowRows, gvOffset, gvShowRows, gvFilterSortId, curFilterSortId, curOffset, curShowRows]);


  // function toggleSortForColumn(fieldName) {
  //   const newSortFieldNames = [];
  //   let ascDescVal = 0;
  //   if ( fieldName in curSortFieldNames )
  //     ascDescVal = curSortFieldNames[fieldName].ascDescVal;
  //   ascDescVal = (ascDescVal + 2) % 3 - 1;
  //   if ( ascDescVal )
  //     newSortFieldNames[fieldName] = {ascDescVal: ascDescVal, index: 0};
  //   setCurSortFieldNames(newSortFieldNames);
  // }

  function toggleSortForColumn(fieldName) {

    //console.log("---- GridView toggleSortForColumn {fieldName}=", {fieldName});

    const newSortFieldNames = [];
    let ascDescVal = 0;
    const sortField = gvSortFieldNames.find(f=>f.fieldName === fieldName);
    if ( sortField )
      ascDescVal = sortField.ascending ? 1 : -1;
    ascDescVal = (ascDescVal + 2) % 3 - 1;
    if ( ascDescVal )
      newSortFieldNames[0] = {fieldName: fieldName, ascending: (ascDescVal > 0) }
    setCurSortFieldNames(newSortFieldNames);
    if ( onFilterSortChange ) 
      onFilterSortChange(null, newSortFieldNames, gvShowRows);
    if ( filterSortId === undefined )      
      setCurFilterSortId(-1);
    //changeOffsetAndShowRows(0, gvShowRows, true);      
    //console.log("---finish--- GridView toggleSortForColumn {fieldName}=", {fieldName});
  }


  //ყველა ველისათვის აუცილებლად უნდა იყოს განსაზღვრული შემდეგი თვისებები
  //visible - თუ საჭიროა, რომ სევტი გამოჩნდეს
  //caption - სათაური
  //fieldName - ველის სახელი, რომლითაც სტრიქონებიდან უნდა ამოვიღოთ უჯრის მნიშვნელობა

  //ერთერთ ველს უნდა ჰქონდეს 
  const keyCol = columns.find(f=>f.isKey);

  if ( !keyCol )
    return (
      <div>
        უნიკალური მნიშვნელობების სვეტი განსაზღვრული არ არის
      </div>
    );
  
  if ( !rows || rows.length === 0 )
    return (
      <div>
        მონაცემები არ არის
      </div>
    );

  return (
    <div id="gridview">
      <Table striped bordered hover responsive="sm" className="table table-sm table-bordered">
        <thead>
          <tr>
            {!!showCountColumn && <th><span>N</span></th>}
            {columns.filter(col => col.visible).map(col => {
              const caption = col.caption ? col.caption : "";
              const sortable = !!col.sortable;

              if ( sortable ) {
                let ascDescVal = 0;
                const sortField = gvSortFieldNames.find(f=>f.fieldName === col.fieldName);
                if ( sortField )
                  ascDescVal = sortField.ascending ? 1 : -1;

                let sortIconName = "sort";
                if ( ascDescVal > 0 )
                  sortIconName = "sort-up";
                else if ( ascDescVal < 0 )
                  sortIconName = "sort-down";
                return (<th key={caption}>
                  <Link to="#" onClick={() => toggleSortForColumn(col.fieldName)}>{caption} <FontAwesomeIcon icon={sortIconName} /></Link>
                </th>);
              }
              else {
                return (<th key={caption}><span>{caption}</span></th>);
              }
            })}
          </tr>
        </thead>
        <tbody>
          {rows.slice(gvOffset, gvOffset + gvShowRows).map((row, i) => {
            //console.log("GridView row=", row);
            const index = gvOffset + i + 1;
            const bl = (curscrollTo?.index === index);
            return(
              <tr key={row[keyCol.fieldName]} ref={ bl ? backLigth : null} >
                {!!showCountColumn && <td className={ bl ? "backLigth" : null} >{index}</td>}
                {columns.filter(col => col.visible).map(col => {
                  const fieldName = col.fieldName ? col.fieldName : "";
                  const value = fieldName ? row[fieldName] : "";
                  const changingFieldName = col.changingFieldName ? col.changingFieldName : "";
                  const changing = changingFieldName ? row[changingFieldName] : false;
                  if ( col.caption === "თოლია") {
                    //console.log("GridView col=", col);
                    //console.log("GridView changingFieldName=", changingFieldName);
                    //console.log("GridView changing=", changing);
                  }
                  return (
                    <td key={col.fieldName} className={ bl ? "backLigth" : null} >{
                      (col.control && React.isValidElement(col.control)) ? React.cloneElement(col.control, {value, index, offset: curOffset, showRows: curShowRows, changing, record: row}, null) : value 
                    }</td>);
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex align-items-center justify-content-center">
      <div className="form-inline"> გვერდზე ჩანაწერების რაოდენობა: <select className="ml-1 mr-1" onChange={(e) => { 
            e.preventDefault();
            const newValue = NzInt(e.target.value, gvShowRows);
            changeOffsetAndShowRows(gvOffset, newValue);
          }}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
         წანაცვლება: 
          <input className="ml-1 mr-1" autoComplete="off" type="number" style={{ width:`${curOffsetInputWith}px` }} value={gvOffset} min="0" onChange={(e)=>{
            e.preventDefault();
            const newValue = NzInt(e.target.value, gvOffset);
            changeOffsetAndShowRows(newValue, gvShowRows);
            }} />  
          </div>
          </div>

          <div className="d-flex align-items-center justify-content-center mt-3"> 
            <button className="btn-space btn btn-primary" onClick={(e)=>{
              e.preventDefault();
              changeOffsetAndShowRows(0, gvShowRows);
            }}><FontAwesomeIcon icon="angle-double-left" /></button>  
            <button className="btn-space btn btn-primary" onClick={(e)=>{
              e.preventDefault();
              changeOffsetAndShowRows(gvOffset - gvShowRows, gvShowRows);
            }}><FontAwesomeIcon icon="angle-left" /></button>  
            <span className="mr-1">ჩანაწერები: {gvOffset+1}-{(gvOffset + gvShowRows > gvAllRowsCount) ? gvAllRowsCount : gvOffset + gvShowRows} სულ: {gvAllRowsCount} </span>
            <button className="btn-space btn btn-primary" onClick={(e)=>{
              e.preventDefault();
              changeOffsetAndShowRows(gvOffset + gvShowRows, gvShowRows);
            }}><FontAwesomeIcon icon="angle-right" /></button>  
            <button className="btn-space btn btn-primary" onClick={(e)=>{
              e.preventDefault();
              changeOffsetAndShowRows(gvAllRowsCount - gvShowRows, gvShowRows);
            }}><FontAwesomeIcon icon="angle-double-right" /></button>  
          </div>
      <Row>
        { !!loading && <Loading />}
      </Row>
    </div>
  );
}

export default GridView