//configureStore.js
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as Alert from './AlertStore';
import * as Authentication from '../user/UserStore';
import * as NavMenu from '../menu/NavMenuStore';
import * as MasterData from '../masterdata/MasterDataStore';
import * as RightsStore from '../rights/RightsStore';
import * as ParamsStore from './ParamsStore';
import projectReducers from '../../project/store/projectReducers';

export default function configureStore(initialState) {
  const reducers = {
    alert: Alert.reducer,
    authentication: Authentication.reducer,
    masterData: MasterData.reducer,
    navMenu: NavMenu.reducer,
    rights: RightsStore.reducer,
    params: ParamsStore.reducer
  };

  const middleware = [
    thunk
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const appReducer = combineReducers({
    ...reducers, ...projectReducers
});

  const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      //ეს არის ვარიანტი, როცა ყველა საცავი გვინდა გავანულოთ
      //state = undefined; 
      //console.log("configureStore rootReducer USER_LOGOUT state before clear=", state);
      //ეს არის ვარიანტი, როცა რომელიღაცა საცავი, გვსურს რომ შევინარჩუნოთ
      const { authentication, params } = state;
      state = { authentication, params };
      //console.log("configureStore rootReducer USER_LOGOUT state=", state);
    }
    return appReducer(state, action);
  };

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
