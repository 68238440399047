//DerivationEdit.js
import React, {useEffect, useState, useCallback, useMemo} from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import * as yup from 'yup';

import WaitPage from '../../carcass/common/WaitPage';
import { useForman } from '../../carcass/common/MyHooks';
//import { useHistory } from "react-router-dom";
import EditorHeader from '../../carcass/editorParts/EditorHeader';
import OnePlaintextRow from '../editorParts/OnePlaintextRow';
import OneStrongLabel from '../editorParts/OneStrongLabel';
import OneSaveCancelButtons from '../../carcass/editorParts/OneSaveCancelButtons';
import OneErrorRow from '../../carcass/editorParts/OneErrorRow';
import OneComboBoxControl from '../../carcass/editorParts/OneComboBoxControl';
import BasesAndFreeMorphemes from './BasesAndFreeMorphemes';
import PhoneticsCombEditor from '../editorParts/PhoneticsCombEditor';
import StatusConfirmRejectPart from './StatusConfirmRejectPart';


import { createFormulaFormData } from '../overviews/FormulasModule';

import { actionCreators as DerivTreeActions } from './DerivationTreeStore';
import { actionCreators as alertActions } from '../../carcass/store/AlertStore';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as RootEditorActions } from './RootEditorStore';
import { actionCreators as FormulasActions } from '../overviews/FormulasStore';


const DerivationEdit = (props) => {

  //const history = useHistory();
  
  //1. იდენტიფიკატორი
  const [curDbrIdVal, setCurDbrIdVal] = useState(null); 
  const [currentRootId, setCurrentRootId] = useState(null);
  const [autoPhonetics, setAutoPhonetics] = useState(null);
  

  //2. კომპონენტის თვისებები
  const { alert, user, mdWorkingOnLoad, 
    derivationFormulasQuery, morphemeRanges, classifiers, morphemeGroups, derivationTypes, morphemesQuery, 
    morphemeRangesByDerivationTypes, phoneticsChangesQuery, phoneticsTypes,
    derivFormulasLoading, derivationFormulas, 
    savingDerivationBranch, loadingDerivationBranch, workingOnDeleteDerivationBranch, derivationBranchForEdit, 
    getOneDerivationBranchById, 
    DeleteFailure, alertError,
    rootLoading, checkLoadRootsByBranchId, checkLoadMdTables, checkLoadDerivFormulas,
    clearForConfirmRootsPagesMemo, clearMemo,
    confirmRejectFailure,
    clearTablesFromRepo, clearRootsByDerivation,
    //clearRoot,
    workingOnConfirmRejectDerivationBranchChange, confirmRejectDerivationBranchChange, clearConfirmRejectFailure

  } = props;

  //console.log("DerivationEdit props=", props);

  //3. ეს არის ის ცხრილები, რომელზეც მოქმედებს ეს კონკრეტული რედაქტორი
  const tableNamesForClear = useMemo(() => [], []);

  //4. ეს არის ის ცხრილები, რომლებიდანაც ინფორმაცია სჭირდება ამ რედაქტრს
  const tableNamesForLoad = useMemo(() => ["derivationFormulasQuery", "morphemeRanges", "classifiers", "morphemeGroups", "derivationTypes", 
    "morphemesQuery", "morphemeRangesByDerivationTypes", "phoneticsChangesQuery", "phoneticsTypes"], []);
                                                        

  useEffect(() => {
    checkLoadMdTables(tableNamesForLoad);
    checkLoadDerivFormulas();
  }, [checkLoadDerivFormulas, checkLoadMdTables, tableNamesForLoad]);
  //mdWorkingOnLoad, derivFormulasLoading


  // useEffect(() => {
  //   checkLoadMdTables(tableNamesForLoad);
  //   checkLoadDerivFormulas();
  // }, [tableNamesForLoad, checkLoadMdTables, checkLoadDerivFormulas]);

      //5. სარედაქტირებელი ობიექტის სქემა
  const yupSchema = yup.object().shape({
    derivationBranch: yup.object().shape({
      dbrId: yup.number().integer().default(0).required(),
      classifierId: yup.number().integer().default(0),
      derivationFormulaId: yup.number().integer().positive("დერივაციის მოდელი არჩეული უნდა იყოს")
        .required("დერივაციის მოდელი არჩეული უნდა იყოს").default(0),
      dbrBaseName: yup.string().nullable(true).default("")
    }),
    basePhoneticsCombDetails: yup.array().ensure()
      .of(yup.number().integer().positive("გამოყოფილ უჯრაში ფონეტიკური ცვლილება არჩეული უნდა იყოს").default(0)
      .required()),
    freeMorphemeIds: yup.array().ensure().of(yup.number().integer().positive().default(0).required()),
    derivationPredecessors: yup.array().ensure().of(yup.object().shape({
      baseNom: yup.number().integer().default(0).required(),
      parentBranchId: yup.number().integer().positive("წინაპარი არჩეული უნდა იყოს")
        .required("წინაპარი არჩეული უნდა იყოს").default(0),
      phoneticsChangeId: yup.number().transform((v, o) => o === null ? 0 : v).integer().min(0).default(0)
    })),
    formData: yup.object().shape({
      ranges: yup.array().ensure(),
      morphemes: yup.array().ensure().of(yup.number().integer().positive("მორფემა არჩეული უნდა იყოს").default(0).required())
    })
  });

  //console.log("DerivationEdit yupSchema=", yupSchema);

  //6. ფორმის მენეჯერი
  const [frm, changeField, getError, getAllErrors, clearToDefaults, setFormData] = useForman(yupSchema);

  function clearUsedTables(){
    clearMemo();
    clearTablesFromRepo(tableNamesForClear, tableNamesForLoad);
    //clearRoot(currentRootId);
    clearRootsByDerivation(curDbrIdVal);
    clearForConfirmRootsPagesMemo(); 
  }



  const copyMorphemsToMainData = useCallback((forForm) => {
    const newForm = {...forForm};
    newForm.freeMorphemeIds = newForm.formData.morphemes.map((mrpId) => {
      return mrpId ? morphemesQuery.find((f)=> f.mrpId === mrpId) : null;
    }).filter((f, index)=> f && newForm.formData.ranges[index].mrBaseNom === null && f.mrpNom).map(m=>m.mrpId);

    return newForm;
  },[morphemesQuery]);

  const setderivationFormula = useCallback((curForm, newderivationFormulaId) => {

    //თუ საჭირო ინფორმაცია ჯერ ჩატვირთული არ არის, მაშინ გაგრეძელებას აზრი არ აქვს
    if ( !derivationTypes || !morphemeRanges || !morphemesQuery || !morphemeRangesByDerivationTypes || !derivationFormulas )
      return;

    //დავამზადოთ ფორმის ახალი ობიექტი
    let newForm = {...curForm};

    //console.log("DerivationEdit setderivationFormula newForm=", newForm);

    //დერივაციის ფორმულის იდენტიფიკატორის მიხედვით მოხდეს დერივაციის ფორმულის პოვნა
    const derivationFormula = derivationFormulas.find(f=>f.dfId === newderivationFormulaId);
    if ( !derivationFormula ) {
      alertError("ფორმულა ვერ მოიძებნა");
      return;
    }

    //console.log("DerivationEdit setderivationFormula derivationFormula=", derivationFormula);


    //დერივაციის ტიპის პოვნას აზრი აქვს მხოლოდ იმ შემთხვევაში, თუ დერივაციიც ტიპების ცხრილი ჩატვირთულია
    //მოვძებნოთ დერივაციის ტიპის შესაბამისი ობიექტი
    const derivType = derivationTypes.find(f=>f.drtId === derivationFormula.derivationTypeId);
    if ( !derivType ) {
      alertError("დერივაციის ტიპი ვერ მოიძებნა");
      return;
    }

    //console.log("DerivationEdit setderivationFormula derivType=", derivType);

    //დერივაციის ტიპის მიხედვით დადგინდეს მორფემების ჯგუფი
    const morphemeGroup = morphemeGroups.find(mog => mog.mogId === derivType.morphemeGroupId);
    if ( !morphemeGroup ) {
      alertError("მორფემების ჯგუფი ვერ მოიძებნა");
      return;
    }

    //console.log("DerivationEdit setderivationFormula morphemeGroup=", morphemeGroup);

     //დავადგინოთ ფორმულაში არსებული მორფემების რანგები და ამოვარჩიოთ მათგან მხოლოდ ისინი, 
     //რომლებიც არჩევითია და მითითებულია (-1) , ან ფუძეა და მითითებულია (1)
     const selectableRangeIds = derivationFormula.derivationFormulaDetails.map(m=> {
       const morpheme = morphemesQuery.find(f=>f.mrpId === m.morphemeId);
       const range = morphemeRanges.find(f=>f.mrId === morpheme.morphemeRangeId);
       return {morpheme, range};
      }).filter(f => f.range.morphemeGroupId === morphemeGroup.mogId && 
          ((f.range.mrSelectable && f.morpheme.mrpNom === -1) || 
            (f.range.mrBaseNom !== null && f.morpheme.mrpNom === 1)) )
      .map(m=>m.range.mrId);

      //console.log("DerivationEdit setderivationFormula selectableRangeIds=", selectableRangeIds);

      setAutoPhonetics(morphemeGroup.mogAutoPhonetics);

      //ამოვკრიბოთ იმ რანგების შესახებ ინფორმაცია, რომლებიც მონაწილეობენ ამ ტიპის დერივაციის ფორმირებაში
    const morphemeRangeIdsByDT = morphemeRangesByDerivationTypes
      .filter(f=>f.derivationTypeId === derivationFormula.derivationTypeId)
      .map(m=>m.morphemeRangeId);

      //console.log("DerivationEdit setderivationFormula morphemeRangeIdsByDT=", morphemeRangeIdsByDT);


    //დერივაციის ტიპში მითითებული ჯგუფის მიხედვით ამოვკრიბოთ რანგების შესაბამისი ობიექტები
    //თან გავითვალისწინოთ რომ ამ დერივაციის ტიპში ეს რანგი უნდა მონაწილეობდეს თუ არა
    const rangesInGroup = morphemeRanges
      .filter(f=>f.morphemeGroupId === derivType.morphemeGroupId && 
        ( f.mrBaseNom !== null || f.mrSelectable ) && 
        morphemeRangeIdsByDT.includes(f.mrId) && 
        selectableRangeIds.includes(f.mrId) )
      .sort((a,b) => a.mrPosition-b.mrPosition);

      //console.log("DerivationEdit setderivationFormula rangesInGroup=", rangesInGroup);



      //დავაფიქსიროთ დერივაციის ტიპი
    newForm.derivationBranch.derivationFormulaId = newderivationFormulaId;

    newForm.formData = createFormulaFormData(newForm.formData, rangesInGroup, newForm.freeMorphemeIds, morphemesQuery, true);
    
    //console.log("DerivationEdit setderivationFormula newForm=", newForm);

    //console.log("DerivationFormulaEdit setDerivationType newForm=", newForm);
    newForm = copyMorphemsToMainData(newForm);
    
    return newForm;

  },[derivationFormulas, derivationTypes, morphemeGroups, morphemeRanges, morphemesQuery, 
    morphemeRangesByDerivationTypes, copyMorphemsToMainData, alertError]);
  //



  const fromParamsRootId = props.match.params.rootId;
  const fromParamsDbrId = props.match.params.dbrId;

  

  //7. იდენტიფიკატორის ეფექტი
  useEffect(() => {

    //დავადგინოთ ძირის იდენტიფიკატორი
    const rootIdVal = fromParamsRootId ? parseInt(fromParamsRootId) : 0;
    if (currentRootId !== rootIdVal)
      setCurrentRootId(rootIdVal);

      //დავადგინოთ დერივაციის იდენტიფიკატორი
    const dbrIdVal = fromParamsDbrId ? parseInt(fromParamsDbrId) : 0;

    //ვამოწმებთ მისამართში შეიცვალა თუ არა იდენტიფიკატორი
    if (curDbrIdVal !== dbrIdVal) { //შეცვლილა
      //დავიმახსოვროთ შეცვლილი იდენტიფიკატორი
      setCurDbrIdVal(dbrIdVal);
      if (dbrIdVal) { //თუ იდენტიფიკატორი კარგია, ჩავტვირთოთ ბაზიდან ინფორმაცია
        //console.log("DerivationEdit getOneDerivationBranchById dbrIdVal=", dbrIdVal);
        getOneDerivationBranchById(dbrIdVal);
        checkLoadRootsByBranchId(dbrIdVal);
        return;
      }

      //ახალი სარედაქტირებელი ობიექტის შექმნა
      clearToDefaults();
      return;
    }

    //აქ თუ მოვედით, ესეიგი იდენტიფიკატორი იგივეა და კომპონენტის თვისებები შეიცვალა
    //ანუ სავარაუდოდ ჩატვირთვა დამთავრდა
    //თუმცა მაინც უნდა დავრწმუნდეთ
    //ან თუ ახალი ჩანაწერი იქნება, ჩატვირთვას არ ველოდებით
    //ან თუ ჩატვირთვა კი დასრულდა, მაგრამ ჩატვირთულიობიექტი მაინც შევამოწმოთ
    if ( loadingDerivationBranch || !fromParamsDbrId || !derivationBranchForEdit || 
      mdWorkingOnLoad || !derivationTypes || !morphemeGroups || !morphemeRanges || !classifiers || !morphemesQuery || !morphemeRangesByDerivationTypes || 
      derivFormulasLoading || !derivationFormulas ||
      rootLoading )
      return;

    const newForm = setderivationFormula(derivationBranchForEdit, derivationBranchForEdit.derivationBranch.derivationFormulaId);
    setFormData(newForm);


  }, [clearToDefaults, currentRootId, curDbrIdVal, fromParamsRootId, fromParamsDbrId, getOneDerivationBranchById, loadingDerivationBranch, 
    derivationBranchForEdit, setFormData, derivationTypes, morphemeGroups, morphemeRanges, classifiers,
    morphemeRangesByDerivationTypes, morphemesQuery, derivFormulasLoading, derivationFormulas, mdWorkingOnLoad, 
    setderivationFormula, rootLoading, checkLoadRootsByBranchId]);

  //8. ჩატვირთვის შემოწმება
  //თუ იდენტიფიკატორი წესიერია და ჩატვირთული ობიექტი ჯერ არ არის, ან საჭირო ცხრილები ჩატვირთული არ არის
  if ( (curDbrIdVal && !derivationBranchForEdit) || !derivationFormulasQuery || !morphemeRanges || !classifiers || !morphemeGroups || 
    !derivationTypes || !morphemesQuery || !morphemeRangesByDerivationTypes || !phoneticsChangesQuery || !phoneticsTypes || 
    !derivationFormulas ) { 
    if ( loadingDerivationBranch || mdWorkingOnLoad || derivFormulasLoading || rootLoading ) //თუ ინფორმაციის ჩატვირთვა ჯერ კიდევ მიმდინარეობა
      return (<WaitPage />);
      return (
        <div>
          <h5>ჩატვირთვის პრობლემა</h5>
          {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        </div>
      );
  }

  //9. შეცდომების შესახებ ინფორმაცია გამოიყენება საბმიტის ფუნქციაში
  const allErrors = getAllErrors();
  const haveErrors = (allErrors !== "");

  //10. საბმიტის ფუნქცია
  function handleSubmit(e) {
    e.preventDefault();
    props.clearAlert();
    if (haveErrors)
      return;

//გადავიტანოთ ფორმის მონაცემები ძირითად მონაცემებში
//წავშალოთ ფორმის ინფორმაცია, რადგან ის საჭირო არ არის შენახვისას

    const currentForm = {...frm};
    delete currentForm.formData;

    currentForm.derivationPredecessors.forEach((pred, index, arr)=>{
      if ( pred.phoneticsChangeId === 0 )
        arr[index].phoneticsChangeId = null;
    })

    if ( curDbrIdVal )
      props.updateDerivationBranch(props.history, currentForm, currentRootId);
    else
      props.createDerivationBranch(props.history, currentForm, currentRootId);
    clearUsedTables();
  }

  const derivationBranchResultName = (frm && frm.derivationBranch && frm.derivationBranch.dbrBaseName) ? frm.derivationBranch.dbrBaseName : "";
  const userHasConfirmRight = user.appClaims.some(s=>s === 'Confirm');
  const allowEditAndDelete = (userHasConfirmRight && frm.derivationBranch && frm.derivationBranch.recordStatusId !== 1 && frm.derivationBranch.recordStatusId !== 0) || 
    (!userHasConfirmRight && frm.derivationBranch.recordStatusId !== 1);


  return (
    <Row>
      <Col md="6">
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
        <Form onSubmit={handleSubmit} >
          <EditorHeader curIdVal={curDbrIdVal} EditorName="დერივაცია" EditorNameGenitive="დერივაციის"
            EditedObjectName={derivationBranchResultName}BasesAndFreeMorphemes
            workingOnDelete={workingOnDeleteDerivationBranch} DeleteFailure={DeleteFailure}
            onDelete={() => {props.deleteDerivationBranch(props.history, curDbrIdVal, currentRootId); clearUsedTables(); }}
            onClearDeletingFailure={() => {props.clearDeletingFailure();}} allowDelete={allowEditAndDelete} />

          { userHasConfirmRight && frm.derivationBranch.recordStatusId !== undefined && frm.derivationBranch.recordStatusId !== 2 && 
            <StatusConfirmRejectPart recordStatusId={frm.derivationBranch.recordStatusId} creator={frm.derivationBranch.creator} 
              workingOnConfirmReject={workingOnConfirmRejectDerivationBranchChange}
              confirmRejectFailure={confirmRejectFailure}
              onConfirmRejectClick={(confirm, withAllDescendants) => {
                confirmRejectDerivationBranchChange(props.history, curDbrIdVal, currentRootId, confirm, withAllDescendants); 
                clearUsedTables();
              }} 
              onClearConfirmRejectFailure={() => {clearConfirmRejectFailure();}} /> }

          <OnePlaintextRow controlId="result" label="შედეგი" 
            text={derivationBranchResultName} />          
          <OneStrongLabel controlId="mainParametersLabel" label="მთავარი პარამეტრები" />

          <OneComboBoxControl controlId="derivationBranch.classifierId" 
                  label="კლასიფიკატორი" value={frm.derivationBranch.classifierId} 
                  dataMember={classifiers} firstItem={{ id: 0, name: "არცერთი" }} 
                  valueMember="clfId" displayMember="clfName" sortByDisplayMember={true} getError={getError} 
                  firstItemIsSelectable 
                  onChangeValue={changeField} />

          <OneComboBoxControl controlId="derivationBranch.derivationFormulaId" 
            label="დერივაციის მოდელი" value={frm.derivationBranch.derivationFormulaId} 
            dataMember={derivationFormulasQuery} firstItem={{ id: 0, name: "აირჩიე დერივაციის მოდელი" }} 
            valueMember="dfId" displayMember="dfName" sortByDisplayMember={true} getError={getError} 
            onChangeValue={(fieldPath, value) => {
              const newForm = setderivationFormula(frm, value);
              setFormData(newForm);
            }} />

          { !!frm.derivationBranch.derivationFormulaId &&
          
            <div>

              <BasesAndFreeMorphemes ranges={frm.formData.ranges} morphemes={frm.formData.morphemes} 
                morphemesQuery={morphemesQuery} phoneticsTypes={phoneticsTypes} 
                phoneticsChangesQuery={phoneticsChangesQuery} morphemesdPath="frm.formData.morphemes" 
                getError={getError} selectPhoneticsType={false} autoPhonetics={autoPhonetics}
                predecessors={frm.derivationPredecessors} 
                onMorphemeChange={(index, value) => {
                  let newForm = {...frm};
                  newForm.formData.morphemes[index] = value;
                  newForm = copyMorphemsToMainData(newForm);
                  // //გავუგზავნოთ ახალი ფორმა ფორმის მენეჯერს.
                  setFormData(newForm);
                } }
                onPredecessorChange={(baseNom, item) => {
                  //console.log("DerivationEdit.js BasesAndFreeMorphemes onPredecessorChange {baseNom, item}=", {baseNom, item})
                  const newForm = {...frm};

                  let predByBase = newForm.derivationPredecessors.find((pred) => pred.baseNom === baseNom);
                  if (item) {
                    if (!predByBase) {
                      predByBase = {};
                      predByBase.baseNom = baseNom;
                      predByBase.parentBranchId = item.dbrId;
                      predByBase.phoneticsChangeId = null;
                      newForm.derivationPredecessors.push(predByBase);
                    } else {
                      predByBase.parentBranchId = item.dbrId;
                    }
                  } else {
                    if (predByBase) {
                      newForm.derivationPredecessors = newForm.derivationPredecessors.filter((pred) => pred.baseNom !== baseNom);
                    }
                  }
                  setFormData(newForm);

                }} 
                onPredecessorPhoneticsTypeChange={(baseNom, value) => {
                  const newForm = {...frm};
                  const predByBase = newForm.derivationPredecessors.find((pred) => pred.baseNom === baseNom);
                  if (!predByBase)
                    return;
                  predByBase.phoneticsChangeId = value;
                  setFormData(newForm);
                }}
                />

              <PhoneticsCombEditor controlGroupId="basePhoneticsCombDetails"  label="შედეგის ფონეტიკური შესაძლებლობები"
                basePhoneticsChanges={frm.basePhoneticsCombDetails} phoneticsChangesQuery={phoneticsChangesQuery} 
                getError={getError} onChangeValue={changeField} onTrashButtonClick={(index) => {
                  const newFrm = {...frm};
                  newFrm.basePhoneticsCombDetails.splice(index,1);
                  setFormData(newFrm);
                }} onPlusButtonClick={() => {
                  const newFrm = {...frm};
                  newFrm.basePhoneticsCombDetails.push(0);
                  setFormData(newFrm);
                }} />

            </div>
          
          }
          <OneSaveCancelButtons curIdVal={curDbrIdVal} haveErrors={haveErrors} savingNow={savingDerivationBranch} onCloseClick={() => { 
            props.clearAlert(); props.history.goBack(); }} allowEdit={allowEditAndDelete} />
          <OneErrorRow allErrors={allErrors} />

        </Form>
      </Col>
    </Row>
  );


}



function mapStateToProps(state) {
  const { mdWorkingOnLoad } = state.masterData;
  const { derivationFormulasQuery, morphemeRanges, classifiers, morphemeGroups, derivationTypes, morphemesQuery, 
    morphemeRangesByDerivationTypes, phoneticsChangesQuery, phoneticsTypes } = state.masterData.mdRepo;
  const { savingDerivationBranch, loadingDerivationBranch, workingOnDeleteDerivationBranch, 
    derivationBranchForEdit, getOneDerivationBranchById, DeleteFailure,
    confirmRejectFailure, workingOnConfirmRejectDerivationBranchChange } = state.rootEditorStore;
  const alert = state.alert;
  const { user } = state.authentication;
  const { derivFormulasLoading, derivationFormulas } = state.formulasStore;
  const { rootLoading } = state.derivTree;
  return { alert, user, mdWorkingOnLoad, 
    derivationFormulasQuery, morphemeRanges, classifiers, morphemeGroups, derivationTypes, morphemesQuery, 
    morphemeRangesByDerivationTypes, phoneticsChangesQuery, phoneticsTypes, 
    derivFormulasLoading, derivationFormulas,
    savingDerivationBranch, loadingDerivationBranch, workingOnDeleteDerivationBranch, derivationBranchForEdit, 
    getOneDerivationBranchById, DeleteFailure,
    confirmRejectFailure, workingOnConfirmRejectDerivationBranchChange,
    rootLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    getOneDerivationBranchById: (dbrId) => dispatch(RootEditorActions.getOneDerivationBranchById(dbrId, true)),
    updateDerivationBranch: (history, derivationBranchForSave, rootId) => dispatch(RootEditorActions.updateDerivationBranch(history, derivationBranchForSave, rootId)),
    createDerivationBranch: (history, derivationBranchForSave, rootId) => dispatch(RootEditorActions.createDerivationBranch(history, derivationBranchForSave, rootId)),
    deleteDerivationBranch: (history, dbrId, rootId) => dispatch(RootEditorActions.deleteDerivationBranch(history, dbrId, rootId)),
    clearDeletingFailure: () => dispatch(RootEditorActions.clearDeletingFailure()),
    clearTablesFromRepo: (tableNamesForClear, tableNamesForLoad) => dispatch(MasterDataActions.clearTablesFromRepo(tableNamesForClear, tableNamesForLoad)),
    clearAlert: () => dispatch(alertActions.clear()),
    //clearRoot: (rootId) => dispatch(DerivTreeActions.clearRoot(rootId)),
    clearRootsByDerivation: (dbrId) => dispatch(DerivTreeActions.clearRootsByDerivation(dbrId)),
    clearMemo: () => dispatch(DerivTreeActions.clearMemo()),
    clearForConfirmRootsPagesMemo: () => dispatch(DerivTreeActions.clearForConfirmRootsPagesMemo()),
    checkLoadDerivFormulas: () => dispatch(FormulasActions.checkLoadDerivFormulas()),
    checkLoadRootsByBranchId: (dbrId) => dispatch(DerivTreeActions.checkLoadRootsByBranchId(dbrId)),
    alertError: (errorMessage) => dispatch(alertActions.error(errorMessage)),

    confirmRejectDerivationBranchChange: (history, dbrId, rootId, confirm, withAllDescendants) => dispatch(RootEditorActions.confirmRejectDerivationBranchChange(history, dbrId, rootId, confirm, withAllDescendants)),
    clearConfirmRejectFailure: () => dispatch(RootEditorActions.clearConfirmRejectFailure()),

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DerivationEdit);
