//OneComboBoxControl.js
import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BsComboBox from '../../carcass/masterdata/BsComboBox';


const OneComboBoxControl = (props) => {

  const { controlId, label, value, dataMember, valueMember, displayMember, 
    secondDisplayMember, firstItem, firstItemIsSelectable, sortByDisplayMember, onTrashButtonClick,
    getError, onChangeValue } = props;

  //console.log("OneComboBoxControl props=", props);
  //console.log("OneComboBoxControl sortByDisplayMember=", sortByDisplayMember);

  const error = getError(controlId);
  return (
    <Form.Group key={controlId} className="mb-1" as={Row} controlId={controlId}>
      <Form.Label column sm="4" className="text-right">{label}</Form.Label>
      <Col sm="6">
        <BsComboBox name={controlId} dataMember={dataMember} valueMember={valueMember} displayMember={displayMember}
          secondDisplayMember={secondDisplayMember}
          value={value}
          error={error}
          onChangeValue={onChangeValue} 
          firstItem={firstItem}
          firstItemIsSelectable={!!firstItemIsSelectable}
          sortByDisplayMember={sortByDisplayMember} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
      {!!onTrashButtonClick && 
        <Col sm="2">
          <Button variant="danger" onClick={(e) => {
            e.preventDefault();
            onTrashButtonClick();
          }}>
            <FontAwesomeIcon icon="trash" />
          </Button>
        </Col>
      }
    </Form.Group>
  );
}

export default OneComboBoxControl;
