//src/project/recounters/RecountsStore.js
import apiClient from '../../carcass/modules/ApiClient';

const requestRecountBases = 'REQUEST_RECOUNT_BASES';
const RecountBasesStartedSuccess = 'RECOUNT_BASES_STARTED_SUCCESS';
const RecountBasesFailure = 'RECOUNT_BASES_FAILURE';

const requestRecountInflectionSamples = 'REQUEST_RECOUNT_INFLECTION_SAMPLES';
const RecountInflectionSamplesStartedSuccess = 'RECOUNT_INFLECTION_SAMPLES_STARTED_SUCCESS';
const RecountInflectionSamplesFailure = 'RECOUNT_INFLECTION_SAMPLES_FAILURE';

const requestRecountFindDerivationBranchesWithoutDescendants = 'REQUEST_RECOUNT_FIND_DERIVATION_BRANCHES_WITHOUT_DESCENDANTS';
const RecountFindDerivationBranchesWithoutDescendantsStartedSuccess = 'RECOUNT_FIND_DERIVATION_BRANCHES_WITHOUT_DESCENDANTS_STARTED_SUCCESS';
const RecountFindDerivationBranchesWithoutDescendantsFailure = 'RECOUNT_FIND_DERIVATION_BRANCHES_WITHOUT_DESCENDANTS_FAILURE';


const initialState = { rcWorkingOnRecountBases: false, failureRecountBases: false, 
  rcWorkingOnRecountInflectionSamples: false, failureRecountInflectionSamples: false, 
  rcWorkingOnRecountFindDerivationBranchesWithoutDescendants: false, failureRecountFindDerivationBranchesWithoutDescendants: false };

  export const actionCreators = {

  RecountBases: () => async (dispatch, getState) => {
    //console.log('RecountsStore.js apiClient.Post /recount/bases');
    await apiClient.Post('/recount/bases',
      { get: requestRecountBases, set: RecountBasesStartedSuccess, failure: RecountBasesFailure },
      dispatch, getState);
  },

  RecountInflectionSamples: () => async (dispatch, getState) => {
    //console.log('RecountsStore.js apiClient.Post /recount/inflectionsamples');
    await apiClient.Post('/recount/inflectionsamples',
      { get: requestRecountInflectionSamples, set: RecountInflectionSamplesStartedSuccess, failure: RecountInflectionSamplesFailure },
      dispatch, getState);
  },

  RecountFindDerivationBranchesWithoutDescendants: () => async (dispatch, getState) => {
    //console.log('RecountsStore.js apiClient.Post /recount/findderivationbrancheswithoutdescendants');
    await apiClient.Post('/recount/findderivationbrancheswithoutdescendants',
      { get: requestRecountFindDerivationBranchesWithoutDescendants, set: RecountFindDerivationBranchesWithoutDescendantsStartedSuccess, failure: RecountFindDerivationBranchesWithoutDescendantsFailure },
      dispatch, getState);
  }

}

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestRecountBases) {
    return { ...state, rcWorkingOnRecountBases: true };
  }

  if (action.type === RecountBasesStartedSuccess) {
    return { rcWorkingOnRecountBases: false, failureRecountBases: false };
  }

  if (action.type === RecountBasesFailure) {
    return { ...state, rcWorkingOnRecountBases: false, failureRecountBases: true };
  }

  if (action.type === requestRecountInflectionSamples) {
    return { ...state, rcWorkingOnRecountInflectionSamples: true };
  }

  if (action.type === RecountInflectionSamplesStartedSuccess) {
    return { rcWorkingOnRecountInflectionSamples: false, failureRecountInflectionSamples: false };
  }

  if (action.type === RecountInflectionSamplesFailure) {
    return { ...state, rcWorkingOnRecountInflectionSamples: false, failureRecountInflectionSamples: true };
  }


  if (action.type === requestRecountFindDerivationBranchesWithoutDescendants) {
    return { ...state, rcWorkingOnRecountFindDerivationBranchesWithoutDescendants: true };
  }

  if (action.type === RecountFindDerivationBranchesWithoutDescendantsStartedSuccess) {
    return { rcWorkingOnRecountFindDerivationBranchesWithoutDescendants: false, failureFindDerivationBranchesWithoutDescendants: false };
  }

  if (action.type === RecountFindDerivationBranchesWithoutDescendantsFailure) {
    return { ...state, rcWorkingOnRecountFindDerivationBranchesWithoutDescendants: false, failureFindDerivationBranchesWithoutDescendants: true };
  }

  return state;

};

