//OneSaveCancelButtons.js
import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const OneSaveCancelButtons = (props) => {

  const { curIdVal, haveErrors, savingNow, onCloseClick, allowEdit } = props;

  return (
    <Row className="mb-1 mt-1" >
      <Col sm="10" align="right">
        { allowEdit && 
          <Button type="submit" className="mr-1" disabled={haveErrors}>
            <FontAwesomeIcon icon="save" />
            {curIdVal ? ' შენახვა' : ' შექმნა'}
            {savingNow && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          </Button>
        }
        { !!onCloseClick &&
        <Button variant="secondary" onClick={(e) => {
          e.preventDefault(); onCloseClick();
          }}><FontAwesomeIcon icon="window-close" /> უარი</Button>
        }
      </Col>
    </Row>
);
}

export default OneSaveCancelButtons;
