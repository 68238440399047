import moment from 'moment';

// export function GetDisplayValue(masterData, mdItem, col) {

//   //console.log("GetDisplayValue START masterData=", masterData);
//   //console.log("GetDisplayValue mdItem=", mdItem);
//   //console.log("GetDisplayValue col=", col);

//   const value = mdItem[col.fieldName];

//   //console.log("GetDisplayValue value=", value);

//   const { dataMember, valueMember, displayMember, rowSource, columnType } = col;

//   if ( columnType === "CheckBox" ) {
//     if ( value === true )
//       return "დიახ";
//     if ( value === false )
//       return "არა";
//   }

//   if ( !!dataMember && !!valueMember && !!displayMember && dataMember in masterData.mdRepo && !!masterData.mdRepo[dataMember]) {
//     const fval = masterData.mdRepo[dataMember].find((mdItm) => mdItm[valueMember] === value);
//     if (!!fval && !!fval[displayMember])
//       return fval[displayMember];
//   }

//   if ( !!rowSource ) {
//     var rsarr = rowSource.split(";");
//     var ind = rsarr.indexOf(value.toString()) + 1;
//     if ( ind > 0 && ind < rsarr.length && !! rsarr[ind] )
//       return rsarr[ind];
//   }


//   //console.log("GetDisplayValue fval=", fval);
//   //console.log("GetDisplayValue FINISH fval[displayMember]=", fval[displayMember]);
//   return value;

// }


export function GetDisplayValue(masterData, mdItem, col) {

  //console.log("GetDisplayValue mdItem=", mdItem);
  //console.log("GetDisplayValue mdItem=", col.fieldName);

  const value = mdItem[col.fieldName];

  const { dataMember } = col;
  if ( dataMember && (!(dataMember in masterData.mdRepo) || !masterData.mdRepo[dataMember]) )
    return value;
  const dataTable = masterData.mdRepo[dataMember];
  return GetDisplayValue2(dataTable, value, col);
}


export function GetDisplayValue2(dataTable, value, col) {

  if ( !col )
    return value;
  
  if ( col.typeName === "Boolean" ) {
    if ( value === true )
      return "დიახ";
    if ( value === false )
      return "არა";
  }

  if ( col.typeName === "Date" ) {
    if ( !col.showDate && ! col.showTime )
    return value;

    const strFormat = `${col.showDate ? "DD-MMM-YYYY" : ""} ${col.showTime ? "HH:mm:ss" : ""}`
    return moment(value).format(strFormat);
  }

  if ( col.typeName === "Lookup" ) {

    if ( !!col.valueMember && !!col.displayMember && !!dataTable) {
      const fval = dataTable.find((mdItm) => mdItm[col.valueMember] === value);
      if (!!fval && !!fval[col.displayMember])
        return fval[col.displayMember];
    }

    if ( !!col.rowSource ) {
      //console.log("GetDisplayValue2 col.rowSource=", col.rowSource);
      var rsarr = col.rowSource.split(";");
      //console.log("GetDisplayValue2 rsarr=", rsarr);
      //console.log("GetDisplayValue2 value=", value);
      var ind = rsarr.indexOf(value.toString()) + 1;
      if ( ind > 0 && ind < rsarr.length && !! rsarr[ind] )
        return rsarr[ind];
    }
  }

  return value;

}