//BsComboBox.js
import React from 'react';
import { Form } from 'react-bootstrap';

import { NzInt } from '../../carcass/common/myFunctions'

const BsComboBox = (props) => {

  const {name, dataMember, valueMember, displayMember, secondDisplayMember, value, error, defaultValue, onChangeValue, 
    firstItem, firstItemIsSelectable, sortByDisplayMember} = props;

  //console.log("BsComboBox props=", props);

  const defValue = ( defaultValue || defaultValue === 0 ) ? defaultValue : -1;
  const needSort = sortByDisplayMember === undefined ? true : sortByDisplayMember;

  let dataArray = null;
  if ( Array.isArray(dataMember) ) { 
    //მოწოდებულია მასივი და მასტერდატაში ნახვა საჭირო აღარ არის.
    dataArray = dataMember;
  } 
  // else if ( typeof dataMember === 'string' || dataMember instanceof String ) { 
  //   //მოწოდებულია სტრიქონი, რომელიც არის მასტერდატას ცხრილის სახელი
  //   dataArray = masterData.mdRepo[dataMember];
  // }
  if ( needSort && dataArray )
    dataArray = dataArray.sort((a,b) => (!!a[displayMember]?a[displayMember]:"").localeCompare(!!b[displayMember]?b[displayMember]:""));

  return (

    <Form.Control as="select" value={( value || value === 0 ) ? value.toString() : defValue.toString()} 
      className={!!error && "is-invalid"} 
      onChange={(e) => { 
        e.preventDefault();
        const newValue = NzInt(e.target.value, defValue);
        if ( firstItem && !firstItemIsSelectable && firstItem.id === newValue )
          return;
        onChangeValue(name, newValue);
      }} >
      { firstItem && <option key={firstItem.id} value={firstItem.id}>{firstItem.name}</option>}
      {dataArray && dataArray.map((mdItm) => (
        <option key={mdItm[valueMember]} 
          value={mdItm[valueMember]}>{mdItm[displayMember]}{secondDisplayMember && `-${mdItm[secondDisplayMember]}`}</option>
      ))}
    </Form.Control>
  );

}

export default BsComboBox;


