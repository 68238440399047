import React, {useContext} from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Navbar, Button } from 'react-bootstrap';

import { actionCreators as NavMenuActions } from './NavMenuStore';
import RightsTopNavMenu from '../rights/RightsTopNavMenu';
import ProfileTopNavMenu from '../user/ProfileTopNavMenu';

import { ProjectContext } from '../../project/ProjectProvider';


const TopNavMenu = (props) => {

  const { TopNavRoutes } = useContext(ProjectContext);
  //const { TopNavRoutes } = value;

  return (
    <Navbar bg="light" expand="lg" >
      <Button className="btn-space" variant="info" onClick={props.toggleactive}>
        <FontAwesomeIcon icon="align-left" />
        <span> მენიუ</span>
      </Button>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">

        <Route path='/Rights/:rView?/:dtKey?/:key?' component={RightsTopNavMenu} />
        <Route path='/profile' component={ProfileTopNavMenu} />
        
        <TopNavRoutes />

      </Navbar.Collapse>
    </Navbar>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    toggleactive: () => dispatch(NavMenuActions.toggleactive())
  };
}

export default connect(
  null,
  mapDispatchToProps
)(TopNavMenu);
