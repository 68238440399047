//CreateVerbPersonMarkerCombinationFormulaDetails.js
import React, {useEffect, useMemo, useCallback} from 'react';
import { connect } from 'react-redux';
import { Table, Alert } from 'react-bootstrap'

import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
import { actionCreators as FormulasActions } from './FormulasStore';

import { NzInt, filterByHeader } from '../../carcass/common/myFunctions';
import BsComboBox from '../../carcass/masterdata/BsComboBox';
import { useScroller } from '../../carcass/common/MyHooks';
import NameListEditor from './NameListEditor';
import Loading from '../../carcass/common/Loading';
import { useForman } from '../../carcass/common/MyHooks';
import * as yup from 'yup';
import { getFormulaVisual2 } from './FormulasModule';
import { GetDisplayValue2 } from '../../carcass/modules/GetDisplayValue'



const CreateVerbPersonMarkerCombinationFormulaDetails = (props) => {

  const { alert, isMenuLoading, flatMenu, masterData, verbPersonMarkerCombinationFormulasLoading, verbPersonMarkerCombinationFormulas,
    checkLoadMdTables, checkloadVerbPersonMarkerCombinationFormulas } = props;

    const { mdWorkingOnLoad, datatypesLoading, datatypes } = props.masterData;
  const { morphemeRanges, morphemesQuery, inflectionBlocks, inflectionTypes, morphemeRangesByInflectionBlocks,
    verbPluralityTypes, verbPersonMarkerParadigms, verbTypes, verbSeries } = props.masterData.mdRepo;

  //console.log("CreateVerbPersonMarkerCombinationFormulaDetails props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["morphemeRanges", "morphemesQuery", "inflectionBlocks", 
    "inflectionTypes", "morphemeRangesByInflectionBlocks", 
    "verbPluralityTypes", "verbPersonMarkerParadigms", "verbTypes", "verbSeries"], []);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    checkLoadMdTables(tableNamesForLoad);
  }, [isMenuLoading, flatMenu, isValidPage, checkLoadMdTables, tableNamesForLoad]);

  useEffect(() => {
    checkloadVerbPersonMarkerCombinationFormulas(1,1,1,1);
  }, [checkloadVerbPersonMarkerCombinationFormulas]);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recId: NzInt(props.match.params.recName), 
    recName: props.match.params.recName
  });

  const yupSchema = yup.object().shape({
    verbPluralityTypeId: yup.number().integer().default(1),
    verbPersonMarkerParadigmId: yup.number().integer().default(1),
    verbTypeId: yup.number().integer().default(1),
    verbSeriesId: yup.number().integer().default(1)
  });

  const [frm, changeField, getError, , , , ] = useForman(yupSchema);

  function saveReturnPageName() {
    props.saveReturnPageName(props.match.url.split('/')[1]);
  }

  if (mdWorkingOnLoad || verbPersonMarkerCombinationFormulasLoading || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }


  if ( !curscrollTo || 
    !morphemeRanges || !morphemesQuery || !inflectionBlocks || !inflectionTypes || !morphemeRangesByInflectionBlocks ||
    !verbPluralityTypes || !verbPersonMarkerParadigms || !verbTypes || !verbSeries || 
    !verbPersonMarkerCombinationFormulas || !datatypes) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }



  const inflectionBlock = inflectionBlocks.find(f=>f.inbKey === 'PersonMarkersBlock');
  if ( ! inflectionBlock ) {
    return (<h5>არასწორი ფლექსიის ბლოკი</h5>);
  }

  const inflectionType = inflectionTypes.find(f=>f.iftId === inflectionBlock.inflectionTypeId);
  if ( ! inflectionType ) {
    return (<h5>არასწორი ფლექსიის ტიპი</h5>);
  }

  const MorphemeRangeIdsByIT = morphemeRangesByInflectionBlocks
    .filter(f=>f.inflectionBlockId === inflectionBlock.inbId)
    .map(m=>m.morphemeRangeId);

  const rangesInGroup = morphemeRanges
    .filter(f=>f.morphemeGroupId === inflectionType.morphemeGroupId && MorphemeRangeIdsByIT.includes(f.mrId))
    .sort((a,b) => a.mrPosition-b.mrPosition);

  function onChangeFilterValue(fieldName, newValue) {
      changeField(fieldName, newValue);
      const tfrm = {...frm};
      tfrm[fieldName] = newValue;
      checkloadVerbPersonMarkerCombinationFormulas(frm.verbPluralityTypeId, frm.verbPersonMarkerParadigmId, frm.verbTypeId, frm.verbSeriesId);
    }

  const listEditorTableNames = ["verbPluralityTypes", "verbPersonMarkerParadigms", "verbTypes", "verbSeries"];

  return (
    <div>
      <h3>პირის ნიშნების ფორმულების დათვლის საბოლოო ფაზა</h3>
      <h4>ტერმინები</h4>
      <p><strong>ზმნის მრავლობითობის ტიპი</strong> - ზმნის მრავლობითობის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის ცალი პირის ნიშნების პარადიგმა</strong> - ზმნის ცალი პირის ნიშნების პარადიგმის განმარტება (გასაკეთებელია).</p>
      <p><strong>ზმნის ტიპი</strong> - ზმნის ტიპის განმარტება (გასაკეთებელია).</p>
      <p><strong>სერია</strong> - სერიის განმარტება (გასაკეთებელია).</p>

      {listEditorTableNames.map(tn => {
        const dataType = datatypes.find(f=>f.dtTable === tn);

        return (<NameListEditor dataType={dataType} tableForEdit={masterData.mdRepo[tn]} 
          curscrollTo={curscrollTo} backLigth={backLigth} saveReturnPageName={saveReturnPageName} />);
      } )}

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>ფორმულა</th>
            <th>ზმნის მრავლობითობის ტიპი
              <BsComboBox name="verbPluralityTypeId" value={frm ? frm.verbPluralityTypeId : null} 
                dataMember={verbPluralityTypes.sort((a, b) => a.sortId-b.sortId)} valueMember="vptId" 
                displayMember="vptName" getError={getError} sortByDisplayMember={false} 
                firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable  
                onChangeValue={onChangeFilterValue} />
            </th>
            <th>პირის ნიშნების პარადიგმა
              <BsComboBox name="verbPersonMarkerParadigmId" value={frm ? frm.verbPersonMarkerParadigmId : null} 
                dataMember={verbPersonMarkerParadigms.sort((a, b) => a.sortId-b.sortId)} valueMember="vpmpnId" 
                displayMember="vpmpnKey" getError={getError} sortByDisplayMember={false} 
                firstItem={ { id: -1, name: "ყველა" } } firstItemIsSelectable 
                onChangeValue={onChangeFilterValue} />
            </th>
            <th>ზმნის ტიპი
              <BsComboBox name="verbTypeId" value={frm ? frm.verbTypeId : null} 
                dataMember={verbTypes.sort((a, b) => a.sortId-b.sortId)} valueMember="vtpId" displayMember="vtpName" 
                getError={getError} sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } 
                firstItemIsSelectable onChangeValue={onChangeFilterValue} />
            </th>
            <th>სერია
              <BsComboBox name="verbSeriesId" value={frm ? frm.verbSeriesId : null} 
                dataMember={verbSeries.sort((a, b) => a.sortId-b.sortId)} valueMember="vsrId" displayMember="vsrName" 
                getError={getError} sortByDisplayMember={false} firstItem={ { id: -1, name: "ყველა" } } 
                firstItemIsSelectable onChangeValue={onChangeFilterValue} />
            </th>
          </tr>
        </thead>
        <tbody>
          {verbPersonMarkerCombinationFormulas.filter(f=>filterByHeader(f,frm))
            .sort((a, b) => a.vpmcId-b.vpmcId)
            .map((vpmc, index) => {
              const bl = (curscrollTo === vpmc.vpmcId);
              return (
                <tr key={vpmc.vpmcId.toString()} ref={ bl ? backLigth : null} >
                  <td className={ bl ? "backLigth" : null}>{vpmc.vpmcId}</td>
                  <td className={ bl ? "backLigth" : null}>{getFormulaVisual2(vpmc.verbPersonMarkerCombinationFormulaDetails.map(m=>m.morphemeId), rangesInGroup, morphemesQuery)}</td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbPluralityTypes, vpmc.verbPluralityTypeId, {valueMember: "vptId", displayMember: "vptName", typeName: "Lookup"})}
                  </td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbPersonMarkerParadigms, vpmc.verbPersonMarkerParadigmId, {valueMember: "vpmpnId", displayMember: "vpmpnKey", typeName: "Lookup"})}
                  </td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbTypes, vpmc.verbTypeId, {valueMember: "vtpId", displayMember: "vtpName", typeName: "Lookup"})}
                  </td>
                  <td className={ bl ? "backLigth" : null}>
                    {GetDisplayValue2(verbSeries, vpmc.verbSeriesId, {valueMember: "vsrId", displayMember: "vsrName", typeName: "Lookup"})}
                  </td>
                </tr>);
            })}
        </tbody>
      </Table>


    </div>
  );
}
//    verbPluralityTypes, verbPersonMarkerParadigms, verbTypes, verbSeries,

function mapStateToProps(state) {
  const alert = state.alert;
  const { isMenuLoading, flatMenu } = state.navMenu;
  const masterData = state.masterData;
  const { verbPersonMarkerCombinationFormulasLoading, verbPersonMarkerCombinationFormulas } = state.formulasStore;

  return { alert, isMenuLoading, flatMenu, masterData, verbPersonMarkerCombinationFormulasLoading, verbPersonMarkerCombinationFormulas };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName)),
    checkloadVerbPersonMarkerCombinationFormulas: (verbPluralityTypeId, verbPersonMarkerParadigmId, verbTypeId, verbSeriesId) => dispatch(FormulasActions.checkloadVerbPersonMarkerCombinationFormulas(verbPluralityTypeId, verbPersonMarkerParadigmId, verbTypeId, verbSeriesId))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVerbPersonMarkerCombinationFormulaDetails);
