import React, {useEffect, useMemo, useCallback} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {Table, Alert} from 'react-bootstrap'

import Loading from '../../carcass/common/Loading';
import { actionCreators as MasterDataActions } from '../../carcass/masterdata/MasterDataStore';
//import { useTabRecscroller } from '../../carcass/common/MyHooks';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';

const MorphemesOverview = (props) => {

  const {alert, isMenuLoading, flatMenu, masterData, checkLoadMdTables } = props;

  const { mdWorkingOnLoad, datatypesLoading, datatypes } = masterData;
  const {morphemeGroups, morphemeRanges, morphemes, phoneticsTypes} = masterData.mdRepo;

  // const { mdWorkingOnLoad, morphemeGroups, morphemeRanges, morphemes, phoneticsTypes, checkLoadMdTables } = props;
  //console.log("MorphemesOverview props=", props);

  const menLinkKey = props.match.url.split('/')[1];

  const tableNamesForLoad = useMemo(() => ["morphemeGroups", "morphemeRanges", "morphemes", "phoneticsTypes"], []);

  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey);
  }, [flatMenu, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    checkLoadMdTables(tableNamesForLoad);
  }, [isMenuLoading, flatMenu, isValidPage, checkLoadMdTables, tableNamesForLoad]);

  //const [curTabKey, curRecName, backLigth] = useTabRecscroller(props.match.params.tabKey, props.match.params.recName);

  const [curscrollTo, backLigth] = useScroller({
    tabKey: props.match.params.tabKey, 
    recName: NzInt(props.match.params.recName)
  });  

  if (mdWorkingOnLoad || isMenuLoading || datatypesLoading) {
    return (<Loading />);
  }
  
  if (!morphemeGroups || !morphemeRanges || !morphemes || !phoneticsTypes || !curscrollTo || !datatypes ) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }
  
  const morphemeGroupsDataType = datatypes.find(f=>f.dtTable === "morphemeGroups");
  const morphemeRangesDataType = datatypes.find(f=>f.dtTable === "morphemeRanges");
  const morphemesDataType = datatypes.find(f=>f.dtTable === "morphemes");
  

  return (
    <div>
      <h3>მორფემების მიმოხილვა</h3>
      <h4>ტერმინები</h4>
      <p><strong>მორფემის ჯგუფი</strong> - მორფემის ჯგუფის განმარტება (გასაკეთებელია).</p>
      <p><strong>მორფემის რანგი</strong> - მორფემის რანგის განმარტება (გასაკეთებელია).</p>
      <p><strong>მორფემა</strong> - მორფემის განმარტება (გასაკეთებელია).</p>

  <h4>მორფემის ჯგუფები ({morphemeGroups.length}) {morphemeGroupsDataType.create && (<Link to= {`/mdItemEdit/morphemeGroups`}> + </Link>)}</h4>
      <ol>
        {morphemeGroups
          .sort((a,b) => a.mogName.localeCompare(b.mogName))
          .map(mog => { 
            const bl = (curscrollTo.tabKey === 'morphemeGroups' && curscrollTo.recName === mog.mogId);
            return (
              <li key={mog.mogId.toString()} ref={ bl ? backLigth : null} >
                {!morphemeGroupsDataType.update && !morphemeGroupsDataType.delete && mog.mogName}
                {(morphemeGroupsDataType.update || morphemeGroupsDataType.delete) && (<Link to={`/mdItemEdit/morphemeGroups/${mog.mogId}`} 
                  className={ bl ? "backLigth" : null} onClick={(e) => {
                  // e.preventDefault(); ეს საჭირო არ არის, რადგან კინკზე აღარ გადადის
                  //ვინახავთ გვერდის სახელს, საიდანაც მოხდა რედაქტორის გახსნა. იმისათვისმ რომ კორექტულად მოხდეს უკან დაბრუნება
                  props.saveReturnPageName(props.match.url.split('/')[1]);
                }} >{mog.mogName}</Link>)}
              </li>
            );
        })}
      </ol>

      <h4>მორფემის რანგები ჯგუფების მიხედვით ({morphemeRanges.length}) {morphemeRangesDataType.create && (<Link to= {`/mdItemEdit/morphemeRanges`}> + </Link>)}</h4>

      <p>მორფემის რანგის სახელი =&gt; კოდი</p>

      {morphemeGroups
          .sort((a,b) => a.mogName.localeCompare(b.mogName))
          .map(mog => { 
            const mrsf = morphemeRanges.filter((mr) => mr.morphemeGroupId === mog.mogId);
            return (
              <div key={mog.mogId.toString()}>
              <h5>{mog.mogName} ({mrsf.length})</h5>

              <ol>
                {mrsf
                  .sort((a,b) => a.mrPosition - b.mrPosition)
                  .map(mr => { 
                    const bl = (curscrollTo.tabKey === 'morphemeRanges' && curscrollTo.recName === mr.mrId);
                    return (<li key={mr.mrId.toString()} ref={ bl ? backLigth : null} >
                      {!morphemeRangesDataType.update && !morphemeRangesDataType.delete && mr.mrName}
                      {(morphemeRangesDataType.update || morphemeRangesDataType.delete) && (<Link to={`/mdItemEdit/morphemeRanges/${mr.mrId}`} 
                        className={ bl ? "backLigth" : null} onClick={(e) => {
                        // e.preventDefault(); ეს საჭირო არ არის, რადგან კინკზე აღარ გადადის
                        //ვინახავთ გვერდის სახელს, საიდანაც მოხდა რედაქტორის გახსნა. იმისათვისმ რომ კორექტულად მოხდეს უკან დაბრუნება
                        props.saveReturnPageName(props.match.url.split('/')[1]);
                      }} >{mr.mrName}</Link>)} =&gt; {mr.mrKey} {mr.mrBaseNom === null?"":"არის ფუძე"}{mr.mrBaseRequired?", დერივაციაში ან ფლექსიაში ამ ფუძის არჩევა აუცილებლად უნდა მოხდეს":""}{mr.mrSelectable || mr.mrInflectionSelectable?"თავისუფალი მორფემა":""}{mr.mrSelectable && mr.mrInflectionSelectable?" დერივაციაში და ფლექსიაში":(mr.mrSelectable?" დერივაციაში":"")+(mr.mrInflectionSelectable?" ფლექსიაში":"")}
                  </li>);
                })}
              </ol>

              
              </div>
            );
      })}

      <h4>მორფემები ({morphemes.length}) {morphemesDataType.create && (<Link to= {`/morphemeEdit`}> + </Link>)}</h4>

      {morphemeGroups
        .sort((a,b) => a.mogName.localeCompare(b.mogName))
        .map(mog => { 
          const mrsf = morphemeRanges.filter((mr) => mr.morphemeGroupId === mog.mogId);
          const mrsfmin = Math.min(...mrsf.map(mr => {
            const mfrnoms = morphemes.filter(mf=>mf.morphemeRangeId === mr.mrId).map(mf=>mf.mrpNom);
            //console.log("mfrnoms = ", mfrnoms);
            return Math.min(...mfrnoms);
          } ));
          const mrsfmax = Math.max(...mrsf.map(mr => {
            const mfrnoms = morphemes.filter(mf=>mf.morphemeRangeId === mr.mrId).map(mf=>mf.mrpNom);
            return Math.max(...mfrnoms);
          } ));
          const list = [];
          for (let i = mrsfmin; i <= mrsfmax; i++) {
            list.push(i);
          }
          //console.log("MorphemesOverview {mrsfmin, mrsfmax, list} = ", {mrsfmin, mrsfmax, list});
          return (

            <div key={mog.mogId.toString()}>
              <h5>{mog.mogName}</h5>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    {mrsf
                      .sort((a,b) => a.mrPosition - b.mrPosition)
                      .map(mr => { 
                        return (<th key={mr.mrId.toString()}>
                          {mr.mrKey}
                      </th>);
                    })}
                  </tr>
                </thead>
                <tbody>
                  {list.map(l=> {
                    return(
                      <tr key={`${mog.mogId}${l}`}>
                        <td>{l}</td>

                        {mrsf
                          .sort((a,b) => a.mrPosition - b.mrPosition)
                          .map(mr => { 
                            const morpheme = morphemes.find(m=>m.morphemeRangeId === mr.mrId && m.mrpNom === l);
                            if ( morpheme ) {
                              let phoneticsTypeName = null;
                              if ( morpheme.phoneticsTypeId ) {
                                const phoneticsType = phoneticsTypes.find(f=>f.phtId === morpheme.phoneticsTypeId);
                                if ( phoneticsType )
                                  phoneticsTypeName = phoneticsType.phtName;
                              }
                              const bl = (curscrollTo.tabKey === 'mrp' && curscrollTo.recName === morpheme.mrpId);
                              const morphemeShow = (morpheme.mrpMorpheme ? morpheme.mrpMorpheme: "_") +  (phoneticsTypeName ? ` (${phoneticsTypeName})`: "");
                              return (
                                <td key={mr.mrId.toString()} ref={ bl ? backLigth : null} >
                                  {!morphemesDataType.update && !morphemesDataType.delete && morphemeShow}
                                  {(morphemesDataType.update || morphemesDataType.delete) && (<Link to={`/morphemeEdit/${morpheme.mrpId}`} className={ bl ? "backLigth" : null} >
                                    {morphemeShow}
                                  </Link>)}
                                </td>
                              );
                            }
                            else
                              return (<td key={mr.mrId.toString()}> </td>);

                        })}


                      </tr>
                    );
                  })}

                </tbody>

              </Table>
            </div>
          );
      })}
    </div>
  );    


}

function mapStateToProps(state) {
  const alert = state.alert;
  const { isMenuLoading, flatMenu } = state.navMenu;
  const masterData = state.masterData;

  return {alert, isMenuLoading, flatMenu, masterData };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLoadMdTables: (tableNames) => dispatch(MasterDataActions.checkLoadMdTables(tableNames)),
    saveReturnPageName: (pageName) => dispatch(MasterDataActions.saveReturnPageName(pageName))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MorphemesOverview);
   