// import { lowerNormalizeOneTableName } from '../common/myFunctions';


// export function checkListDataLoaded(mdWorkingOnLoad, listTableName, listTable, listGridRules, datatypes) {

//   if (mdWorkingOnLoad) {
//     console.log("checkListDataLoaded 1");
//     return false;
//   }

//   if ( !datatypes ) {
//     console.log("checkListDataLoaded 2");
//     return false;
//   }

//   if ( datatypes.length === 0) {
//     console.log("checkListDataLoaded 3");
//     return false;
//   }

//   if ( !listTable ) {
//     console.log("checkListDataLoaded 5");
//     return false;
//   }

//   if (!listGridRules) {
//     console.log("checkListDataLoaded 9");
//     return false;
//   }
  
//   const dataType = datatypes.find(dt => { return dt.dtTable === listTableName; });

//   if ( !dataType )
//   {
//     console.log("checkListDataLoaded 10");
//     return false;
//   }

//   return dataType;
// }

export function checkDataLoaded(masterData, tableName, gridName) {

  console.log("checkDataLoaded 0 masterData=", masterData);

  if (masterData.mdWorkingOnLoad) {
    console.log("checkDataLoaded 1 steel loading");
    return false;
  }

  const datatypes = masterData.datatypes;
    
  if ( ! datatypes ) {
    console.log("checkDataLoaded 2 datatypes not loaded");
    return false;
  }

  if ( datatypes.length === 0) {
    console.log("checkDataLoaded 3 datatypes has not any element");
    return false;
  }

  if ( !(tableName in masterData.mdRepo) ) {
    console.log("checkDataLoaded 4 table not loaded tableName in masterData.mdRepo=", tableName in masterData.mdRepo);
    console.log("checkDataLoaded 4 table not loaded tableName=", tableName);
    console.log("checkDataLoaded 4 table not loaded masterData.mdRepo=", masterData.mdRepo);
    return false;
  }

  if (!masterData.mdRepo[tableName]) {
    console.log("checkDataLoaded 5 table is ampty");
    return false;
  }

  if ( !(gridName in masterData.gridRules) ) {
    console.log("checkDataLoaded 8 grid rules not found gridName=", gridName);
    return false;
  }

  if (!masterData.gridRules[gridName]) {
    console.log("checkDataLoaded 9 grid rules empty gridName=", gridName);
    return false;
  }
  
  const dataType = datatypes.find(dt => { return dt.dtTable === tableName; });

  if ( !dataType )
  {
    console.log("checkDataLoaded 10 datatype not found for table ", tableName);
    return false;
  }

  const gridRules = masterData.gridRules[gridName];

  console.log("checkDataLoaded 11 return {dataType, gridRules}", {dataType, gridRules});
  return {dataType, gridRules};
}


export function checkDataTypeLoaded(masterData, tableName) {

  //console.log("checkDataLoaded masterData=", masterData);

  if (masterData.mdWorkingOnLoad) {
    //console.log("checkDataLoaded 1 steel loading");
    return false;
  }

  const datatypes = masterData.datatypes;
    
  if ( ! datatypes ) {
    //console.log("checkDataLoaded 2 datatypes not loaded");
    return false;
  }

  if ( datatypes.length === 0) {
    //console.log("checkDataLoaded 3 datatypes has not any element");
    return false;
  }

  const dataType = datatypes.find(dt => { return dt.dtTable === tableName; });

  if ( !dataType )
  {
    //console.log("checkDataLoaded 10 datatype not found for table ", tableName);
    return false;
  }

  return dataType;
}


export function checkDataTableLoaded(masterData, tableName) {

  //console.log("checkDataLoaded masterData=", masterData);

  if (masterData.mdWorkingOnLoad) {
    //console.log("checkDataLoaded 1 steel loading");
    return false;
  }

  if ( !(tableName in masterData.mdRepo) ) {
    //console.log("checkDataLoaded 4 table not loaded tableName=", tableName);
    //console.log("checkDataLoaded 4 table not loaded masterData.mdRepo=", masterData.mdRepo);
    return false;
  }

  if (!masterData.mdRepo[tableName]) {
    //console.log("checkDataLoaded 5 table is ampty");
    return false;
  }

  return true;
}


export function checkGridLoaded(masterData, gridName) {

  //console.log("checkDataLoaded masterData=", masterData);

  if (masterData.mdWorkingOnLoad) {
    //console.log("checkDataLoaded 1 steel loading");
    return false;
  }

  if ( !(gridName in masterData.gridRules) ) {
    //console.log("checkDataLoaded 8 grid rules not found gridName=", gridName);
    return false;
  }

  if (!masterData.gridRules[gridName]) {
    //console.log("checkDataLoaded 9 grid rules empty gridName=", gridName);
    return false;
  }

  const gridRules = masterData.gridRules[gridName];

  return gridRules;
}

