import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';

import { actionCreators as MasterDataActions } from './MasterDataStore';
import { checkDataLoaded } from '../modules/CheckDataLoaded'
import Loading from '../common/Loading';
import { useScroller } from '../../carcass/common/MyHooks';
import { NzInt } from '../../carcass/common/myFunctions';
import MdListView from './MdListView';


const MdList = (props) => {

  const [curTableName, setCurTableName] = useState(null);
  const [curDataType, setCurDataType] = useState(null);
  const [curGridRules, setCurGridRules] = useState(null);
  const { isMenuLoading, flatMenu, masterData, mdWorkingOnLoad, deletingKey, mdWorkingOnSave, loadListData } = props;
  const tableName = props.match.params.dataType;
  const recId = NzInt(props.match.params.recName);
  const menLinkKey = props.match.url.split('/')[1];

  //const [curTabKey, curRecName, backLigth] = useTabRecscroller(null, props.match.params.recName);

  console.log("MdList props=", props);


  const isValidPage = useCallback(() => {
    if (!flatMenu) {
      return false;
    }
    return flatMenu.find(f=>f.menLinkKey === menLinkKey && f.menValue === tableName);
  }, [flatMenu, tableName, menLinkKey]);

  useEffect(() => {

    const menuItem = isValidPage();
    if ( ! menuItem )
      return;

    if (curTableName !== tableName) {
      setCurTableName(tableName);
      setCurDataType(null);
      setCurGridRules(null);
      loadListData(tableName);
      return;
    }

    if (mdWorkingOnLoad || deletingKey || mdWorkingOnSave) {
      return;
    }

    const checkResult = checkDataLoaded(masterData, tableName, tableName);
    console.log("MdList useEffect checkResult=", checkResult);
    if (checkResult) {
      const { dataType, gridRules } = checkResult;

      console.log("MdItemEdit useEffect new dataType=", dataType);
      setCurDataType(dataType);
      console.log("MdItemEdit useEffect new gridRules=", gridRules);
      setCurGridRules(gridRules);
    }

  }, [isMenuLoading, flatMenu, isValidPage, mdWorkingOnLoad, tableName, curTableName, masterData, deletingKey, mdWorkingOnSave, loadListData]); //, loading, loadingFailure, drParentsLoading, drChildsLoading, drWorkingOnSave

  const [curscrollTo, backLigth] = useScroller(recId);

  console.log("MdList before Check Loading {mdWorkingOnLoad, isMenuLoading}=", {mdWorkingOnLoad, isMenuLoading});

  if (mdWorkingOnLoad || isMenuLoading)
    return (<Loading />);

  console.log("MdList before Check Loading {curGridRules, curscrollTo, mdWorkingOnLoad}=", {curGridRules, curscrollTo, mdWorkingOnLoad});

  if (!curGridRules || curscrollTo === null || !flatMenu) {
    return (
      <div>
        <h5>ჩატვირთვის პრობლემა</h5>
        {alert.message && <Alert variant={alert.type}>{alert.message}</Alert>}
      </div>
    );
  }

  const curMasterDataTable = masterData.mdRepo[curTableName];
  // const gridColumns=  curGridRules.cells.sort((a,b)=>a.position-b.position);

  console.log("MdList before Rendering {curTableName, curMasterDataTable}=", {curTableName, curMasterDataTable});

  return (<MdListView dataType={curDataType} table={curMasterDataTable} gridColumns={curGridRules.cells} 
    masterData={masterData} curscrollTo={curscrollTo} backLigth={backLigth} />);

}

function mapStateToProps(state) {
  const masterData = state.masterData;
  const { mdWorkingOnLoad, deletingKey, mdWorkingOnSave } = state.masterData;
  const { isMenuLoading, flatMenu } = state.navMenu;

  return { isMenuLoading, flatMenu, masterData, mdWorkingOnLoad, deletingKey, mdWorkingOnSave };
}

function mapDispatchToProps(dispatch) {
  return {
    loadListData: (tableName) => dispatch(MasterDataActions.loadListData(tableName, tableName))
  };
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(MdList);